import React from "react";
import {Stack} from "../styled/layouts/Stack";
import {Title} from "../styled/texts/Title";
import {TopLogoImage} from "../styled/main/TopLogoImage";
import {StackDirection} from "../styled/layouts/stack/StackDirection";
import {StackAlignItems} from "../styled/layouts/stack/StackAlignItems";
import {TitleType} from "../styled/texts/title/TitleType";

export const MainLogo: React.FC = () => (
  <Stack
    direction={StackDirection.Row}
    alignItems={StackAlignItems.Center}
    gap={3}
    fullHeight
  >
    <TopLogoImage />
    <Title
      type={TitleType.H3}
    >
      Stats Cockpit
    </Title>
  </Stack>
);
