// region imports

import {StoreTools} from "../../tools/StoreTools";
import {TrialToSubscriptionsEntry} from "../../types/interfaces/data/TrialToSubscriptionsEntry";

// endregion

// region exports

/**
 * Trial to subscription conversions related values.
 */
export const trialToSubscriptionsSlice = StoreTools.createDataListForDateRangeSlice<TrialToSubscriptionsEntry>(
  'trial-to-subscriptions'
);

/**
 * The actions for the slice
 */
export const {
  initializeDashboard,
  setHistoryRange,
  setLoading,
  setDashboard,
  setHistoryEntries,
  resetDashboard,
} = trialToSubscriptionsSlice.actions;

// endregion
