// region imports

import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";
import React from "react";
import {PaperContainer} from "./PaperContainer";
import {BusyAnimation} from "../animations/BusyAnimation";
import {UFOptionalOnClickProp} from "../../../UF-react/types/UFOptionalOnClickProp";
import {Stack} from "../layouts/Stack";
import {StackDirection} from "../layouts/stack/StackDirection";

// endregion

// region local

/**
 * Properties for component
 */
interface FullWidthContentPanelProps extends UFChildrenProp, UFOptionalOnClickProp {
  /**
   * True to show loading animation instead of children
   */
  readonly loading: boolean;

  /**
   * Title to use
   */
  readonly title: React.ReactNode;

  /**
   * True to fit width to content else use width of parent.
   */
  readonly fitContent?: boolean;
}

// endregion

// region exports

/**
 * Panel that uses the full width. It can be used in the dashboard or as the container for some history view.
 */
export const FullWidthContentPanel: React.FC<FullWidthContentPanelProps> = (
  {loading, children, title, fitContent = false, onClick}
) => {
  return (
    <PaperContainer
      classExtra={fitContent ? 'w-fit min-w-full' : 'w-full'}
      onClick={onClick}
    >
      <Stack
        direction={StackDirection.Column}
        gap={2}
      >
        {title}
        {
          loading &&
          <div className="h-[300px] w-full flex items-center justify-center">
            <BusyAnimation size={60}/>
          </div>
        }
        {!loading && children}
      </Stack>
    </PaperContainer>
  );
}

// endregion