import {DateTools} from "../../DateTools";
import {DateAndWeekFields} from "../../../types/interfaces/data/fields/DateAndWeekFields";

export abstract class DateAndWeekTools<TData extends DateAndWeekFields> {
  /**
   * Creates a new instance of data.
   *
   * @param aDate
   * @param aWeek
   */
  abstract factory(aDate: Date, aWeek: number): TData;

  /**
   * Combines a previous and new data instances.
   *
   * @param aPrevious
   *   Might get updates with data from aNew
   * @param aNew
   *   A new instance to combine with aPrevious
   *
   * @return either an updated aPrevious or some new instance
   */
  abstract reduce(aPrevious: TData, aNew: Readonly<TData>): TData;

  /**
   * Gets a default value for data type.
   */
  default(): TData {
    const now = new Date();
    return this.factory(now, DateTools.getWeekNumber(now));
  }
}