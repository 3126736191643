// region imports

import React from "react";
import {GraphColorTools} from "../../../tools/GraphColorTools";
import {GeneralTableData} from "../../../types/classes/table-data/GeneralTableData";
import {Tools} from "../../../tools/Tools";
import {Config} from "../../../Config";
import {TableTextSize} from "../../../types/enums/TableTextSize";

// endregion

// region local

/**
 * Properties for component
 */
interface GeneralTableProps {
  /**
   * Data to render
   */
  readonly data: GeneralTableData;

  /**
   * Row to focus (-1 to focus none)
   */
  readonly focus?: number;

  /**
   * Callback to focus a certain line or -1 to no focus any
   */
  readonly setFocus?: (newLine: number) => any;

  /**
   * Size of text
   */
  readonly tableTextSize?: TableTextSize;
}

/**
 * Gets the style for a cell. Handles fixed columns.
 *
 * Code based on:
 * https://stackoverflow.com/questions/39213603/html-table-with-horizontal-scrolling-four-columns-fixed
 *
 * @param data
 * @param column
 * @param rowHeight
 */
function getCellStyle(data: GeneralTableData, column: number, rowHeight: number): object {
  return data.hasFixedColumn
    ? (column === 0
        ? {
          position: 'absolute',
          left: 0,
          // +1 to overlap borders
          width: (data.fixedColumnWidth + 1) + 'px',
          height: (rowHeight + 1) + 'px',
          // -1 to position at border of previous cell
          marginTop: '-1px'
        }
        : {
          height: rowHeight + 'px'
        }
    )
    : {};
}

/**
 */
function createCellsForRow(data: GeneralTableData, row: number, focus: number): React.ReactNode[] {
  const tableCells = [];
  if (data.isEmptyRow(row)) {
    tableCells.push(<td key={0} colSpan={data.columnCount} className="h-6 border-0"></td>);
  }
  else {
    for (let column = 0; column < data.columnCount;) {
      const colSpan = data.cellColSpan(column, row);
      tableCells.push(
        <td
          className={
            'border border-inherit whitespace-nowrap ' +
            (data.cellLeftBorder(column, row) ? '' : 'border-l-0 ') +
            (data.cellRightBorder(column, row) ? '' : 'border-r-0 ') +
            (data.cellTopBorder(column, row) ? '' : 'border-t-0 ') +
            (data.cellBottomBorder(column, row) ? '' : 'border-b-0 ') +
            (data.cellLeftSpacing(column, row) ? 'pl-2 ' : 'pl-0 ') +
            (data.cellRightSpacing(column, row) ? 'pr-2 ' : 'pr-0 ') +
            (data.cellTopSpacing(column, row) ? 'pt-1 ' : 'pt-0 ') +
            (data.cellBottomSpacing(column, row) ? 'pb-1 ' : 'pb-0 ') +
            Tools.getHorizontalPositionClass(data.cellHorizontalPosition(column, row)) + ' ' +
            Tools.getVerticalPositionClass(data.cellVerticalPosition(column, row)) + ' ' +
            (data.isFullWidthCell(column, row) ? 'w-full ' : '') +
            (focus === row ? 'bg-inherit' : GraphColorTools.getTableBackgroundClass(data.cellColor(column, row)))
          }
          colSpan={colSpan}
          key={column}
          style={getCellStyle(data, column, data.rowHeight(row))}
        >
          {data.cell(column, row)}
        </td>
      );
      column += colSpan;
    }
  }
  return tableCells;
}

/**
 */
function createBodyRows(data: GeneralTableData, focus: number, setFocus: (newLine: number) => any): React.ReactNode[] {
  const tableRows = [];
  for (let row = 0; row < data.rowCount; row++) {
    tableRows.push(
      <tr
        key={row}
        className={Config.theme.tableBorder + (focus === row ? ' ' + Config.theme.tableFocus : '')}
        onMouseEnter={() => setFocus(row)}
        onMouseLeave={() => setFocus(-1)}
      >
        {createCellsForRow(data, row, focus)}
      </tr>
    )
  }
  return tableRows;
}

/**
 */
function createCellsForHeader(data: GeneralTableData, row: number): React.ReactNode[] {
  const headerCells = [];
  for (let column = 0; column < data.columnCount;) {
    const colSpan = data.headerColSpan(column, row);
    headerCells.push(
      <th
        className={
          'border border-inherit whitespace-nowrap ' +
          (data.headerLeftBorder(column, row) ? '' : 'border-l-0 ') +
          (data.headerRightBorder(column, row) ? '' : 'border-r-0 ') +
          (data.headerTopBorder(column, row) ? '' : 'border-t-0 ') +
          (data.headerBottomBorder(column, row) ? '' : 'border-b-0 ') +
          (data.headerLeftSpacing(column, row) ? 'pl-2 ' : 'pl-0 ') +
          (data.headerRightSpacing(column, row) ? 'pr-2 ' : 'pr-0 ') +
          (data.headerTopSpacing(column, row) ? 'pt-1 ' : 'pt-0 ') +
          (data.headerBottomSpacing(column, row) ? 'pb-1 ' : 'pb-0 ') +
          Tools.getHorizontalPositionClass(data.headerHorizontalPosition(column, row)) + ' ' +
          Tools.getVerticalPositionClass(data.headerVerticalPosition(column, row)) + ' '
        }
        colSpan={colSpan}
        key={column}
        style={getCellStyle(data, column, data.headerRowHeight(row))}
      >
        {data.header(column, row)}
      </th>
    );
    column += colSpan;
  }
  return headerCells;
}

/**
 */
function createHeaderRows(data: GeneralTableData): React.ReactNode[] {
  const tableHeaderRows = [];
  for (let row = 0; row < data.headerRowCount; row++) {
    tableHeaderRows.push(
      <tr
        className={Config.theme.tableBorder}
        key={row}
      >
        {createCellsForHeader(data, row)}
      </tr>
    )
  }
  return tableHeaderRows;
}

// endregion

// region exports

/**
 * A general table showing a number of rows and columns with table headers.
 */
export const GeneralTable: React.FC<GeneralTableProps> = (
  {
    data,
    focus = -1,
    setFocus = () => null,
    tableTextSize= TableTextSize.Normal
  }) => {
  const headerRows = createHeaderRows(data);
  const bodyRows = createBodyRows(data, focus, setFocus);
  const table = (
    <table
      className={'border-collapse table-auto' + (tableTextSize === TableTextSize.Small  ? ' text-xs' : '')}
    >
      {
        (headerRows.length > 0) &&
        <thead>
          {headerRows}
        </thead>
      }
      {
        (bodyRows.length > 0) &&
        <tbody>
          {bodyRows}
        </tbody>
      }
    </table>
  );
  if (!data.hasFixedColumn) {
    return table;
  }
  return (
    <div
      style={{
        position: 'relative',
        width: '100%'
      }}
    >
      <div
        style={{
          overflowX: 'auto',
          overflowY: 'visible',
          width: 'calc(100% - ' + data.fixedColumnWidth + 'px)',
          marginLeft: data.fixedColumnWidth + 'px'
        }}
      >
        {table}
      </div>
    </div>
  )
};

// endregion