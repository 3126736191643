// region imports

import {UserRegistrationsResponseIO} from "../../../types/interfaces/data/io/UserRegistrationsResponseIO";
import {UserRegistrationEntryIO} from "../../../types/interfaces/data/io/UserRegistrationEntryIO";
import {UserRegistrationEntry} from "../../../types/interfaces/data/UserRegistrationEntry";
import {DateRange} from "../../../types/classes/DateRange";
import {LoadDateRangeWithCallbackAction} from "../LoadDateRangeWithCallbackAction";

// endregion

// region exports

/**
 * Loads the transaction results, grouped per day for a certain date range and data target.
 */
export class UserRegistrationsLoadAction
  extends LoadDateRangeWithCallbackAction<UserRegistrationsResponseIO, UserRegistrationEntry[]>
{
  // region public methods

  /**
   * Constructs an instance.
   *
   * @protected
   */
  public constructor(aDateRange: DateRange, aCallback: (entries: UserRegistrationEntry[]) => any) {
    super('/user/registrations', aDateRange, aCallback);
  }

  // endregion

  // region protected methods

  /**
   * @inheritDoc
   */
  protected process(aResponse: UserRegistrationsResponseIO): Promise<void> {
    this.callback(this.convertEntriesFromIO(aResponse.entries));
    return Promise.resolve();
  }

  // endregion

  // region private methods

  /**
   * Converts data received from the server to data structure used within the application.
   *
   * @param anEntry
   */
  private convertEntryFromIO(anEntry: UserRegistrationEntryIO): UserRegistrationEntry {
    return {
      date: new Date(anEntry.date),
      week: anEntry.week,
      total: anEntry.total || 0,
      verified: anEntry.verified || 0,
    };
  }

  /**
   * Converts data received from the server to data structure used within the application.
   *
   * @param anEntries
   */
  private convertEntriesFromIO(anEntries: UserRegistrationEntryIO[]): UserRegistrationEntry[] {
    return anEntries.map(this.convertEntryFromIO)
  }

  // endregion
}

// endregion