import React from "react";

interface HorizontalLinesProps {
  readonly count: number;
}

/**
 * Renders the horizontal lines shown for each value.
 */
export const HorizontalLines: React.FC<HorizontalLinesProps> = ({count}) => {
  const horizontalLines = [];
  for (let line: number = -1; line <= count; line++) {
    horizontalLines.push(
      <div
        className="h-px w-full bg-gray-400"
        key={line}
      />
    );
  }
  return (
    <div
      className="line-graph__horizontal-lines flex flex-col justify-between pointer-events-none"
    >
      {horizontalLines}
    </div>
  );
}