import {RadioButtonsBar} from "../styled/buttons/RadioButtonsBar";
import React from "react";
import {WebsiteDomainType} from "../../types/enums/WebsiteDomainType";
import {useAppSelector} from "../../store/store";
import {mainController} from "../../controllers/mainController";

/**
 * Buttons to select a certain domain.
 *
 * @constructor
 */
export const WebsiteDomainButtons: React.FC = () => {
  const values = new Map<WebsiteDomainType, string>([
    [WebsiteDomainType.FundoradoDe, 'fundorado.de'],
    [WebsiteDomainType.FundoradoCom, 'fundorado.com'],
  ]);
  const value = useAppSelector(state => state.application.websiteDomain);
  return (
    <RadioButtonsBar
      onChange={(value) => mainController.setWebsiteDomain(value)}
      values={values}
      value={value}
    />
  );
}
