// region imports

import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {userSlice} from "./userSlice";
import {Config} from "../Config";
import {throttle} from "underscore";
import logger from 'redux-logger';
import * as Redux from "redux";
import {networkSlice} from "./networkSlice";
import {alertSlice} from "./alertSlice";
import {applicationSlice} from "./applicationSlice";
import {transactionResultsSlice} from "./data/transactionResultsSlice";
import {selectDateSlice} from "./selectDateSlice";
import {userInterfaceSlice} from "./userInterfaceSlice";
import {userRegistrationsSlice} from "./data/userRegistrationsSlice";
import {transactionFailsSlice} from "./data/transactionFailsSlice";
import {visitorInfoSlice} from "./data/visitorInfoSlice";
import {trafficInfoSlice} from "./data/trafficInfoSlice";
import {ticketsSlice} from "./data/ticketsSlice";
import {activeSubscriptionsSlice} from "./data/activeSubscriptionsSlice";
import {cancellationsSlice} from "./data/cancellationsSlice";
import {trialToSubscriptionsSlice} from "./data/trialToSubscriptionsSlice";
import {coinsSlice} from "./data/coinsSlice";
import {arpuSlice} from "./data/arpuSlice";
import {subscriptionsAndRecurringsSlice} from "./data/subscriptionsAndRecurringsSlice";
import {churnSlice} from "./data/churnSlice";
import {legacySlice} from "./data/legacySlice";
import {legacyBookingsSlice} from "./data/legacyBookingsSlice";
import {bankBookingsSlice} from "./data/bankBookingsSlice";

// endregion

// region local constants

/**
 * True if application is running from within development environment
 */
const IS_DEVELOPMENT = process.env.NODE_ENV !== 'production';

// endregion

// region local storage support functions

/**
 * Loads the redux state from local storage.
 *
 * @returns Loaded state or undefined if no state was stored or the version of the stored data no longer
 *   matches the {@link Config.storeVersion} value.
 */
function loadState() {
  try {
    const serializedState = localStorage.getItem(Config.storageKey);
    if (serializedState !== null) {
      let result: any = JSON.parse(serializedState);
      if (result.__version && (result.__version === Config.storeVersion)) {
        // remove version property from state data
        result.__version = undefined;
        delete result.__version;
        return result;
      }
      // remove store data from localstorage (no longer valid)
      localStorage.removeItem(Config.storageKey);
    }
  } catch (error) {
    console.error('loadState', error);
  }
  return undefined;
}

/**
 * Saves the redux state to the local storage.
 *
 * @param aState
 *   State (should be serializable as JSON)
 */
function saveState(aState: any) {
  try {
    aState.__version = Config.storeVersion;
    const serializedState = JSON.stringify(aState);
    localStorage.setItem(Config.storageKey, serializedState);
  } catch (error) {
    console.error('saveState', error);
  }
}

/**
 * Saves the redux store to the local storage.
 *
 * @param {AppState} anApplicationState
 *   Complete store
 */
function saveStore(anApplicationState: AppState) {
  saveState({
    user: anApplicationState.user,
    application: anApplicationState.application
  });
}

/**
 * Only save the store once every second.
 */
const throttledSaveStore = throttle(saveStore, 1000);

// endregion

// region custom redux middleware

/**
 * Saves the store state to local storage.
 *
 * @param store
 */
const saveMiddleware: Redux.Middleware = store => next => action => {
  const result = next(action);
  throttledSaveStore(store.getState());
  return result;
}

// endregion

// region exports

/**
 * Creates the store using RTK.
 */
export const store = configureStore({
  reducer: {
    // stored in local storage
    user: userSlice.reducer,
    application: applicationSlice.reducer,
    // not stored
    network: networkSlice.reducer,
    userInterface: userInterfaceSlice.reducer,
    // dialogs
    alert: alertSlice.reducer,
    selectDate: selectDateSlice.reducer,
    // data
    transactionResults: transactionResultsSlice.reducer,
    userRegistrations: userRegistrationsSlice.reducer,
    transactionFails: transactionFailsSlice.reducer,
    visitorInfo: visitorInfoSlice.reducer,
    trafficInfo: trafficInfoSlice.reducer,
    tickets: ticketsSlice.reducer,
    activeSubscriptions: activeSubscriptionsSlice.reducer,
    cancellations: cancellationsSlice.reducer,
    trialToSubscriptions: trialToSubscriptionsSlice.reducer,
    coins: coinsSlice.reducer,
    arpu: arpuSlice.reducer,
    subscriptionsAndRecurrings: subscriptionsAndRecurringsSlice.reducer,
    churn: churnSlice.reducer,
    legacy: legacySlice.reducer,
    legacyBookings: legacyBookingsSlice.reducer,
    bankBookings: bankBookingsSlice.reducer,
  },
  preloadedState: loadState(),
  devTools: IS_DEVELOPMENT,
  middleware: (getDefaultMiddleware) => IS_DEVELOPMENT
    ? getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [
          'alert', 'transactionResults', 'selectDate', 'userRegistrations', 'ticketCounts', 'activeSubscriptions',
          'cancellations', 'coins', 'tickets', 'transactionSubscriptions', 'transactionFails',
          'trialToSubscriptions', 'subscriptionsAndRecurrings', 'churn', 'trafficInfo', 'visitorInfo', 'legacy',
          'legacyBookings', 'bankBookings'
        ],
        ignoreActions: true
      }
    }).concat(logger, saveMiddleware)
    : getDefaultMiddleware().concat(saveMiddleware),
});

/**
 * Typed store state (inferred from the store)
 */
export type AppState = ReturnType<typeof store.getState>;

/**
 * Typed dispatch function (inferred from the store)
 */
export type AppDispatch = typeof store.dispatch;

/**
 * Typed hook to call the dispatch function
 */
export const useAppDispatch: () => AppDispatch = useDispatch;

/**
 * Typed hook to get a value from the store state
 */
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

// endregion