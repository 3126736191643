// region imports

import {GeneralTableData} from "./GeneralTableData";
import {GraphColor} from "../../enums/GraphColor";
import React from "react";
import {GraphNumberList} from "../GraphNumberList";
import {DifferenceInformation} from "../../../components/styled/texts/DifferenceInformation";
import {Text} from "../../../components/styled/texts/Text";
import {TextSize} from "../../../components/styled/texts/text/TextSize";
import {TextColor} from "../../../components/styled/texts/text/TextColor";
import {Position} from "../../enums/Position";
import {NumberType} from "../../enums/NumberType";
import {NumberTypeTools} from "../../../tools/NumberTypeTools";

// endregion

// region exports

/**
 * This class shows one or more {@link GraphNumberList}, each list in its own column. It assumes the values contained
 * by all lists have the same meaning.
 */
export class MultipleListsTableData extends GeneralTableData {
  // region private methods

  /**
   * The lists.
   *
   * @private
   */
  private readonly m_lists: GraphNumberList[];

  /**
   * Name for first column
   *
   * @private
   */
  private readonly m_firstColumnName: React.ReactNode;

  /**
   * The labels for the first column.
   *
   * @private
   */
  private readonly m_firstColumnLabels: React.ReactNode[];

  /**
   * See {@link start}.
   *
   * @private
   */
  private readonly m_start: number;

  /**
   *
   * @private
   */
  private readonly m_showDifference: boolean;

  /**
   *
   * @private
   */
  private readonly m_totalRow: boolean;

  // endregion

  // region public methods

  /**
   * Constructs an instance of {@link MultipleListsTableData}.
   *
   * @param aLists
   *   The lists to contain
   * @param aFirstColumnName
   *   Name of first column
   * @param aFirstColumnLabels
   *   The labels shown in the first column. The length also determines the maximum number of rows.
   * @param aShowDifference
   *   When true show the difference with the previous value
   * @param aTotalRow
   *   When true show an extra row with the sum of all values in the column
   */
  constructor(
    aLists: GraphNumberList[], aFirstColumnName: React.ReactNode, aFirstColumnLabels: React.ReactNode[],
    aShowDifference: boolean, aTotalRow: boolean
  ) {
    super();
    this.m_lists = aLists;
    this.m_showDifference = aShowDifference;
    this.m_firstColumnLabels = aFirstColumnLabels;
    this.m_firstColumnName = aFirstColumnName;
    this.m_start = Math.min(...aLists.map(list => list.start));
    this.m_totalRow = aTotalRow;
  }

  // endregion

  // region public properties

  /**
   * Start of first value, the first data row will start at this positing within the lists.
   */
  public get start(): number {
    return this.m_start;
  }

  // endregion

  // region GeneralTableData

  /**
   * @inheritDoc
   */
  cell(aColumn: number, aRow: number): React.ReactNode {
    if (this.isTotalRow(aRow)) {
      if (aColumn === 0) {
        return <Text><em>Total</em></Text>;
      }
      const part = (aColumn - 1) % 2;
      if (part === 1) {
        return '';
      }
      const list = this.getList(aColumn);
      switch(list.valueType) {
        case NumberType.Percentage:
        case NumberType.Duration:
          return null;
      }
      return <Text><em>{NumberTypeTools.getAsText(list.sum(false), list.valueType)}</em></Text>
    }
    const row = aRow + this.m_start;
    if (aColumn === 0) {
      return this.m_firstColumnLabels[row];
    }
    const list = this.getList(aColumn);
    if ((list.size < row) || (list.start > row)) {
      return '';
    }
    const part = (aColumn - 1) % 2;
    if (!this.m_showDifference || (part === 0)) {
      if (list.isForecast(row)) {
        return <em>{list.getAsText(row)}</em>;
      }
      return list.getAsText(row);
    }
    if (aRow === 0) {
      return '';
    }
    return <DifferenceInformation data={list.getCurrentAndPrevious(row)}/>;
  }

  /**
   * @inheritDoc
   */
  cellColor(aColumn: number, aRow: number): GraphColor {
    if (this.isTotalRow(aRow)) {
      return GraphColor.None;
    }
    return aColumn === 0 ? GraphColor.None : this.getList(aColumn).color;
  }

  /**
   * @inheritDoc
   */
  get columnCount(): number {
    return this.m_showDifference ? this.m_lists.length * 2 + 1 : this.m_lists.length + 1;
  }

  /**
   * @inheritDoc
   */
  header(aColumn: number, aRow: number): React.ReactNode {
    const title = aColumn === 0
      ? this.m_firstColumnName
      : !this.m_showDifference || ((aColumn % 2) === 1)
        ? this.getList(aColumn).columnTitle
        : '';
    return <Text size={TextSize.Small} color={TextColor.Light}>{title}</Text>;
  }

  /**
   * @inheritDoc
   */
  headerColSpan(aColumn: number, aRow: number): number {
    if ((aColumn === 0) || !this.m_showDifference) {
      return 1;
    }
    return (aColumn - 1) % 2 === 0 ? 2 : 1;
  }

  /**
   * @inheritDoc
   */
  cellColSpan(aColumn: number, aRow: number): number {
    if (!this.m_showDifference || (aColumn === 0)) {
      return 1;
    }
    aColumn--;
    return this.m_lists[Math.floor(aColumn / 2)].valueType === NumberType.Percentage ? 2 : 1;
  }

  /**
   * @inheritDoc
   */
  get rowCount(): number {
    return this.m_firstColumnLabels.length - this.m_start + (this.m_totalRow ? 1 : 0);
  }

  /**
   * @inheritDoc
   */
  cellHorizontalPosition(aColumn: number, aRow: number): Position {
    if (this.isTotalRow(aRow) && (aColumn === 0)) {
      return Position.Start;
    }
    return Position.End;
  }

  // endregion

  // region private methods

  /**
   * Gets a list for a certain column.
   *
   * @private
   */
  private getList(aColumn: number): GraphNumberList {
    return this.m_showDifference
      ? this.m_lists[Math.floor((aColumn - 1) / 2)]
      : this.m_lists[aColumn - 1];
  }

  /**
   * Checks if the row with total values.
   * @param aRow
   * @private
   */
  private isTotalRow(aRow: number): boolean {
    return this.m_totalRow && (aRow === this.rowCount - 1);
  }

  // endregion
}
