// region imports

import {UFDate} from "../UF/tools/UFDate";
import {WeekInYear} from "../types/interfaces/data/support/WeekInYear";
import React from "react";
import {UFText} from "../UF/tools/UFText";
import {
  add,
  endOfISOWeek,
  getISOWeek,
  isThisISOWeek,
  isThisMonth,
  isToday,
  lastDayOfISOWeek,
  lastDayOfMonth, setISOWeek,
  startOfISOWeek,
  sub
} from "date-fns";
import {DateRange} from "../types/classes/DateRange";

// endregion

// region local

/**
 * 3 letter names for the months (zero based)
 */
const MONTHS_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

/**
 * Full names for the months (zero based)
 */
const MONTHS_LONG = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

// noinspection JSUnusedLocalSymbols
/**
 * 3 letter names for days (zero based)
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DAYS_SHORT = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun'
];

/**
 * Full names for days (zero based)
 */
const DAYS_LONG = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

// endregion

// region exports

/**
 * Support methods related to dates.
 */
export class DateTools {
  /**
   * Gets the labels for use below the graph.
   */
  static getDayBottomLabels(aDates: Date[]): React.ReactNode[] {
    return aDates.map(date => {
      if (isToday(date)) {
        return 'today';
      }
      return <>{date.getDate().toString()}<br/>{MONTHS_SHORT[date.getMonth()]}</>;
    });
  }

  /**
   * Gets the labels for use with the tables (and graph hover popup)
   */
  static getDayTableLabels(aDates: Date[]): React.ReactNode[] {
    return aDates.map(date => {
      if (isToday(date)) {
        return 'today';
      }
      return <span>{MONTHS_LONG[date.getMonth()]}, {this.formatOrdinalNumber(date.getDate())}</span>;
    });
  }

  /**
   * Gets the labels for use below the graph.
   */
  static getMonthBottomLabels(aDates: Date[]): React.ReactNode[] {
    return aDates.map(date => {
      return `${MONTHS_SHORT[date.getMonth()]} '${date.getFullYear().toString().substring(2)}`;
    });
  }

  /**
   * Gets the labels for use with the tables (and graph hover popup)
   */
  static getMonthTableLabels(aDates: Date[]): React.ReactNode[] {
    return aDates.map(date => {
      return `${MONTHS_LONG[date.getMonth()]}, ${date.getFullYear()}`;
    });
  }

  /**
   * Gets the labels for use below the graph.
   */
  static getYearBottomLabels(aDates: Date[]): React.ReactNode[] {
    return aDates.map(date => {
      return `${date.getFullYear().toString()}`;
    });
  }

  /**
   * Gets the labels for use with the tables (and graph hover popup)
   */
  static getYearTableLabels(aDates: Date[]): React.ReactNode[] {
    return aDates.map(date => {
      return `${date.getFullYear().toString()}`;
    });
  }

  /**
   * Gets the labels for use below the graph.
   */
  static getWeekBottomLabels(aWeeks: WeekInYear[]): React.ReactNode[] {
    let previous: number = -1;
    return aWeeks.map((week, index) => {
      if ((previous !== week.year) || (index === aWeeks.length - 1)) {
        previous = week.year;
        return <>{week.week}<br/>'{week.year.toString().substring(2)}</>;
      }
      else {
        return week.week.toString();
      }
    });
  }

  /**
   * Gets the labels for use with the tables (and graph hover popup)
   */
  static getWeekTableLabels(aWeeks: WeekInYear[]): React.ReactNode[] {
    let previous: number = -1;
    return aWeeks.map((week, index) => {
      if ((previous !== week.year) || (index === aWeeks.length - 1)) {
        previous = week.year;
        return `(${week.year.toString()}) ${week.week}`;
      }
      else {
        return week.week.toString();
      }
    });
  }

  /**
   * Gets the labels for use with the tables (and graph hover popup)
   */
  static getHourLabels(): React.ReactNode[] {
    const result: React.ReactNode[] = [];
    for (let hour = 0; hour < 25; hour++) {
      result.push(`${UFText.twoDigits(hour % 24)}:00`);
    }
    return result;
  }

  /**
   * Gets the labels for use with the tables (and graph hover popup)
   */
  static getDayInWeekLabels(): React.ReactNode[] {
    return DAYS_LONG;
  }

  /**
   * Gets the labels for use with the tables (and graph hover popup)
   */
  static getDayInMonthLabels(): React.ReactNode[] {
    return [
      '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
      '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
    ];
  }

  /**
   * Gets the start of this week
   */
  static getCurrentWeekStart(): Date {
    return startOfISOWeek(Date.now());
  }

  /**
   * Gets the start of current month
   */
  static getCurrentMonthStart(): Date {
    return this.getStartOfMonth(new Date());
  }

  /**
   * Gets the last day in of the current month.
   *
   * @return 1..31
   */
  static getCurrentMonthLastDay(): number {
    return lastDayOfMonth(Date.now()).getDate();
  }

  /**
   * Gets the start of week
   */
  static getStartOfWeek(aDate: Date): Date {
    return startOfISOWeek(aDate);
  }

  /**
   * Gets the end of the week in milliseconds since 1970-1-1 0:0:0
   */
  static getEndOfWeek(aDate: Date): Date {
    return endOfISOWeek(aDate);
  }

  /**
   * Gets the start of the month
   */
  static getStartOfMonth(aDate: Date): Date {
    return new Date(aDate.getFullYear(), aDate.getMonth(), 1);
  }

  /**
   * Gets the end of the month
   */
  static getEndOfMonth(aDate: Date) {
    return lastDayOfMonth(aDate);
  }

  /**
   * Gets the last day of the month.
   */
  static getLastDayOfMonth(aDate: Date): number {
    return lastDayOfMonth(aDate).getDate();
  }

  /**
   * Gets date with the time set to the start of the day (0:0:0.0).
   */
  static getStartOfDay(aDate: Date): Date {
    return new Date(aDate.getFullYear(), aDate.getMonth(), aDate.getDate());
  }

  /**
   * Gets date with the time set to the end of the day (23:59:59.999).
   */
  static getEndOfDay(aDate: Date): Date {
    return new Date(aDate.getFullYear(), aDate.getMonth(), aDate.getDate(), 23, 59, 59, 999);
  }

  /**
   * Gets a date that starts at the year.
   */
  static getStartOfYear(aDate: Date): Date {
    return new Date(aDate.getFullYear(), 1, 1);
  }

  /**
   * Gets a date that ends at the year.
   */
  static getEndOfYear(aDate: Date): Date {
    return new Date(aDate.getFullYear(), 12, 31, 23, 59, 59, 999);
  }

  /**
   * Gets the previous day.
   */
  static getPreviousDay(aDate: Date): Date {
    return new Date(aDate.getTime() - UFDate.DAY_IN_MILLISECONDS);
  }

  /**
   * Gets the previous week.
   */
  static getPreviousWeek(aDate: Date): Date {
    return new Date(aDate.getTime() - 7 * UFDate.DAY_IN_MILLISECONDS);
  }

  /**
   * Gets the previous month
   */
  static getPreviousMonth(aDate: Date): Date {
    return sub(aDate, {months: 1});
  }

  /**
   * Gets the next month
   */
  static getNextMonth(aDate: Date): Date {
    return add(aDate, {months: 1});
  }

  /**
   * Checks if a date is today's date.
   *
   * @param aDate
   */
  static isToday(aDate: Date): boolean {
    return isToday(aDate);
  }

  /**
   * Checks if a date falls within the current week.
   *
   * @param aDate
   */
  static isCurrentWeek(aDate: Date): boolean {
    return isThisISOWeek(aDate);
  }

  /**
   * Checks if a date falls within the current month
   *
   * @param aDate
   */
  static isCurrentMonth(aDate: Date): boolean {
    return isThisMonth(aDate);
  }

  /**
   * Gets the week number for a date.
   *
   * @param aDate
   */
  static getWeekNumber(aDate: Date): number {
    return getISOWeek(aDate);
  }

  /**
   * Gets the starting date of a week in a year.
   *
   * @param aWeek
   * @param aYear
   */
  static getDateForWeek(aWeek: number, aYear: number): Date {
    // do not use 1st of january, this will cause the function to select the previous year
    return setISOWeek(new Date(aYear, 2, 1), aWeek);
  }


  // region formatting methods

  /**
   * Gets the date part of a {@link Date} instance
   *
   * @param aDate
   *
   * @return 'YYYY-[M]M-[D]D'
   */
  static formatDateAsCompactText(aDate: Date): string {
    return aDate.getFullYear() + '-' + (aDate.getMonth() + 1) + '-' + aDate.getDate();
  }

  /**
   * Formats a number by adding ordinal text as sup.
   *
   * @param aNumber
   */
  static formatOrdinalNumber(aNumber: number): React.ReactNode {
    return <span>{aNumber}<sup>{UFText.getOrdinalPost(aNumber)}</sup></span>
  }

  /**
   * Formats a date to English date.
   */
  static formatDay(aDate: Date, anIncludeYear: boolean = true): React.ReactNode {
    return anIncludeYear
      ? <>{MONTHS_LONG[aDate.getMonth()]} {this.formatOrdinalNumber(aDate.getDate())}, {aDate.getFullYear()}</>
      : <>{MONTHS_LONG[aDate.getMonth()]} {this.formatOrdinalNumber(aDate.getDate())}</>;
  }

  /**
   * Formats a month to English date.
   */
  static formatMonth(aDate: Date, anIncludeYear: boolean = true): React.ReactNode {
    return anIncludeYear
      ? <>{MONTHS_LONG[aDate.getMonth()]} {aDate.getFullYear()}</>
      : <>{MONTHS_LONG[aDate.getMonth()]}</>;
  }

  /**
   * Formats a week number and the start date and end date of a week.
   */
  static formatWeekWithRange(aDate: Date, aLinebreak: boolean, anIncludeYear: boolean = true): React.ReactNode {
    return aLinebreak
      ? <>{this.formatWeek(aDate, anIncludeYear)}<br/>{this.formatWeekRange(aDate)}</>
      : <>{this.formatWeek(aDate, anIncludeYear)}, {this.formatWeekRange(aDate)}</>;
  }

  /**
   * Formats a week number and optionally a year.
   */
  static formatWeek(aDate: Date, anIncludeYear: boolean = true): React.ReactNode {
    const week = getISOWeek(aDate);
    return anIncludeYear
      ? <>Wk {week}, {aDate.getFullYear()}</>
      : <>Wk {week}</>;
  }

  /**
   * Formats the start and end date of a week.
   */
  static formatWeekRange(aDate: Date): React.ReactNode {
    const start = startOfISOWeek(aDate);
    const end = lastDayOfISOWeek(aDate);
    const startText = <>{MONTHS_SHORT[start.getMonth()]} {this.formatOrdinalNumber(start.getDate())}</>;
    const endText = <>{MONTHS_SHORT[end.getMonth()]} {this.formatOrdinalNumber(end.getDate())}</>;
    return <>{startText} - {endText}</>
  }

  /**
   * Format a day and add the week.
   */
  static formatDayWithWeek(aDate: Date): React.ReactNode {
    return <>{this.formatDay(aDate)}, {this.formatWeek(aDate, false)}</>;
  }

  /**
   * Formats a date range.
   */
  static formatDateRange(aDateRange: DateRange, aSkipEnd: boolean = false, aTwoLine: boolean = false): React.ReactNode {
    if (aDateRange.sameDay || aSkipEnd) {
      return this.formatDayWithWeek(aDateRange.start);
    }
    return <>{this.formatDayWithWeek(aDateRange.start)}{aTwoLine ? <div/> : ' - '}{this.formatDayWithWeek(aDateRange.end)}</>;
  }

  // endregion
}