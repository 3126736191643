// region import

import React, {useRef, useState} from "react";
import {GraphNumberList} from "../../../../types/classes/GraphNumberList";
import {LegendColor} from "./LegendColor";

// endregion

// region local

/**
 * Properties for component
 */
interface VerticalLinesAndValuesProps {
  /**
   * Number of lines to show
   */
  readonly count: number;

  /**
   * The data
   */
  readonly data: GraphNumberList[];

  /**
   * Titles to use in popup hovers
   */
  readonly hoverTitles: React.ReactNode[];

  /**
   * index of slice to focus or -1 to focus none
   */
  readonly focus: number;

  /**
   * Callback to change focus.
   */
  readonly setFocus: (value: number) => any;
}

/**
 * Renders a value list at a certain position.
 */
function renderValueList(aTitle: React.ReactNode, aData: GraphNumberList[], aFocus: number, aCount: number, aX: number, aY: number) {
  return (
    <div
      className={
        'absolute bg-white border border-gray-400 radius-sm sm:rounded-lg shadow-md z-40 pointer-events-none ' +
        'flex flex-col gap-1 px-2 py-1 ' +
        (aFocus > aCount * 0.70 ? '-translate-x-full' : '')
      }
      style={{
        left: aX + 'px',
        top: aY + 'px'
      }}
    >
      <div className="font-semibold text-xs mb-2 whitespace-nowrap">{aTitle}</div>
      <table className="table-auto text-xs leading-none whitespace-nowrap">
        <tbody>
          {
            aData.map((list, index) => {
              return (list.hasValue(aFocus))
                ? (
                  <tr key={index}>
                    <td className="align-middle py-0.5"><LegendColor color={list.color} light={false}/></td>
                    <td className="align-middle p-0.5">{list.legendTitle}</td>
                    <td className="font-semibold text-right align-middle">{list.getAsText(aFocus)}</td>
                  </tr>
                )
                : null
            })
          }
        </tbody>
      </table>
    </div>
  );
}

// endregion

// region exports

/**
 * Renders vertical lines, which are shown on mouse over. This component also shows a list of values at that vertical
 * position (if any).
 */
export const VerticalLinesAndValues: React.FC<VerticalLinesAndValuesProps> = (
  {data, count, focus, hoverTitles, setFocus}
) => {
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [showValues, setShowValues] = useState(false);
  const container = useRef<HTMLDivElement>(null);
  const lines: React.ReactNode[] = [];
  const first = Math.min(...data.map(list => list.start));
  for (let index = 0; index < count; index++) {
    lines.push(
      <div
        className="h-full w-0 relative"
        key={index}
      >
        <div
          className="absolute w-8 -left-4 h-full"
          onMouseEnter={() => {
            setFocus(index);
            setShowValues(true);
          }}
          onMouseLeave={() => {
            setFocus(-1);
            setShowValues(false);
          }}
          onMouseMove={(event) => {
            if (focus === index) {
              setMouseX(event.clientX);
              setMouseY(event.clientY);
            }
          }}
        >
          {
            (focus === index) &&
            <div className="bg-gray-400 w-px h-full ml-4"/>
          }
        </div>
      </div>
    );
    if (index < count - 1) {
      lines.push(<div className="flex-1" key={index + count}/>);
    }
  }
  const rect = container.current ? container.current.getBoundingClientRect() : null;
  const x = mouseX - (rect ? rect.left : 0) + 10;
  const y = mouseY - (rect ? rect.top : 0) + 35;
  return (
    <div
      className="line-graph__hover flex flex-row w-full h-full relative"
      ref={container}
    >
      {lines}
      {
        (focus >= first) && showValues && renderValueList(hoverTitles[focus], data, focus, count, x, y)
      }
    </div>
  );
}

// endregion