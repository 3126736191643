// region imports

import {DateRange} from "../../../types/classes/DateRange";
import {TransactionFailEntry} from "../../../types/interfaces/data/TransactionFailEntry";
import {TransactionFailsResponseIO} from "../../../types/interfaces/data/io/TransactionFailsResponseIO";
import {TransactionFailsInfo} from "../../../types/interfaces/data/TransactionFailsInfo";
import {TransactionFailEntryIO} from "../../../types/interfaces/data/io/TransactionFailEntryIO";
import {LoadDateRangeWithCallbackAction} from "../LoadDateRangeWithCallbackAction";

// endregion

// region exports

/**
 * Base class for all actions that get transaction results for a certain period.
 */
export class TransactionFailsLoadAction
  extends LoadDateRangeWithCallbackAction<TransactionFailsResponseIO, TransactionFailsInfo>
{
  // region public methods

  /**
   * Constructs an instance.
   *
   *
   * @protected
   */
  public constructor(aDateRange: DateRange, aCallback: (info: TransactionFailsInfo) => any) {
    super('/transaction/fails', aDateRange, aCallback);
  }

  // endregion

  // region protected methods

  /**
   * @inheritDoc
   */
  protected process(aResponse: TransactionFailsResponseIO): Promise<void> {
    this.callback(this.convertFromIO(aResponse));
    return Promise.resolve();
  }

  // endregion

  // region private methods

  /**
   * Convert entry from IO to internal structure
   *
   * @param anEntry
   *
   * @private
   */
  private convertEntryFromIO(anEntry: TransactionFailEntryIO): TransactionFailEntry {
    return {
      count: anEntry.count || 0,
      status: anEntry.status || -1
    };
  }

  /**
   * Converts a list of entries.
   *
   * @param anEntries
   *
   * @private
   */
  private convertEntriesFromIO(anEntries: TransactionFailEntryIO[]): TransactionFailEntry[] {
    return anEntries.map(entry => this.convertEntryFromIO(entry));
  }

  /**
   * Converts IO structure to internal data structure
   *
   * @private
   */
  private convertFromIO(aResponse: TransactionFailsResponseIO): TransactionFailsInfo {
    return {
      entries: this.convertEntriesFromIO(aResponse.entries)
    }
  }

  // endregion
}

// endregion