import React from "react";
import {useAppSelector} from "../../../store/store";
import {GraphColor} from "../../../types/enums/GraphColor";
import {NumberType} from "../../../types/enums/NumberType";
import {HistoryPanel} from "../../panels/HistoryPanel";
import {Text} from "../../styled/texts/Text";
import {TextPlacement} from "../../styled/texts/text/TextPlacement";
import {BulletList} from "../../styled/texts/BulletList";
import {BulletItem} from "../../styled/texts/BulletItem";
import {ActiveSubscriptionsTools} from "../../../tools/data/ActiveSubscriptionsTools";

export const ActiveSubscriptionsHistory: React.FC = () => {
  const {historyEntries, historyRange, loading} = useAppSelector(state => state.activeSubscriptions);
  return (
    <HistoryPanel
      entries={historyEntries}
      loading={loading || !historyRange}
      tools={new ActiveSubscriptionsTools()}
      totalRow={false}
      providers={[
        {
          color: GraphColor.Other1,
          name: '1 month sub.',
          type: NumberType.Integer,
          getValue: entry => entry.count1
        },
        {
          color: GraphColor.Other2,
          name: '3 month sub.',
          type: NumberType.Integer,
          getValue: entry => entry.count3
        },
        {
          color: GraphColor.Other3,
          name: '6 month sub.',
          type: NumberType.Integer,
          getValue: entry => entry.count6
        },
        {
          color: GraphColor.Other4,
          name: '12 month sub.',
          type: NumberType.Integer,
          getValue: entry => entry.count12
        },
        {
          color: GraphColor.Other5,
          name: '24 month sub.',
          type: NumberType.Integer,
          getValue: entry => entry.count24
        },
        {
          color: GraphColor.None,
          name: 'Total',
          type: NumberType.Integer,
          reverse: true,
          getValue: entry => entry.count1 + entry.count3 + entry.count6 + entry.count12 + entry.count24
        },
      ]}
      bottom={
        <Text placement={TextPlacement.Paragraph}>
          The active subscription count is calculated by counting the number of subscriptions for which:
          <BulletList>
            <BulletItem>The subscription state is active.</BulletItem>
            <BulletItem>The subscription has a recur date that is today or somewhere in the future.</BulletItem>
            <BulletItem>The subscription start date is before the start of the selected date range.</BulletItem>
          </BulletList>
        </Text>
      }
    />
  );
}
