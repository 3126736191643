// region imports

import {TrafficInfo} from "../../types/interfaces/data/TrafficInfo";
import {StoreTools} from "../../tools/StoreTools";

// endregion

// region exports

/**
 * Traffic info related values.
 */
export const trafficInfoSlice = StoreTools.createSingleDataForDateRangePerDomainSlice<TrafficInfo>(
  'traffic-info',
  {
    visits: [],
    webmasters: []
  }
);

/**
 * The actions for the slice
 */
export const {
  initializeDashboard,
  setLoading,
  setDashboardCurrent,
  setDashboardPrevious,
  setHistoryRange,
  setHistoryCurrent,
  setHistoryPrevious,
  resetDashboard,
} = trafficInfoSlice.actions;

// endregion