// region imports

import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RevenueAndUsersFields} from "../../types/interfaces/data/fields/RevenueAndUsersFields";
import {RevenueAndUsersAndWebmasterFields} from "../../types/interfaces/data/fields/RevenueAndUsersAndWebmasterFields";

// endregion

// region local

/**
 * Slice state
 */
interface ArpuState {
  /**
   * True if data has been loaded.
   */
  initialized: boolean;

  /**
   * True if busy loading data
   */
  loading: boolean;

  /**
   * Total information ending today
   */
  totalToday: RevenueAndUsersFields;

  /**
   * Information per webmaster, sorted on number of users from high to low ending today
   */
  webmastersToday: RevenueAndUsersAndWebmasterFields[];

  /**
   * Total information ending some days in the past
   */
  totalPast: RevenueAndUsersFields;

  /**
   * Information per webmaster, sorted on number of users from high to low ending in the past
   */
  webmastersPast: RevenueAndUsersAndWebmasterFields[];
}

/**
 * Initial slice state
 */
const initialState: ArpuState = {
  initialized: false,
  loading: false,
  totalToday: {
    users: 0,
    revenue: 0
  },
  webmastersToday: [],
  totalPast: {
    users: 0,
    revenue: 0
  },
  webmastersPast: [],
};

// endregion

// region exports

/**
 * Arpu related values.
 */
export const arpuSlice = createSlice({
  name: 'arpu',
  initialState,
  reducers: {
    initialized(state) {
      state.initialized = true;
      state.loading = false;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setTotalToday(state, action: PayloadAction<RevenueAndUsersFields>) {
      state.totalToday = action.payload;
    },
    setWebmastersToday(state, action: PayloadAction<RevenueAndUsersAndWebmasterFields[]>) {
      state.webmastersToday = action.payload;
    },
    setTotalPast(state, action: PayloadAction<RevenueAndUsersFields>) {
      state.totalPast = action.payload;
    },
    setWebmastersPast(state, action: PayloadAction<RevenueAndUsersAndWebmasterFields[]>) {
      state.webmastersPast = action.payload;
    },
    reset() {
      return initialState;
    }
  }
})

/**
 * The actions for the slice
 */
export const {
  initialized,
  setLoading,
  setTotalToday,
  setWebmastersToday,
  setTotalPast,
  setWebmastersPast,
  reset
} = arpuSlice.actions;

// endregion