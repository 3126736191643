// region imports

import {TicketEntry} from "../../types/interfaces/data/TicketEntry";
import {StoreTools} from "../../tools/StoreTools";

// endregion

// region exports

/**
 * Ticket related values.
 */
export const ticketsSlice = StoreTools.createDataListForDateRangeSlice<TicketEntry>('tickets');

/**
 * The actions for the slice
 */
export const {
  initializeDashboard,
  setHistoryRange,
  setLoading,
  setDashboard,
  setHistoryEntries,
  resetDashboard,
} = ticketsSlice.actions;

// endregion