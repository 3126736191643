/**
 * Possible colors for graphs.
 */
export enum GraphColor {
  None,
  Success,
  Warning,
  Failure,
  Other1,
  Other2,
  Other3,
  Other4,
  Other5,
  Other6,
  Other7,
  Other8,
  Other9,
  Other10
}