// region imports

import React from "react";
import {useAppSelector} from "../../../store/store";
import {GraphColor} from "../../../types/enums/GraphColor";
import {NumberType} from "../../../types/enums/NumberType";
import {HistoryPanel} from "../../panels/HistoryPanel";
import {Text} from "../../styled/texts/Text";
import {TextPlacement} from "../../styled/texts/text/TextPlacement";
import {BulletList} from "../../styled/texts/BulletList";
import {BulletItem} from "../../styled/texts/BulletItem";
import {TrialToSubscriptionsTools} from "../../../tools/data/TrialToSubscriptionsTools";

// endregion

// region exports

export const TrialToSubscriptionsHistory: React.FC = () => {
  const {historyEntries, historyRange, loading} = useAppSelector(state => state.trialToSubscriptions);
  const tools = new TrialToSubscriptionsTools();
  return (
    <HistoryPanel
      entries={historyEntries}
      loading={loading || !historyRange}
      providers={[
        {
          color: GraphColor.Other1,
          name: 'Trials to subscriptions',
          type: NumberType.Integer,
          getValue: entry => entry.conversionCount
        },
        {
          color: GraphColor.Other2,
          name: 'New trial subscriptions',
          type: NumberType.Integer,
          getValue: entry => entry.trialCount
        },
        {
          color: GraphColor.None,
          name: 'Percentage',
          type: NumberType.Percentage,
          getValue: entry => tools.calcPercentage(entry)
        }
      ]}
      tools={tools}
      bottom={
        <div>
          <Text placement={TextPlacement.Paragraph}>
            The number of trial to subscriptions is determined by counting the number of transactions that match the
            following:
            <BulletList>
              <BulletItem>The transaction <em>amount</em> is between 1.00 and 10.99</BulletItem>
              <BulletItem>The transaction <em>type</em> is <em>subscribe</em>.</BulletItem>
              <BulletItem>The transaction is successful.</BulletItem>
              <BulletItem>The transaction <em>created_at</em> is within the selected date range.</BulletItem>
              <BulletItem>
                There is a second transaction for which:
                <BulletList>
                  <BulletItem>The second transaction is for the same user.</BulletItem>
                  <BulletItem>The second transaction <em>amount</em> is above 10.00</BulletItem>
                  <BulletItem>The second transaction <em>type</em> is
                    either <em>subscribe</em> or <em>recurring</em>.</BulletItem>
                  <BulletItem>The second transaction is successful.</BulletItem>
                  <BulletItem>
                    The second transaction <em>created_at</em> date is max 32 days after the first
                    transaction <em>created_at</em> date.
                  </BulletItem>
                </BulletList>
              </BulletItem>
            </BulletList>
          </Text>
          <Text placement={TextPlacement.Paragraph}>
            The number of trials is determined by counting the successful transactions with an <em>amount</em> between
            1.00 and 10.00 within the selected date range.
          </Text>
        </div>
      }
    />
  );
}

// endregion