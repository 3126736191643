// region imports

import React from "react";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";
import {UFOptionalOnClickProp} from "../../../UF-react/types/UFOptionalOnClickProp";
import {PaperContainer} from "./PaperContainer";

// endregion

// region locals

interface AutoSizePaperContainerProps extends UFChildrenProp, UFOptionalOnClickProp {
}

// endregion

// region exports

/**
 * A paper container that which dimensions are based on the content.
 */
export const AutoSizePaperContainer: React.FC<AutoSizePaperContainerProps> = (
  {children, onClick}
) =>
  <PaperContainer onClick={onClick}>{children}</PaperContainer>;

// endregion