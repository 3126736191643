// region imports

import {TrafficInfo} from "../../../types/interfaces/data/TrafficInfo";
import {TrafficInfoResponseIO} from "../../../types/interfaces/data/io/TrafficInfoResponseIO";
import {WebsiteDomainType} from "../../../types/enums/WebsiteDomainType";
import {DateRange} from "../../../types/classes/DateRange";
import {LoadDateRangeWithDomainAndCallbackAction} from "../LoadDateRangeWithDomainAndCallbackAction";
import {TrafficInfoEntryIO} from "../../../types/interfaces/data/io/TrafficInfoEntryIO";
import {ValueAndKeyAndLabelFields} from "../../../types/interfaces/data/fields/ValueAndKeyAndLabelFields";

// endregion

// region exports

/**
 * Loads and converts the traffic info data.
 */
export class TrafficInfoLoadAction
  extends LoadDateRangeWithDomainAndCallbackAction<TrafficInfoResponseIO, TrafficInfo>
{
  // region public methods

  /**
   * Constructs an instance.
   *
   *
   * @protected
   */
  public constructor(aDomain: WebsiteDomainType, aDateRange: DateRange, aCallback: (data: TrafficInfo) => any) {
    super('/analytics/traffic', aDomain, aDateRange, aCallback);
  }

  // endregion

  // region protected methods

  /**
   * @inheritDoc
   */
  protected process(aResponse: TrafficInfoResponseIO): Promise<void> {
    this.callback(this.convertTrafficInfoFromIO(aResponse));
    return Promise.resolve();
  }

  // endregion

  // region private methods

  /**
   * Converts the user counts for various categories to a list of value and label fields. Sort the lost from highest
   * to lowest user count
   *
   * @private
   */
  private convertFromIO(anInfo: TrafficInfoEntryIO): ValueAndKeyAndLabelFields[] {
    const results =[
      {
        value: anInfo.direct,
        label: 'Direct',
        key: 'direct'
      },
      {
        value: anInfo.other,
        label: 'Other',
        key: 'other'
      },
      {
        value: anInfo.tube,
        label: 'Tube',
        key: 'tube'
      },
      {
        value: anInfo.referral,
        label: 'Referral',
        key: 'referral'
      },
      {
        value: anInfo.search,
        label: 'Search',
        key: 'search'
      },
      {
        value: anInfo.social,
        label: 'Social',
        key: 'social'
      }
    ];
    results.sort((first, second) => second.value - first.value);
    return results;
  }

  /**
   * Converts IO structure to internal data structure
   *
   * @protected
   */
  private convertTrafficInfoFromIO(aResponse: TrafficInfoResponseIO): TrafficInfo {
    return {
      visits: this.convertFromIO(aResponse.visit),
      webmasters: this.convertFromIO(aResponse.webmaster)
    };
  }

  // endregion
}

// endregion