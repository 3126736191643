import React from "react";
import {CurrentAndPreviousValue} from "../../../types/classes/CurrentAndPreviousValue";
import {useAppSelector} from "../../../store/store";
import {DataTools} from "../../../tools/DataTools";
import {NumberType} from "../../../types/enums/NumberType";
import {LastAndPreviousDaysDashboardPanel} from "../../panels/LastAndPreviousDaysDashboardPanel";
import {mainController} from "../../../controllers/mainController";
import {UserLocation} from "../../../types/enums/UserLocation";
import {LegacyBookingTools} from "../../../tools/data/LegacyBookingTools";

export const BadDepthCostsDashboard: React.FC = () => {
  const entries = useAppSelector(state => state.legacyBookings.dashboardEntries);
  const loading = useAppSelector(state => state.legacyBookings.loading);
  const initialized = useAppSelector(state => state.legacyBookings.initializedDashboard);
  const tools = new LegacyBookingTools();
  const current = DataTools.reduceForDashboardCurrent(entries, tools);
  const previous = DataTools.reduceForDashboardPrevious(entries, tools);
  const currentAndPrevious = new CurrentAndPreviousValue(
    {value: current.debitFees}, {value: previous.debitFees}, NumberType.Currency, true
  );
  return (
    <LastAndPreviousDaysDashboardPanel
      title="Bad depth costs"
      currentAndPrevious={currentAndPrevious}
      loading={loading || !initialized}
      onClick={() => mainController.setUserLocation(UserLocation.LegacyBookings)}
    />
  );
};
