import React from "react";
import {useAppSelector} from "../../../store/store";
import {NumberType} from "../../../types/enums/NumberType";
import {UserLocation} from "../../../types/enums/UserLocation";
import {mainController} from "../../../controllers/mainController";
import {CurrentAndPreviousValue} from "../../../types/classes/CurrentAndPreviousValue";
import {CurrentAndPreviousDashboardPanel} from "../../panels/CurrentAndPreviousDashboardPanel";
import {ArpuTools} from "../../../tools/data/ArpuTools";

export const ArpuDashboard: React.FC = () => {
  const {initialized, totalToday, totalPast, loading} = useAppSelector(state => state.arpu);
  const currentAndPrevious = new CurrentAndPreviousValue(
    {value: ArpuTools.calc(totalToday)},
    {value: ArpuTools.calc(totalPast)},
    NumberType.Currency
  );
  return (
    <CurrentAndPreviousDashboardPanel
      title="ARPU"
      currentAndPrevious={currentAndPrevious}
      loading={loading || !initialized}
      onClick={() => mainController.setUserLocation(UserLocation.Arpu)}
      previousText="ARPU 90 days ago:"
    />
  );
}