import {DateSelectionType} from "../enums/DateSelectionType";

/**
 * Maps the various {@link DateSelectionType} to a textual representation.
 */
export const DateSelectionToStringMap: Map<DateSelectionType, string> = new Map([
  [DateSelectionType.Today, 'Today'],
  [DateSelectionType.Yesterday, 'Yesterday'],
  [DateSelectionType.ThisWeek, 'This week'],
  [DateSelectionType.LastWeek, 'Last week'],
  [DateSelectionType.ThisMonth, 'This month'],
  [DateSelectionType.LastMonth, 'Last month'],
  [DateSelectionType.Last7Days, 'Last 7 days'],
  [DateSelectionType.Last30Days, 'Last 30 days'],
  [DateSelectionType.Custom, 'Custom'],
]);