// region imports

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

// endregion

// region local

/**
 * Slice state
 */
interface UserState {
  /**
   * Name of user
   */
  fullName: string;

  /**
   * The token to use with all communication or empty string if user is not authenticated
   */
  authenticationToken: string;
}

/**
 * Initial slice state
 */
const initialState: UserState = {
  fullName: '',
  authenticationToken: '',
};

// endregion

// region exports

/**
 * User related values. This slice gets saved in the local storage.
 */
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setFullName: (state, action: PayloadAction<string>) => {
      state.fullName = action.payload;
    },
    setAuthenticationToken: (state, action: PayloadAction<string>) => {
      state.authenticationToken = action.payload;
    },
    clear: state => {
      state.fullName = '';
      state.authenticationToken = '';
    }
  }
})

/**
 * The actions for the slice
 */
export const { setFullName, setAuthenticationToken, clear } = userSlice.actions;

// endregion