// region imports

import {StoreTools} from "../../tools/StoreTools";
import {ChurnEntry} from "../../types/interfaces/data/ChurnEntry";

// endregion

// region exports

/**
 * Churn related values.
 */
export const churnSlice = StoreTools.createDataListForDateRangeSlice<ChurnEntry>(
  'churn'
);

/**
 * The actions for the slice
 */
export const {
  initializeDashboard,
  setHistoryRange,
  setLoading,
  setDashboard,
  setHistoryEntries,
  resetDashboard,
} = churnSlice.actions;

// endregion