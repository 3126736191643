// region imports

import {TicketsResponseIO} from "../../../types/interfaces/data/io/TicketsResponseIO";
import {TicketEntryIO} from "../../../types/interfaces/data/io/TicketEntryIO";
import {TicketEntry} from "../../../types/interfaces/data/TicketEntry";
import {DateRange} from "../../../types/classes/DateRange";
import {LoadDateRangeWithCallbackAction} from "../LoadDateRangeWithCallbackAction";

// endregion

// region exports

/**
 * Loads the transaction results, grouped per day for a certain date range and data target.
 */
export class TicketsLoadAction extends LoadDateRangeWithCallbackAction<TicketsResponseIO, TicketEntry[]> {
  // region public methods
  /**
   * Constructs an instance.
   *
   * @protected
   */
  public constructor(aDateRange: DateRange, aCallback: (entries: TicketEntry[]) => any) {
    super('/crm/tickets', aDateRange, aCallback);
  }

  // endregion

  // region protected methods

  /**
   * @inheritDoc
   */
  protected process(aResponse: TicketsResponseIO): Promise<void> {
    this.callback(this.convertEntriesFromIO(aResponse.entries));
    return Promise.resolve();
  }

  // endregion

  // region private methods

  /**
   * Converts data received from the server to data structure used within the application.
   *
   * @param anEntry
   */
  private convertEntryFromIO(anEntry: TicketEntryIO): TicketEntry {
    return {
      date: new Date(anEntry.date),
      week: anEntry.week,
      count: anEntry.count,
      time: anEntry.time,
      replies: anEntry.replies
    };
  }

  /**
   * Converts data received from the server to data structure used within the application.
   *
   * @param anEntries
   */
  private convertEntriesFromIO(anEntries: TicketEntryIO[]): TicketEntry[] {
    return anEntries.map(this.convertEntryFromIO)
  }

  // endregion
}

// endregion