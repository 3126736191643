import {ActiveSubscriptionsEntry} from "../../types/interfaces/data/ActiveSubscriptionsEntry";
import {UFDate} from "../../UF/tools/UFDate";
import {DateAndWeekTools} from "./base/DateAndWeekTools";

export class ActiveSubscriptionsTools extends DateAndWeekTools<ActiveSubscriptionsEntry> {
  factory(aDate: Date, aWeek: number): ActiveSubscriptionsEntry {
    return {
      date: aDate,
      week: aWeek,
      count1: 0,
      count12: 0,
      count24: 0,
      count3: 0,
      count6: 0
    }
  }

  reduce(aPrevious: ActiveSubscriptionsEntry, anEntry: Readonly<ActiveSubscriptionsEntry>): ActiveSubscriptionsEntry {
    if (UFDate.isEqualDate(aPrevious.date, anEntry.date)) {
      aPrevious.count1 = Math.max(anEntry.count1, aPrevious.count1);
      aPrevious.count3 = Math.max(anEntry.count3, aPrevious.count3);
      aPrevious.count6 = Math.max(anEntry.count6, aPrevious.count6);
      aPrevious.count12 = Math.max(anEntry.count12, aPrevious.count12);
      aPrevious.count24 = Math.max(anEntry.count24, aPrevious.count24);
    }
    return aPrevious;
  }
}