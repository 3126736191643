// region imports

import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {DateSelectionType} from "../types/enums/DateSelectionType";

// endregion

// region local

/**
 * Slice state
 */
interface SelectDateState {
  /**
   * True to show dialog
   */
  visible: boolean;

  /**
   * Callback to call when dialog gets closed
   */
  onClose: (aResult: SelectDateResult | null) => any;
}

/**
 * Initial slice state
 */
const initialState: SelectDateState = {
  visible: false,
  onClose: () => null,
};

// endregion

// region exports

/**
 * Data returned by the dialog.
 */
export interface SelectDateResult {
  readonly dateSelection: DateSelectionType;
  readonly customDateStart: number;
  readonly customDateEnd: number;
}

/**
 * Alert dialog related values.
 */
export const selectDateSlice = createSlice({
  name: 'select-date',
  initialState,
  reducers: {
    hide: (state) => {
      state.visible = false;
    },
    setAll: (state, action: PayloadAction<SelectDateState>) => {
      state.onClose = action.payload.onClose;
      state.visible = action.payload.visible;
    }
  }
})

/**
 * Creates an async action to show the dialog. The other actions are not exported, since they are used only internally.
 */
export const showSelectDateUsingPayload = createAsyncThunk(
  'alert/show',
  async (payload , thunkApi) => new Promise<SelectDateResult | null>(resolve => {
    thunkApi.dispatch(selectDateSlice.actions.setAll({
      visible: true,
      onClose: (result) => {
        thunkApi.dispatch(selectDateSlice.actions.hide());
        resolve(result);
      }
    }));
  })
);
