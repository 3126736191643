// region imports

import React from "react";

// endregion

// region local

/**
 * Properties for component
 */
interface TextDropDownProps<T> {
  /**
   * When set, show label above dropdown.
   */
  readonly label?: React.ReactNode;

  /**
   * Values to show in the dropdown.
   */
  readonly values: Map<T, string>;

  /**
   * Current selected value (if any)
   */
  readonly value?: T;

  /**
   * Will be called when the user selects a new item
   */
  readonly onChange: (newValue: T) => any;

  /**
   * Set to true to autofocus the dropdown
   */
  readonly autoFocus?: boolean;
}

// endregion

// region exports

/**
 * {@link TextDropDown} shows a dropdown of texts using the select tag with option child tags.
 */
export const TextDropDown = <T extends unknown>(
  {
    label = null,
    values,
    value,
    onChange,
    autoFocus = false
  }: TextDropDownProps<T>): JSX.Element => {
  const keys: T[] = [];
  const options: React.ReactNode[] = [];
  let selected;
  values.forEach((valueEntry, keyEntry) => {
    const index = keys.length;
    keys.push(keyEntry);
    options.push(<option key={index} value={index}>{valueEntry}</option>);
    if (keyEntry === value) {
      selected = index;
    }
  });
  const selectTag = <select
    className="
      form-control
      block
      w-96
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-violet-600 focus:outline-none
    "
    onChange={(event) => onChange(keys[parseInt(event.target.value)])}
    autoFocus={autoFocus}
    value={selected}
  >
    {options}
  </select>;
  if (label) {
    return <label>
      <div>{label}</div>
      {selectTag}
    </label>;
  }
  else {
    return selectTag;
  }
};

// endregion