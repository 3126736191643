// region imports

import React, {useState} from "react";
import {Stack} from "../../styled/layouts/Stack";
import {StackDirection} from "../../styled/layouts/stack/StackDirection";
import {useAppSelector} from "../../../store/store";
import {FullWidthContentPanel} from "../../styled/containers/FullWidthContentPanel";
import {Text} from "../../styled/texts/Text";
import {TextPlacement} from "../../styled/texts/text/TextPlacement";
import {GeneralTable} from "../../styled/tables/GeneralTable";
import {ArpuTableData} from "../../../types/classes/table-data/ArpuTableData";

// endregion

// region exports

export const ArpuMain: React.FC = () => {
  const {webmastersToday, webmastersPast, loading, initialized} = useAppSelector(state => state.arpu);
  const tableTextSize = useAppSelector(state => state.application.tableTextSize);
  const [focus, setFocus] = useState(-1);
  return (
    <FullWidthContentPanel
      loading={loading || !initialized}
      title="ARPU top 20 webmasters"
    >
      <Stack
        direction={StackDirection.Column}
        gap={8}
      >
        <GeneralTable
          data={new ArpuTableData(webmastersToday, webmastersPast)}
          focus={focus}
          setFocus={setFocus}
          tableTextSize={tableTextSize}
        />
        <Text placement={TextPlacement.Paragraph}>
          Total revenue is the sum of all successful transaction amounts for subscriptions and recurring. Arpu is
          calculated by dividing the total revenue by the number of unique users.
        </Text>
      </Stack>
    </FullWidthContentPanel>
  );
}

// endregion