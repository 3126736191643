// region imports

import {UFSerialQueueAction} from "../../../UF/actions/UFSerialQueueAction";
import {DateRange} from "../../../types/classes/DateRange";
import {UFCallbackAction} from "../../../UF/actions/UFCallbackAction";
import {store} from "../../../store/store";
import {TransactionFailsLoadAction} from "./TransactionFailsLoadAction";
import {setHistoryData, setHistoryRange, setLoading} from "../../../store/data/transactionFailsSlice";

// endregion

// region exports

/**
 * Gets the visitor info data for the history view.
 */
export class TransactionFailsHistoryAction extends UFSerialQueueAction {
  constructor(anAlways: boolean, aDateRange: DateRange) {
    if (anAlways || !aDateRange.isEqual(store.getState().transactionFails.historyRange)) {
      super(
        new UFCallbackAction(() => store.dispatch(setLoading(true))),
        new TransactionFailsLoadAction(aDateRange, info => store.dispatch(setHistoryData(info))),
        new UFCallbackAction(() => store.dispatch(setHistoryRange(aDateRange))),
      );
    }
    else {
      super();
    }
  }
}

// endregion