import React from "react";
import {useAppSelector} from "../../../store/store";
import {GraphColor} from "../../../types/enums/GraphColor";
import {NumberType} from "../../../types/enums/NumberType";
import {HistoryPanel} from "../../panels/HistoryPanel";
import {Text} from "../../styled/texts/Text";
import {SubscriptionsAndRecurringsTools} from "../../../tools/data/SubscriptionsAndRecurringsTools";
import {TextPlacement} from "../../styled/texts/text/TextPlacement";

export const NewSubscriptionsAmountHistory: React.FC = () => {
  const {historyEntries, historyRange, loading} = useAppSelector(state => state.subscriptionsAndRecurrings);
  const tools = new SubscriptionsAndRecurringsTools();
  return (
    <HistoryPanel
      entries={historyEntries}
      loading={loading || !historyRange}
      tools={tools}
      providers={[
        {
          color: GraphColor.Other1,
          name: 'Revenue',
          type: NumberType.Currency,
          getValue: entry => tools.totalSubscriptionAmount(entry)
        }
      ]}
      bottom={
        <Text placement={TextPlacement.Paragraph}>
          The subscription revenue is the sum of the amount of all successful subscription transactions within the period.
        </Text>
      }
    />
  );
}
