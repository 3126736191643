// region imports

import {DateRange} from "../../../types/classes/DateRange";
import {ChurnEntryIO} from "../../../types/interfaces/data/io/ChurnEntryIO";
import {ChurnEntry} from "../../../types/interfaces/data/ChurnEntry";
import {ChurnResponseIO} from "../../../types/interfaces/data/io/ChurnResponseIO";
import {LoadDateRangeWithCallbackAction} from "../LoadDateRangeWithCallbackAction";

// endregion

// region exports

/**
 * Loads the transaction results, grouped per day for a certain date range and data target.
 */
export class ChurnLoadAction
  extends LoadDateRangeWithCallbackAction<ChurnResponseIO, ChurnEntry[]>
{
  // region public methods

  /**
   * Constructs an instance.
   *
   * @protected
   */
  public constructor(aDateRange: DateRange, aCallback: (entries: ChurnEntry[]) => any) {
    super('/subscription/churn', aDateRange, aCallback);
  }

  // endregion

  // region protected methods

  /**
   * @inheritDoc
   */
  protected process(aResponse: ChurnResponseIO): Promise<void> {
    this.callback(this.convertEntriesFromIO(aResponse.entries));
    return Promise.resolve();
  }

  // endregion

  // region private methods

  /**
   * Converts data received from the server to data structure used within the application.
   *
   * @param anEntry
   */
  private convertEntryFromIO(anEntry: ChurnEntryIO): ChurnEntry {
    return {
      date: new Date(anEntry.date),
      week: anEntry.week,
      cancellations: anEntry.cancellations || 0,
      registrations: anEntry.registrations || 0,
      active: anEntry.active || 0
    };
  }

  /**
   * Converts data received from the server to data structure used within the application.
   *
   * @param anEntries
   */
  private convertEntriesFromIO(anEntries: ChurnEntryIO[]): ChurnEntry[] {
    return anEntries.map(this.convertEntryFromIO)
  }

  // endregion
}

// endregion