// region imports

import {StoreTools} from "../../tools/StoreTools";
import {CancellationsEntry} from "../../types/interfaces/data/CancellationsEntry";

// endregion

// region exports

/**
 * Cancellations related values.
 */
export const cancellationsSlice = StoreTools.createDataListForDateRangeSlice<CancellationsEntry>(
  'cancellations'
);

/**
 * The actions for the slice
 */
export const {
  initializeDashboard,
  setHistoryRange,
  setLoading,
  setDashboard,
  setHistoryEntries,
  resetDashboard,
} = cancellationsSlice.actions;

// endregion