// region imports

import React from "react";
import {useAppSelector} from "../../../store/store";
import {Stack} from "../../styled/layouts/Stack";
import {WebsiteDomainButtons} from "../../buttons/WebsiteDomainButtons";
import {NumberType} from "../../../types/enums/NumberType";
import {StackDirection} from "../../styled/layouts/stack/StackDirection";
import {FullWidthContentPanel} from "../../styled/containers/FullWidthContentPanel";
import {GeneralTableWithTitle} from "../../tables/GeneralTableWithTitle";
import {CurrentAndPreviousRankedTableData} from "../../../types/classes/table-data/CurrentAndPreviousRankedTableData";
import {CurrentAndPreviousRankedRow} from "../../../types/classes/CurrentAndPreviousRankedRow";
import {DateTools} from "../../../tools/DateTools";
import {HeaderTitle} from "../../text/HeaderTitle";

// endregion

// region exports

export const VisitorInfoHistory: React.FC = () => {
  const {loading, historyCurrent, historyPrevious, historyRange} = useAppSelector(state => state.visitorInfo);
  const domain = useAppSelector(state => state.application.websiteDomain);
  const tableTextSize = useAppSelector(state => state.application.tableTextSize);
  const available = !loading && historyRange[domain];
  const currentTitle = available
    ? <HeaderTitle block>{DateTools.formatDateRange(historyRange[domain]!, false, true)}</HeaderTitle> : null;
  const previousTitle = available
    ? <HeaderTitle block>{DateTools.formatDateRange(historyRange[domain]!.getPrevious(), false, true)}</HeaderTitle> : null;
  return (
    <FullWidthContentPanel
      loading={!available}
      title="Visitor Information History"
    >
      {
        available &&
        <Stack
          direction={StackDirection.Column}
          gap={4}
        >
          <WebsiteDomainButtons/>
          <Stack
            direction={StackDirection.Row}
            gap={8}
            wrap
          >
            <GeneralTableWithTitle
              tableTextSize={tableTextSize}
              title="OS"
              data={
                new CurrentAndPreviousRankedTableData(
                  currentTitle,
                  previousTitle,
                  CurrentAndPreviousRankedRow.createRows(
                    historyCurrent[domain].osEntries, historyPrevious[domain].osEntries,
                    NumberType.Integer, true, 15
                  ),
                  true,
                  false,
                  false
                )
              }
            />
            <GeneralTableWithTitle
              tableTextSize={tableTextSize}
              title="Country"
              data={
                new CurrentAndPreviousRankedTableData(
                  currentTitle,
                  previousTitle,
                  CurrentAndPreviousRankedRow.createRows(
                    historyCurrent[domain].countryEntries, historyPrevious[domain].countryEntries,
                    NumberType.Integer, true, 15
                  ),
                  true,
                  false,
                  false
                )
              }
            />
            <GeneralTableWithTitle
              tableTextSize={tableTextSize}
              title="Language"
              data={
                new CurrentAndPreviousRankedTableData(
                  currentTitle,
                  previousTitle,
                  CurrentAndPreviousRankedRow.createRows(
                    historyCurrent[domain].languageEntries, historyPrevious[domain].languageEntries,
                    NumberType.Integer, true, 15
                  ),
                  true,
                  false,
                  false
                )
              }
            />
            <GeneralTableWithTitle
              tableTextSize={tableTextSize}
              title="Mobile"
              data={
                new CurrentAndPreviousRankedTableData(
                  currentTitle,
                  previousTitle,
                  CurrentAndPreviousRankedRow.createRows(
                    historyCurrent[domain].mobileEntries, historyPrevious[domain].mobileEntries,
                    NumberType.Integer, false, 15
                  ),
                  false,
                  false,
                  false
                )
              }
            />
            <GeneralTableWithTitle
              tableTextSize={tableTextSize}
              title="Resolution"
              data={
                new CurrentAndPreviousRankedTableData(
                  currentTitle,
                  previousTitle,
                  CurrentAndPreviousRankedRow.createRows(
                    historyCurrent[domain].resolutionEntries, historyPrevious[domain].resolutionEntries,
                    NumberType.Integer, false, 15
                  ),
                  false,
                  false,
                  false
                )
              }
            />
          </Stack>
        </Stack>
      }
    </FullWidthContentPanel>
  );
}

// endregion