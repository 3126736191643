// region imports

import React, {useState} from "react";
import {FullWidthDashboardPanel} from "../../styled/containers/FullWidthDashboardPanel";
import {useAppSelector} from "../../../store/store";
import {GeneralTable} from "../../styled/tables/GeneralTable";
import {mainController} from "../../../controllers/mainController";
import {UserLocation} from "../../../types/enums/UserLocation";
import {
  SubscriptionsCountDashboardTableData
} from "../../../types/classes/table-data/SubscriptionsCountDashboardTableData";
import {RadioButtonsBar} from "../../styled/buttons/RadioButtonsBar";
import {
  SubscriptionsAmountDashboardTableData
} from "../../../types/classes/table-data/SubscriptionsAmountDashboardTableData";

// endregion

// region local types

enum ViewType {
  Count,
  Revenue
}

// endregion

// region exports

export const SubscriptionsAndRecurringsDashboard: React.FC = () => {
  const current = useAppSelector(state => state.subscriptionsAndRecurrings);
  const legacy = useAppSelector(state => state.legacy);
  const legacyBookings = useAppSelector(state => state.legacyBookings);
  const bankBookings = useAppSelector(state => state.bankBookings);
  const tableTextSize = useAppSelector(state => state.application.tableTextSize);
  const [focus, setFocus] = useState(-1);
  const [viewType, setViewType] = useState(ViewType.Count);
  const available = !current.loading && current.initializedDashboard
    && !legacy.loading && legacy.initializedDashboard
    && !legacyBookings.loading && legacyBookings.initializedDashboard
    && !bankBookings.loading && bankBookings.initializedDashboard;
  const values = new Map<ViewType, string>([
    [ViewType.Count, 'Count'],
    [ViewType.Revenue, 'Revenue'],
  ]);
  return (
    <FullWidthDashboardPanel
      loading={!available}
      title="New subscription and recurring transactions"
      onClick={() => mainController.setUserLocation(UserLocation.SubscriptionsAndRecurrings)}
    >
      {
        available &&
        <>
          <RadioButtonsBar
            onChange={(value) => setViewType(value)}
            values={values}
            value={viewType}
          />
        <GeneralTable
          data={
          viewType === ViewType.Count
            ? new SubscriptionsCountDashboardTableData(
              current.dashboardEntries, legacy.dashboardEntries, legacyBookings.dashboardEntries,
              bankBookings.dashboardEntries, tableTextSize
            )
            : new SubscriptionsAmountDashboardTableData(
              current.dashboardEntries, legacy.dashboardEntries, legacyBookings.dashboardEntries,
              bankBookings.dashboardEntries, tableTextSize
            )
          }
          focus={focus}
          setFocus={setFocus}
          tableTextSize={tableTextSize}
        />
        </>
      }

    </FullWidthDashboardPanel>
  )
}

// endregion