// region imports

import {UFSerialQueueAction} from "../../../UF/actions/UFSerialQueueAction";
import {UFCallbackAction} from "../../../UF/actions/UFCallbackAction";
import {
  initializeDashboard,
  resetDashboard,
  setDashboard,
  setLoading
} from "../../../store/data/transactionResultsSlice";
import {store} from "../../../store/store";
import {DateRange} from "../../../types/classes/DateRange";
import {TransactionResultsLoadAction} from "./TransactionResultsLoadAction";

// endregion

// region exports

/**
 * An action that loads all data used for transaction results dashboard.
 */
export class TransactionResultsDashboardAction extends UFSerialQueueAction {
  constructor(anAlways: boolean, aDateRange: DateRange) {
    if (anAlways || !store.getState().transactionResults.initializedDashboard) {
      super(
        new UFCallbackAction(() => store.dispatch(setLoading(true))),
        new TransactionResultsLoadAction(aDateRange, entries => store.dispatch(setDashboard(entries))),
        new UFCallbackAction(() => store.dispatch(initializeDashboard()))
      );
      store.dispatch(resetDashboard());
    }
    else {
      super();
    }
  }
}

// endregion