import React from "react";
import {UFChildrenProp} from "../../UF-react/types/UFChildrenProp";
import {Stack} from "../styled/layouts/Stack";
import {StackDirection} from "../styled/layouts/stack/StackDirection";

interface ButtonsProps extends UFChildrenProp {
}

/**
 * {@link Buttons} is a horizontal {@link Stack} that can be used to style a row of buttons.
 */
export const Buttons: React.FC<ButtonsProps> = ({children}) => (
  <Stack
    direction={StackDirection.Row}
    gap={1}
    fullWidth
  >
    {children}
  </Stack>
);
