import React from "react";
import {CurrentAndPreviousValue} from "../../../types/classes/CurrentAndPreviousValue";
import {useAppSelector} from "../../../store/store";
import {DataTools} from "../../../tools/DataTools";
import {NumberType} from "../../../types/enums/NumberType";
import {LastAndPreviousDaysDashboardPanel} from "../../panels/LastAndPreviousDaysDashboardPanel";
import {mainController} from "../../../controllers/mainController";
import {UserLocation} from "../../../types/enums/UserLocation";
import {DateWithWeekAndValueTools} from "../../../tools/data/DateWithWeekAndValueTools";

export const CoinsDashboard: React.FC = () => {
  const entries = useAppSelector(state => state.coins.dashboardEntries);
  const loading = useAppSelector(state => state.coins.loading);
  const initialized = useAppSelector(state => state.coins.initializedDashboard);
  const tools = new DateWithWeekAndValueTools();
  const current = DataTools.reduceForDashboardCurrent(entries, tools);
  const previous = DataTools.reduceForDashboardPrevious(entries, tools);
  const currentAndPrevious = new CurrentAndPreviousValue(current, previous, NumberType.Currency);
  return (
    <LastAndPreviousDaysDashboardPanel
      title="Coins sales"
      currentAndPrevious={currentAndPrevious}
      loading={loading || !initialized}
      onClick={() => mainController.setUserLocation(UserLocation.Coins)}
    />
  );
};
