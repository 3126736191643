// region imports

import {StoreTools} from "../../tools/StoreTools";
import {SubscriptionAndRecurringEntry} from "../../types/interfaces/data/SubscriptionAndRecurringEntry";

// endregion

// region exports

/**
 * Coins related values.
 */
export const subscriptionsAndRecurringsSlice = StoreTools.createDataListForDateRangeSlice<SubscriptionAndRecurringEntry>(
  'new-subscriptions'
);

/**
 * The actions for the slice
 */
export const {
  initializeDashboard,
  setHistoryRange,
  setLoading,
  setDashboard,
  setHistoryEntries,
  resetDashboard,
} = subscriptionsAndRecurringsSlice.actions;

// endregion