// region imports

import {SubscriptionAndRecurringEntry} from "../../interfaces/data/SubscriptionAndRecurringEntry";
import {NumberType} from "../../enums/NumberType";
import {CurrentAndPreviousValue} from "../CurrentAndPreviousValue";
import {LegacyEntry} from "../../interfaces/data/LegacyEntry";
import {SubscriptionsDashboardTableData} from "./SubscriptionsDashboardTableData";
import {WebsiteAndBank} from "../../interfaces/data/WebsiteAndBank";
import {RecurringPerWebsiteAndBank} from "../../interfaces/data/RecurringPerWebsiteAndBank";
import {LegacyTools} from "../../../tools/data/LegacyTools";
import {LegacyBookingsEntry} from "../../interfaces/data/LegacyBookingsEntry";
import {LegacyBookingPaymentType} from "../../enums/LegacyBookingPaymentType";
import {LegacyBookingTools} from "../../../tools/data/LegacyBookingTools";
import {BankBookingsEntry} from "../../interfaces/data/BankBookingsEntry";
import {BankBookingTools} from "../../../tools/data/BankBookingTools";
import {BankBookingPaymentType} from "../../enums/BankBookingPaymentType";

// endregion

// region exports

export class SubscriptionsAmountDashboardTableData extends SubscriptionsDashboardTableData {
  /**
   * Convert subscription entries to current and previous value entries
   */
  protected getAsCurrentAndPreviousForPlan(anEntries: SubscriptionAndRecurringEntry[], anId: number): CurrentAndPreviousValue[] {
    let previous: SubscriptionAndRecurringEntry | null = null;
    const result: CurrentAndPreviousValue[] = [];
    anEntries.forEach(entry => {
      result.push(
        new CurrentAndPreviousValue(
          {value: entry.subscriptionAmount.get(anId) || 0},
          {value: previous?.subscriptionAmount.get(anId) || 0},
          NumberType.Currency
        ),
        new CurrentAndPreviousValue(
          {value: entry.recurringAmount.get(anId) || 0},
          {value: previous?.recurringAmount.get(anId) || 0},
          NumberType.Currency
        )
      );
      previous = entry;
    });
    return result;
  }

  /**
   * Convert legacy entries to current and previous value entries
   */
  protected getAsCurrentAndPreviousForWebsite(
    anEntries: LegacyEntry[], aWebsiteAndBank: WebsiteAndBank
  ): CurrentAndPreviousValue[] {
    let previous: RecurringPerWebsiteAndBank | null = null;
    const tools = new LegacyTools();
    const result: CurrentAndPreviousValue[] = [];
    const empty = new CurrentAndPreviousValue(
      {value: 0},
      {value: 0},
      NumberType.Currency
    );
    anEntries.forEach(entry => {
      const item = tools.findForWebsiteAndBank(entry.payments, aWebsiteAndBank) || null;
      result.push(
        empty,
        new CurrentAndPreviousValue(
          {value: item?.amount || 0},
          {value: previous?.amount || 0},
          NumberType.Currency
        )
      );
      previous = item;
    });
    return result;
  }

  /**
   * Convert subscription entries to current and previous value entries
   */
  protected getAsCurrentAndPreviousForLegacyBooking(
    anEntries: LegacyBookingsEntry[], aType: LegacyBookingPaymentType
  ): CurrentAndPreviousValue[] {
    let previous: LegacyBookingsEntry | null = null;
    const tools = new LegacyBookingTools();
    const result: CurrentAndPreviousValue[] = [];
    const empty = new CurrentAndPreviousValue(
      {value: 0},
      {value: 0},
      NumberType.Currency
    );
    anEntries.forEach(entry => {
      result.push(
        empty,
        new CurrentAndPreviousValue(
          {value: tools.getTotalPayment(entry, aType)},
          {value: previous ? tools.getTotalPayment(previous, aType) : 0},
          NumberType.Currency
        )
      );
      previous = entry;
    });
    return result;
  }

  /**
   * Convert subscription entries to current and previous value entries
   */
  protected getAsCurrentAndPreviousForBankBooking(
    anEntries: BankBookingsEntry[], aType: BankBookingPaymentType
  ): CurrentAndPreviousValue[] {
    let previous: BankBookingsEntry | null = null;
    const tools = new BankBookingTools();
    const result: CurrentAndPreviousValue[] = [];
    const empty = new CurrentAndPreviousValue(
      {value: 0},
      {value: 0},
      NumberType.Currency
    );
    anEntries.forEach(entry => {
      result.push(
        empty,
        new CurrentAndPreviousValue(
          {value: tools.getTotalPayment(entry, aType)},
          {value: previous ? tools.getTotalPayment(previous, aType) : 0},
          NumberType.Currency
        )
      );
      previous = entry;
    });
    return result;
  }

  // endregion
}

// endregion