// region imports

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

// endregion

// region local

/**
 * Slice state
 */
interface NetworkState {
  /**
   * True while there is communication with the server
   */
  networkBusy: boolean;
}

/**
 * Initial slice state
 */
const initialState: NetworkState = {
  networkBusy: false,
};

// endregion

// region exports

/**
 * Network related values.
 */
export const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    setNetworkBusy: (state, action: PayloadAction<boolean>) => {
      state.networkBusy = action.payload;
    },
  }
})

/**
 * The actions for the slice
 */
export const { setNetworkBusy } = networkSlice.actions

// endregion
