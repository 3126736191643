// region imports

import {UFOptionalOnClickProp} from "../../../UF-react/types/UFOptionalOnClickProp";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";
import React from "react";
import {LoadingProp} from "../../../types/interfaces/props/LoadingProp";
import {FullWidthContentPanel} from "./FullWidthContentPanel";
import {TitleProp} from "../../../types/interfaces/props/TitleProp";
import {Title} from "../texts/Title";

// endregion

// region local

interface FullWidthMainPanelProps extends UFChildrenProp, UFOptionalOnClickProp, LoadingProp, TitleProp {
}

// endregion

// region exports

/**
 * A panel to be used as main panel (for history views).
 */
export const MainPanel: React.FC<FullWidthMainPanelProps> = (
  {
    onClick, title, children, loading
  }
) => (
  <FullWidthContentPanel
    title={<Title bottomSpacing>{title}</Title>}
    loading={loading}
    onClick={onClick}
    fitContent={true}
  >
    {children}
  </FullWidthContentPanel>
);

// endregion