// region imports

import {CurrentAndPreviousRankedTableData} from "./CurrentAndPreviousRankedTableData";
import {RevenueAndUsersAndWebmasterFields} from "../../interfaces/data/fields/RevenueAndUsersAndWebmasterFields";
import {NumberType} from "../../enums/NumberType";
import {CurrentAndPreviousRankedRow} from "../CurrentAndPreviousRankedRow";
import {HeaderTitle} from "../../../components/text/HeaderTitle";
import React from "react";
import {Text} from "../../../components/styled/texts/Text";
import {TextSize} from "../../../components/styled/texts/text/TextSize";
import {TextPlacement} from "../../../components/styled/texts/text/TextPlacement";
import {NumberTypeTools} from "../../../tools/NumberTypeTools";
import {ValueAndKeyAndLabelFields} from "../../interfaces/data/fields/ValueAndKeyAndLabelFields";
import {ArpuTools} from "../../../tools/data/ArpuTools";

// endregion

// region local

/**
 * Helper type that adds values required by {@link CurrentAndPreviousRankedTableData} to
 * {@link RevenueAndUsersAndWebmasterFields}
 */
interface ArpuRowEntry extends RevenueAndUsersAndWebmasterFields, ValueAndKeyAndLabelFields {
}

/**
 * Converts data, calculate the arpu value and webmasterId for both label and key.
 *
 * Todo: get name for webmasterId and use for label
 */
function convertToArpuRowEntry(anEntry: RevenueAndUsersAndWebmasterFields): ArpuRowEntry {
  return {
    value: ArpuTools.calc(anEntry),
    key: anEntry.webmasterId,
    label: anEntry.webmasterId,
    ...anEntry
  }
}

// endregion

// region exports

/**
 * {@link ArpuTableData} extends {@link CurrentAndPreviousRankedTableData} and shows two extra columns with users &
 * revenue information.
 */
export class ArpuTableData extends CurrentAndPreviousRankedTableData {
  // region public methods

  constructor(aTodayEntries: RevenueAndUsersAndWebmasterFields[], aPastEntries: RevenueAndUsersAndWebmasterFields[]) {
    const rows = CurrentAndPreviousRankedRow.createRows(
      aTodayEntries.map(convertToArpuRowEntry),
      aPastEntries.map(convertToArpuRowEntry),
      NumberType.Currency,
      false,
      20
    );
    super(
      <HeaderTitle>ARPU today</HeaderTitle>,
      <HeaderTitle>ARPU 90 days ago</HeaderTitle>,
      rows,
      false,
      false,
      false
    );
  }

  // endregion

  // region GeneralTableData

  get columnCount(): number {
    return super.columnCount + 1;
  }

  cell(aColumn: number, aRow: number): React.ReactNode {
    switch (aColumn) {
      case 0:
      case 1:
      case 2:
      case 3:
        return super.cell(aColumn, aRow);
      case 4:
        return this.getInfo(this.rowData(aRow).current as ArpuRowEntry);
      case 5:
      case 6:
      case 7:
        return super.cell(aColumn - 1, aRow);
      case 8:
        return this.getInfo(this.rowData(aRow).previous as ArpuRowEntry);
    }
    return super.cell(aColumn, aRow);
  }

  header(aColumn: number, aRow: number): React.ReactNode {
    return super.header(this.adjustColumn(aColumn), aRow);
  }

  cellLeftBorder(aColumn: number, aRow: number): boolean {
    return super.cellLeftBorder(this.adjustColumn(aColumn), aRow);
  }

  cellRightBorder(aColumn: number, aRow: number): boolean {
    return (aColumn === 8) || super.cellRightBorder(this.adjustColumn(aColumn), aRow);
  }

  cellLeftSpacing(aColumn: number, aRow: number): boolean {
    return super.cellLeftSpacing(this.adjustColumn(aColumn), aRow);
  }

  cellRightSpacing(aColumn: number, aRow: number): boolean {
    return super.cellRightSpacing(this.adjustColumn(aColumn), aRow);
  }

  headerColSpan(aColumn: number, aRow: number): number {
    switch (aColumn) {
      case 0:
        return 6;
      case 6:
        return 4;
      default:
        return 1;
    }
  }

  // endregion

  // region private methods

  private adjustColumn(aColumn: number): number {
    switch (aColumn) {
      case 0:
      case 1:
      case 2:
        return aColumn;
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        return aColumn - 1;
      case 8:
        return 6;
      default:
        return aColumn;
    }
  }

  private getInfo(anEntry: RevenueAndUsersAndWebmasterFields): React.ReactNode {
    if (anEntry.webmasterId && (anEntry.webmasterId.length > 0)) {
      return (
        <Text
          size={TextSize.VerySmall} placement={TextPlacement.Block}
        >
          {NumberTypeTools.getAsText(anEntry.revenue, NumberType.Currency)}<br/>
          {NumberTypeTools.getAsText(anEntry.users, NumberType.Integer)}
        </Text>
      );
    }
    return null;
  }

  // endregion
}

// endregion