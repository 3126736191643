// region imports

import {UFOptionalOnClickProp} from "../../../UF-react/types/UFOptionalOnClickProp";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";
import React from "react";
import {LoadingProp} from "../../../types/interfaces/props/LoadingProp";
import {Text} from "../texts/Text";
import {FullWidthContentPanel} from "./FullWidthContentPanel";
import {TitleProp} from "../../../types/interfaces/props/TitleProp";

// endregion

// region local

interface FullWidthDashboardPanelProps extends UFChildrenProp, UFOptionalOnClickProp, LoadingProp, TitleProp {
}

// endregion

// region exports

/**
 * A panel to be used with the dashboard. It will use the full width of the available dashboard space.
 */
export const FullWidthDashboardPanel: React.FC<FullWidthDashboardPanelProps> = (
  {
    onClick, title, children, loading
  }
) => (
  <FullWidthContentPanel
    title={<Text>{title}</Text>}
    loading={loading}
    onClick={onClick}
  >
    {children}
  </FullWidthContentPanel>
);

// endregion