// region imports

import React from "react";
import {GeneralTable} from "../styled/tables/GeneralTable";
import {MultipleListsTableData} from "../../types/classes/table-data/MultipleListsTableData";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {setFocus} from "../../store/userInterfaceSlice";
import {GraphNumberList} from "../../types/classes/GraphNumberList";

// endregion

// region local

interface MultipleListsTableProps {
  /**
   * Data tho show
   */
  readonly lists: GraphNumberList[];

  /**
   * Labels to use within the table
   */
  readonly tableLabels: React.ReactNode[];

  /**
   * Name for labels
   */
  readonly columnName: string;

  /**
   * When true show extra columns with difference to previous row information
   */
  readonly showDifference?: boolean;

  /**
   * When true show an extra row
   */
  readonly totalRow?: boolean;
}

// endregion

// region exports

/**
 * Shows a {@link GeneralTable} for a {@link MultipleListsTableData}.
 */
export const MultipleListsTable: React.FC<MultipleListsTableProps> = (
  {
    lists,
    columnName,
    tableLabels,
    showDifference = false,
    totalRow = true
  }
) => {
  const focus = useAppSelector(state => state.userInterface.focus);
  const tableTextSize = useAppSelector(state => state.application.tableTextSize);
  const dispatch = useAppDispatch();
  const tableData = new MultipleListsTableData(lists, columnName, tableLabels, showDifference, totalRow);
  return (
    <GeneralTable
      data={tableData}
      focus={focus - tableData.start}
      setFocus={(value) => dispatch(setFocus(value + tableData.start))}
      tableTextSize={tableTextSize}
    />
  );
}

// endregion