
export enum NumberType {
  /**
   * Whole numbers
   */
  Integer,

  /**
   * Currency values
   */
  Currency,

  /**
   * Float with .0
   */
  Float1,

  /**
   * Float with .00
   */
  Float2,

  /**
   * Float with .000
   */
  Float3,

  /**
   * Float with .0000
   */
  Float4,

  /**
   * Float with .00000
   */
  Float5,

  /**
   * Duration in seconds
   */
  Duration,

  /**
   * Show value as percentage (xx.x%)
   */
  Percentage,
}