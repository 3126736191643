import {GeneralTableData} from "./GeneralTableData";
import {GraphColor} from "../../enums/GraphColor";
import React from "react";
import {GraphNumberList} from "../GraphNumberList";
import {LegendColor} from "../../../components/styled/graphs/line-graph/LegendColor";
import {Position} from "../../enums/Position";

/**
 * This data can be used to display the first value of every {@link GraphNumberList} including a ranking column.
 *
 * The class will use the coloring information to set a background.
 */
export class RankedWithColorTableData extends GeneralTableData {
  // region private methods

  private readonly m_lists: GraphNumberList[];

  // endregion

  // region public methods

  constructor(aLists: GraphNumberList[]) {
    super();
    this.m_lists = aLists;
  }

  // endregion

  // region GeneralTableData

  /**
   * @inheritDoc
   */
  cell(aColumn: number, aRow: number): React.ReactNode {
    switch(aColumn) {
      case 0:
        return (aRow + 1).toString();
      case 1:
        return <LegendColor color={this.m_lists[aRow].color} light={false} />;
      case 2:
        return this.m_lists[aRow].getAsText(0);
      case 3:
        return this.m_lists[aRow].columnTitle;
      default:
        return '';
    }
  }

  /**
   * @inheritDoc
   */
  cellColor(aColumn: number, aRow: number): GraphColor {
    return aColumn === 0 ? GraphColor.None : this.m_lists[aRow].color;
  }

  /**
   * @inheritDoc
   */
  get columnCount(): number {
    return 4;
  }

  /**
   * @inheritDoc
   */
  header(aColumn: number, aRow: number): React.ReactNode {
    switch(aColumn) {
      case 0:
        return 'rank';
      case 1:
        return 'color';
      case 2:
        return 'count';
      case 3:
        return 'description';
      default:
        return '';
    }
  }

  /**
   * @inheritDoc
   */
  get rowCount(): number {
    return this.m_lists.length;
  }

  /**
   * @inheritDoc
   */
  cellHorizontalPosition(aColumn: number, aRow: number): Position {
    switch(aColumn) {
      case 0:
      case 2:
        return Position.End;
      case 1:
        return Position.Center;
      default:
        return Position.Start;
    }
  }

  // endregion
}