import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";
import React from "react";

interface DialogProps extends UFChildrenProp {
  readonly visible: boolean;
}

export const Dialog: React.FC<DialogProps> = ({children, visible}) => {
  if (!visible) {
    return null;
  }
  return (
    <div className="bg-white sm:rounded-lg shadow min-w-[15rem] overflow-hidden z-30 relative">
      {children}
    </div>
  );
};
