import React from "react";
import {useAppSelector} from "../../../store/store";
import {DataTools} from "../../../tools/DataTools";
import {NumberType} from "../../../types/enums/NumberType";
import {mainController} from "../../../controllers/mainController";
import {UserLocation} from "../../../types/enums/UserLocation";
import {CurrentAndPreviousRow} from "../../../types/classes/CurrentAndPreviousRow";
import {CurrentAndPreviousTableDashboardPanel} from "../../panels/CurrentAndPreviousTableDashboardPanel";
import {GraphColor} from "../../../types/enums/GraphColor";
import {CancellationsTools} from "../../../tools/data/CancellationsTools";

export const CancellationsDashboard: React.FC = () => {
  const entries = useAppSelector(state => state.cancellations.dashboardEntries);
  const loading = useAppSelector(state => state.cancellations.loading);
  const initialized = useAppSelector(state => state.cancellations.initializedDashboard);
  const tools = new CancellationsTools();
  const current = DataTools.reduceForDashboardCurrent(entries, tools);
  const previous = DataTools.reduceForDashboardPrevious(entries, tools);
  const rows = [
    new CurrentAndPreviousRow(
      {value: current.count1}, {value: previous.count1}, NumberType.Integer, '1 month sub.', GraphColor.Other1, true
    ),
    new CurrentAndPreviousRow(
      {value: current.count3}, {value: previous.count3}, NumberType.Integer, '3 month sub.', GraphColor.Other1, true
    ),
    new CurrentAndPreviousRow(
      {value: current.count6}, {value: previous.count6}, NumberType.Integer, '6 month sub.', GraphColor.Other1, true
    ),
    new CurrentAndPreviousRow(
      {value: current.count12}, {value: previous.count12}, NumberType.Integer, '12 month sub.', GraphColor.Other1, true
    ),
    new CurrentAndPreviousRow(
      {value: current.count24}, {value: previous.count24}, NumberType.Integer, '24 month sub.', GraphColor.Other1, true
    ),
  ];
  return (
    <CurrentAndPreviousTableDashboardPanel
      title="Cancellations"
      rows={rows}
      loading={loading || !initialized}
      onClick={() => mainController.setUserLocation(UserLocation.Cancellations)}
    />
  );
};
