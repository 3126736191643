import React from "react";

interface HorizontalLabelsProps {
  readonly labels: React.ReactNode[];
  readonly count: number;
}

/**
 * Renders the horizontal labels at the bottom.
 */
export const HorizontalLabels: React.FC<HorizontalLabelsProps> = ({labels, count}) => {
  const wrappedLabels = [];
  for (let index = 0; index < count; index++) {
    wrappedLabels.push(
      <div className="w-0" key={index}>
        <div className="w-fit -translate-x-1/2 whitespace-nowrap text-sm text-center leading-none">
          {labels[index]}
        </div>
      </div>
    );
    if (index < count - 1) {
      wrappedLabels.push(
        <div
          className="flex-1"
          key={index + count}
        />
      );
    }
  }
  return (
    <div className="line-graph__horizontal-labels-row flex flex-row justify-between">
      {wrappedLabels}
    </div>
  );
}