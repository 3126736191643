import React from "react";
import {CurrentAndPreviousValue} from "../../../types/classes/CurrentAndPreviousValue";
import {useAppSelector} from "../../../store/store";
import {DataTools} from "../../../tools/DataTools";
import {NumberType} from "../../../types/enums/NumberType";
import {LastAndPreviousDaysDashboardPanel} from "../../panels/LastAndPreviousDaysDashboardPanel";
import {mainController} from "../../../controllers/mainController";
import {UserLocation} from "../../../types/enums/UserLocation";
import {UserRegistrationsTools} from "../../../tools/data/UserRegistrationsTools";

export const UserRegistrationsDashboard: React.FC = () => {
  const {dashboardEntries, loading, initializedDashboard} = useAppSelector(state => state.userRegistrations);
  const tools = new UserRegistrationsTools();
  const current = DataTools.reduceForDashboardCurrent(dashboardEntries, tools);
  const previous = DataTools.reduceForDashboardPrevious(dashboardEntries, tools);
  const currentAndPrevious = new CurrentAndPreviousValue(
    {value: current.total}, {value: previous.total}, NumberType.Integer
  );
  return (
    <LastAndPreviousDaysDashboardPanel
      title="User registrations"
      currentAndPrevious={currentAndPrevious}
      loading={loading || !initializedDashboard}
      onClick={() => mainController.setUserLocation(UserLocation.UserRegistrations)}
    />
  );
};
