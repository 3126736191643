import React from "react";

interface SpacerProps {
  readonly size?: number;
}

const sizes = [
  'basis-0', 'basis-1', 'basis-2', 'basis-3', 'basis-4', 'basis-5', 'basis-6', 'basis-7', 'basis-8', 'basis-9',
  'basis-10', 'basis-11', 'basis-12', 'basis-13', 'basis-14', 'basis-15', 'basis-16', 'basis-17', 'basis-18', 'basis-19',
  'basis-20', 'basis-21', 'basis-22', 'basis-23', 'basis-24', 'basis-25', 'basis-26', 'basis-27', 'basis-28', 'basis-29',
];

/**
 * Use {@link Spacer} to add an empty space to a {@link Stack}.
 */
export const Spacer: React.FC<SpacerProps> = ({size = 1}) => (
  <div className={sizes[size]}></div>
);
