import {DateAndWeekTools} from "./base/DateAndWeekTools";
import {LegacyBookingsEntry} from "../../types/interfaces/data/LegacyBookingsEntry";
import {LegacyBookingPaymentType} from "../../types/enums/LegacyBookingPaymentType";

export class LegacyBookingTools extends DateAndWeekTools<LegacyBookingsEntry> {
  factory(aDate: Date, aWeek: number): LegacyBookingsEntry {
    return {
      date: aDate,
      week: aWeek,
      incomingPayments: 0,
      incomingCount: 0,
      outgoingPayments: 0,
      outgoingCount: 0,
      dunningFees: 0,
      dunningCount: 0,
      debitFees: 0,
      debitCount: 0
    }
  }

  reduce(aPrevious: LegacyBookingsEntry, anEntry: Readonly<LegacyBookingsEntry>): LegacyBookingsEntry {
    return {
      date: aPrevious.date,
      week: aPrevious.week,
      incomingPayments: aPrevious.incomingPayments + anEntry.incomingPayments,
      incomingCount: aPrevious.incomingCount + anEntry.incomingCount,
      outgoingPayments: aPrevious.outgoingPayments + anEntry.outgoingPayments,
      outgoingCount: aPrevious.outgoingCount + anEntry.outgoingCount,
      dunningFees: aPrevious.dunningFees + anEntry.dunningFees,
      dunningCount: aPrevious.dunningCount + anEntry.dunningCount,
      debitFees: aPrevious.debitFees + anEntry.debitFees,
      debitCount: aPrevious.debitCount + anEntry.debitCount
    }
  }

  /**
   * Gets the total payment for a type. If type is outgoing, the value will be negative.
   *
   * @param anEntry
   * @param aType
   */
  getTotalPayment(anEntry: Readonly<LegacyBookingsEntry>, aType: LegacyBookingPaymentType): number {
    switch(aType) {
      case LegacyBookingPaymentType.Incoming:
        return anEntry.incomingPayments + anEntry.dunningFees;
      case LegacyBookingPaymentType.Outgoing:
        return -anEntry.outgoingPayments -anEntry.debitFees;
    }
  }

  getTotalCount(anEntry: Readonly<LegacyBookingsEntry>, aType: LegacyBookingPaymentType): number {
    switch(aType) {
      case LegacyBookingPaymentType.Incoming:
        return anEntry.incomingCount + anEntry.dunningCount;
      case LegacyBookingPaymentType.Outgoing:
        return anEntry.outgoingCount + anEntry.debitCount;
    }
  }
}