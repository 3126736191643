// region imports

import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {DataPeriodType} from "../types/enums/DataPeriodType";
import {UserLocation} from "../types/enums/UserLocation";
import {DateSelectionType} from "../types/enums/DateSelectionType";
import {WebsiteDomainType} from "../types/enums/WebsiteDomainType";
import {TableTextSize} from "../types/enums/TableTextSize";

// endregion

// region local

/**
 * Slice state
 */
interface ApplicationState {
  /**
   * Current selected date period.
   */
  dataPeriod: DataPeriodType;

  /**
   * Current location user is at
   */
  location: UserLocation;

  /**
   * Current selected website domain (not used by all stats)
   */
  websiteDomain: WebsiteDomainType;

  /**
   * Which date to use
   */
  dateSelection: DateSelectionType;

  /**
   * Custom date start, used when {@link dateSelection} is {@link DateSelectionType.Custom}. It contains the time in
   * milliseconds since 1970-1-1 0:0:0
   */
  customDateStart: number;

  /**
   * Custom date end, used when {@link dateSelection} is {@link DateSelectionType.Custom}. It contains the time in
   * milliseconds since 1970-1-1 0:0:0
   */
  customDateEnd: number;

  /**
   * Size of the text in the tables
   */
  tableTextSize: TableTextSize;
}

/**
 * Initial slice state
 */
const initialState: ApplicationState = {
  dataPeriod: DataPeriodType.Day,
  location: UserLocation.Start,
  websiteDomain: WebsiteDomainType.FundoradoDe,
  dateSelection: DateSelectionType.Today,
  customDateStart: Date.now(),
  customDateEnd: Date.now(),
  tableTextSize: TableTextSize.Normal
};

// endregion

// region exports

/**
 * Application related values, this slice gets saved in local storage.
 */
export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setDataPeriod: (state, action: PayloadAction<DataPeriodType>) => {
      state.dataPeriod = action.payload;
    },
    setLocation: (state, action: PayloadAction<UserLocation>) => {
      state.location = action.payload;
    },
    setWebsiteDomain: (state, action: PayloadAction<WebsiteDomainType>) => {
      state.websiteDomain = action.payload;
    },
    setDateSelection: (state, action: PayloadAction<DateSelectionType>) => {
      state.dateSelection = action.payload;
    },
    setCustomDateStart: (state, action: PayloadAction<number>) => {
      state.customDateStart = action.payload;
    },
    setCustomDateEnd: (state, action: PayloadAction<number>) => {
      state.customDateEnd = action.payload;
    },
    setTableTextSize: (state, action: PayloadAction<TableTextSize>) => {
      state.tableTextSize = action.payload;
    }
  }
})

/**
 * The actions for the slice
 */
export const {
  setDataPeriod,
  setLocation,
  setWebsiteDomain,
  setDateSelection,
  setCustomDateStart,
  setCustomDateEnd,
  setTableTextSize
} = applicationSlice.actions

// endregion
