import React from "react";
import {LineGraph} from "../styled/graphs/LineGraph";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {setFocus} from "../../store/userInterfaceSlice";
import {GraphNumberList} from "../../types/classes/GraphNumberList";

interface ResultsLineGraphProps {
  /**
   * Data to draw
   */
  data: GraphNumberList[];

  /**
   * Labels to use at the bottom
   */
  bottomLabels: React.ReactNode[];

  /**
   * Labels to use within the popup hover
   */
  hoverTitles: React.ReactNode[];

  /**
   * Extra text for legend
   */
  legendExtra?: React.ReactNode;
}

export const ResultsLineGraph: React.FC<ResultsLineGraphProps> = (
  {
    data,
    bottomLabels,
    hoverTitles,
    legendExtra = null
  }
  ) => {
  const focus = useAppSelector(state => state.userInterface.focus);
  const dispatch = useAppDispatch();
  return (
    <LineGraph
      data={data}
      hoverTitles={hoverTitles}
      bottomLabels={bottomLabels}
      unitStep={50}
      lines={4}
      legendExtra={legendExtra}
      focus={focus}
      setFocus={(value) => dispatch(setFocus(value))}
    />
  )
};