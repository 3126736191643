import {DateAndWeekTools} from "./base/DateAndWeekTools";
import {TrialToSubscriptionsEntry} from "../../types/interfaces/data/TrialToSubscriptionsEntry";

export class TrialToSubscriptionsTools extends DateAndWeekTools<TrialToSubscriptionsEntry> {
  factory(aDate: Date, aWeek: number): TrialToSubscriptionsEntry {
    return {
      date: aDate,
      week: aWeek,
      trialAmount: 0,
      trialCount: 0,
      conversionCount: 0
    }
  }

  reduce(
    aPrevious: TrialToSubscriptionsEntry, anEntry: Readonly<TrialToSubscriptionsEntry>
  ): TrialToSubscriptionsEntry {
    aPrevious.conversionCount += anEntry.conversionCount;
    aPrevious.trialCount += anEntry.trialCount;
    aPrevious.trialAmount += anEntry.trialAmount;
    return aPrevious;
  }

  calcPercentage(anEntry: TrialToSubscriptionsEntry): number {
    return anEntry.trialCount > 0 ? 100 * anEntry.conversionCount / anEntry.trialCount : 0;
  }
}