/**
 * How to align the items in the same direction.
 */
export enum StackAlignContent {
  Normal,
  Start,
  Center,
  End,
  Stretch,
  SpaceBetween,
  SpaceAround,
  SpaceEvenly
}
