import React from "react";
import {useAppSelector} from "../../../store/store";
import {DataTools} from "../../../tools/DataTools";
import {NumberType} from "../../../types/enums/NumberType";
import {mainController} from "../../../controllers/mainController";
import {UserLocation} from "../../../types/enums/UserLocation";
import {TrialToSubscriptionsTools} from "../../../tools/data/TrialToSubscriptionsTools";
import {CurrentAndPreviousRow} from "../../../types/classes/CurrentAndPreviousRow";
import {GraphColor} from "../../../types/enums/GraphColor";
import {CurrentAndPreviousTableDashboardPanel} from "../../panels/CurrentAndPreviousTableDashboardPanel";

export const TrialToSubscriptionsDashboard: React.FC = () => {
  const {dashboardEntries, loading, initializedDashboard} = useAppSelector(state => state.trialToSubscriptions);
  const tools = new TrialToSubscriptionsTools();
  const current = DataTools.reduceForDashboardCurrent(dashboardEntries, tools);
  const previous = DataTools.reduceForDashboardPrevious(dashboardEntries, tools);
  const rows = [
    new CurrentAndPreviousRow(
      {value: current.conversionCount}, {value: previous.conversionCount}, NumberType.Integer, 'Trial conversions',
      GraphColor.Other1, false
    ),
    new CurrentAndPreviousRow(
      {value: current.trialCount}, {value: previous.trialCount}, NumberType.Integer, 'Trial subscriptions',
      GraphColor.Other1, false
    ),
    new CurrentAndPreviousRow(
      {value: tools.calcPercentage(current)}, {value: tools.calcPercentage(previous)}, NumberType.Percentage, 'Percentage',
      GraphColor.Other1, false
    )
  ];
  return (
    <CurrentAndPreviousTableDashboardPanel
      title="Trials to subscriptions"
      rows={rows}
      loading={loading || !initializedDashboard}
      onClick={() => mainController.setUserLocation(UserLocation.Cancellations)}
    />
  );
};
