import {GraphNumberList} from "../../../../types/classes/GraphNumberList";
import React from "react";
import {LegendColor} from "./LegendColor";

interface LegendProps {
  readonly data: GraphNumberList[];
  readonly extra: React.ReactNode;
}

export const Legend: React.FC<LegendProps> = ({data, extra}) => {
  const hasForecast = !!data.find(list => list.hasForecast);
  const hasLightColor = false;
  return (
    <div
      className="line-graph__legend flex flex-row justify-start mb-1 gap-4"
    >
      {data.map((list, index) => {
        return (
          <div
            className="flex flex-row gap-1 items-center"
            key={index}
          >
            <LegendColor color={list.color} light={false}/>
            {hasLightColor && <div className="text-sm leading-none">/</div>}
            {hasLightColor && <LegendColor color={list.color} light={true}/>}
            <div
              className="text-sm leading-none"
            >
              {list.legendTitle}
            </div>
          </div>
        );
      })}
      {
        extra &&
        <div className="text-sm leading-none">
          {extra}
        </div>
      }
      {
        hasForecast &&
        <div className="text-sm leading-none">
          (dotted lines = forecast)
        </div>
      }
    </div>
  )
}