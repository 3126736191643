// region imports

import {DateRange} from "../../../types/classes/DateRange";
import {DateAndWeekAndCountResponseIO} from "../../../types/interfaces/data/io/DateAndWeekAndCountResponseIO";
import {LoadDateRangeWithCallbackAction} from "../LoadDateRangeWithCallbackAction";
import {TrialToSubscriptionsEntry} from "../../../types/interfaces/data/TrialToSubscriptionsEntry";
import {TrialToSubscriptionsEntryIO} from "../../../types/interfaces/data/io/TrialToSubscriptionsEntryIO";

// endregion

// region exports

/**
 * Loads the trial to subscription results, grouped per day for a certain date range and data target.
 */
export class TrialToSubscriptionsLoadAction
  extends LoadDateRangeWithCallbackAction<DateAndWeekAndCountResponseIO, TrialToSubscriptionsEntry[]>
{
  // region public methods

  /**
   * Constructs an instance.
   *
   * @protected
   */
  public constructor(aDateRange: DateRange, aCallback: (entries: TrialToSubscriptionsEntry[]) => any) {
    super('/transaction/trial-to-subscriptions', aDateRange, aCallback);
  }

  // endregion

  // region protected methods

  /**
   * @inheritDoc
   */
  protected process(aResponse: DateAndWeekAndCountResponseIO): Promise<void> {
    this.callback(this.convertEntriesFromIO(aResponse.entries));
    return Promise.resolve();
  }

  // endregion

  // region private methods

  private convertEntryFromIO(anEntry: TrialToSubscriptionsEntryIO): TrialToSubscriptionsEntry {
    return {
      date: new Date(anEntry.date),
      week: anEntry.week,
      trialAmount: anEntry.trial_amount || 0,
      trialCount: anEntry.trial_count || 0,
      conversionCount: anEntry.conversion_count || 0
    }
  }

  private convertEntriesFromIO(anEntries: TrialToSubscriptionsEntryIO[]): TrialToSubscriptionsEntry[] {
    return anEntries.map(entry => this.convertEntryFromIO(entry));
  }

  // endregion
}

// endregion