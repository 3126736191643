// region imports

import React from "react";
import {TitleType} from "./title/TitleType";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";

// endregion

// region local

/**
 * Properties for title.
 */
interface TitleProps extends UFChildrenProp {
  /**
   * Type of title
   */
  readonly type?: TitleType;

  /**
   * Add additional spacing at the bottom.
   */
  readonly bottomSpacing?: boolean;

  /**
   * True to use full width
   */
  readonly fullWidth?: boolean;
}

// endregion

// region exports

export const Title: React.FC<TitleProps> = (
  {
    type = TitleType.H1,
    bottomSpacing = false,
    fullWidth = false,
    children
  }: TitleProps
) => {
  let className = 'leading-tight ';
  if (bottomSpacing) {
    className += ' mb-4';
  }
  if (fullWidth) {
    className += ' full-width';
  }
  switch(type) {
    case TitleType.H2:
      return <h2 className={'text-3xl font-semibold ' + className}>{children}</h2>;
    case TitleType.H3:
      return <h3 className={'text-2xl font-bold ' + className}>{children}</h3>;
    case TitleType.H4:
      return <h4 className={'text-2xl font-semibold ' + className}>{children}</h4>;
    case TitleType.H5:
      return <h5 className={'text-xl font-medium ' + className}>{children}</h5>;
    case TitleType.H6:
      return <h6 className={'text-lg font-medium ' + className}>{children}</h6>;
    case TitleType.SmallGray:
      return <div className={'text-sm text-gray-500 ' + className}>{children}</div>
    default:
      return <h1 className={'text-3xl font-bold ' + className}>{children}</h1>;
  }
};

// endregion