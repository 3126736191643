import React from "react";
import {useAppSelector} from "../../../store/store";
import {GraphColor} from "../../../types/enums/GraphColor";
import {NumberType} from "../../../types/enums/NumberType";
import {HistoryPanel} from "../../panels/HistoryPanel";
import {Text} from "../../styled/texts/Text";
import {LegacyBookingTools} from "../../../tools/data/LegacyBookingTools";

export const LegacyBookingsHistory: React.FC = () => {
  const {historyEntries, historyRange, loading} = useAppSelector(state => state.legacyBookings);
  return (
    <HistoryPanel
      entries={historyEntries}
      loading={loading || !historyRange}
      tools={new LegacyBookingTools()}
      providers={[
        {
          color: GraphColor.Other5,
          name: 'Incoming payments',
          type: NumberType.Currency,
          getValue: entry => entry.incomingPayments
        },
        {
          color: GraphColor.Success,
          name: 'Dunning fees',
          type: NumberType.Currency,
          getValue: entry => entry.dunningFees
        },
        {
          color: GraphColor.Other7,
          name: 'Outgoing payments',
          type: NumberType.Currency,
          getValue: entry => entry.outgoingPayments,
          reverse: true
        },
        {
          color: GraphColor.Failure,
          name: 'Debit fees',
          type: NumberType.Currency,
          getValue: entry => entry.debitFees,
          reverse: true
        },
      ]}
      bottom={
        <Text>The amounts are obtained from legacy booking imports.</Text>
      }
    />
  );
}
