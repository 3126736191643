import {TransactionResultValues} from "../../types/interfaces/data/TransactionResultValues";
import {TransactionResultEntry} from "../../types/interfaces/data/TransactionResultEntry";
import {DateAndWeekTools} from "./base/DateAndWeekTools";

function addValues(aPrevious: TransactionResultValues, anEntry: TransactionResultValues) {
  aPrevious.successCount += anEntry.successCount;
  aPrevious.successAmount += anEntry.successAmount;
  aPrevious.failCount += anEntry.failCount;
  aPrevious.failAmount += anEntry.failAmount;
}

export class TransactionResultsTools extends DateAndWeekTools<TransactionResultEntry> {
  factory(aDate: Date, aWeek: number): TransactionResultEntry {
    return {
      date: aDate,
      week: aWeek,
      coin: {
        failCount: 0,
        successCount: 0,
        failAmount: 0,
        successAmount: 0
      },
      recurring: {
        failCount: 0,
        successCount: 0,
        failAmount: 0,
        successAmount: 0
      },
      subscription: {
        failCount: 0,
        successCount: 0,
        failAmount: 0,
        successAmount: 0
      }
    }
  }


  reduce(aPrevious: TransactionResultEntry, anEntry: Readonly<TransactionResultEntry>): TransactionResultEntry {
    addValues(aPrevious.subscription, anEntry.subscription);
    addValues(aPrevious.recurring, anEntry.recurring);
    addValues(aPrevious.coin, anEntry.coin);
    return aPrevious;
  }
}