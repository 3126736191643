// region imports

import {StoreTools} from "../../tools/StoreTools";
import {LegacyEntry} from "../../types/interfaces/data/LegacyEntry";

// endregion

// region exports

/**
 * Coins related values.
 */
export const legacySlice = StoreTools.createDataListForDateRangeSlice<LegacyEntry>(
  'legacy'
);

/**
 * The actions for the slice
 */
export const {
  initializeDashboard,
  setHistoryRange,
  setLoading,
  setDashboard,
  setHistoryEntries,
  resetDashboard,
} = legacySlice.actions;

// endregion