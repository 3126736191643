// region imports

import React from "react";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";
import {UFOptionalOnClickProp} from "../../../UF-react/types/UFOptionalOnClickProp";

// endregion

// region local

interface PaperContainerProps extends UFChildrenProp, UFOptionalOnClickProp {
  /**
   * Extra css classes to add
   */
  readonly classExtra?: string;
}

// endregion

// region exports

/**
 * A container using white background, rounded corners and a small shadow. It acts as a base for specialized containers.
 */
export const PaperContainer: React.FC<PaperContainerProps> = (
  {children, onClick, classExtra= '' }
) => (
  <div
    className={'bg-white sm:rounded-lg shadow-sm p-6 ' + classExtra + (onClick ? ' cursor-pointer' : '')}
    onClick={onClick}
  >
    {children}
  </div>
);

// endregion