import {CancellationsEntry} from "../../types/interfaces/data/CancellationsEntry";
import {DateAndWeekTools} from "./base/DateAndWeekTools";

export class CancellationsTools extends DateAndWeekTools<CancellationsEntry> {
  factory(aDate: Date, aWeek: number): CancellationsEntry {
    return {
      date: aDate,
      week: aWeek,
      count1: 0,
      count12: 0,
      count24: 0,
      count3: 0,
      count6: 0,
      subscriptions: 0
    }
  }

  reduce(aPrevious: CancellationsEntry, anEntry: Readonly<CancellationsEntry>): CancellationsEntry {
    aPrevious.count1 += anEntry.count1;
    aPrevious.count3 += anEntry.count3;
    aPrevious.count6 += anEntry.count6;
    aPrevious.count12 += anEntry.count12;
    aPrevious.count24 += anEntry.count24;
    aPrevious.subscriptions += anEntry.subscriptions;
    return aPrevious;
  }
}