/**
 * Resuls from server calls.
 */
export enum ServerResult {
  /**
   * No server result
   */
  None = 'none',

  /**
   * Call was successful.
   */
  Success = 'Success',

  /**
   * Token has expired.
   */
  TokenExpired = 'token_expired',

  /**
   * Action can only be performed by webmasters
   */
  RequiresWebmaster = 'requires_webmaster',

  /**
   * Action can only be performed by administrators
   */
  RequiresAdministrator = 'requires_administrator',

  /**
   * One or more request parameters are missing.
   */
  MissingParameters = 'missing_parameters',

  /**
   * There is no user for the token.
   */
  NoUser = 'no_user',

  /**
   * Id parameter is invalid
   */
  InvalidId = 'invalid_id',

  /**
   * An unknown exception occurred at the server.
   */
  UnknownException = 'unknown_exception',

  /**
   * The login parameters are not valid.
   */
  InvalidCredentials = 'invalid_credentials',

  /**
   * The code url is already in use.
   */
  InvalidCodeUrl = 'invalid_url_code',

  /**
   * An error occurred while communicating with the database
   */
  DatabaseError = 'database_error',

  /**
   * Trying to access a resource that the user is not allowed to access.
   */
  NoAccess = 'no_access',

  /**
   * Image format is not supported.
   */
  UnsupportedFormat = 'unsupported_format',

  /**
   * The file size of the uploaded fle is too large.
   */
  FileSize = 'file_size',

  /**
   * Uploaded file is empty.
   */
  EmptyFile = 'empty_file',

  /**
   * Exception happened within client code.
   */
  InternalException = 'internal_exception'
}