// region imports

import {RadioButtonsBar} from "../styled/buttons/RadioButtonsBar";
import {DataPeriodType} from "../../types/enums/DataPeriodType";
import React from "react";
import {useAppSelector} from "../../store/store";
import {mainController} from "../../controllers/mainController";
import {TableTextSize} from "../../types/enums/TableTextSize";

// endregion

// region local

/**
 * Maps enum to string
 */
const s_tableTextSizes = new Map<TableTextSize, string>([
  [TableTextSize.Normal, '↑A'],
  [TableTextSize.Small, '↓ᴀ']
]);

// endregion

// region exports

/**
 * Shows buttons to select a certain data period.
 */
export const TableTextSizeButtons: React.FC = () => {
  const current = useAppSelector(state => state.application.tableTextSize);
  return (
    <RadioButtonsBar
      onChange={(value) => mainController.setTableTextSize(value)}
      values={s_tableTextSizes}
      value={current}
    />
  );
}

// endregion