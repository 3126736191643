// region imports

import {UserRegistrationEntry} from "../../types/interfaces/data/UserRegistrationEntry";
import {StoreTools} from "../../tools/StoreTools";

// endregion

// region exports

/**
 * User registrations related values.
 */
export const userRegistrationsSlice = StoreTools.createDataListForDateRangeSlice<UserRegistrationEntry>(
  'user-registrations'
);

/**
 * The actions for the slice
 */
export const {
  initializeDashboard,
  setHistoryRange,
  setLoading,
  setDashboard,
  setHistoryEntries,
  resetDashboard,
} = userRegistrationsSlice.actions;

// endregion