import {UserRegistrationEntry} from "../../types/interfaces/data/UserRegistrationEntry";
import {DateAndWeekTools} from "./base/DateAndWeekTools";

export class UserRegistrationsTools extends DateAndWeekTools<UserRegistrationEntry> {
  factory(aDate: Date, aWeek: number): UserRegistrationEntry {
    return {
      date: aDate,
      week: aWeek,
      verified: 0,
      total: 0
    }
  }

  reduce(aPrevious: UserRegistrationEntry, anEntry: Readonly<UserRegistrationEntry>): UserRegistrationEntry {
    aPrevious.verified += anEntry.verified;
    aPrevious.total += anEntry.total;
    return aPrevious;
  }
}