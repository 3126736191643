import {GraphNumberList} from "../../../../types/classes/GraphNumberList";
import React from "react";
import {GraphColorTools} from "../../../../tools/GraphColorTools";

interface ColumnPointsProps {
  readonly data: GraphNumberList[];
  readonly topValue: number;
  readonly bottomValue: number;
  readonly column: number;
}

/**
 * Renders the points for each graph in a column.
 */
export const ColumnPoints: React.FC<ColumnPointsProps> = (
  {data, topValue, bottomValue, column}
) => {
  const points: React.ReactNode[] = [];
  const pointClass: string = ' z-10 relative pointer ' +
    'after:block after:border-2 after:border-transparent after:w-[6px] after:h-[6px] after:absolute after:rounded-full after:bg-inherit after:-translate-x-1/2 after:-translate-y-1/2'
  data.forEach((list, index) => {
    if (!list.hasValue(column)) {
      return;
    }
    const hasLightColor = false;
    const value = list.get(column);
    const y = topValue === bottomValue ? 50 : 100 - ((value - bottomValue) * 100 / (topValue - bottomValue));
    points.unshift(
      <div
        key={index}
        className={GraphColorTools.getBackgroundClass(list.color, hasLightColor) + pointClass}
        style={{
          top: y + '%'
        }}
        title={value.toString()}
      />
    );
  });
  return (
    <div
      className="relative"
    >
      {points}
    </div>
  );
}