// region imports

import {StoreTools} from "../../tools/StoreTools";
import {LegacyBookingsEntry} from "../../types/interfaces/data/LegacyBookingsEntry";

// endregion

// region exports

/**
 * Coins related values.
 */
export const legacyBookingsSlice = StoreTools.createDataListForDateRangeSlice<LegacyBookingsEntry>(
  'legacy-booking'
);

/**
 * The actions for the slice
 */
export const {
  initializeDashboard,
  setHistoryRange,
  setLoading,
  setDashboard,
  setHistoryEntries,
  resetDashboard,
} = legacyBookingsSlice.actions;

// endregion