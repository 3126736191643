import React from "react";
import {Config} from "../../../Config";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";

interface MainGridContentProps extends UFChildrenProp {
}

export const MainGridContent: React.FC<MainGridContentProps> = ({children}) => (
  <div className={"main-grid-content p-2 " + Config.theme.backgroundColor}>
    {children}
  </div>
);
