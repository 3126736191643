// region imports

import React from "react";
import {Config} from "../../Config";
import {CurrentAndPreviousValue} from "../../types/classes/CurrentAndPreviousValue";
import {CurrentAndPreviousDashboardPanel} from "./CurrentAndPreviousDashboardPanel";

// endregion

// region local

interface LastAndPreviousDaysDashboardPanelProps {
  /**
   * Title to show
   */
  readonly title: string;

  /**
   * When true show loading animation
   */
  readonly loading: boolean;

  /**
   * Current value
   */
  readonly currentAndPrevious: CurrentAndPreviousValue;

  /**
   * On click handler if any
   */
  readonly onClick?: () => any;
}

// endregion

// region export

export const LastAndPreviousDaysDashboardPanel: React.FC<LastAndPreviousDaysDashboardPanelProps> = (
  {
    title,
    currentAndPrevious,
    loading,
    onClick
  }
) => {
  return (
    <CurrentAndPreviousDashboardPanel
      onClick={onClick}
      title={title + ` last ${Config.dashboardDays} days`}
      loading={loading}
      currentAndPrevious={currentAndPrevious}
      previousText={`Previous ${Config.dashboardDays} days:`}
    />
  );
}

// endregion