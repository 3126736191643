import React from "react";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";

interface DialogContainerProps extends UFChildrenProp {
  /**
   * True to show the dialog container.
   */
  readonly visible: boolean;
}

/**
 * The dialog container shows a semi transparent background with the children centered in the middle.
 */
export const DialogContainer: React.FC<DialogContainerProps> = ({children, visible}) => {
  if (!visible) {
    return null;
  }
  return (
    <div className="absolute inset-0 bg-black/75 flex justify-center items-center z-20">
      {children}
    </div>
  );
};