// region imports

import React from "react";
import {Text} from "../texts/Text";
import {BusyAnimation} from "../animations/BusyAnimation";
import {UFOptionalOnClickProp} from "../../../UF-react/types/UFOptionalOnClickProp";
import {LoadingProp} from "../../../types/interfaces/props/LoadingProp";
import {TitleProp} from "../../../types/interfaces/props/TitleProp";
import {PaperContainer} from "./PaperContainer";

// endregion

// region local

/**
 * Properties for component
 */
interface SmallDashboardPanelProps extends UFOptionalOnClickProp, LoadingProp, TitleProp {
  /**
   * Content that will be placed at the top left below the title
   */
  readonly topLeft?: React.ReactNode;

  /**
   * Content that will be placed at the top right
   */
  readonly topRight?: React.ReactNode;

  /**
   * Content that will be centered vertically
   */
  readonly center?: React.ReactNode;

  /**
   * When true center the center content.
   */
  readonly centerCenter?: boolean;

  /**
   * Content placed at the bottom left
   */
  readonly bottomLeft?: React.ReactNode;

  /**
   * Content placed at the bottom right
   */
  readonly bottomRight?: React.ReactNode;
}

// endregion

// region exports

export const SmallDashboardPanel: React.FC<SmallDashboardPanelProps> = (
  {
    title,
    topLeft,
    topRight,
    center,
    bottomLeft,
    bottomRight,
    loading,
    onClick,
    centerCenter = true
  }
) => {
  // use a grid of one cell and place the content in the same cell at different positions
  return (
    <PaperContainer
      onClick={onClick}
      classExtra="min-w-[400px] min-h-[175px] max-w-[33%] grid grid-cols-1 grid-rows-1 flex-1"
    >
      <div
        className="col-start-1 col-span-1 row-start-1 row-span-1 self-start flex flex-col gap-2"
      >
        <Text>{title}</Text>
        {!loading && topLeft}
      </div>
      {
        !loading && topRight &&
        <div className="col-start-1 col-span-1 row-start-1 row-span-1 justify-self-end">
          {topRight}
        </div>
      }
      <div
        className={
          'col-start-1 col-span-1 row-start-1 row-span-1 self-center' +
          (loading || centerCenter ? ' justify-self-center' : '')
        }
      >
        {
          !loading && center
        }
        {
          loading && <BusyAnimation size={60}/>
        }
      </div>
      {
        !loading && bottomLeft &&
        <div
          className="col-start-1 col-span-1 row-start-1 row-span-1 self-end"
        >
          {bottomLeft}
        </div>
      }
      {
        !loading && bottomRight &&
        <div className="col-start-1 col-span-1 row-start-1 row-span-1 self-end justify-self-end">
          {bottomRight}
        </div>
      }
    </PaperContainer>
  );
}

// endregion