import React from "react";
import {useAppSelector} from "../../../store/store";
import {DataTools} from "../../../tools/DataTools";
import {NumberType} from "../../../types/enums/NumberType";
import {mainController} from "../../../controllers/mainController";
import {UserLocation} from "../../../types/enums/UserLocation";
import {ActiveSubscriptionsEntry} from "../../../types/interfaces/data/ActiveSubscriptionsEntry";
import {CurrentAndPreviousRow} from "../../../types/classes/CurrentAndPreviousRow";
import {CurrentAndPreviousTableDashboardPanel} from "../../panels/CurrentAndPreviousTableDashboardPanel";
import {GraphColor} from "../../../types/enums/GraphColor";
import {UFDate} from "../../../UF/tools/UFDate";
import {Config} from "../../../Config";

export const ActiveSubscriptionsDashboard: React.FC = () => {
  const entries = useAppSelector(state => state.activeSubscriptions.dashboardEntries);
  const loading = useAppSelector(state => state.activeSubscriptions.loading);
  const initialized = useAppSelector(state => state.activeSubscriptions.initializedDashboard);
  const empty: ActiveSubscriptionsEntry = {
    date: new Date(),
    week: 0,
    count1: 0,
    count3: 0,
    count6: 0,
    count12: 0,
    count24: 0
  };
  const now = new Date();
  const previousDate = new Date(Date.now() - Config.dashboardDays * UFDate.DAY_IN_MILLISECONDS);
  const current = DataTools.findForDay(now, entries) || empty;
  const previous = DataTools.findForDay(previousDate, entries) || empty;
  const rows = [
    new CurrentAndPreviousRow(
      {value: current.count1}, {value: previous.count1}, NumberType.Integer, '1 month sub.', GraphColor.Other1
    ),
    new CurrentAndPreviousRow(
      {value: current.count3}, {value: previous.count3}, NumberType.Integer, '3 month sub.', GraphColor.Other1
    ),
    new CurrentAndPreviousRow(
      {value: current.count6}, {value: previous.count6}, NumberType.Integer, '6 month sub.', GraphColor.Other1
    ),
    new CurrentAndPreviousRow(
      {value: current.count12}, {value: previous.count12}, NumberType.Integer, '12 month sub.', GraphColor.Other1
    ),
    new CurrentAndPreviousRow(
      {value: current.count24}, {value: previous.count24}, NumberType.Integer, '24 month sub.', GraphColor.Other1
    ),
  ];
  return (
    <CurrentAndPreviousTableDashboardPanel
      title="Active subscriptions"
      rows={rows}
      loading={loading || !initialized}
      onClick={() => mainController.setUserLocation(UserLocation.ActiveSubscriptions)}
    />
  );
};
