/**
 * Color for text
 */
export enum TextColor {
  /**
   * Normal color (black)
   */
  Normal,

  /**
   * Success color (green)
   */
  Success,

  /**
   * Failure color (red)
   */
  Failure,

  /**
   * Warning color (orange)
   */
  Warning,

  /**
   * Lighter color (gray)
   */
  Light,

  /**
   * When the text is placed on a dark background (white)
   */
  Inverse
}