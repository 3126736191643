import React from "react";

interface VerticalLabelsProps {
  readonly count: number;
  readonly topValue: number;
  readonly bottomValue: number;
}

/**
 * Renders the vertical labels, shown in a column at the start using the top and bottom value.
 */
export const VerticalLabels: React.FC<VerticalLabelsProps> = ({count, topValue, bottomValue}) => {
  const verticalValueClass = 'text-base h-px text-right leading-[0] mx-1';
  if (topValue !== bottomValue) {
    let verticalValue = topValue;
    const stepSize = Math.floor((topValue - bottomValue + 1) / (count + 1));
    const verticalValues = [];
    for (let line = 0; line <= count; line++) {
      verticalValues.push(
        <div
          key={line}
          className={verticalValueClass}
        >
          {verticalValue}
        </div>
      );
      verticalValue -= stepSize;
    }
    verticalValues.push(
      <div
        key={count + 1}
        className={verticalValueClass}
      >
        {bottomValue}
      </div>
    );
    return (
      <div
        className="line-graph__vertical-labels-column flex flex-col justify-between"
      >
        {verticalValues}
      </div>
    );
  }
  else {
    return (
      <div
        className="line-graph__vertical-labels-column flex flex-col justify-center"
      >
        <div
          key={0}
          className={verticalValueClass}
        >
          {bottomValue}
        </div>
      </div>
    );
  }
}