// region imports

import {WebsiteDomainType} from "../../types/enums/WebsiteDomainType";
import {Tools} from "../../tools/Tools";
import {UFText} from "../../UF/tools/UFText";
import {DateRange} from "../../types/classes/DateRange";
import {LoadDateRangeWithCallbackAction} from "./LoadDateRangeWithCallbackAction";

// endregion

// region exports

/**
 * Base class for actions that load data using following url format:
 * {action}/{domain}/{start}/{end}
 */
export abstract class LoadDateRangeWithDomainAndCallbackAction<TResponse, TData>
  extends LoadDateRangeWithCallbackAction<TResponse, TData>
{
  // region private variables

  /**
   * See {@link domain}
   *
   * @private
   */
  private readonly m_domain: WebsiteDomainType;

  // endregion

  // region protected properties

  /**
   * Constructs an instance of {@link LoadDateRangeWithDomainAndCallbackAction}.
   */
  protected constructor(
    anAction: string, aDomain: WebsiteDomainType, aDateRange: DateRange, aCallback: (data: TData) => any
  ) {
    super(anAction, aDateRange, aCallback);
    this.m_domain = aDomain;
  }

  /**
   * Website domain data should be loaded for
   *
   * @protected
   */
  protected get domain(): WebsiteDomainType {
    return this.m_domain;
  }

  // endregion

  // region protected methods

  /**
   * @inheritDoc
   */
  protected getActionWithDateRange(aStart: string, anEnd: string): string {
    return UFText.joinPath(this.action, Tools.getDomainAsText(this.domain), aStart, anEnd);
  }

  // endregion
}

// endregion