// region imports

import {UFText} from "../../UF/tools/UFText";
import {DateAndWeekAndValueIO} from "../../types/interfaces/data/io/DateAndWeekAndValueIO";
import {DateAndWeekAndValueEntry} from "../../types/interfaces/data/DateAndWeekAndValueEntry";
import {LoadDataWithCallbackAction} from "./LoadDataWithCallbackAction";
import {DateRange} from "../../types/classes/DateRange";
import {DateAndWeekAndCountIO} from "../../types/interfaces/data/io/DateAndWeekAndCountIO";

// endregion

// region exports

/**
 * Base class for actions that load data using following url format:
 * {action}/{start}/{end}
 *
 * If additional parameters should be used, subclasses must override the {@link getActionWithDateRange} method.
 */
export abstract class LoadDateRangeWithCallbackAction<TResponse, TData>
  extends LoadDataWithCallbackAction<TResponse, TData>
{
  // region private variables

  /**
   * See {@link dateRange}
   *
   * @private
   */
  private readonly m_dateRange: DateRange;

  // endregion

  // region protected properties

  /**
   * Period to get data for
   *
   * @protected
   */
  protected get dateRange(): DateRange {
    return this.m_dateRange;
  }

  // endregion

  // region protected methods

  /**
   * Constructs an instance of the class.
   *
   * @protected
   */
  protected constructor(anAction: string, aRange: DateRange, aCallback: (data: TData) => any) {
    super(anAction, aCallback);
    this.m_dateRange = aRange;
  }

  /**
   * Gets the action url including start and end parameters using {@link getActionWithDateRange}.
   *
   * @protected
   */
  protected getAction(): string {
    return this.getActionWithDateRange(this.dateRange.startAsCompactText, this.dateRange.endAsCompactText);
  }

  /**
   * Gets the url including start and end parameters.
   *
   * @param aStart
   * @param anEnd
   *
   * @protected
   */
  protected getActionWithDateRange(aStart: string, anEnd: string): string {
    return UFText.joinPath(this.action, aStart, anEnd);
  }

  // endregion

  // region protected support methods

  /**
   * Converts data received from the server to data structure used within the application.
   *
   * @param anEntry
   */
  protected convertDateWithWeekAndValueEntryFromIO(anEntry: DateAndWeekAndValueIO): DateAndWeekAndValueEntry {
    return {
      date: new Date(anEntry.date),
      week: anEntry.week,
      value: anEntry.value
    };
  }

  /**
   * Converts data received from the server to data structure used within the application.
   *
   * @param anEntries
   */
  protected convertDateWithWeekAndValueEntriesFromIO(anEntries: DateAndWeekAndValueIO[]): DateAndWeekAndValueEntry[] {
    return anEntries.map(this.convertDateWithWeekAndValueEntryFromIO)
  }

  /**
   * Converts data received from the server to data structure used within the application.
   *
   * @param anEntry
   */
  protected convertDateWithWeekAndCountEntryFromIO(anEntry: DateAndWeekAndCountIO): DateAndWeekAndValueEntry {
    return {
      date: new Date(anEntry.date),
      week: anEntry.week,
      value: anEntry.count
    };
  }

  /**
   * Converts data received from the server to data structure used within the application.
   *
   * @param anEntries
   */
  protected convertDateWithWeekAndCountEntriesFromIO(anEntries: DateAndWeekAndCountIO[]): DateAndWeekAndValueEntry[] {
    return anEntries.map(this.convertDateWithWeekAndCountEntryFromIO)
  }

  // endregion
}

// endregion