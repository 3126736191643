import React, {useState} from "react";
import {PieChart} from "../../styled/graphs/PieChart";
import {useAppSelector} from "../../../store/store";
import {GeneralTable} from "../../styled/tables/GeneralTable";
import {Stack} from "../../styled/layouts/Stack";
import {StackDirection} from "../../styled/layouts/stack/StackDirection";
import {GraphNumberList} from "../../../types/classes/GraphNumberList";
import {GraphColorTools} from "../../../tools/GraphColorTools";
import {NumberType} from "../../../types/enums/NumberType";
import {MainPanel} from "../../styled/containers/MainPanel";
import {ValueAndLabelFields} from "../../../types/interfaces/data/fields/ValueAndLabelFields";
import {WebsiteDomainButtons} from "../../buttons/WebsiteDomainButtons";
import {Title} from "../../styled/texts/Title";
import {Text} from "../../styled/texts/Text";
import {TextPlacement} from "../../styled/texts/text/TextPlacement";
import {TitleType} from "../../styled/texts/title/TitleType";
import {HeaderTitle} from "../../text/HeaderTitle";
import {DateTools} from "../../../tools/DateTools";
import {CurrentAndPreviousRankedTableData} from "../../../types/classes/table-data/CurrentAndPreviousRankedTableData";
import {CurrentAndPreviousRankedRow} from "../../../types/classes/CurrentAndPreviousRankedRow";
import {ValueAndKeyAndLabelFields} from "../../../types/interfaces/data/fields/ValueAndKeyAndLabelFields";
import {TableTextSize} from "../../../types/enums/TableTextSize";

function buildList(anEntries: ValueAndLabelFields[]): GraphNumberList[] {
  return anEntries.map((entry, index) =>
    GraphNumberList.createForSingleValue(
      entry.label, GraphColorTools.getOtherColor(index), NumberType.Integer, entry.value
    )
  );
}

interface TrafficInfoChartAndTableProps {
  readonly currentEntries: ValueAndKeyAndLabelFields[];
  readonly previousEntries: ValueAndKeyAndLabelFields[];
  readonly title: string;
  readonly currentTitle: React.ReactNode;
  readonly previousTitle: React.ReactNode;
  readonly tableTextSize: TableTextSize;
}

const TrafficInfoChartAndTable: React.FC<TrafficInfoChartAndTableProps> = (
  {
    currentEntries,
    previousEntries,
    title, currentTitle,
    previousTitle,
    tableTextSize
  }
) => {
  const [focus, setFocus] = useState(-1);
  const lists = buildList(currentEntries);
  const tableData = new CurrentAndPreviousRankedTableData(
    currentTitle,
    previousTitle,
    CurrentAndPreviousRankedRow.createRows(
      currentEntries, previousEntries,
      NumberType.Integer, true, 15
    ),
    true,
    true,
    true
  )
  return (
    <Stack
      direction={StackDirection.Column}
      gap={4}
    >
      <Title type={TitleType.H4}>{title}</Title>
      <PieChart
        values={lists}
        focus={focus}
        setFocus={(value) => setFocus(value)}
      />
      <GeneralTable
        tableTextSize={tableTextSize}
        data={tableData}
        focus={focus}
        setFocus={(value) => setFocus(value)}
      />
    </Stack>
  );
}

export const TrafficInfoHistory: React.FC = () => {
  const {loading, historyRange, historyCurrent, historyPrevious} = useAppSelector(state => state.trafficInfo);
  const domain = useAppSelector(state => state.application.websiteDomain);
  const tableTextSize = useAppSelector(state => state.application.tableTextSize);
  const available = !loading && historyRange[domain];
  const currentTitle = available
    ? <HeaderTitle block>{DateTools.formatDateRange(historyRange[domain]!, false, true)}</HeaderTitle> : null;
  const previousTitle = available
    ? <HeaderTitle block>{DateTools.formatDateRange(historyRange[domain]!.getPrevious(), false, true)}</HeaderTitle> : null;
  return (
    <MainPanel
      loading={!available}
      title="Traffic information"
    >
      <Stack
        direction={StackDirection.Column}
        gap={4}
        fullWidth
      >
        <WebsiteDomainButtons/>
        <Stack
          direction={StackDirection.Row}
          gap={8}
        >
          <TrafficInfoChartAndTable
            title="Total visitors"
            currentEntries={historyCurrent[domain].visits}
            previousEntries={historyPrevious[domain].visits}
            currentTitle={currentTitle}
            previousTitle={previousTitle}
            tableTextSize={tableTextSize}
          />
          <TrafficInfoChartAndTable
            title="Via webmasters"
            currentEntries={historyCurrent[domain].webmasters}
            previousEntries={historyPrevious[domain].webmasters}
            currentTitle={currentTitle}
            previousTitle={previousTitle}
            tableTextSize={tableTextSize}
          />
        </Stack>
        <Text placement={TextPlacement.Paragraph}>
          The visitors via webmasters is based on the number of landing page urls that contain a <em>cdc=</em> part.
        </Text>
      </Stack>
    </MainPanel>
  );
}
