import React from "react";
import {Text} from "../components/styled/texts/Text";
import {TextSize} from "../components/styled/texts/text/TextSize";
import {TextPlacement} from "../components/styled/texts/text/TextPlacement";

/**
 * Fundorado related support methods.
 *
 * The plan id related methods are hardcoded for now, maybe in the future the information can be obtained from the
 * server.
 */
export class FundoTools {
  static getSubscriptionPlanAsNode(anId: number): React.ReactNode {
    switch(anId) {
      case 1:
        return <Text placement={TextPlacement.Block}>Tester<br/><Text size={TextSize.Small}>14 days</Text></Text>;
      case 2:
        return <Text placement={TextPlacement.Block}>Basic<br/><Text size={TextSize.Small}>1 month</Text></Text>;
      case 3:
        return <Text placement={TextPlacement.Block}>Silver old<br/><Text size={TextSize.Small}>6 months</Text></Text>;
      case 7:
        return <Text placement={TextPlacement.Block}>Tester LP<br/><Text size={TextSize.Small}>1 month</Text></Text>;
      case 13:
        return <Text placement={TextPlacement.Block}>2 + 1<br/><Text size={TextSize.Small}>3 months</Text></Text>;
      case 4:
        return <Text placement={TextPlacement.Block}>Gold<br/><Text size={TextSize.Small}>12 months</Text></Text>;
      case 5:
        return <Text placement={TextPlacement.Block}>Platinum<br/><Text size={TextSize.Small}>24 months</Text></Text>;
      case 22:
        return <Text placement={TextPlacement.Block}>Venus<br/><Text size={TextSize.Small}>12 months</Text></Text>;
      default:
        return <>Plan {anId}</>;
    }
  }

  static getSubscriptionPlanAsText(anId: number): string {
    switch(anId) {
      case 1:
        return 'Tester (14 days)';
      case 2:
        return 'Basic (1 month)';
      case 3:
        return 'Silver old (6 months)'
      case 7:
        return 'Tester - LP (1 month)'
      case 13:
        return '2 + 1 (3 months)';
      case 4:
        return 'Gold (12 months)';
      case 5:
        return 'Platinum (24 months)';
      case 22:
        return 'Venus (12 months)';
      default:
        return 'Plan ' + anId.toString();
    }
  }


}