// region imports

import {GraphColor} from "../types/enums/GraphColor";
import {NumberDifferenceType} from "../types/enums/NumberDifferenceType";

// endregion

// region local

/**
 * The 10 first colors
 */
const OTHER_COLORS = [
  GraphColor.Other1, GraphColor.Other2, GraphColor.Other3, GraphColor.Other4, GraphColor.Other5,
  GraphColor.Other6, GraphColor.Other7, GraphColor.Other8, GraphColor.Other9, GraphColor.Other10
];

// endregion

// region exports

/**
 * Support methods for {@link GraphColor}
 */
export class GraphColorTools {
  /**
   * Gets background css class for a certain color.
   *
   * @param aColor
   * @param aLight
   */
  static getBackgroundClass(aColor: GraphColor, aLight: boolean = false): string {
    if (aLight) {
      switch (aColor) {
        case GraphColor.None:
          return '';
        case GraphColor.Success:
          return 'bg-green-300';
        case GraphColor.Warning:
          return 'bg-orange-300';
        case GraphColor.Failure:
          return 'bg-red-300';
        case GraphColor.Other1:
          return 'bg-indigo-300';
        case GraphColor.Other2:
          return 'bg-teal-300';
        case GraphColor.Other3:
          return 'bg-blue-300';
        case GraphColor.Other4:
          return 'bg-purple-300';
        case GraphColor.Other5:
          return 'bg-lime-300'
        case GraphColor.Other6:
          return 'bg-fuchsia-300'
        case GraphColor.Other7:
          return 'bg-pink-300';
        case GraphColor.Other8:
          return 'bg-cyan-300';
        case GraphColor.Other9:
          return 'bg-yellow-300';
        case GraphColor.Other10:
          return 'bg-emerald-300';
        default:
          throw new Error(`Unknown color ${aColor}`);
      }
    }
    switch (aColor) {
      case GraphColor.None:
        return '';
      case GraphColor.Success:
        return 'bg-green-500';
      case GraphColor.Warning:
        return 'bg-orange-500';
      case GraphColor.Failure:
        return 'bg-red-500';
      case GraphColor.Other1:
        return 'bg-indigo-500';
      case GraphColor.Other2:
        return 'bg-teal-500';
      case GraphColor.Other3:
        return 'bg-blue-500';
      case GraphColor.Other4:
        return 'bg-purple-500';
      case GraphColor.Other5:
        return 'bg-lime-500'
      case GraphColor.Other6:
        return 'bg-fuchsia-500'
      case GraphColor.Other7:
        return 'bg-pink-500';
      case GraphColor.Other8:
        return 'bg-cyan-500';
      case GraphColor.Other9:
        return 'bg-yellow-500';
      case GraphColor.Other10:
        return 'bg-emerald-500';
      default:
        throw new Error(`Unknown color ${aColor}`);
    }
  }

  /**
   * Gets background css class for a certain color.
   */
  static getTableBackgroundClass(aColor: GraphColor): string {
    switch (aColor) {
      case GraphColor.None:
        return '';
      case GraphColor.Success:
        return 'bg-green-100';
      case GraphColor.Warning:
        return 'bg-orange-100';
      case GraphColor.Failure:
        return 'bg-red-100';
      case GraphColor.Other1:
        return 'bg-indigo-100';
      case GraphColor.Other2:
        return 'bg-teal-100';
      case GraphColor.Other3:
        return 'bg-blue-100';
      case GraphColor.Other4:
        return 'bg-purple-100';
      case GraphColor.Other5:
        return 'bg-lime-100'
      case GraphColor.Other6:
        return 'bg-fuchsia-100'
      case GraphColor.Other7:
        return 'bg-pink-100';
      case GraphColor.Other8:
        return 'bg-cyan-100';
      case GraphColor.Other9:
        return 'bg-yellow-100';
      case GraphColor.Other10:
        return 'bg-emerald-100';
      default:
        throw new Error(`Unknown color ${aColor}`);
    }
  }

  /**
   * Gets border css class for a certain color.
   *
   * @param aColor
   * @param aLight
   */
  static getBorderClass(aColor: GraphColor, aLight: boolean = false): string {
    if (aLight) {
      switch (aColor) {
        case GraphColor.None:
          return '';
        case GraphColor.Success:
          return 'border-green-300';
        case GraphColor.Warning:
          return 'border-orange-300';
        case GraphColor.Failure:
          return 'border-red-300';
        case GraphColor.Other1:
          return 'border-indigo-300';
        case GraphColor.Other2:
          return 'border-teal-300';
        case GraphColor.Other3:
          return 'border-blue-300';
        case GraphColor.Other4:
          return 'border-purple-300';
        case GraphColor.Other5:
          return 'border-lime-300'
        case GraphColor.Other6:
          return 'border-fuchsia-300'
        case GraphColor.Other7:
          return 'border-pink-300';
        case GraphColor.Other8:
          return 'border-cyan-300';
        case GraphColor.Other9:
          return 'border-yellow-300';
        case GraphColor.Other10:
          return 'border-emerald-300';
        default:
          throw new Error(`Unknown color ${aColor}`);
      }
    }
    switch (aColor) {
      case GraphColor.None:
        return '';
      case GraphColor.Success:
        return 'border-green-500';
      case GraphColor.Warning:
        return 'border-orange-500';
      case GraphColor.Failure:
        return 'border-red-500';
      case GraphColor.Other1:
        return 'border-indigo-500';
      case GraphColor.Other2:
        return 'border-teal-500';
      case GraphColor.Other3:
        return 'border-blue-500';
      case GraphColor.Other4:
        return 'border-purple-500';
      case GraphColor.Other5:
        return 'border-lime-500'
      case GraphColor.Other6:
        return 'border-fuchsia-500'
      case GraphColor.Other7:
        return 'border-pink-500';
      case GraphColor.Other8:
        return 'border-cyan-500';
      case GraphColor.Other9:
        return 'border-yellow-500';
      case GraphColor.Other10:
        return 'border-emerald-500';
      default:
        throw new Error(`Unknown color ${aColor}`);
    }
  }

  /**
   * Gets stroke css class for a certain color.
   *
   * @param aColor
   * @param aLight
   */
  static getStrokeClass(aColor: GraphColor, aLight: boolean = false): string {
    if (aLight) {
      switch (aColor) {
        case GraphColor.None:
          return '';
        case GraphColor.Success:
          return 'stroke-green-300';
        case GraphColor.Warning:
          return 'stroke-orange-300';
        case GraphColor.Failure:
          return 'stroke-red-300';
        case GraphColor.Other1:
          return 'stroke-indigo-300';
        case GraphColor.Other2:
          return 'stroke-teal-300';
        case GraphColor.Other3:
          return 'stroke-blue-300';
        case GraphColor.Other4:
          return 'stroke-purple-300';
        case GraphColor.Other5:
          return 'stroke-lime-300'
        case GraphColor.Other6:
          return 'stroke-fuchsia-300'
        case GraphColor.Other7:
          return 'stroke-pink-300';
        case GraphColor.Other8:
          return 'stroke-cyan-300';
        case GraphColor.Other9:
          return 'stroke-yellow-300';
        case GraphColor.Other10:
          return 'stroke-emerald-300';
        default:
          throw new Error(`Unknown color ${aColor}`);
      }
    }
    switch (aColor) {
      case GraphColor.None:
        return '';
      case GraphColor.Success:
        return 'stroke-green-500';
      case GraphColor.Warning:
        return 'stroke-orange-500';
      case GraphColor.Failure:
        return 'stroke-red-500';
      case GraphColor.Other1:
        return 'stroke-indigo-500';
      case GraphColor.Other2:
        return 'stroke-teal-500';
      case GraphColor.Other3:
        return 'stroke-blue-500';
      case GraphColor.Other4:
        return 'stroke-purple-500';
      case GraphColor.Other5:
        return 'stroke-lime-500'
      case GraphColor.Other6:
        return 'stroke-fuchsia-500'
      case GraphColor.Other7:
        return 'stroke-pink-500';
      case GraphColor.Other8:
        return 'stroke-cyan-500';
      case GraphColor.Other9:
        return 'stroke-yellow-500';
      case GraphColor.Other10:
        return 'stroke-emerald-500';
      default:
        throw new Error(`Unknown color ${aColor}`);
    }
  }

  /**
   * Gets dark version of a stroke css class for a certain color.
   *
   * @param aColor
   */
  static getDarkStrokeHoverClass(aColor: GraphColor): string {
    switch (aColor) {
      case GraphColor.None:
        return '';
      case GraphColor.Success:
        return 'hover:stroke-green-700';
      case GraphColor.Warning:
        return 'hover:stroke-orange-700';
      case GraphColor.Failure:
        return 'hover:stroke-red-700';
      case GraphColor.Other1:
        return 'hover:stroke-indigo-700';
      case GraphColor.Other2:
        return 'hover:stroke-teal-700';
      case GraphColor.Other3:
        return 'hover:stroke-blue-700';
      case GraphColor.Other4:
        return 'hover:stroke-purple-700';
      case GraphColor.Other5:
        return 'hover:stroke-lime-700'
      case GraphColor.Other6:
        return 'hover:stroke-fuchsia-700'
      case GraphColor.Other7:
        return 'hover:stroke-pink-700';
      case GraphColor.Other8:
        return 'hover:stroke-cyan-700';
      case GraphColor.Other9:
        return 'hover:stroke-yellow-700';
      case GraphColor.Other10:
        return 'hover:stroke-emerald-700';
      default:
        throw new Error(`Unknown color ${aColor}`);
    }
  }

  /**
   * Gets stroke css class for a certain color.
   *
   * @param aColor
   * @param aLight
   */
  static getFillClass(aColor: GraphColor, aLight: boolean = false): string {
    if (aLight) {
      switch (aColor) {
        case GraphColor.None:
          return '';
        case GraphColor.Success:
          return 'fill-green-400';
        case GraphColor.Warning:
          return 'fill-orange-400';
        case GraphColor.Failure:
          return 'fill-red-400';
        case GraphColor.Other1:
          return 'fill-indigo-400';
        case GraphColor.Other2:
          return 'fill-teal-400';
        case GraphColor.Other3:
          return 'fill-blue-400';
        case GraphColor.Other4:
          return 'fill-purple-400';
        case GraphColor.Other5:
          return 'fill-lime-400'
        case GraphColor.Other6:
          return 'fill-fuchsia-400'
        case GraphColor.Other7:
          return 'fill-pink-400';
        case GraphColor.Other8:
          return 'fill-cyan-400';
        case GraphColor.Other9:
          return 'fill-yellow-400';
        case GraphColor.Other10:
          return 'fill-emerald-400';
        default:
          throw new Error(`Unknown color ${aColor}`);
      }
    }
    switch (aColor) {
      case GraphColor.None:
        return '';
      case GraphColor.Success:
        return 'fill-green-500';
      case GraphColor.Warning:
        return 'fill-orange-500';
      case GraphColor.Failure:
        return 'fill-red-500';
      case GraphColor.Other1:
        return 'fill-indigo-500';
      case GraphColor.Other2:
        return 'fill-teal-500';
      case GraphColor.Other3:
        return 'fill-blue-500';
      case GraphColor.Other4:
        return 'fill-purple-500';
      case GraphColor.Other5:
        return 'fill-lime-500'
      case GraphColor.Other6:
        return 'fill-fuchsia-500'
      case GraphColor.Other7:
        return 'fill-pink-500';
      case GraphColor.Other8:
        return 'fill-cyan-500';
      case GraphColor.Other9:
        return 'fill-yellow-500';
      case GraphColor.Other10:
        return 'fill-emerald-500';
      default:
        throw new Error(`Unknown color ${aColor}`);
    }
  }

  /**
   * Gets css class for a certain color.
   *
   * @param aColor
   */
  static getHoverFillClass(aColor: GraphColor): string {
    switch (aColor) {
      case GraphColor.None:
        return '';
      case GraphColor.Success:
        return 'hover:fill-green-400';
      case GraphColor.Warning:
        return 'hover:fill-orange-400';
      case GraphColor.Failure:
        return 'hover:fill-red-400';
      case GraphColor.Other1:
        return 'hover:fill-indigo-400';
      case GraphColor.Other2:
        return 'hover:fill-teal-400';
      case GraphColor.Other3:
        return 'hover:fill-blue-400';
      case GraphColor.Other4:
        return 'hover:fill-purple-400';
      case GraphColor.Other5:
        return 'hover:fill-lime-400'
      case GraphColor.Other6:
        return 'hover:fill-fuchsia-400'
      case GraphColor.Other7:
        return 'hover:fill-pink-400';
      case GraphColor.Other8:
        return 'hover:fill-cyan-400';
      case GraphColor.Other9:
        return 'hover:fill-yellow-400';
      case GraphColor.Other10:
        return 'hover:fill-emerald-400';
      default:
        throw new Error(`Unknown color ${aColor}`);
    }
  }

  /**
   * Gets other color for a certain index. The colors will be reused if anIndex is larger than the available colors.
   *
   * @param anIndex
   */
  static getOtherColor(anIndex: number): GraphColor {
    return OTHER_COLORS[anIndex % 10];
  }

  /**
   * Gets css class for background focus color.
   */
  static get TABLE_FOCUS_COLOR(): string {
    return 'amber-200';
  }

  /**
   * Gets css class to use for number differences.
   */
  static getDifferenceTextColor(aType: NumberDifferenceType): string {
    switch(aType) {
      case NumberDifferenceType.Positive:
        return 'text-green-700';
      case NumberDifferenceType.Warning:
        return 'text-orange-500';
      case NumberDifferenceType.Danger:
        return 'text-red-700';
    }
  }
}

// endregion