// region imports

import {UFChildrenProp} from "../../UF-react/types/UFChildrenProp";
import React from "react";
import {Text} from "../styled/texts/Text";
import {TextSize} from "../styled/texts/text/TextSize";
import {TextColor} from "../styled/texts/text/TextColor";
import {TextPlacement} from "../styled/texts/text/TextPlacement";

// endregion

// region local

interface HeaderTitleProps extends UFChildrenProp {
  readonly block?: boolean;
}

// endregion

// region exports

/**
 * Header title renders a text using light color and small font.
 */
export const HeaderTitle: React.FC<HeaderTitleProps> = ({children, block = false}) =>
  <Text
    size={TextSize.VerySmall}
    color={TextColor.Light}
    placement={block ? TextPlacement.Block : TextPlacement.Inline}
    lineHeight={block}
  >
    {children}
  </Text>
;

// endregion
