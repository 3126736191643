import React from "react";
import {BusyAnimation} from "../animations/BusyAnimation";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";

/**
 * Types of button
 */
export enum ButtonType {
  /**
   * Filled without border
   */
  Primary,

  /**
   * Border and light background
   */
  Secondary,

  /**
   * No border, use underline with hover
   */
  Tertiary
}

interface ButtonProps extends UFChildrenProp {
  /**
   * Click handler
   */
  readonly onClick: () => any;

  /**
   * Type of button
   */
  readonly type?: ButtonType;

  /**
   * True to render button using red colors
   */
  readonly danger?: boolean;

  /**
   * Element to show before the caption
   */
  readonly iconAtStart?: React.ReactNode;

  /**
   * True to render a small button
   */
  readonly small?: boolean;

  /**
   * True to render button disabled and ignore clicks
   */
  readonly disabled?: boolean;

  /**
   * True to render a busy animation on top of the button
   */
  readonly busy?: boolean;

  /**
   * When true render button in selected state; it can not be clicked upon, and it will not show a pointer.
   */
  readonly selected?: boolean;
}

/**
 * A general usable button component.
 */
export const Button: React.FC<ButtonProps> = (
  {
    children,
    onClick,
    type = ButtonType.Primary,
    danger = false,
    iconAtStart = null,
    small = false,
    disabled = false,
    busy = false,
    selected = false,
  }
) => {
  let className = 'relative inline-flex flex-row items-center font-semibold text-xs leading-none uppercase' +
    ' tracking-wide outline-none transition ease-in-out duration-150 gap-1';
  let iconClassName = 'w-4 h-4 absolute -translate-x-5';
  if (iconAtStart) {
    if (small) {
      className += ' pr-1 pl-5';
    }
    else {
      className += ' pr-4 pl-9';
    }
  }
  else {
    if (small) {
      className += ' px-1';
    }
    else {
      className += ' px-4';
    }
  }
  if (small) {
    className += ' py-0 rounded-sm gap-0.5';
  }
  else {
    className += ' py-2 rounded-md gap-1';
  }
  switch (type) {
    case ButtonType.Secondary:
      if (disabled) {
        className += ' bg-gray-300 border border-gray-900 text-gray-800';
      }
      else if (selected) {
        className += ' bg-blue-400 border border-blue-700 text-white';
      }
      else if (danger) {
        className += ' bg-red-100 border border-red-300 text-red-800 hover:bg-red-200';
      }
      else {
        className += ' bg-white border border-gray-300 text-gray-800 hover:bg-gray-100';
      }
      break;
    case ButtonType.Tertiary:
      if (disabled) {
        className += ' bg-white text-gray-400 no-underline';
      }
      else if (selected) {
        className += ' bg-white text-blue-800 no-underline';
      }
      else if (danger) {
        className += ' bg-white text-red-800 no-underline hover:underline';
      }
      else {
        className += ' bg-white text-gray-800 no-underline hover:underline';
      }
      break;
    default:
      if (disabled) {
        className += ' bg-gray-200 border border-transparent text-gray-400';
      }
      else if (selected) {
        className += ' bg-blue-200 border border-transparent text-white';
      }
      else if (danger) {
        className += ' bg-red-800 border border-transparent hover:bg-red-700 active:bg-red-900 text-white';
      }
      else {
        className += ' bg-gray-800 border border-transparent hover:bg-gray-700 active:bg-gray-900 text-white';
      }
      break;
  }
  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled || selected}
    >
      {
        iconAtStart &&
        <span className={iconClassName}>
          {iconAtStart}
        </span>
      }
      {children}
      {
        busy &&
        <div className="absolute inset-0 flex justify-center items-center">
          <BusyAnimation size="20px"/>
        </div>
      }
    </button>
  );
};
