// region imports

import React from "react";
import {DateAndWeekFields} from "../../types/interfaces/data/fields/DateAndWeekFields";
import {useAppSelector} from "../../store/store";
import {HistoryDataProvider} from "../../types/interfaces/HistoryDataProvider";
import {DataTools} from "../../tools/DataTools";
import {DateRange} from "../../types/classes/DateRange";
import {DataPeriodType} from "../../types/enums/DataPeriodType";
import {DateTools} from "../../tools/DateTools";
import {GraphNumberList} from "../../types/classes/GraphNumberList";
import {Stack} from "../styled/layouts/Stack";
import {StackDirection} from "../styled/layouts/stack/StackDirection";
import {ResultsLineGraph} from "../graphs/ResultsLineGraph";
import {MultipleListsTable} from "../tables/MultipleListsTable";
import {Tools} from "../../tools/Tools";
import {MainPanel} from "../styled/containers/MainPanel";
import {DateAndWeekTools} from "../../tools/data/base/DateAndWeekTools";

// endregion

// region local

interface HistoryPanelProps<TEntry extends DateAndWeekFields> {
  /**
   * Entries from a slice in the store
   */
  readonly entries: TEntry[];

  /**
   * True while busy getting data
   */
  readonly loading: boolean;

  /**
   * One or more providers.
   */
  readonly providers: HistoryDataProvider<TEntry>[];

  /**
   * Factory and reduce methods
   */
  readonly tools: DateAndWeekTools<TEntry>;

  /**
   * Additional content to show at the bottom
   */
  readonly bottom?: React.ReactNode;

  /**
   * Additional content to show below the title at the top
   */
  readonly top?: React.ReactNode;

  /**
   * Show total row
   */
  readonly totalRow?: boolean;
}

// endregion

// region component

/**
 * Generic component to render one or more {@link DateAndWeekFields} data instances.
 */
export function HistoryPanel<TEntry extends DateAndWeekFields>(
  {entries, loading, providers, tools, bottom = null, top = null, totalRow = true}: HistoryPanelProps<TEntry>
) {
  const {dateSelection, customDateStart, customDateEnd, dataPeriod, location} = useAppSelector(
    state => state.application
  );
  let bottomLabels: React.ReactNode[] = [];
  let tableLabels: React.ReactNode[] = [];
  let graphLists: GraphNumberList[] = [];
  let columnName: string = '';
  if (!loading) {
    const dateRange = DateRange.createFromDateSelection(dateSelection, customDateStart, customDateEnd);
    const entriesForRange = DataTools.reduceForPeriod(dataPeriod, dateRange, entries, tools);
    switch (dataPeriod) {
      case DataPeriodType.Day:
        const dayDates = dateRange.createDayDates();
        bottomLabels = DateTools.getDayBottomLabels(dayDates);
        tableLabels = DateTools.getDayTableLabels(dayDates);
        columnName = "Date";
        graphLists = providers.map(provider => GraphNumberList.createDayHistory(
          provider.name,
          provider.color,
          provider.type,
          entriesForRange,
          dayDates,
          provider.getValue,
          provider.reverse || false
        ));
        break;
      case DataPeriodType.Week:
        const weekDates = dateRange.createWeekNumbers();
        bottomLabels = DateTools.getWeekBottomLabels(weekDates);
        tableLabels = DateTools.getWeekTableLabels(weekDates);
        columnName = "Week";
        graphLists = providers.map(provider => GraphNumberList.createWeekHistory(
          provider.name,
          provider.color,
          provider.type,
          entriesForRange,
          weekDates,
          provider.getValue,
          provider.reverse || false
        ));
        break;
      case DataPeriodType.Month:
        const monthDates = dateRange.createMonthDates();
        bottomLabels = DateTools.getMonthBottomLabels(monthDates);
        tableLabels = DateTools.getMonthTableLabels(monthDates);
        columnName = "Month";
        graphLists = providers.map(provider => GraphNumberList.createMonthHistory(
          provider.name,
          provider.color,
          provider.type,
          entriesForRange,
          monthDates,
          provider.getValue,
          provider.reverse || false
        ));
        break;
      case DataPeriodType.Year:
        const yearDates = dateRange.createYearDates();
        bottomLabels = DateTools.getYearBottomLabels(yearDates);
        tableLabels = DateTools.getYearTableLabels(yearDates);
        columnName = "Year";
        graphLists = providers.map(provider => GraphNumberList.createYearHistory(
          provider.name,
          provider.color,
          provider.type,
          entriesForRange,
          yearDates,
          provider.getValue,
          provider.reverse || false
        ));
        break;
    }
  }
  return (
    <MainPanel
      loading={loading}
      title={Tools.getLocationName(location) + ' History'}
    >
      <Stack
        direction={StackDirection.Column}
        gap={8}
        fullWidth
      >
        {top}
        <ResultsLineGraph
          data={graphLists}
          bottomLabels={bottomLabels}
          hoverTitles={tableLabels}
        />
        <MultipleListsTable
          lists={graphLists}
          tableLabels={tableLabels}
          columnName={columnName}
          showDifference={true}
          totalRow={totalRow}
        />
        {bottom}
      </Stack>
    </MainPanel>
  );
}
