import {UserLocation} from "../../types/enums/UserLocation";
import React from "react";
import {mainController} from "../../controllers/mainController";
import {Tools} from "../../tools/Tools";
import {MenuButton} from "../styled/buttons/MenuButton";
import {useAppSelector} from "../../store/store";

interface LocationMenuButtonProps {
  readonly location: UserLocation;
}

/**
 * A button to select a location and will show itself in selected state if the location has been selected.
 */
export const LocationMenuButton: React.FC<LocationMenuButtonProps> = ({location}) => {
  const currentLocation = useAppSelector(state => state.application.location);
  return (
    <MenuButton
      onClick={() => mainController.setUserLocation(location)}
      selected={location === currentLocation}
    >
      {Tools.getLocationName(location)}
    </MenuButton>
  );
};