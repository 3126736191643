import React from "react";
import {TwoSeventyRing} from "react-svg-spinners";
import {Config} from "../../../Config";

interface BusyAnimationProps {
  /**
   * Width and height of animation.
   */
  readonly size: number|string;
}

export const BusyAnimation: React.FC<BusyAnimationProps> = ({size}) =>
  <TwoSeventyRing color={Config.theme.busyAnimationColor} width={size} height={size} />;