import React from "react";

export enum TextInputType {
  Text,
  Email,
  Password
}

interface TextInputProps {
  readonly type?: TextInputType;
  readonly label?: React.ReactNode;
  readonly value?: string;
  readonly onChange: (newValue: string) => any;
  readonly required?: boolean;
  readonly placeHolder?: string;
  readonly autoFocus?: boolean;
}

const INPUT_TYPE_TO_TYPE = new Map<TextInputType, string>([
  [TextInputType.Text, 'text'],
  [TextInputType.Email, 'email'],
  [TextInputType.Password, 'password'],
]);

export const TextInput: React.FC<TextInputProps> = (
  {
    type = TextInputType.Text,
    label = '',
    value = '',
    onChange,
    required = false,
    placeHolder = '',
    autoFocus = false,
  }
) => {
  const inputTag = <input
    className="
        form-control
        block
        w-96
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-violet-600 focus:outline-none
    "
    type={INPUT_TYPE_TO_TYPE.get(type)!}
    value={value}
    onInput={event => onChange(event.currentTarget.value)}
    placeholder={placeHolder}
    required={required}
    autoFocus={autoFocus}
  />
  if (label) {
    return <label>
      <div>{label}</div>
      {inputTag}
    </label>;
  }
  else {
    return inputTag;
  }
}
