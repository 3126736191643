import React from "react";
import {Stack} from "../styled/layouts/Stack";
import {StackDirection} from "../styled/layouts/stack/StackDirection";
import {ChurnDashboard} from "./churn/ChurnDashboard";
import {CancellationsDashboard} from "./cancellations/CancellationsDashboard";
import {ArpuDashboard} from "./arpu/ArpuDashboard";
import {VisitorInfoDashboard} from "./visitor-info/VisitorInfoDashboard";
import {TransactionFailsDashboard} from "./transaction-fails/TransactionFailsDashboard";
import {TicketsDashboard} from "./tickets/TicketsDashboard";
import {ActiveSubscriptionsDashboard} from "./active-subscriptions/ActiveSubscriptionsDashboard";
import {TransactionResultsDashboard} from "./transaction-results/TransactionResultsDashboard";
import {SubscriptionsAndRecurringsDashboard} from "./subscriptions-and-recurrings/SubscriptionsAndRecurringsDashboard";
import {TrialToSubscriptionsDashboard} from "./trial-to-subscriptions/TrialToSubscriptionsDashboard";
import {UserRegistrationsDashboard} from "./user-registrations/UserRegistrationsDashboard";
import {StackAlignContent} from "../styled/layouts/stack/StackAlignContent";
import {CoinsDashboard} from "./coins/CoinsDashboard";
import {NewSubscriptionsCountDashboard} from "./new-subscriptions-count/NewSubscriptionsCountDashboard";
import {NewSubscriptionsAmountDashboard} from "./new-subscriptions-amount/NewSubscriptionsAmountDashboard";
import {TrafficInfoDashboard} from "./traffic-info/TrafficInfoDashboard";
import {StackAlignItems} from "../styled/layouts/stack/StackAlignItems";
import {BadDepthCostsDashboard} from "./bad-depth-costs/BadDepthCostsDashboard";

export const Dashboard: React.FC = () => {
  return (
    <Stack
      direction={StackDirection.Row}
      alignContent={StackAlignContent.Stretch}
      alignItems={StackAlignItems.Stretch}
      wrap
      gap={2}
    >
      <NewSubscriptionsCountDashboard />
      <NewSubscriptionsAmountDashboard />
      <ArpuDashboard />
      <CancellationsDashboard />
      <CoinsDashboard />
      <TransactionFailsDashboard />
      <TicketsDashboard />
      <ActiveSubscriptionsDashboard />
      <TransactionResultsDashboard />
      <TrialToSubscriptionsDashboard />
      <UserRegistrationsDashboard />
      <ChurnDashboard />
      <TrafficInfoDashboard />
      <BadDepthCostsDashboard />
      <SubscriptionsAndRecurringsDashboard />
      <VisitorInfoDashboard />
    </Stack>
  );
}