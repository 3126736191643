import React from "react";
import {useAppSelector} from "../../../store/store";
import {GraphColor} from "../../../types/enums/GraphColor";
import {NumberType} from "../../../types/enums/NumberType";
import {HistoryPanel} from "../../panels/HistoryPanel";
import {TicketsTools} from "../../../tools/data/TicketsTools";

export const TicketsHistory: React.FC = () => {
  const {historyEntries, historyRange, loading} = useAppSelector(state => state.tickets);
  return (
    <HistoryPanel
      entries={historyEntries}
      loading={loading || !historyRange}
      tools={new TicketsTools()}
      providers={[
        {
          color: GraphColor.Other1,
          name: 'Count',
          type: NumberType.Integer,
          reverse: true,
          getValue: entry => entry.count
        },
        {
          color: GraphColor.None,
          name: 'Average duration',
          type: NumberType.Duration,
          reverse: true,
          getValue: entry => entry.count ? entry.time / entry.count : 0
        },
        {
          color: GraphColor.None,
          name: 'Average replies',
          type: NumberType.Float2,
          reverse: true,
          getValue: entry => entry.count ? entry.replies / entry.count : 0
        },
      ]}
    />
  );
}
