import React from "react";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";

interface MainGridTopProps extends UFChildrenProp {
}

export const MainGridTop: React.FC<MainGridTopProps> = ({children}) => (
  <div className="main-grid-top px-2 py-1 shadow bg-white">
    {children}
  </div>
);