import React from "react";
import {Button, ButtonType} from "../styled/buttons/Button";
import {ReactComponent as Calendar} from "../../resources/icons/calendar.svg";
import {useAppSelector} from "../../store/store";
import {DateTools} from "../../tools/DateTools";
import {mainController} from "../../controllers/mainController";
import {DateSelectionToStringMap} from "../../types/maps/DateSelectionToStringMap";
import {DateRange} from "../../types/classes/DateRange";

/**
 * A button that will update its caption based on current date selection. Clicking it will open the date select dialog.
 */
export const SelectDateButton: React.FC = () => {
  const dateSelection = useAppSelector(state => state.application.dateSelection);
  const customDateStart = useAppSelector(state => state.application.customDateStart);
  const customDateEnd = useAppSelector(state => state.application.customDateEnd);
  const range = DateRange.createFromDateSelection(dateSelection, customDateStart, customDateEnd);
  return (
    <Button
      onClick={async () => await mainController.selectDate()}
      type={ButtonType.Secondary}
      iconAtStart={<Calendar />}
    >
      {DateSelectionToStringMap.get(dateSelection)} : {DateTools.formatDateRange(range)}
    </Button>
  );
};
