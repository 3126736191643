// region imports

import {DateRange} from "../../../types/classes/DateRange";
import {LoadDateRangeWithCallbackAction} from "../LoadDateRangeWithCallbackAction";
import {LegacyBookingsEntry} from "../../../types/interfaces/data/LegacyBookingsEntry";
import {LegacyBookingsResponseIO} from "../../../types/interfaces/data/io/LegacyBookingsResponseIO";
import {LegacyBookingsEntryIO} from "../../../types/interfaces/data/io/LegacyBookingsEntryIO";

// endregion

// region exports

/**
 * Loads the transaction results, grouped per day for a certain date range and data target.
 */
export class LegacyBookingsLoadAction
  extends LoadDateRangeWithCallbackAction<LegacyBookingsResponseIO, LegacyBookingsEntry[]> {
  // region public methods

  /**
   * Constructs an instance.
   *
   * @protected
   */
  public constructor(aDateRange: DateRange, aCallback: (entries: LegacyBookingsEntry[]) => any) {
    super('/transaction/legacy-bookings', aDateRange, aCallback);
  }

  // endregion

  // region protected methods

  /**
   * @inheritDoc
   */
  protected process(aResponse: LegacyBookingsResponseIO): Promise<void> {
    this.callback(this.convertEntriesFromIO(aResponse.entries));
    return Promise.resolve();
  }

  // endregion

  // region private methods

  /**
   * Converts the loaded entries, grouping them per plan.
   *
   * @private
   */
  private convertEntryFromIO(anEntry: LegacyBookingsEntryIO): LegacyBookingsEntry {
    return {
      date: new Date(anEntry.date),
      week: anEntry.week,
      incomingPayments: anEntry.incoming_payments || 0,
      incomingCount: anEntry.incoming_count || 0,
      outgoingPayments: anEntry.outgoing_payments || 0,
      outgoingCount: anEntry.outgoing_count || 0,
      dunningFees: anEntry.dunning_fees || 0,
      dunningCount: anEntry.dunning_count || 0,
      debitFees: anEntry.debit_fees || 0,
      debitCount: anEntry.debit_count || 0
    };
  }

  /**
   * Converts the loaded entries, grouping them per plan.
   *
   * @private
   */
  private convertEntriesFromIO(anEntries: LegacyBookingsEntryIO[]): LegacyBookingsEntry[] {
    return anEntries.map(entry => this.convertEntryFromIO(entry));
  }

  // endregion
}

// endregion