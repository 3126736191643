import React from "react";
import {useAppSelector} from "../../../store/store";
import {GraphColor} from "../../../types/enums/GraphColor";
import {NumberType} from "../../../types/enums/NumberType";
import {HistoryPanel} from "../../panels/HistoryPanel";
import {Text} from "../../styled/texts/Text";
import {DateWithWeekAndValueTools} from "../../../tools/data/DateWithWeekAndValueTools";

export const CoinsHistory: React.FC = () => {
  const {historyEntries, historyRange, loading} = useAppSelector(state => state.coins);
  return (
    <HistoryPanel
      entries={historyEntries}
      loading={loading || !historyRange}
      tools={new DateWithWeekAndValueTools()}
      providers={[
        {
          color: GraphColor.Other1,
          name: 'Sales',
          type: NumberType.Currency,
          getValue: entry => entry.value
        }
      ]}
      bottom={
        <Text>The sales is calculated by adding successful coin sale transactions for a certain period.</Text>
      }
    />
  );
}
