// region imports

import {UFSerialQueueAction} from "../../../UF/actions/UFSerialQueueAction";
import {DateRange} from "../../../types/classes/DateRange";
import {UFCallbackAction} from "../../../UF/actions/UFCallbackAction";
import {store} from "../../../store/store";
import {TransactionFailsLoadAction} from "./TransactionFailsLoadAction";
import {
  initializeDashboard,
  resetDashboard,
  setDashboardCurrent, setDashboardPrevious,
  setLoading
} from "../../../store/data/transactionFailsSlice";

// endregion

// region exports

/**
 * Gets the visitor info data for the dashboard.
 */
export class TransactionFailsDashboardAction extends UFSerialQueueAction {
  constructor(anAlways: boolean, aCurrentRange: DateRange, aPreviousRange: DateRange) {
    if (anAlways || !store.getState().transactionFails.initializedDashboard) {
      super(
        new UFCallbackAction(() => store.dispatch(setLoading(true))),
        new TransactionFailsLoadAction(aCurrentRange, info => store.dispatch(setDashboardCurrent(info))),
        new TransactionFailsLoadAction(aPreviousRange, info => store.dispatch(setDashboardPrevious(info))),
        new UFCallbackAction(() => store.dispatch(initializeDashboard())),
      );
      store.dispatch(resetDashboard());
    }
    else {
      super();
    }
  }
}

// endregion