import React from "react";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";
import './MainGridPage.css';

interface MainGridProps extends UFChildrenProp {
}

export const MainGridPage: React.FC<MainGridProps> = ({children}) => (
  <div className="w-full min-h-screen max-h-screen main-grid">
    {children}
  </div>
);