// region imports

import {UFSerialQueueAction} from "../../../UF/actions/UFSerialQueueAction";
import {UFCallbackAction} from "../../../UF/actions/UFCallbackAction";
import {
  initializeDashboard,
  resetDashboard,
  setDashboard,
  setLoading
} from "../../../store/data/userRegistrationsSlice";
import {store} from "../../../store/store";
import {DateRange} from "../../../types/classes/DateRange";
import {UserRegistrationsLoadAction} from "./UserRegistrationsLoadAction";

// endregion

// region exports

/**
 * An action that loads all data used for transaction results dashboard.
 */
export class UserRegistrationsDashboardAction extends UFSerialQueueAction {
  constructor(anAlways: boolean, aDateRange: DateRange) {
    if (anAlways || !store.getState().userRegistrations.initializedDashboard) {
      super(
        new UFCallbackAction(() => store.dispatch(setLoading(true))),
        new UserRegistrationsLoadAction(aDateRange, entries => store.dispatch(setDashboard(entries))),
        new UFCallbackAction(() => store.dispatch(initializeDashboard()))
      );
      store.dispatch(resetDashboard());
    }
    else {
      super();
    }
  }
}

// endregion