
export enum DateSelectionType {
  Today,
  Yesterday,
  ThisWeek,
  LastWeek,
  ThisMonth,
  LastMonth,
  Last7Days,
  Last30Days,
  Custom
}