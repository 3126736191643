import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";
import React from "react";

interface DialogContentProps extends UFChildrenProp {
}

export const DialogContent: React.FC<DialogContentProps> = ({children}) => (
  <div className="px-4 py-4 w-full">
    {children}
  </div>
);