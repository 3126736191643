// region imports

import {UFQueueableAction} from "../../UF/actions/UFQueueableAction";
import {network} from "../../tools/network";
import {DimensionWithMetricIO} from "../../types/interfaces/data/io/DimensionWithMetricIO";
import {ValueAndLabelFields} from "../../types/interfaces/data/fields/ValueAndLabelFields";
import {IUFCancellationToken} from "../../UF/actions/IUFCancellationToken";

// endregion

// region exports

/**
 * Base class for actions that load data using following url format:
 * {action}
 *
 * Subclasses have to implement the {@link process} method.
 *
 * Subclasses can override the {@link getAction} to add additional parameters.
 */
export abstract class LoadDataAction<TResponse> extends UFQueueableAction {
  // region private variables

  /**
   * See {@link action}
   *
   * @private
   */
  private readonly m_action: string;

  // endregion

  // region protected properties

  /**
   * Api action
   *
   * @protected
   */
  protected get action(): string {
    return this.m_action;
  }

  // endregion

  // region protected methods

  /**
   * Constructs an instance of the class.
   *
   * @protected
   */
  protected constructor(anAction: string) {
    super();
    this.m_action = anAction;
  }

  /**
   * Process the data received from the server.
   *
   * @param aResponse
   *   Response from the server.
   *
   * @protected
   */
  protected abstract process(aResponse: TResponse): Promise<void>;

  /**
   * Gets the action url.
   *
   * The default implementation just returns the {@link action} property.
   *
   * @protected
   */
  protected getAction(): string {
    return this.action;
  }

  // endregion

  // region protected support methods

  /**
   * Converts a dimension and metric received from IO to a value/label structure.
   *
   * @param anEntry
   *
   * @protected
   */
  protected convertMetricAndDimensionEntryFromIO(anEntry: DimensionWithMetricIO): ValueAndLabelFields {
    return {
      value: anEntry.metric,
      label: anEntry.dimension
    }
  }

  /**
   * Converts an array of dimension and metric structures to a value/label structures.
   *
   * @param anEntries
   *
   * @protected
   */
  protected convertMetricAndDimensionEntriesFromIO(anEntries: DimensionWithMetricIO[]): ValueAndLabelFields[] {
    return anEntries.map(entry => this.convertMetricAndDimensionEntryFromIO(entry));
  }

  // endregion

  // region UFQueueableAction

  /**
   * Calls the data API
   *
   * {@link process} will be called when there was a response.
   *
   * @return True if there was a response and the process callback has been called, false if there was no response
   *
   * @protected
   */
  async run(aToken: IUFCancellationToken): Promise<boolean> {
    const {response} = await network.apiGet<TResponse>(this.getAction());
    if (response && !aToken.isCancellationRequested) {
      await this.process(response);
      return true;
    }
    return false;
  }

  // endregion
}

// endregion