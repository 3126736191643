// region imports

import React from "react";
import {DirectionType} from "./arrow/DirectionType";

// endregion

// region local

/**
 * Properties for component
 */
interface ArrowProps {
  readonly direction: DirectionType;
}

// endregion

// region exports

/**
 * Renders an arrow character.
 */
export const Arrow: React.FC<ArrowProps> = ({direction}) => {
  switch (direction) {
    case DirectionType.Up:
      return <>&#129045;</>;
    case DirectionType.Down:
      return <>&#129047;</>;
    case DirectionType.Left:
      return <>&#129046;</>;
    case DirectionType.Right:
      return <>&#129048;</>;
  }
}

// endregion