import React from 'react';
import {Provider} from "react-redux";
import {store} from "../../store/store";
import {Pages} from "./Pages";
import {Dialogs} from "./Dialogs";
//import GoogleFontLoader from "react-google-font-loader";

export const Application: React.FC = () => {
  return (
    <Provider store={store}>
      {/*
      <GoogleFontLoader
        fonts={[
          {
            font: 'Open Sans',
            weights: [300, 400, 500, 600, 700, 900]
          }
        ]}
      />
      */}
      <Pages />
      <Dialogs />
    </Provider>
  );
}
