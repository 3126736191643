// region imports

import React from "react";
import {SmallDashboardPanel} from "../styled/containers/SmallDashboardPanel";
import {CurrentAndPreviousRow} from "../../types/classes/CurrentAndPreviousRow";
import {GeneralTable} from "../styled/tables/GeneralTable";
import {CurrentAndPreviousTableData} from "../../types/classes/table-data/CurrentAndPreviousTableData";
import {Tools} from "../../tools/Tools";

// endregion

// region local

interface CurrentAndPreviousTableDashboardPanelProps {
  /**
   * Title to show
   */
  readonly title: string;

  /**
   * When true show loading animation
   */
  readonly loading: boolean;

  /**
   * Current value
   */
  readonly rows: (CurrentAndPreviousRow | null)[];

  /**
   * On click handler if any
   */
  readonly onClick?: () => any;

  /**
   * Content to place before the table
   */
  readonly top?: React.ReactNode;
}

// endregion

// region export

export const CurrentAndPreviousTableDashboardPanel: React.FC<CurrentAndPreviousTableDashboardPanelProps> = (
  {
    title,
    rows,
    loading,
    onClick,
    top
  }
) => {
  const {lastTitle, previousTitle} = Tools.getLastAndPreviousTitles();
  return (
    <SmallDashboardPanel
      onClick={onClick}
      loading={loading}
      title={title}
      topLeft={
        <>
          {top}
          <GeneralTable
            data={new CurrentAndPreviousTableData(lastTitle, previousTitle, rows)}
          />
        </>
      }
    />
  );
}

// endregion