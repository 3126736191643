import {DateAndWeekTools} from "./base/DateAndWeekTools";
import {LegacyEntry} from "../../types/interfaces/data/LegacyEntry";
import {RecurringPerWebsiteAndBank} from "../../types/interfaces/data/RecurringPerWebsiteAndBank";
import {WebsiteAndBank} from "../../types/interfaces/data/WebsiteAndBank";

export class LegacyTools extends DateAndWeekTools<LegacyEntry> {
  factory(aDate: Date, aWeek: number): LegacyEntry {
    return {
      date: aDate,
      week: aWeek,
      payments: [],
      cancellations: 0
    }
  }

  reduce(aPrevious: LegacyEntry, anEntry: Readonly<LegacyEntry>): LegacyEntry {
    anEntry.payments.forEach(payment => {
      const item = this.findForWebsiteAndBank(aPrevious.payments, payment);
      if (item === false) {
        aPrevious.payments.push({
          ...payment
        });
      }
      else {
        item.amount += payment.amount;
        item.count += payment.count;
      }
    })
    return aPrevious;
  }

  totalRecurringCount(anEntry: LegacyEntry): number {
    return anEntry.payments.reduce((previous, current) => previous + current.count, 0);
  }

  totalRecurringAmount(anEntry: LegacyEntry): number {
    return anEntry.payments.reduce((previous, current) => previous + current.amount, 0);
  }

  /**
   * Gets all website and bank combinations. The list will be sorted on website and bank.
   *
   * @param anEntries
   */
  getAllWebsiteAndBanks(anEntries: LegacyEntry[]): WebsiteAndBank[] {
    const result: WebsiteAndBank[] = [];
    anEntries.forEach(entry => entry.payments.forEach(payment => {
      if (this.findForWebsiteAndBank(result, payment) === false) {
        result.push({
          website: payment.website,
          bank: payment.bank
        })
      }
    }));
    result.sort((first, second) => {
      const websiteCompare = first.website.localeCompare(second.website);
      return websiteCompare === 0 ? first.bank.localeCompare(second.bank) : websiteCompare;
    });
    return result;
  }

  public findForWebsiteAndBank<T extends WebsiteAndBank>(aList: T[], anEntry: WebsiteAndBank): T | false {
    for (const item of aList) {
      if ((item.website === anEntry.website) && (item.bank === anEntry.bank)) {
        return item;
      }
    }
    return false;
  }

  // region private methods

  // endregion
}