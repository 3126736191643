// region imports

import {GeneralTableData} from "./GeneralTableData";
import {CurrentAndPreviousRankedRow} from "../CurrentAndPreviousRankedRow";
import React from "react";
import {TextSize} from "../../../components/styled/texts/text/TextSize";
import {Text} from "../../../components/styled/texts/Text";
import {TextWeight} from "../../../components/styled/texts/text/TextWeight";
import {DifferenceInformation} from "../../../components/styled/texts/DifferenceInformation";
import {RankedTriangle} from "../../../components/styled/texts/RankedTriangle";
import {Position} from "../../enums/Position";
import {GraphColor} from "../../enums/GraphColor";
import {CurrentAndPreviousRow} from "../CurrentAndPreviousRow";

// endregion

// region exports

/**
 * Builds data that includes current and previous rankings and % change between current and previous value.
 */
export class CurrentAndPreviousRankedTableData extends GeneralTableData {
  // region private variables

  /**
   * The rows with data
   *
   * @private
   */
  private readonly m_rows: CurrentAndPreviousRankedRow[];

  /**
   * The title for the current part
   *
   * @private
   */
  private readonly m_leftTitle: React.ReactNode;

  /**
   * Title for the the previous part
   *
   * @private
   */
  private readonly m_rightTitle: React.ReactNode;

  /**
   * True to show a total percentage column
   *
   * @private
   */
  private readonly m_totalPercentage: boolean;

  /**
   * When true use color for each row.
   *
   * @private
   */
  private readonly m_useColors: boolean;

  /**
   * When true show an additional row with the sum of all values.
   *
   * @private
   */
  private readonly m_totalRow: boolean;


  // endregion

  // region public methods

  constructor(
    aLeftTitle: React.ReactNode, aRightTitle: React.ReactNode, aRows: CurrentAndPreviousRankedRow[],
    aTotalPercentage: boolean, anUseColors: boolean, aTotalRow: boolean
  ) {
    super();
    this.m_rightTitle = aRightTitle;
    this.m_leftTitle = aLeftTitle;
    this.m_rows = aRows;
    this.m_totalPercentage = aTotalPercentage;
    this.m_useColors = anUseColors;
    this.m_totalRow = aTotalRow;
    if (aTotalRow) {
      this.m_rows.push(
        new CurrentAndPreviousRankedRow(
          {
            value: this.calcTotal(row => row.current.value)
          },
          {
            value: this.calcTotal(row => row.previous.value)
          },
          aRows[0].valueType,
          'total',
          0,
          GraphColor.None,
          false
        )
      );
    }
  }

  // endregion

  // region GeneralTableData

  /**
   * @inheritDoc
   */
  cellColor(aColumn: number, aRow: number): GraphColor {
    return this.m_useColors ? this.m_rows[aRow].color : super.cellColor(aColumn, aRow);
  }

  /**
   * @inheritDoc
   */
  get headerRowCount(): number {
    return 1;
  }

  /**
   * @inheritDoc
   */
  header(aColumn: number, aRow: number): React.ReactNode {
    aColumn = this.updateColumnIndex(aColumn);
    switch (aColumn) {
      case 0:
        return this.m_leftTitle;
      case 6:
        return this.m_rightTitle;
      default:
        return null;
    }
  }

  /**
   * @inheritDoc
   */
  headerLeftBorder(aColumn: number, aRow: number): boolean {
    return false;
  }

  /**
   * @inheritDoc
   */
  headerRightBorder(aColumn: number, aRow: number): boolean {
    return false;
  }

  /**
   * @inheritDoc
   */
  headerTopBorder(aColumn: number, aRow: number): boolean {
    return false;
  }

  /**
   * @inheritDoc
   */
  headerBottomBorder(aColumn: number, aRow: number): boolean {
    return false;
  }

  /**
   * @inheritDoc
   */
  headerBottomSpacing(aColumn: number, aRow: number): boolean {
    return false;
  }

  /**
   * @inheritDoc
   */
  headerTopSpacing(aColumn: number, aRow: number): boolean {
    return false;
  }

  /**
   * @inheritDoc
   */
  headerLeftSpacing(aColumn: number, aRow: number): boolean {
    return false;
  }

  /**
   * @inheritDoc
   */
  headerHorizontalPosition(aColumn: number, aRow: number): Position {
    return Position.Start;
  }

  /**
   * @inheritDoc
   */
  headerColSpan(aColumn: number, aRow: number): number {
    aColumn = this.updateColumnIndex(aColumn);
    switch (aColumn) {
      case 0:
        return this.m_totalPercentage ? 6 : 5;
      case 6:
        return 3;
      default:
        return 1;
    }
  }

  /**
   * @inheritDoc
   */
  cell(aColumn: number, aRow: number): React.ReactNode {
    if (this.isTotalRow(aRow)) {
      switch (aColumn) {
        case 0:
          return <Text><em>Total</em></Text>;
        case 7:
          return null;
      }
    }
    const row = this.m_rows[aRow];
    aColumn = this.updateColumnIndex(aColumn);
    switch (aColumn) {
      case 0:
        return (aRow + 1) + '.';
      case 1:
        return <RankedTriangle currentRank={aRow} previousRank={row.previousRank}/>;
      case 2:
        return <Text>{row.name}</Text>;
      case 3:
        return <Text weight={TextWeight.Bold}>{row.currentAsText}</Text>;
      case 4:
        return <Text size={TextSize.Small}>{row.totalPercentageAsText}</Text>;
      case 5:
        return <DifferenceInformation data={row}/>;
      case 6:
        return <Text size={TextSize.Small}>{row.previousAsText}</Text>;
      case 7:
        return <Text size={TextSize.Small}>{row.previousRank + 1}.</Text>;
      case 8:
        return '';
      default:
        return null;
    }
  }

  /**
   * @inheritDoc
   */
  cellColSpan(aColumn: number, aRow: number): number {
    return (this.isTotalRow(aRow) && (aColumn === 0)) ? 3 : super.cellColSpan(aColumn, aRow);
  }

  /**
   * @inheritDoc
   */
  cellLeftBorder(aColumn: number, aRow: number): boolean {
    switch (aColumn) {
      case 1:
        return false;
      case 8:
        return !this.m_totalPercentage;
      case 7:
        return this.m_totalPercentage;
      default:
        return true;
    }
  }

  /**
   * @inheritDoc
   */
  cellRightBorder(aColumn: number, aRow: number): boolean {
    switch (aColumn) {
      case 0:
        return false;
      case 7:
        return !this.m_totalPercentage;
      case 6:
        return this.m_totalPercentage;
      default:
        return true;
    }
  }

  /**
   * @inheritDoc
   */
  cellLeftSpacing(aColumn: number, aRow: number): boolean {
    aColumn = this.updateColumnIndex(aColumn);
    return (aColumn !== 1) && (aColumn !== 8);
  }

  /**
   * @inheritDoc
   */
  cellRightSpacing(aColumn: number, aRow: number): boolean {
    aColumn = this.updateColumnIndex(aColumn);
    return (aColumn !== 0) && (aColumn !== 8);
  }

  /**
   * @inheritDoc
   */
  get columnCount(): number {
    return this.m_totalPercentage ? 9 : 8;
  }

  /**
   * @inheritDoc
   */
  get rowCount(): number {
    return this.m_rows.length;
  }

  /**
   * @inheritDoc
   */
  cellHorizontalPosition(aColumn: number, aRow: number): Position {
    if (this.isTotalRow(aRow) && (aColumn === 0)) {
      return Position.Start;
    }
    switch (aColumn) {
      case 1:
      case 2:
        return Position.Start;
      default:
        return Position.End;
    }
  }

  // endregion

  // region protected methods

  /**
   * Gets the row data for a row.
   *
   * @protected
   */
  protected rowData(aRow: number): CurrentAndPreviousRankedRow {
    return this.m_rows[aRow];
  }

  // endregion

  // region private methods

  /**
   * Adjusts the column index depending on if the total percentages are shown or not.
   *
   * @private
   */
  private updateColumnIndex(aColumn: number): number {
    return ((aColumn > 3) && !this.m_totalPercentage) ? aColumn + 1 : aColumn;
  }

  /**
   * Checks if the row is the total row.
   *
   * @private
   */
  private isTotalRow(aRow: number): boolean {
    return this.m_totalRow && (aRow === this.m_rows.length - 1);
  }

  /**
   * Calculates the total sum of some value obtained per row.
   *
   * @private
   */
  private calcTotal(aGetValue: (aRow: CurrentAndPreviousRow) => number): number {
    return this.m_rows.reduce((previous, current) => previous + aGetValue(current), 0);
  }

  // endregion
}

// endregion