import React from "react";
import {useAppSelector} from "../../../store/store";
import {GraphColor} from "../../../types/enums/GraphColor";
import {NumberType} from "../../../types/enums/NumberType";
import {HistoryPanel} from "../../panels/HistoryPanel";
import {Text} from "../../styled/texts/Text";
import {ChurnTools} from "../../../tools/data/ChurnTools";
import {TextPlacement} from "../../styled/texts/text/TextPlacement";

export const ChurnHistory: React.FC = () => {
  const {historyEntries, historyRange, loading} = useAppSelector(state => state.churn);
  const tools = new ChurnTools();
  return (
    <HistoryPanel
      entries={historyEntries}
      loading={loading || !historyRange}
      tools={tools}
      totalRow={false}
      providers={[
        {
          color: GraphColor.Other1,
          name: 'Churn',
          type: NumberType.Float5,
          getValue: entry => tools.calc(entry)
        }
      ]}
      bottom={
        <div>
          <Text placement={TextPlacement.Paragraph}>
            The churn is calculated by dividing the number of cancellations by the sum of the number new registrations
            in the period and number of active users for that period.
          </Text>
          <Text placement={TextPlacement.Paragraph}>
            An user is considered active at a certain date when the <em>start_date</em> is at or before that date and
            the <em>status</em> is <em>active</em>.
          </Text>
        </div>
      }
    />
  );
}
