// region imports

import {StoreTools} from "../../tools/StoreTools";
import {TransactionFailsInfo} from "../../types/interfaces/data/TransactionFailsInfo";

// endregion

// region exports

/**
 * Transaction fails related values.
 */
export const transactionFailsSlice = StoreTools.createSingleDataForDateRangeSlice<TransactionFailsInfo>(
  'transaction-fails',
  {
    entries: []
  }
);

/**
 * The actions for the slice
 */
export const {
  initializeDashboard,
  setLoading,
  setDashboardCurrent,
  setDashboardPrevious,
  setHistoryRange,
  setHistoryData,
  resetDashboard,
} = transactionFailsSlice.actions;

// endregion
