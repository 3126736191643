// region imports

import {DateRange} from "../../../types/classes/DateRange";
import {CancellationsEntryIO} from "../../../types/interfaces/data/io/CancellationsEntryIO";
import {CancellationsEntry} from "../../../types/interfaces/data/CancellationsEntry";
import {CancellationsResponseIO} from "../../../types/interfaces/data/io/CancellationsResponseIO";
import {LoadDateRangeWithCallbackAction} from "../LoadDateRangeWithCallbackAction";

// endregion

// region exports

/**
 * Loads the transaction results, grouped per day for a certain date range and data target.
 */
export class CancellationsLoadAction
  extends LoadDateRangeWithCallbackAction<CancellationsResponseIO, CancellationsEntry[]>
{
  // region public methods

  /**
   * Constructs an instance.
   *
   * @protected
   */
  public constructor(aDateRange: DateRange, aCallback: (entries: CancellationsEntry[]) => any) {
    super('/subscription/cancellations', aDateRange, aCallback);
  }

  // endregion

  // region protected methods

  /**
   * @inheritDoc
   */
  protected process(aResponse: CancellationsResponseIO): Promise<void> {
    this.callback(this.convertEntriesFromIO(aResponse.entries));
    return Promise.resolve();
  }

  // endregion

  // region private methods

  /**
   * Converts data received from the server to data structure used within the application.
   *
   * @param anEntry
   */
  private convertEntryFromIO(anEntry: CancellationsEntryIO): CancellationsEntry {
    return {
      date: new Date(anEntry.date),
      week: anEntry.week,
      count1: anEntry.count_1 || 0,
      count3: anEntry.count_3 || 0,
      count6: anEntry.count_6 || 0,
      count12: anEntry.count_12 || 0,
      count24: anEntry.count_24 || 0,
      subscriptions: anEntry.subscriptions || 0
    };
  }

  /**
   * Converts data received from the server to data structure used within the application.
   *
   * @param anEntries
   */
  private convertEntriesFromIO(anEntries: CancellationsEntryIO[]): CancellationsEntry[] {
    return anEntries.map(this.convertEntryFromIO)
  }

  // endregion
}

// endregion