// region imports

import {UFSerialQueueAction} from "../../../UF/actions/UFSerialQueueAction";
import {DateRange} from "../../../types/classes/DateRange";
import {UFCallbackAction} from "../../../UF/actions/UFCallbackAction";
import {store} from "../../../store/store";
import {
  setHistoryCurrent,
  setHistoryPrevious,
  setHistoryRange,
  setLoading
} from "../../../store/data/visitorInfoSlice";
import {VisitorInfoLoadAction} from "./VisitorInfoLoadAction";
import {WebsiteDomainType} from "../../../types/enums/WebsiteDomainType";

// endregion

// region exports

/**
 * Gets the visitor info data for the history view.
 */
export class VisitorInfoHistoryAction extends UFSerialQueueAction {
  constructor(anAlways: boolean, aDomain: WebsiteDomainType, aDateRange: DateRange) {
    if (anAlways || !aDateRange.isEqual(store.getState().visitorInfo.historyRange[aDomain])) {
      const previousRange = aDateRange.getPrevious();
      super(
        new UFCallbackAction(() => store.dispatch(setLoading(true))),
        new VisitorInfoLoadAction(
          aDomain, aDateRange, info => store.dispatch(setHistoryCurrent({domain: aDomain, data: info}))
        ),
        new VisitorInfoLoadAction(
          aDomain, previousRange, info => store.dispatch(setHistoryPrevious({domain: aDomain, data: info}))
        ),
        new UFCallbackAction(() => store.dispatch(setHistoryRange({domain: aDomain, data: aDateRange}))),
      );
    }
    else {
      super();
    }
  }
}

// endregion