import React from "react";
import {useAppSelector} from "../../store/store";
import {UserLocation} from "../../types/enums/UserLocation";
import {Dashboard} from "../content/Dashboard";
import {ArpuMain} from "../content/arpu/ArpuMain";
import {ChurnHistory} from "../content/churn/ChurnHistory";
import {CancellationsHistory} from "../content/cancellations/CancellationsHistory";
import {VisitorInfoHistory} from "../content/visitor-info/VisitorInfoHistory";
import {TransactionFailsHistory} from "../content/transaction-fails/TransactionFailsHistory";
import {TicketsHistory} from "../content/tickets/TicketsHistory";
import {ActiveSubscriptionsHistory} from "../content/active-subscriptions/ActiveSubscriptionsHistory";
import {TransactionResultsHistory} from "../content/transaction-results/TransactionResultsHistory";
import {TrialToSubscriptionsHistory} from "../content/trial-to-subscriptions/TrialToSubscriptionsHistory";
import {
  SubscriptionsAndRecurringsHistory
} from "../content/subscriptions-and-recurrings/SubscriptionsAndRecurringsHistory";
import {UserRegistrationsHistory} from "../content/user-registrations/UserRegistrationsHistory";
import {CoinsHistory} from "../content/coins/CoinsHistory";
import {NewSubscriptionsCountHistory} from "../content/new-subscriptions-count/NewSubscriptionsCountHistory";
import {NewSubscriptionsAmountHistory} from "../content/new-subscriptions-amount/NewSubscriptionsAmountHistory";
import {TrafficInfoHistory} from "../content/traffic-info/TrafficInfoHistory";
import {LegacyBookingsHistory} from "../content/bad-depth-costs/LegacyBookingsHistory";

/**
 * Show content in the main area; used when user has been authenticated.
 *
 * @constructor
 */
export const MainContent: React.FC = () => {
  const location = useAppSelector(state => state.application.location);
  switch(location) {
    case UserLocation.Dashboard:
      return <Dashboard />;
    case UserLocation.Arpu:
      return <ArpuMain />;
    case UserLocation.Coins:
      return <CoinsHistory />;
    case UserLocation.Cancellations:
      return <CancellationsHistory />;
    case UserLocation.VisitorInfo:
      return <VisitorInfoHistory />;
    case UserLocation.TransactionFails:
      return <TransactionFailsHistory />;
    case UserLocation.Tickets:
      return <TicketsHistory />;
    case UserLocation.ActiveSubscriptions:
      return <ActiveSubscriptionsHistory />;
    case UserLocation.TransactionResults:
      return <TransactionResultsHistory />;
    case UserLocation.TrialToSubscriptions:
      return <TrialToSubscriptionsHistory />;
    case UserLocation.SubscriptionsAndRecurrings:
      return <SubscriptionsAndRecurringsHistory />;
    case UserLocation.UserRegistrations:
      return <UserRegistrationsHistory />;
    case UserLocation.Churn:
      return <ChurnHistory />;
    case UserLocation.NewSubscriptionsCount:
      return <NewSubscriptionsCountHistory />;
    case UserLocation.NewSubscriptionsAmount:
      return <NewSubscriptionsAmountHistory />;
    case UserLocation.TrafficInfo:
      return <TrafficInfoHistory />;
    case UserLocation.LegacyBookings:
      return <LegacyBookingsHistory />;
    default:
      return null;
  }
}
