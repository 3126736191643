import {NumberType} from "../types/enums/NumberType";

export class NumberTypeTools {
  /**
   * Get value as text.
   */
  public static getAsText(aValue: number, aType: NumberType, anAddPlus: boolean = false): string {
    switch (aType) {
      case NumberType.Integer:
        return aValue.toLocaleString('en-US', {
          useGrouping: true,
          signDisplay: anAddPlus ? 'always' : 'auto',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
      case NumberType.Currency:
        return aValue.toLocaleString('en-US', {
          useGrouping: true,
          currency: 'EUR',
          style: 'currency',
          signDisplay: anAddPlus ? 'always' : 'auto',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      case NumberType.Float1:
        return aValue.toLocaleString('en-US', {
          useGrouping: true,
          signDisplay: anAddPlus ? 'always' : 'auto',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        });
      case NumberType.Float2:
        return aValue.toLocaleString('en-US', {
          useGrouping: true,
          signDisplay: anAddPlus ? 'always' : 'auto',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      case NumberType.Float3:
        return aValue.toLocaleString('en-US', {
          useGrouping: true,
          signDisplay: anAddPlus ? 'always' : 'auto',
          minimumFractionDigits: 3,
          maximumFractionDigits: 3
        });
      case NumberType.Float4:
        return aValue.toLocaleString('en-US', {
          useGrouping: true,
          signDisplay: anAddPlus ? 'always' : 'auto',
          minimumFractionDigits: 4,
          maximumFractionDigits: 4
        });
      case NumberType.Float5:
        return aValue.toLocaleString('en-US', {
          useGrouping: true,
          signDisplay: anAddPlus ? 'always' : 'auto',
          minimumFractionDigits: 5,
          maximumFractionDigits: 5
        });
      case NumberType.Duration:
        const days = Math.floor(Math.abs(aValue) / (24 * 60 * 60));
        const hours = Math.floor(Math.abs(aValue) / (60 * 60)) % 24;
        const minutes = Math.floor(Math.abs(aValue) / 60) % 60;
        return (days > 0 ? days + 'd' : '') + (days + hours > 0 ? hours + 'h' : '') + minutes + 'm';
      case NumberType.Percentage:
        return aValue.toLocaleString('en-US', {
          useGrouping: true,
          signDisplay: anAddPlus ? 'always' : 'auto',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        }) + '%';
      default:
        return aValue.toLocaleString('en-US', {
          useGrouping: true,
          signDisplay: anAddPlus ? 'always' : 'auto'
        });
    }
  }

  /**
   * Normalize a number based on the {@link NumberType} by rounding to a certain number of fractional digits.
   */
  public static normalize(aValue: number, aType: NumberType): number {
    let size = 1;
    switch (aType) {
      case NumberType.Float2:
      case NumberType.Currency:
        size = 100;
        break;
      case NumberType.Float1:
        size = 10;
        break;
      case NumberType.Float3:
        size = 1000;
        break;
      case NumberType.Float4:
        size = 10000;
        break;
      case NumberType.Float5:
        size = 100000;
        break;
    }
    return Math.round(aValue * size) / size;
  }
}
