// region imports

import {TransactionResultEntry} from "../../types/interfaces/data/TransactionResultEntry";
import {StoreTools} from "../../tools/StoreTools";

// endregion

// region exports

/**
 * Transaction results related values.
 */
export const transactionResultsSlice = StoreTools.createDataListForDateRangeSlice<TransactionResultEntry>(
  'transaction-results'
);

/**
 * The actions for the slice
 */
export const {
  initializeDashboard,
  setHistoryRange,
  setLoading,
  setDashboard,
  setHistoryEntries,
  resetDashboard,
} = transactionResultsSlice.actions;

// endregion