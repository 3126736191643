import {DateAndWeekTools} from "./base/DateAndWeekTools";
import {BankBookingsEntry} from "../../types/interfaces/data/BankBookingsEntry";
import {BankBookingPaymentType} from "../../types/enums/BankBookingPaymentType";

export class BankBookingTools extends DateAndWeekTools<BankBookingsEntry> {
  factory(aDate: Date, aWeek: number): BankBookingsEntry {
    return {
      date: aDate,
      week: aWeek,
      incomingPayments: 0,
      incomingCount: 0,
      outgoingPayments: 0,
      outgoingCount: 0,
    }
  }

  reduce(aPrevious: BankBookingsEntry, anEntry: Readonly<BankBookingsEntry>): BankBookingsEntry {
    return {
      date: aPrevious.date,
      week: aPrevious.week,
      incomingPayments: aPrevious.incomingPayments + anEntry.incomingPayments,
      incomingCount: aPrevious.incomingCount + anEntry.incomingCount,
      outgoingPayments: aPrevious.outgoingPayments + anEntry.outgoingPayments,
      outgoingCount: aPrevious.outgoingCount + anEntry.outgoingCount,
    }
  }

  /**
   * Gets the total payment for a type. If type is outgoing, the value will be negative.
   *
   * @param anEntry
   * @param aType
   */
  getTotalPayment(anEntry: Readonly<BankBookingsEntry>, aType: BankBookingPaymentType): number {
    switch(aType) {
      case BankBookingPaymentType.Incoming:
        return anEntry.incomingPayments;
      case BankBookingPaymentType.Outgoing:
        return -anEntry.outgoingPayments;
    }
  }

  getTotalCount(anEntry: Readonly<BankBookingsEntry>, aType: BankBookingPaymentType): number {
    switch(aType) {
      case BankBookingPaymentType.Incoming:
        return anEntry.incomingCount;
      case BankBookingPaymentType.Outgoing:
        return anEntry.outgoingCount;
    }
  }
}