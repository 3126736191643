// region imports

import React, {useState} from "react";
import {useAppSelector} from "../../../store/store";
import {GraphColor} from "../../../types/enums/GraphColor";
import {NumberType} from "../../../types/enums/NumberType";
import {HistoryPanel} from "../../panels/HistoryPanel";
import {Text} from "../../styled/texts/Text";
import {TextPlacement} from "../../styled/texts/text/TextPlacement";
import {RadioButtonsBar} from "../../styled/buttons/RadioButtonsBar";
import {BulletList} from "../../styled/texts/BulletList";
import {BulletItem} from "../../styled/texts/BulletItem";
import {TransactionResultsTools} from "../../../tools/data/TransactionResultsTools";

// endregion

// region local

enum ViewType {
  Success,
  Failure
}

const VIEW_TYPE_MAP = new Map<ViewType, string>([
  [ViewType.Success, 'Success'],
  [ViewType.Failure, 'Failure'],
]);

// endregion

export const TransactionResultsHistory: React.FC = () => {
  const {historyEntries, historyRange, loading} = useAppSelector(state => state.transactionResults);
  const [viewType, setViewType] = useState(ViewType.Success);
  return (
    <HistoryPanel
      entries={historyEntries}
      loading={loading || !historyRange}
      tools={new TransactionResultsTools()}
      top={
        <RadioButtonsBar
          onChange={setViewType}
          values={VIEW_TYPE_MAP}
          value={viewType}
        />
      }
      providers={[
        {
          color: GraphColor.Other1,
          name: 'Subscriptions',
          type: NumberType.Integer,
          reverse: viewType === ViewType.Failure,
          getValue: viewType === ViewType.Success
            ? entry => entry.subscription.successCount
            : entry => entry.subscription.failCount
        },
        {
          color: GraphColor.Other2,
          name: 'Recurring',
          type: NumberType.Integer,
          reverse: viewType === ViewType.Failure,
          getValue: viewType === ViewType.Success
            ? entry => entry.recurring.successCount
            : entry => entry.recurring.failCount
        },
        {
          color: GraphColor.Other6,
          name: 'Coins',
          type: NumberType.Integer,
          reverse: viewType === ViewType.Failure,
          getValue: viewType === ViewType.Success
            ? entry => entry.coin.successCount
            : entry => entry.coin.failCount
        },
        {
          color: GraphColor.None,
          name: 'Total',
          type: NumberType.Integer,
          reverse: viewType === ViewType.Failure,
          getValue: viewType === ViewType.Success
            ? entry => entry.subscription.successCount + entry.recurring.successCount + entry.coin.successCount
            : entry => entry.subscription.failCount + entry.recurring.failCount + entry.coin.failCount
        },
      ]}
      bottom={
        <Text placement={TextPlacement.Paragraph}>
          Only transactions with a remote status have been processed:
          <BulletList>
            <BulletItem>A remote status that is either 200 or 210, is interpreted as successful.</BulletItem>
            <BulletItem>A remote status that is either 100 or 0, is interpreted as pending and is skipped.</BulletItem>
            <BulletItem>All other remote status values are interpreted as failure.</BulletItem>
          </BulletList>
        </Text>
      }
    />
  );
}
