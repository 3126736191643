import React from "react";
import {useAppSelector} from "../../../store/store";
import {GraphColor} from "../../../types/enums/GraphColor";
import {NumberType} from "../../../types/enums/NumberType";
import {HistoryPanel} from "../../panels/HistoryPanel";
import {Text} from "../../styled/texts/Text";
import {UserRegistrationsTools} from "../../../tools/data/UserRegistrationsTools";
import {TextPlacement} from "../../styled/texts/text/TextPlacement";

export const UserRegistrationsHistory: React.FC = () => {
  const {historyEntries, historyRange, loading} = useAppSelector(state => state.userRegistrations);
  return (
    <HistoryPanel
      entries={historyEntries}
      loading={loading || !historyRange}
      tools={new UserRegistrationsTools()}
      providers={[
        {
          color: GraphColor.Other1,
          name: 'Total',
          type: NumberType.Integer,
          getValue: entry => entry.total
        },
        {
          color: GraphColor.Other2,
          name: 'Verified',
          type: NumberType.Integer,
          getValue: entry => entry.verified
        }

      ]}
      bottom={
      <Text placement={TextPlacement.Paragraph}>
        Total are all newly created user record in the specified period.<br/>
        Verified the number of records that got verified in the specified period.
      </Text>}
    />
  );
}
