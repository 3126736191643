import React from "react";
import {CurrentAndPreviousValue} from "../../../types/classes/CurrentAndPreviousValue";
import {useAppSelector} from "../../../store/store";
import {DataTools} from "../../../tools/DataTools";
import {NumberType} from "../../../types/enums/NumberType";
import {LastAndPreviousDaysDashboardPanel} from "../../panels/LastAndPreviousDaysDashboardPanel";
import {mainController} from "../../../controllers/mainController";
import {UserLocation} from "../../../types/enums/UserLocation";
import {ChurnTools} from "../../../tools/data/ChurnTools";

export const ChurnDashboard: React.FC = () => {
  const {dashboardEntries, loading, initializedDashboard} = useAppSelector(state => state.churn);
  const tools = new ChurnTools();
  const current = DataTools.reduceForDashboardCurrent(dashboardEntries, tools);
  const previous = DataTools.reduceForDashboardPrevious(dashboardEntries, tools);
  const currentAndPrevious = new CurrentAndPreviousValue(
    {value: tools.calc(current)}, {value: tools.calc(previous)}, NumberType.Float5
  );
  return (
    <LastAndPreviousDaysDashboardPanel
      title="Churn"
      currentAndPrevious={currentAndPrevious}
      loading={loading || !initializedDashboard}
      onClick={() => mainController.setUserLocation(UserLocation.Churn)}
    />
  );
};
