// region imports

import {LoadDataAction} from "./LoadDataAction";

// endregion

// region exports

/**
 * Base class for actions that load data using following url format:
 * {action}
 *
 * Subclasses have to implement the {@link process} method.
 *
 * Subclasses can override the {@link getAction} to add additional parameters.
 */
export abstract class LoadDataWithCallbackAction<TResponse, TData> extends LoadDataAction<TResponse> {
  // region private variables

  /**
   * Callback that is passed on to process.
   *
   * @private
   */
  private readonly m_callback: (data: TData) => any;

  // endregion

  // region protected properties

  /**
   * Callback passed on the constructor
   *
   * @protected
   */
  protected get callback(): (data: TData) => any {
    return this.m_callback;
  }

  // endregion

  // region protected methods

  /**
   * Constructs an instance of the class.
   *
   * @protected
   */
  protected constructor(anAction: string, aCallback: (data: TData) => any) {
    super(anAction);
    this.m_callback = aCallback;
  }

  // endregion
}

// endregion