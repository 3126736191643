import {SubscriptionAndRecurringEntry} from "../../types/interfaces/data/SubscriptionAndRecurringEntry";
import {DateAndWeekTools} from "./base/DateAndWeekTools";


export class SubscriptionsAndRecurringsTools extends DateAndWeekTools<SubscriptionAndRecurringEntry> {
  factory(aDate: Date, aWeek: number): SubscriptionAndRecurringEntry {
    return {
      date: aDate,
      week: aWeek,
      subscriptionCount: new Map(),
      recurringCount: new Map(),
      subscriptionAmount: new Map(),
      recurringAmount: new Map(),
      cancellations: 0
    }
  }

  reduce(aPrevious: SubscriptionAndRecurringEntry, anEntry: Readonly<SubscriptionAndRecurringEntry>): SubscriptionAndRecurringEntry {
    this.updateMap(aPrevious.subscriptionCount, anEntry.subscriptionCount);
    this.updateMap(aPrevious.subscriptionAmount, anEntry.subscriptionAmount);
    this.updateMap(aPrevious.recurringCount, anEntry.recurringCount);
    this.updateMap(aPrevious.recurringAmount, anEntry.recurringAmount);
    aPrevious.cancellations += anEntry.cancellations;
    return aPrevious;
  }

  totalSubscriptionCount(anEntry: SubscriptionAndRecurringEntry): number {
    return this.sum(anEntry.subscriptionCount);
  }

  totalSubscriptionAmount(anEntry: SubscriptionAndRecurringEntry): number {
    return this.sum(anEntry.subscriptionAmount);
  }

  private updateMap(aPrevious: Map<number, number>, anEntry: Map<number, number>) {
    anEntry.forEach((value, key) => {
      if (aPrevious.has(key)) {
        aPrevious.set(key, value + aPrevious.get(key)!);
      }
      else {
        aPrevious.set(key, value);
      }
    })
  }

  private sum(aMap: Map<number, number>): number {
    let result = 0;
    aMap.forEach(value => {
      result += value;
    });
    return result;
  }

}