// region imports

import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import React from "react";

// endregion

// region local

/**
 * Slice state
 */
interface AlertState {
  /**
   * True to show dialog
   */
  visible: boolean;

  /**
   * Title to show in dialog
   */
  title: string;

  /**
   * Content to show in dialog
   */
  content: React.ReactNode;

  /**
   * Caption for the close button
   */
  closeCaption: string;

  /**
   * Callback to call when dialog gets closed
   */
  onClose: () => any;
}

/**
 * Initial slice state
 */
const initialState: AlertState = {
  visible: false,
  title: '',
  content: '',
  closeCaption: 'Close',
  onClose: () => null,
};

// endregion

// region exports

/**
 * Alert dialog related values.
 */
export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    hide: (state) => {
      state.visible = false;
    },
    setAll: (state, action: PayloadAction<AlertState>) => {
      state.title = action.payload.title;
      state.content = action.payload.content;
      state.onClose = action.payload.onClose;
      state.closeCaption = action.payload.closeCaption;
      state.visible = action.payload.visible;
    }
  }
})

/**
 * The data to call the async show function with
 */
export interface AlertPayload {
  title: string;
  closeCaption: string;
  content: React.ReactNode;
}

/**
 * Creates an async action to show the alert. The other actions are not exported, since they are used only internally.
 */
export const showAlertUsingPayload = createAsyncThunk(
  'alert/show',
  async (payload: AlertPayload, thunkApi) => new Promise<void>(resolve => {
    thunkApi.dispatch(alertSlice.actions.setAll({
      visible: true,
      title: payload.title,
      content: payload.content,
      closeCaption: payload.closeCaption,
      onClose: () => {
        thunkApi.dispatch(alertSlice.actions.hide());
        resolve();
      }
    }));
  })
);
