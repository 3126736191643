import {ChurnEntry} from "../../types/interfaces/data/ChurnEntry";
import {DateAndWeekTools} from "./base/DateAndWeekTools";

export class ChurnTools extends DateAndWeekTools<ChurnEntry> {
  factory(aDate: Date, aWeek: number): ChurnEntry {
    return {
      date: aDate,
      week: aWeek,
      cancellations: 0,
      registrations: 0,
      active: 0
    }
  }

  reduce(aPrevious: ChurnEntry, anEntry: Readonly<ChurnEntry>): ChurnEntry {
    aPrevious.cancellations += anEntry.cancellations;
    aPrevious.registrations += anEntry.registrations;
    aPrevious.active += anEntry.active;
    return aPrevious;
  }

  calc(anEntry: ChurnEntry): number {
    const sum = anEntry.registrations + anEntry.active;
    return sum <= 0 ? 0 : anEntry.cancellations / sum;
  }
}