// region imports

import {UFSerialQueueAction} from "../../../UF/actions/UFSerialQueueAction";
import {UFCallbackAction} from "../../../UF/actions/UFCallbackAction";
import {initializeDashboard, resetDashboard, setDashboard, setLoading} from "../../../store/data/bankBookingsSlice";
import {store} from "../../../store/store";
import {DateRange} from "../../../types/classes/DateRange";
import {BankBookingsLoadAction} from "./BankBookingsLoadAction";

// endregion

// region exports

/**
 * An action that loads all data for use with the dashboard.
 */
export class BankBookingsDashboardAction extends UFSerialQueueAction {
  constructor(anAlways: boolean) {
    // for dashboard always load the data from the start of the same month in the previous year till now.
    const now = new Date();
    const dateRange = new DateRange(new Date(now.getFullYear() - 1, now.getMonth(), 1), now);
    if (anAlways || !store.getState().bankBookings.initializedDashboard) {
      super(
        new UFCallbackAction(() => store.dispatch(setLoading(true))),
        new BankBookingsLoadAction(dateRange, entries => store.dispatch(setDashboard(entries))),
        new UFCallbackAction(() => store.dispatch(initializeDashboard()))
      );
      store.dispatch(resetDashboard());
    }
    else {
      super();
    }
  }
}

// endregion