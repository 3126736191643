import React from "react";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";

interface MainGridLeftProps extends UFChildrenProp {
}

export const MainGridLeft: React.FC<MainGridLeftProps> = ({children}) => (
  <div className="main-grid-left px-4 py-1 shadow bg-white">
    {children}
  </div>
);