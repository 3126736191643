import {DateAndWeekAndValueEntry} from "../../types/interfaces/data/DateAndWeekAndValueEntry";
import {DateAndWeekTools} from "./base/DateAndWeekTools";

export class DateWithWeekAndValueTools extends DateAndWeekTools<DateAndWeekAndValueEntry> {
  factory(aDate: Date, aWeek: number): DateAndWeekAndValueEntry {
    return {
      date: aDate,
      week: aWeek,
      value: 0
    };
  }

  reduce(
    aPrevious: DateAndWeekAndValueEntry, anEntry: Readonly<DateAndWeekAndValueEntry>
  ): DateAndWeekAndValueEntry {
    aPrevious.value += anEntry.value;
    return aPrevious;
  }
}