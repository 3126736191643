import React, {useState} from "react";
import {CenteredContentPage} from "../styled/pages/CenteredContentPage";
import {Stack} from "../styled/layouts/Stack";
import {Title} from "../styled/texts/Title";
import {TextInput, TextInputType} from "../styled/form/TextInput";
import {Buttons} from "../buttons/Buttons";
import {Filler} from "../styled/layouts/Filler";
import {Button} from "../styled/buttons/Button";
import {ReactComponent as LoginIcon} from '../../resources/icons/login.svg';
import {mainController} from "../../controllers/mainController";
import {useAppSelector} from "../../store/store";
import {StackDirection} from "../styled/layouts/stack/StackDirection";
import {StackAlignItems} from "../styled/layouts/stack/StackAlignItems";
import {TextColor} from "../styled/texts/text/TextColor";
import {Text} from "../styled/texts/Text";
import {AutoSizePaperContainer} from "../styled/containers/AutoSizePaperContainer";

export const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [failed, setFailed] = useState(false);
  const networkBusy = useAppSelector(state => state.network.networkBusy);
  return (
    <CenteredContentPage>
      <AutoSizePaperContainer>
        <Stack
          direction={StackDirection.Column}
          gap={4}
          alignItems={StackAlignItems.Start}
        >
          <Title>Login</Title>
          {
            failed &&
            <Text color={TextColor.Failure}>
              Unknown or invalid email and/or password.
            </Text>
          }
          <TextInput
            label="Email"
            type={TextInputType.Email}
            value={email}
            onChange={value => {
              setEmail(value);
              setFailed(false);
            }}
            placeHolder="name@some.domain"
            autoFocus
          />
          <TextInput
            label="Password"
            type={TextInputType.Password}
            value={password}
            onChange={value => {
              setPassword(value);
              setFailed(false);
            }}
          />
          <Buttons>
            <Filler/>
            <Button
              onClick={async () => {
                if (!await mainController.login(email, password)) {
                  setPassword('');
                  setFailed(true);
                }
              }}
              iconAtStart={<LoginIcon/>}
              disabled={networkBusy || (email.length <= 0) || (password.length <= 0)}
              busy={networkBusy}
            >
              Login
            </Button>
          </Buttons>
        </Stack>
      </AutoSizePaperContainer>
    </CenteredContentPage>
  );
}
