// region imports

import {useAppSelector} from "../../store/store";
import {Dialog} from "../styled/dialog/Dialog";
import {DialogTitle} from "../styled/dialog/DialogTitle";
import {ReactComponent as Calendar} from "../../resources/icons/calendar.svg";
import {ReactComponent as Check} from "../../resources/icons/check.svg";
import {ReactComponent as Cancel} from "../../resources/icons/cancel.svg";
import {DialogContent} from "../styled/dialog/DialogContent";
import {DialogButtons} from "../styled/dialog/DialogButtons";
import {Filler} from "../styled/layouts/Filler";
import {Button, ButtonType} from "../styled/buttons/Button";
import React, {useState} from "react";
import {Stack} from "../styled/layouts/Stack";
import DatePicker from "react-datepicker";
import {DateSelectionType} from "../../types/enums/DateSelectionType";
import {DateTools} from "../../tools/DateTools";
import {Title} from "../styled/texts/Title";
import {UFDate} from "../../UF/tools/UFDate";
import {StackDirection} from "../styled/layouts/stack/StackDirection";
import {TitleType} from "../styled/texts/title/TitleType";
import {TextDropDown} from "../styled/form/TextDropDown";
import {DateSelectionToStringMap} from "../../types/maps/DateSelectionToStringMap";
import {DateRange} from "../../types/classes/DateRange";

// endregion

// region local

// endregion

// region exports

export const SelectDateDialog: React.FC = () => {
  const onClose = useAppSelector(state => state.selectDate.onClose);
  const [dateSelection, setDateSelection] = useState(useAppSelector(state => state.application.dateSelection));
  const [customDateStart, setCustomDateStart] = useState(
    useAppSelector(state => state.application.customDateStart) || Date.now()
  );
  const customDateEndDefault = useAppSelector(state => state.application.customDateEnd);
  const [customDateEnd, setCustomDateEnd] = useState<number | null>(
    dateSelection === DateSelectionType.Custom ? customDateEndDefault : null
  );
  const range = DateRange.createFromDateSelection(
    dateSelection, customDateStart, customDateEnd ? customDateEnd : Date.now()
  );
  const noCustomEnd = (dateSelection === DateSelectionType.Custom) && (customDateEnd === null);
  return (
    <Dialog
      visible={true}
    >
      <DialogTitle
        icon={<Calendar/>}
      >
        Select a date range
      </DialogTitle>
      <DialogContent>
        <Stack
          direction={StackDirection.Column}
          gap={3}
          fullWidth
        >
          <Title
            type={TitleType.H6}
            fullWidth
          >
            {DateTools.formatDateRange(range, noCustomEnd)}
          </Title>
          <TextDropDown
            values={DateSelectionToStringMap}
            value={dateSelection}
            onChange={(newValue) => {
              setDateSelection(newValue);
            }}
          />
          <DatePicker
            inline
            showWeekNumbers
            weekLabel="wk"
            calendarStartDay={1}
            onChange={dates => {
              setDateSelection(DateSelectionType.Custom);
              setCustomDateStart(dates[0] ? dates[0].getTime() : Date.now());
              setCustomDateEnd(dates[1] ? dates[1].getTime() : null);
            }}
            disabledKeyboardNavigation={true}
            fixedHeight
            monthsShown={3}
            selectsRange
            startDate={range.start}
            endDate={noCustomEnd ? null : range.end}
            includeDateIntervals={[{
              end: new Date(),
              start: new Date(Date.now() - 2 * 365 * UFDate.DAY_IN_MILLISECONDS)
            }]}
          />
        </Stack>
      </DialogContent>
      <DialogButtons>
        <Button
          onClick={() => onClose({
            dateSelection,
            customDateStart,
            customDateEnd: noCustomEnd ? Date.now() : customDateEnd as number
          })}
          disabled={noCustomEnd}
          iconAtStart={<Check/>}
        >
          Update
        </Button>
        <Filler/>
        <Button
          onClick={() => onClose(null)}
          type={ButtonType.Secondary}
          iconAtStart={<Cancel/>}
        >
          Cancel
        </Button>
      </DialogButtons>
    </Dialog>
  );
}

// endregion