import React from "react";
import {useAppSelector} from "../../../store/store";
import {DataTools} from "../../../tools/DataTools";
import {NumberType} from "../../../types/enums/NumberType";
import {mainController} from "../../../controllers/mainController";
import {UserLocation} from "../../../types/enums/UserLocation";
import {CurrentAndPreviousRow} from "../../../types/classes/CurrentAndPreviousRow";
import {CurrentAndPreviousTableDashboardPanel} from "../../panels/CurrentAndPreviousTableDashboardPanel";
import {GraphColor} from "../../../types/enums/GraphColor";
import {TicketsTools} from "../../../tools/data/TicketsTools";

export const TicketsDashboard: React.FC = () => {
  const {dashboardEntries, loading, initializedDashboard } = useAppSelector(state => state.tickets);
  const tools = new TicketsTools();
  const current = DataTools.reduceForDashboardCurrent(dashboardEntries, tools);
  const previous = DataTools.reduceForDashboardPrevious(dashboardEntries, tools);
  const rows = [
    new CurrentAndPreviousRow(
      {value: current.count}, {value: previous.count}, NumberType.Integer, 'Count', GraphColor.Other1, true
    ),
    new CurrentAndPreviousRow(
      {value: current.count ? current.time / current.count : 0},
      {value: previous.count ? previous.time / previous.count : 0},
      NumberType.Duration, 'Avg. duration',
      GraphColor.Other1,
      true
    ),
    new CurrentAndPreviousRow(
      {value: current.count ? current.replies / current.count : 0},
      {value: previous.count ? previous.replies / previous.count : 0},
      NumberType.Float2, 'Avg. replies',
      GraphColor.Other1,
      true
    )
  ];
  return (
    <CurrentAndPreviousTableDashboardPanel
      title="Tickets"
      rows={rows}
      loading={loading || !initializedDashboard}
      onClick={() => mainController.setUserLocation(UserLocation.Tickets)}
    />
  );
};
