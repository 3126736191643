// region imports

import {NumberType} from "../enums/NumberType";
import {GraphColor} from "../enums/GraphColor";
import {GraphNumberList} from "./GraphNumberList";
import {CurrentAndPreviousValue} from "./CurrentAndPreviousValue";
import {ValueField} from "../interfaces/data/fields/ValueField";

// endregion

// region exports

/**
 * {@link CurrentAndPreviousRow} adds properties to {@link CurrentAndPreviousValue} so it can be rendered in a table.
 */
export class CurrentAndPreviousRow extends CurrentAndPreviousValue {
  // region private variables

  /**
   * See {@link name}
   *
   * @private
   */
  private readonly m_name: string;

  /**
   * See {@link color}
   *
   * @private
   */
  private readonly m_color: GraphColor;

  /**
   * Used to generate percentage.
   *
   * @private
   */
  private readonly m_totalPercentage: number | boolean;

  // endregion

  // region public methods

  constructor(
    aCurrent: ValueField, aPrevious: ValueField, aType: NumberType, aName: string,
    aColor: GraphColor = GraphColor.Other1, aReverse: boolean = false, aTotalPercentage: number | boolean = false
  ) {
    super(aCurrent, aPrevious, aType, aReverse);
    this.m_name = aName;
    this.m_color = aColor;
    this.m_totalPercentage = aTotalPercentage;
  }

  /**
   * Creates a row from the first and second value in a {@link GraphNumberList}.
   */
  static createFromGraphNumberList(aList: GraphNumberList): CurrentAndPreviousRow {
    return new CurrentAndPreviousRow(
      {value: aList.getFromEnd(0)}, {value: aList.getFromEnd(1)}, aList.valueType, aList.legendTitle, aList.color
    );
  }

  // endregion

  // region public properties

  /**
   * Name of values
   */
  get name(): string {
    return this.m_name;
  }

  /**
   * Color for row
   */
  get color(): GraphColor {
    return this.m_color;
  }

  /**
   * True if there is a total percentage value.
   */
  get hasTotalPercentage(): boolean {
    return this.m_totalPercentage !== false;
  }

  /**
   * Gets the percentage to some total as text or empty string if there is no total percentage.
   */
  public get totalPercentageAsText(): string {
    return this.hasTotalPercentage ? (this.m_totalPercentage as number).toFixed(1) + '%' : '';
  }

  // endregion
}

// endregion