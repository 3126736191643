import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";
import React from "react";

export interface BulletItemProps extends UFChildrenProp {
}

export const BulletItem: React.FC<BulletItemProps> = ({children}) => {
  return (
    <li>{children}</li>
  );
}
