import React from "react";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";

interface MainGridLogoProps extends UFChildrenProp {
}

export const MainGridLogo: React.FC<MainGridLogoProps> = ({children}) => (
  <div className="main-grid-logo px-4 py-1 bg-white">
    {children}
  </div>
);