// region imports

import {UFSerialQueueAction} from "../../../UF/actions/UFSerialQueueAction";
import {UFCallbackAction} from "../../../UF/actions/UFCallbackAction";
import {
  initializeDashboard,
  resetDashboard,
  setDashboard,
  setLoading
} from "../../../store/data/activeSubscriptionsSlice";
import {store} from "../../../store/store";
import {DateRange} from "../../../types/classes/DateRange";
import {ActiveSubscriptionsLoadAction} from "./ActiveSubscriptionsLoadAction";

// endregion

// region exports

/**
 * An action that loads all data for use with the dashboard.
 */
export class ActiveSubscriptionsDashboardAction extends UFSerialQueueAction {
  constructor(anAlways: boolean, aDateRange: DateRange) {
    if (anAlways || !store.getState().activeSubscriptions.initializedDashboard) {
      super(
        new UFCallbackAction(() => store.dispatch(setLoading(true))),
        new ActiveSubscriptionsLoadAction(aDateRange, entries => store.dispatch(setDashboard(entries))),
        new UFCallbackAction(() => store.dispatch(initializeDashboard()))
      );
      store.dispatch(resetDashboard());
    }
    else {
      super();
    }
  }
}

// endregion