/**
 * Configuration
 */
export const Config = {

  /**
   * Version of store. Change to clear the current stored data.
   */
  storeVersion: 3,

  /**
   * Local storage key used to save part of redux store.
   */
  storageKey: 'statscp-redux-state',

  /**
   * Base url to the server API
   */
  apiRoot: process.env.NODE_ENV !== 'production' ? 'https://statscpapi/api' : 'https://statscp-api.fundorado.com/api',

  /**
   * Number of days to show entries in the dashboard for.
   */
  dashboardDays: 30,

  theme: {
    // css color
    busyAnimationColor: '#5B21B6',
    // css classname for color
    backgroundColor: 'bg-violet-300',
    // css classname for gray text color
    grayTextColor: 'text-gray-500',
    // background color for focussed row
    tableFocus: 'bg-amber-200',
    // border color for tables
    tableBorder: 'border-slate-300'
  }
}
