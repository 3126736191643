import React from "react";
import {CurrentAndPreviousValue} from "../../../types/classes/CurrentAndPreviousValue";
import {useAppSelector} from "../../../store/store";
import {DataTools} from "../../../tools/DataTools";
import {NumberType} from "../../../types/enums/NumberType";
import {LastAndPreviousDaysDashboardPanel} from "../../panels/LastAndPreviousDaysDashboardPanel";
import {mainController} from "../../../controllers/mainController";
import {UserLocation} from "../../../types/enums/UserLocation";
import {SubscriptionsAndRecurringsTools} from "../../../tools/data/SubscriptionsAndRecurringsTools";

export const NewSubscriptionsCountDashboard: React.FC = () => {
  const {dashboardEntries, loading, initializedDashboard} = useAppSelector(state => state.subscriptionsAndRecurrings);
  const tools = new SubscriptionsAndRecurringsTools();
  const current = DataTools.reduceForDashboardCurrent(dashboardEntries, tools);
  const previous = DataTools.reduceForDashboardPrevious(dashboardEntries, tools);
  const currentAndPrevious = new CurrentAndPreviousValue(
    {value: tools.totalSubscriptionCount(current)},
    {value: tools.totalSubscriptionCount(previous)},
    NumberType.Integer
  );
  return (
    <LastAndPreviousDaysDashboardPanel
      title="New Subscriptions"
      currentAndPrevious={currentAndPrevious}
      loading={loading || !initializedDashboard}
      onClick={() => mainController.setUserLocation(UserLocation.NewSubscriptionsCount)}
    />
  );
};
