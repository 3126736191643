// region imports

import {CurrentAndPreviousRow} from "./CurrentAndPreviousRow";
import {NumberType} from "../enums/NumberType";
import {GraphColor} from "../enums/GraphColor";
import {GraphColorTools} from "../../tools/GraphColorTools";
import {ValueField} from "../interfaces/data/fields/ValueField";
import {ValueAndKeyFields} from "../interfaces/data/fields/ValueAndKeyFields";
import {ValueAndKeyAndLabelFields} from "../interfaces/data/fields/ValueAndKeyAndLabelFields";

// endregion

// region exports

/**
 * Adds a {@link previousRank} property to a {@link CurrentAndPreviousRow}.
 */
export class CurrentAndPreviousRankedRow extends CurrentAndPreviousRow {
  // region private variables

  /**
   * See {@link previousRank}
   *
   * @private
   */
  private readonly m_previousRank: number;

  // endregion

  // region public methods

  /**
   * Constructs an instance of the row.
   */
  constructor(
    aCurrent: ValueField, aPrevious: ValueField, aType: NumberType, aName: string, aPreviousRank: number,
    aColor: GraphColor = GraphColor.Other1, aTotalPercentage: number | boolean = false
  ) {
    super(aCurrent, aPrevious, aType, aName, aColor, false, aTotalPercentage);
    this.m_previousRank = aPreviousRank;
  }

  /**
   * Builds an array of {@link CurrentAndPreviousRankedRow} entries. The array gets sorted on the current entries from
   * high to low.
   *
   * @param aCurrentEntries
   *   Entries for the current data.
   * @param aPreviousEntries
   *   Entries for the previous data
   * @param aType
   *   How to display the value
   * @param aTotalPercentage
   *   When true, determine the % of the current value to the sum all current values
   * @param aCount
   *   Maximum number of rows to create
   * @param aDefault
   *   Default value to use for the first previous entry
   *
   * @return up to aCount rows
   */
  static createRows(
    aCurrentEntries: ValueAndKeyAndLabelFields[],
    aPreviousEntries: ValueAndKeyFields[],
    aType: NumberType,
    aTotalPercentage: boolean,
    aCount: number,
    aDefault: number = 0
  ): CurrentAndPreviousRankedRow[] {
    const total = aTotalPercentage
      ? aCurrentEntries.reduce((previous, current) => current.value + previous, 0)
      : 0;
    const sortedCurrentEntries = aCurrentEntries.slice().sort(
      (first, second) => second.value - first.value
    );
    return sortedCurrentEntries.slice(0, aCount).map(
      (currentEntry, index) => {
        const previousIndex = aPreviousEntries.findIndex(entry => entry.key === currentEntry.key);
        return new CurrentAndPreviousRankedRow(
          currentEntry,
          previousIndex >= 0 ? aPreviousEntries[previousIndex] : {value: aDefault},
          aType,
          currentEntry.label,
          previousIndex,
          GraphColorTools.getOtherColor(index),
          aTotalPercentage ? 100 * currentEntry.value / total : false
        );
      }
    );
  }

  // endregion

  // region public properties

  /**
   * Previous rank of the row or -1 if there is no previous data.
   */
  get previousRank(): number {
    return this.m_previousRank;
  }

  // endregion
}

// endregion