import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";
import React from "react";
import {Title} from "../texts/Title";
import {TitleType} from "../texts/title/TitleType";

interface DialogTitleProps extends UFChildrenProp {
  readonly icon?: React.ReactNode;
}

export const DialogTitle: React.FC<DialogTitleProps> = ({children, icon}) => {
  if (typeof children === 'string') {
    children = <Title type={TitleType.H3}>{children}</Title>;
  }
  return (
    <div className="px-4 py-4 bg-gray-100 border-b border-b-gray-200 w-full flex flex-row gap-2 items-end">
      {
        icon &&
        <div className="w-7">
          {icon}
        </div>
      }
      {children}
    </div>
  );
};