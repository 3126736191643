// region imports

import React, {useState} from "react";
import {useAppSelector} from "../../../store/store";
import {NumberType} from "../../../types/enums/NumberType";
import {HistoryPanel} from "../../panels/HistoryPanel";
import {RadioButtonsBar} from "../../styled/buttons/RadioButtonsBar";
import {SubscriptionAndRecurringEntry} from "../../../types/interfaces/data/SubscriptionAndRecurringEntry";
import {HistoryDataProvider} from "../../../types/interfaces/HistoryDataProvider";
import {Tools} from "../../../tools/Tools";
import {GraphColorTools} from "../../../tools/GraphColorTools";
import {FundoTools} from "../../../tools/FundoTools";
import {SubscriptionsAndRecurringsTools} from "../../../tools/data/SubscriptionsAndRecurringsTools";
import {GraphColor} from "../../../types/enums/GraphColor";
import {Stack} from "../../styled/layouts/Stack";
import {StackDirection} from "../../styled/layouts/stack/StackDirection";
import {LegacyEntry} from "../../../types/interfaces/data/LegacyEntry";
import {WebsiteAndBank} from "../../../types/interfaces/data/WebsiteAndBank";
import {LegacyTools} from "../../../tools/data/LegacyTools";

// endregion

// region local

enum SubscriptionType {
  Subscription,
  RecurringCurrent,
  RecurringLegacy
}

enum InfoType {
  Count,
  Revenue
}

const SUBSCRIPTION_TYPE_MAP = new Map<SubscriptionType, string>([
  [SubscriptionType.Subscription, 'New subscriptions'],
  [SubscriptionType.RecurringCurrent, 'Recurring current'],
  [SubscriptionType.RecurringLegacy, 'Recurring legacy'],
]);

const INFO_TYPE_MAP = new Map<InfoType, string>([
  [InfoType.Count, 'Count'],
  [InfoType.Revenue, 'Revenue']
]);

function calcSum(aMap: Map<number, number>): number {
  return Array.from(aMap.values()).reduce((previous, current) => previous + current, 0);
}

// endregion

// region exports

export const SubscriptionsAndRecurringsHistory: React.FC = () => {
  const current = useAppSelector(state => state.subscriptionsAndRecurrings);
  const legacy = useAppSelector(state => state.legacy);
  const [subscriptionType, setSubscriptionType] = useState(SubscriptionType.Subscription);
  const [infoType, setInfoType] = useState(InfoType.Count);
  if (subscriptionType === SubscriptionType.RecurringLegacy) {
    const tools = new LegacyTools();
    const websiteAndBanks: WebsiteAndBank[] = tools.getAllWebsiteAndBanks(legacy.historyEntries);
    const providers: HistoryDataProvider<LegacyEntry>[] = websiteAndBanks.map(
      (websiteAndBank, index) => ({
      color: GraphColorTools.getOtherColor(index),
      name: websiteAndBank.website + ' ('  + websiteAndBank.bank + ')',
      type: infoType === InfoType.Count ? NumberType.Integer : NumberType.Currency,
      reverse: false,
      getValue: entry => {
        const payment = tools.findForWebsiteAndBank(entry.payments, websiteAndBank);
        return payment === false ? 0 : (infoType === InfoType.Count ? payment.count : payment.amount);
      }
    })
    );
    providers.push({
      color: GraphColor.Success,
      name: 'Total',
      type: infoType === InfoType.Count ? NumberType.Integer : NumberType.Currency,
      reverse: false,
      getValue: entry =>
        infoType === InfoType.Count ? tools.totalRecurringCount(entry) : tools.totalRecurringAmount(entry)
    });
    return (
      <HistoryPanel
        entries={legacy.historyEntries}
        loading={legacy.loading || !legacy.historyRange}
        tools={tools}
        top={
          <Stack
            direction={StackDirection.Row}
            gap={4}
          >
            <RadioButtonsBar
              onChange={setSubscriptionType}
              values={SUBSCRIPTION_TYPE_MAP}
              value={subscriptionType}
            />
            <RadioButtonsBar
              onChange={setInfoType}
              values={INFO_TYPE_MAP}
              value={infoType}
            />
          </Stack>
        }
        providers={providers}
      />
    );
  }
  else {
    const ids = Tools.getPlanIds(current.historyEntries);
    const providers: HistoryDataProvider<SubscriptionAndRecurringEntry>[] = ids.map((id, index) => ({
      color: GraphColorTools.getOtherColor(index),
      name: FundoTools.getSubscriptionPlanAsText(id),
      type: infoType === InfoType.Count ? NumberType.Integer : NumberType.Currency,
      reverse: false,
      getValue: subscriptionType === SubscriptionType.Subscription
        ? entry => (infoType === InfoType.Count ? entry.subscriptionCount.get(id) : entry.subscriptionAmount.get(id)) || 0
        : entry => (infoType === InfoType.Count ? entry.recurringCount.get(id) : entry.recurringAmount.get(id)) || 0
    }));
    providers.push({
      color: GraphColor.Success,
      name: 'Total',
      type: infoType === InfoType.Count ? NumberType.Integer : NumberType.Currency,
      reverse: false,
      getValue: subscriptionType === SubscriptionType.Subscription
        ? entry => calcSum(infoType === InfoType.Count ? entry.subscriptionCount : entry.subscriptionAmount)
        : entry => calcSum(infoType === InfoType.Count ? entry.recurringCount : entry.recurringAmount)
    });
    return (
      <HistoryPanel
        entries={current.historyEntries}
        loading={current.loading || !current.historyRange}
        tools={new SubscriptionsAndRecurringsTools()}
        top={
          <Stack
            direction={StackDirection.Row}
            gap={4}
          >
            <RadioButtonsBar
              onChange={setSubscriptionType}
              values={SUBSCRIPTION_TYPE_MAP}
              value={subscriptionType}
            />
            <RadioButtonsBar
              onChange={setInfoType}
              values={INFO_TYPE_MAP}
              value={infoType}
            />
          </Stack>
        }
        providers={providers}
      />
    );
  }
}

//endregion