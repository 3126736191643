import React from "react";
import {PieChart} from "../../styled/graphs/PieChart";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {GeneralTable} from "../../styled/tables/GeneralTable";
import {setFocus} from "../../../store/userInterfaceSlice";
import {Stack} from "../../styled/layouts/Stack";
import {StackDirection} from "../../styled/layouts/stack/StackDirection";
import {TransactionFailEntry} from "../../../types/interfaces/data/TransactionFailEntry";
import {GraphNumberList} from "../../../types/classes/GraphNumberList";
import {GraphColorTools} from "../../../tools/GraphColorTools";
import {NumberType} from "../../../types/enums/NumberType";
import {RankedWithColorTableData} from "../../../types/classes/table-data/RankedWithColorTableData";
import {MainPanel} from "../../styled/containers/MainPanel";
import {tab} from "@testing-library/user-event/dist/tab";

/**
 * Gets a name for a (remote) status.
 */
function getStatusName(aStatus: number) {
  switch (aStatus) {
    case -1:
      return 'not started';
    case 0:
      return 'Transaction in progress';
    case 100:
      return 'Authorization successful';
    case 150:
      return '3D secure status \'Y\' (yes), waiting for 3D secure authentication';
    case 152:
      return '3D secure status \'N\' (no)';
    case 154:
      return '3D secure status \'U\' (unknown)';
    case 156:
      return '3D secure status \'E\' (error)';
    case 200:
      return 'Transaction successful';
    case 210:
      return 'Recurring transaction successful';
    case 300:
      return 'Transaction failed';
    case 301:
      return 'Transaction failed due to anti fraud system';
    case 302:
      return 'Transaction rejected';
    case 308:
      return 'Transaction was expired';
    case 309:
      return 'Transaction was cancelled';
    case 310:
      return 'Recurring transaction failed';
    case 330:
      return 'Authorization failed';
    case 333:
      return 'Card expired';
    case 334:
      return 'Suspicion of manipulation';
    case 341:
      return 'Lost card (was reported lost by owner)';
    case 343:
      return 'Hot card (known fraud with this card)';
    case 344:
      return 'Pickup card (card is flagged/blocked)';
    case 350:
      return 'Transaction failed, time-out for 3D secure authentication';
    case 351:
      return 'Transaction failed, non-3DS transactions are not allowed';
    case 352:
      return 'Transaction failed 3DS verification';
    case 370:
      return 'Wait time expired';
    case 399:
      return 'Acquirer request error';
    case 400:
      return 'Refund to consumer';
    case 404:
      return 'Reversal by system (transaction was never received or bounced)';
    case 410:
      return 'Chargeback by consumer';
    case 420:
      return 'Chargeback (2nd attempt)';
    case 450:
      return 'Authorization cancelled';
    case 511:
      return 'Subscription has expired';
    case 601:
      return 'Fraud notification received from bank';
    case 603:
      return 'Rep-resentment notification received from bank';
    case 604:
      return 'Retrieval notification received from bank';
    case 610:
      return 'Second chargeback notification received from bank';
    case 700:
      return 'Transaction is waiting for user action';
    case 701:
      return 'Waiting for capture';
    case 702:
      return 'Waiting for execution date';
    case 710:
      return 'Waiting for confirmation recurring';
    case 711:
      return 'Subscription waiting for payment';
    case 750:
      return 'Waiting for confirmation (from external party like a bank)';
    case 751:
      return 'Underpaid, waiting for merchant action';
    case 760:
      return 'Waiting for asynchronous batch capture';
    case 761:
      return 'Waiting for batch capture';
    case 762:
      return 'Waiting for batch execution date';
    case 811:
      return 'Subscription on hold (automatic)';
    case 812:
      return 'Subscription on hold (manual)';
    default:
      return `Unknown status ${aStatus}`;
  }
}

function buildTop10List(anEntries: TransactionFailEntry[]): GraphNumberList[] {
  const entries = [...anEntries];
  // sort in reverse order
  entries.sort((first, second) => second.count - first.count);
  entries.length = Math.min(entries.length, 10);
  return entries.map((entry, index) =>
    GraphNumberList.createForSingleValue(
      '[' + entry.status + '] ' + getStatusName(entry.status)!, GraphColorTools.getOtherColor(index), NumberType.Integer, entry.count
    )
  );
}

export const TransactionFailsHistory: React.FC = () => {
  const {loading, historyRange, historyData} = useAppSelector(state => state.transactionFails);
  const focus = useAppSelector(state => state.userInterface.focus);
  const tableTextSize = useAppSelector(state => state.application.tableTextSize);
  const dispatch = useAppDispatch();
  const lists = buildTop10List(historyData.entries);
  const tableData = new RankedWithColorTableData(lists);
  return (
    <MainPanel
      loading={loading || !historyRange}
      title="Top 10 transaction fails"
    >
      <Stack
        direction={StackDirection.Column}
        gap={4}
        fullWidth
      >
        <PieChart
          values={lists}
          focus={focus}
          setFocus={(value) => dispatch(setFocus(value))}
        />
        <GeneralTable
          tableTextSize={tableTextSize}
          data={tableData}
          focus={focus}
          setFocus={(value) => dispatch(setFocus(value))}
        />
      </Stack>
    </MainPanel>
  );
}
