import React from "react";

let s_nameIndex: number = 0;

interface RadioButtonsBarProps<T> {
  readonly value: T;
  readonly values: Map<T, string>;
  readonly onChange: (value: T) => any;
}

/**
 * {@link RadioButtonsBar} creates a horizontal bar with buttons, one of which can be selected.
 */
export function RadioButtonsBar<T>({value, values, onChange}: RadioButtonsBarProps<T>) {
  // generate unique name
  const name = 'radio_button_bar_' + s_nameIndex++;
  // class for each radio element (use after::content to set the text)
  const radioClass = 'appearance-none px-4 py-2 border border-gray-300 z-0 cursor-pointer relative bg-white -ml-px ' +
    'font-semibold uppercase text-gray-800 text-xs leading-none tracking-widest ' +
    'first:rounded-l-md last:rounded-r-md after:content-[attr(title)] ' +
    'checked:border-blue-700 checked:z-10 checked:bg-blue-400 checked:text-white ' +
    'hover:bg-gray-100 hover:checked:bg-blue-400';
  return (
    <div
      className="flex flex-row gap-0 relative"
    >
      {
        Array.from(values).map((item, index) =>
          <input
            key={index}
            type="radio"
            name={name}
            onChange={() => onChange(item[0])}
            onClick={(event) => event.stopPropagation()}
            className={radioClass}
            title={item[1]}
            checked={value === item[0]}
          />
        )
      }
    </div>
  )
}
