// region imports

import React from "react";
import {SmallDashboardPanel} from "../styled/containers/SmallDashboardPanel";
import {TextSize} from "../styled/texts/text/TextSize";
import {Text} from "../styled/texts/Text";
import {TextColor} from "../styled/texts/text/TextColor";
import {CurrentAndPreviousValue} from "../../types/classes/CurrentAndPreviousValue";
import {TextWeight} from "../styled/texts/text/TextWeight";
import {Stack} from "../styled/layouts/Stack";
import {StackDirection} from "../styled/layouts/stack/StackDirection";
import {Filler} from "../styled/layouts/Filler";
import {StackAlignItems} from "../styled/layouts/stack/StackAlignItems";
import {DifferenceInformation} from "../styled/texts/DifferenceInformation";

// endregion

// region local

interface CurrentAndPreviousDashboardPanelProps {
  /**
   * Title to show
   */
  readonly title: string;

  /**
   * When true show loading animation
   */
  readonly loading: boolean;

  /**
   * Text shown before previous value
   */
  readonly previousText: string;

  /**
   * Current and previous value
   */
  readonly currentAndPrevious: CurrentAndPreviousValue;

  /**
   * On click handler if any
   */
  readonly onClick?: () => any;
}

// endregion

// region export

export const CurrentAndPreviousDashboardPanel: React.FC<CurrentAndPreviousDashboardPanelProps> = (
  {
    title,
    currentAndPrevious,
    loading,
    onClick,
    previousText
  }
) => {
  return (
    <SmallDashboardPanel
      onClick={onClick}
      title={title}
      loading={loading}
      center={
        <Text
          size={TextSize.VeryLarge}
          color={TextColor.Success}
          weight={TextWeight.Medium}
        >
          {currentAndPrevious.currentAsText}
        </Text>
      }
      bottomLeft={
        <Stack
          direction={StackDirection.Row}
          fullWidth
          alignItems={StackAlignItems.Center}
        >
          <Text
            size={TextSize.Small}
          >
            <Text color={TextColor.Light}>{previousText} </Text>
            <Text weight={TextWeight.SemiBold}>{currentAndPrevious.previousAsText}</Text>
          </Text>
          <Filler/>
          <DifferenceInformation data={currentAndPrevious} />
        </Stack>
      }
    />
  );
}

// endregion