// region imports

import React from "react";
import {DirectionType} from "./arrow/DirectionType";

// endregion

// region local

/**
 * Properties for component
 */
interface TriangleProps {
  readonly direction: DirectionType;
}

// endregion

// region exports

/**
 * Renders a triangle character.
 */
export const Triangle: React.FC<TriangleProps> = ({direction}) => {
  switch (direction) {
    case DirectionType.Up:
      return <>&#9660;</>;
    case DirectionType.Down:
      return <>&#9650;</>;
    case DirectionType.Left:
      return <>&#9640;</>;
    case DirectionType.Right:
      return <>&#9630;</>;
  }
}

// endregion