// region imports

import {UFNumberList} from "../../UF/data/UFNumberList";
import {LineStyle} from "../enums/LineStyle";
import {DateAndWeekFields} from "../interfaces/data/fields/DateAndWeekFields";
import {DataTools} from "../../tools/DataTools";
import {WeekInYear} from "../interfaces/data/support/WeekInYear";
import {GraphColor} from "../enums/GraphColor";
import {GraphColorProperty} from "../interfaces/GraphColorProperty";
import {NumberType} from "../enums/NumberType";
import React from "react";
import {NumberTypeTools} from "../../tools/NumberTypeTools";
import {CurrentAndPreviousRow} from "./CurrentAndPreviousRow";

// endregion

// region exports

/**
 * {@link GraphNumberList} contains the numbers for a graph.
 */
export class GraphNumberList implements GraphColorProperty {
  // region private variables

  /**
   * See {@link color}
   *
   * @private
   */
  private readonly m_color: GraphColor;

  /**
   * See {@link columnTitle}
   *
   * @private
   */
  private readonly m_columnTitle: React.ReactNode;

  /**
   * See {@link legendTitle}
   *
   * @private
   */
  private readonly m_legendTitle: string;

  /**
   * Contains all numbers (from {@link start} to {@link end}).
   *
   * @private
   */
  private readonly m_data: UFNumberList;

  /**
   * Contains the average value from {@link m_data}.
   *
   * @private
   */
  private m_forecastValue: number;

  /**
   * See {@link count}
   *
   * @private
   */
  private m_sizeWithForecast: number;

  /**
   * See {@link valueType}
   *
   * @private
   */
  private readonly m_valueType: NumberType;

  /**
   * See {@link start}
   *
   * @private
   */
  private readonly m_start: number;

  /**
   * Show reverse status when getting percentage and difference with previous value
   *
   * @private
   */
  private readonly m_reverse: boolean;

  /**
   * Contains current and previous data.
   *
   * @private
   */
  private readonly m_currentAndPreviousRows: CurrentAndPreviousRow[];

  // endregion

  // region factory methods

  /**
   * Creates an instance containing values per day.
   */
  static createDayHistory<TData extends DateAndWeekFields>(
    aName: string,
    aColor: GraphColor,
    aValueType: NumberType,
    aData: TData[],
    aDates: Date[],
    aGetValue: (entry: TData) => number,
    aReverse: boolean = false
  ): GraphNumberList {
    let start: (number | null) = null;
    const data = new UFNumberList();
    aDates.forEach((date, index) => {
      const entry = DataTools.findForDay(date, aData);
      if (entry && (start === null)) {
        start = index;
      }
      if (start !== null) {
        data.add(entry ? aGetValue(entry) : 0);
      }
    });
    return new GraphNumberList(aName, aName, aColor, aValueType, start || 0, data, aReverse);
  }

  /**
   * Creates an instance containing values per week.
   */
  static createWeekHistory<TData extends DateAndWeekFields>(
    aName: string,
    aColor: GraphColor,
    aValueType: NumberType,
    aData: TData[],
    aDates: WeekInYear[],
    aGetValue: (entry: TData) => number,
    aReverse: boolean = false
  ): GraphNumberList {
    let start: (number | null) = null;
    const data = new UFNumberList();
    aDates.forEach((date, index) => {
      const entry = DataTools.findForWeek(date, aData);
      if (entry && (start === null)) {
        start = index;
      }
      if (start !== null) {
        data.add(entry ? aGetValue(entry) : 0);
      }
    });
    return new GraphNumberList(aName, aName, aColor, aValueType, start || 0, data, aReverse);
  }

  /**
   * Creates an instance containing values per month.
   */
  static createMonthHistory<TData extends DateAndWeekFields>(
    aName: string,
    aColor: GraphColor,
    aValueType: NumberType,
    aData: TData[],
    aDates: Date[],
    aGetValue: (entry: TData) => number,
    aReverse: boolean = false
  ): GraphNumberList {
    let start: (number | null) = null;
    const data = new UFNumberList();
    aDates.forEach((date, index) => {
      const entry = DataTools.findForMonth(date, aData);
      if (entry && (start === null)) {
        start = index;
      }
      if (start !== null) {
        data.add(entry ? aGetValue(entry) : 0);
      }
    });
    return new GraphNumberList(aName, aName, aColor, aValueType, start || 0, data, aReverse);
  }

  /**
   * Creates an instance containing values per year.
   */
  static createYearHistory<TData extends DateAndWeekFields>(
    aName: string,
    aColor: GraphColor,
    aValueType: NumberType,
    aData: TData[],
    aDates: Date[],
    aGetValue: (entry: TData) => number,
    aReverse: boolean = false
  ): GraphNumberList {
    let start: (number | null) = null;
    const data = new UFNumberList();
    aDates.forEach((date, index) => {
      const entry = DataTools.findForYear(date, aData);
      if (entry && (start === null)) {
        start = index;
      }
      if (start !== null) {
        data.add(entry ? aGetValue(entry) : 0);
      }
    });
    return new GraphNumberList(aName, aName, aColor, aValueType, start || 0, data, aReverse);
  }

  /**
   * Creates a graph for a single value (for use with pie-charts).
   */
  static createForSingleValue(
    aName: string, aColor: GraphColor, aValueType: NumberType, aValue: number, aReverse: boolean = false
  ): GraphNumberList {
    const data = new UFNumberList();
    data.add(aValue);
    return new GraphNumberList(aName, aName, aColor, aValueType, 0, data, aReverse);
  }

  // endregion

  // public methods

  /**
   * Gets a value at a certain index. If the index is less than {@link start}, the method returns 0.
   *
   * The value might be a calculated forecast value, check {@link isForecast} to see if the number is a forecast
   * number.
   *
   * @param anIndex
   *   Index ranging from 0 to size
   */
  get(anIndex: number): number {
    if (anIndex < this.m_start) {
      return 0;
    }
    return (anIndex < this.m_start + this.m_data.size)
      ? this.m_data.get(anIndex - this.start)
      : this.m_forecastValue;
  }

  /**
   * Gets a value from the end.
   *
   * @param anIndex
   *   Index ranging from 0 to size
   */
  getFromEnd(anIndex: number): number {
    return this.get(this.size - anIndex - 1);
  }

  /**
   * Checks if a number at an index is a forecast number.
   *
   * @param anIndex
   *   Index ranging from 0 to size
   */
  isForecast(anIndex: number): boolean {
    return anIndex >= this.m_start + this.m_data.size;
  }

  /**
   * Get value as text.
   *
   * @param anIndex
   *   Index ranging from 0 to size
   */
  getAsText(anIndex: number): string {
    return NumberTypeTools.getAsText(this.get(anIndex), this.valueType);
  }

  /**
   * Normalize a number based on the {@link valueType}
   *
   * @param aValue
   */
  normalize(aValue: number): number {
    return NumberTypeTools.normalize(aValue, this.valueType);
  }

  /**
   * Gets the line style to use for the segment starting with the value at anIndex.
   *
   * @param anIndex
   *   Index ranging from 0 to size
   */
  getLineStyle(anIndex: number): LineStyle {
    return anIndex - this.m_start < this.m_data.size - 1 ? LineStyle.Solid : LineStyle.Dotted;
  }

  /**
   * Gets minimum value, include forecast value (if used)
   */
  min(): number {
    return this.m_sizeWithForecast < 0 ? this.m_data.min() : Math.min(this.m_forecastValue, this.m_data.min());
  }

  /**
   * Gets maximum value, include forecast value (if used)
   */
  max(): number {
    return this.m_sizeWithForecast < 0 ? this.m_data.max() : Math.max(this.m_forecastValue, this.m_data.max());
  }

  /**
   * Gets sum with or without using the forecast values.
   */
  sum(anIncludeForecast: boolean): number {
    return (this.m_sizeWithForecast < 0) || !anIncludeForecast
      ? this.m_data.sum()
      : this.m_data.sum() + this.m_forecastValue * (this.m_sizeWithForecast - this.m_data.size);
  }

  /**
   * Check if there is a value at the index.
   */
  hasValue(anIndex: number): boolean {
    return (anIndex >= this.start) && (anIndex < this.size);
  }

  /**
   * Gets current and previous data for a certain index. This method creates the instances the first time this method is
   * called.
   *
   * @private
   */
  getCurrentAndPrevious(anIndex: number): CurrentAndPreviousRow {
    if (this.m_currentAndPreviousRows.length <= 0) {
      // first row is never used (since there is no previous value)
      this.m_currentAndPreviousRows.push(new CurrentAndPreviousRow(
        {value: 0}, {value: 0}, this.valueType, '', this.color, this.m_reverse
      ));
      for(let index = this.start + 1; index < this.size; index++) {
        this.m_currentAndPreviousRows.push(new CurrentAndPreviousRow(
          {value: this.get(index)}, {value: this.get(index - 1)}, this.valueType, '', this.color, this.m_reverse
        ));
      }
    }
    return this.m_currentAndPreviousRows[anIndex - this.m_start];
  }

  // endregion

  // region public properties

  /**
   * Number of entries in the graph data.
   */
  get size(): number {
    return this.m_sizeWithForecast < 0 ? this.m_data.size + this.m_start : this.m_sizeWithForecast;
  }

  /**
   * Color index to use
   */
  get color(): GraphColor {
    return this.m_color;
  }

  /**
   * Name of graph (used within table and legends if {@link legendTitle} is empty)
   */
  get columnTitle(): React.ReactNode {
    return this.m_columnTitle;
  }

  /**
   * Name to use in legend.
   */
  get legendTitle(): string {
    return this.m_legendTitle
  }

  /**
   * True if the data includes one or more forecast values.
   */
  get hasForecast(): boolean {
    return (this.m_sizeWithForecast >= 0) && (this.m_sizeWithForecast > this.m_data.size);
  }

  /**
   * Types of value contained within
   */
  get valueType(): NumberType {
    return this.m_valueType;
  }

  /**
   * Start index of first entry with data.
   */
  get start(): number {
    return this.m_start;
  }

  // endregion

  // region private methods

  /**
   * Constructs an instance of {@link GraphNumberList}
   */
  private constructor(
    aColumnTitle: React.ReactNode,
    aLegendTitle: string,
    aColor: GraphColor,
    aValueType: NumberType,
    aStart: number,
    aData: UFNumberList,
    aReverse: boolean
  ) {
    this.m_color = aColor;
    this.m_columnTitle = aColumnTitle;
    this.m_legendTitle = aLegendTitle;
    this.m_data = aData;
    this.m_start = aStart;
    this.m_forecastValue = 0;
    this.m_sizeWithForecast = -1;
    this.m_valueType = aValueType;
    this.m_reverse = aReverse;
    this.m_currentAndPreviousRows = [];
  }

  /**
   * Sets the size including forecast and calculates the forecast value using the values in {@link data}
   *
   * @param aValue
   *
   * @private
   */
  private setSizeWithForecast(aValue: number) {
    this.m_sizeWithForecast = aValue;
    this.m_forecastValue = this.normalize(this.m_data.average());
  }

  // endregion
}

// endregion