// region imports

import {GraphNumberList} from "../../../types/classes/GraphNumberList";
import React from "react";
import {GraphColorTools} from "../../../tools/GraphColorTools";

// endregion

// region local

/**
 * Size of chart
 */
const SIZE: number = 292;

/**
 * Padding on all sides
 */
const PADDING: number = 4;

/**
 * Size classes (should be {@link SIZE} + {@link PADDING})
 */
const SIZE_CLASSES: string = 'w-[300px] h-[300px]';

/**
 * Helper, size divided by 2
 */
const SIZE_HALF: number = SIZE / 2;

/**
 * Gets a position on the circle border.
 *
 * @param aPercentage
 *   Value between 0.0 and 1.0
 * @param aDelta
 *   Additional value to add to angle
 */
function getPositionOnCircle(aPercentage: number, aDelta: number): { x: number, y: number } {
  const angle = aPercentage * Math.PI * 2 + aDelta - Math.PI / 2;
  return {
    x: SIZE_HALF + SIZE_HALF * Math.cos(angle),
    y: SIZE_HALF + SIZE_HALF * Math.sin(angle)
  };
}

/**
 * Gets a pie slice path.
 *
 * @param aFirstPercentage
 * @param aSecondPercentage
 */
function getSlicePath(aFirstPercentage: number, aSecondPercentage: number): string {
  const start = getPositionOnCircle(aFirstPercentage, 0);
  const end = getPositionOnCircle(aSecondPercentage, 0.005);
  const largeArc = aSecondPercentage - aFirstPercentage > 0.5 ? 1 : 0;
  return `
    M ${SIZE_HALF + PADDING} ${SIZE_HALF + PADDING}
    L ${start.x + PADDING} ${start.y + PADDING}
    A ${SIZE_HALF} ${SIZE_HALF}, 0, ${largeArc}, 1, ${end.x + PADDING} ${end.y + PADDING}
    Z
  `;
}

/**
 * Properties for component
 */
interface PieChartProps {
  /**
   * The values, only the first value of each number list is used.
   */
  readonly values: GraphNumberList[];

  /**
   * index of slice to focus or -1 to focus none
   */
  readonly focus: number;

  /**
   * Callback to change focus.
   */
  readonly setFocus: (value: number) => any;
}

// endregion

// region exports

/**
 * Draws a pie chart using svg.
 *
 * Code based on:
 * https://medium.com/explorations-in-javascript/drawing-arcs-and-pie-slices-with-svg-2f00d4af6aba
 */
export const PieChart: React.FC<PieChartProps> = ({values, focus, setFocus}) => {
  const sum = values.reduce((previous, current) => previous + current.get(0), 0);
  let percentage = 0;
  const percentages = values.map(list => {
    const current = percentage;
    percentage += list.get(0) / sum;
    return current;
  });
  return (
    <div
      className={SIZE_CLASSES + ' flex'}
    >
      <svg viewBox={`0 0 ${SIZE + PADDING * 2} ${SIZE + PADDING * 2}`}>
        {percentages.map((value, index) => {
          const color = values[index].color;
          return (
            <path
              key={index}
              d={getSlicePath(value, index < percentages.length - 1 ? percentages[index + 1] : 1.0)}
              className={`${GraphColorTools.getFillClass(color, index === focus)} cursor-pointer`}
              onMouseEnter={() => setFocus(index)}
              onMouseLeave={() => setFocus(-1)}
            />
          );
        })}
      </svg>
    </div>
  );
}

// endregion