// region imports

import {StoreTools} from "../../tools/StoreTools";
import {ActiveSubscriptionsEntry} from "../../types/interfaces/data/ActiveSubscriptionsEntry";

// endregion

// region exports

/**
 * Active subscriptions related values.
 */
export const activeSubscriptionsSlice = StoreTools.createDataListForDateRangeSlice<ActiveSubscriptionsEntry>(
  'active-subscriptions'
);

/**
 * The actions for the slice
 */
export const {
  initializeDashboard,
  setHistoryRange,
  setLoading,
  setDashboard,
  setHistoryEntries,
  resetDashboard,
} = activeSubscriptionsSlice.actions;

// endregion