// region imports

import {UFSerialQueueAction} from "../../../UF/actions/UFSerialQueueAction";
import {DateRange} from "../../../types/classes/DateRange";
import {UFCallbackAction} from "../../../UF/actions/UFCallbackAction";
import {store} from "../../../store/store";
import {
  initializeDashboard,
  resetDashboard,
  setDashboardCurrent, setDashboardPrevious,
  setLoading
} from "../../../store/data/trafficInfoSlice";
import {TrafficInfoLoadAction} from "./TrafficInfoLoadAction";
import {WebsiteDomainType} from "../../../types/enums/WebsiteDomainType";

// endregion

// region exports

/**
 * Gets the traffic info data for the dashboard.
 */
export class TrafficInfoDashboardAction extends UFSerialQueueAction {
  constructor(anAlways: boolean, aDomain: WebsiteDomainType, aCurrentRange: DateRange, aPreviousRange: DateRange) {
    if (anAlways || !store.getState().trafficInfo.initializedDashboard[aDomain]) {
      super(
        new UFCallbackAction(() => store.dispatch(setLoading(true))),
        new TrafficInfoLoadAction(
          aDomain, aCurrentRange, info => store.dispatch(setDashboardCurrent({domain: aDomain, data: info}))
        ),
        new TrafficInfoLoadAction(
          aDomain, aPreviousRange, info => store.dispatch(setDashboardPrevious({domain: aDomain, data: info}))
        ),
        new UFCallbackAction(() => store.dispatch(initializeDashboard(aDomain))),
      );
      store.dispatch(resetDashboard(aDomain));
    }
    else {
      super();
    }
  }
}

// endregion