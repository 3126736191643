import {GeneralTableData} from "./GeneralTableData";

/**
 * A subclass of {@link GeneralTableData} that override all the border methods to return false.
 *
 * It also removes spacing on the left of the first cells, spacing on the right of the last cells, bottom spacing for
 * all cells and top spacing of the first header row.
 */
export abstract class GeneralNoBordersTableData extends GeneralTableData {
  // region GeneralTableData

  /**
   * @inheritDoc
   */
  headerLeftBorder(aColumn: number, aRow: number): boolean {
    return false;
  }

  /**
   * @inheritDoc
   */
  headerRightBorder(aColumn: number, aRow: number): boolean {
    return false;
  }

  // endregion
  /**
   * @inheritDoc
   */
  headerTopBorder(aColumn: number, aRow: number): boolean {
    return false;
  }

  /**
   * @inheritDoc
   */
  headerBottomBorder(aColumn: number, aRow: number): boolean {
    return false;
  }

  /**
   * @inheritDoc
   */
  headerLeftSpacing(aColumn: number, aRow: number): boolean {
    return aColumn > 0;
  }

  /**
   * @inheritDoc
   */
  headerRightSpacing(aColumn: number, aRow: number): boolean {
    return aColumn < this.columnCount - 1;
  }

  /**
   * @inheritDoc
   */
  headerTopSpacing(aColumn: number, aRow: number): boolean {
    return aRow > 0;
  }

  /**
   * @inheritDoc
   */
  headerBottomSpacing(aColumn: number, aRow: number): boolean {
    return false;
  }

  /**
   * @inheritDoc
   */
  cellLeftBorder(aColumn: number, aRow: number): boolean {
    return false;
  }

  /**
   * @inheritDoc
   */
  cellRightBorder(aColumn: number, aRow: number): boolean {
    return false;
  }

  // endregion
  /**
   * @inheritDoc
   */
  cellTopBorder(aColumn: number, aRow: number): boolean {
    return false;
  }

  /**
   * @inheritDoc
   */
  cellBottomBorder(aColumn: number, aRow: number): boolean {
    return false;
  }

  /**
   * @inheritDoc
   */
  cellLeftSpacing(aColumn: number, aRow: number): boolean {
    return aColumn > 0;
  }

  /**
   * @inheritDoc
   */
  cellRightSpacing(aColumn: number, aRow: number): boolean {
    return aColumn < this.columnCount - 1;
  }

  /**
   * @inheritDoc
   */
  cellBottomSpacing(aColumn: number, aRow: number): boolean {
    return false;
  }

  // endregion
}