// region imports

import {TransactionResultsResponseIO} from "../../../types/interfaces/data/io/TransactionResultsResponseIO";
import {TransactionResultEntryIO} from "../../../types/interfaces/data/io/TransactionResultEntryIO";
import {TransactionResultEntry} from "../../../types/interfaces/data/TransactionResultEntry";
import {DateRange} from "../../../types/classes/DateRange";
import {TransactionResultValuesIO} from "../../../types/interfaces/data/io/TransactionResultValuesIO";
import {TransactionResultValues} from "../../../types/interfaces/data/TransactionResultValues";
import {LoadDateRangeWithCallbackAction} from "../LoadDateRangeWithCallbackAction";

// endregion

// region exports

/**
 * Loads the transaction results, grouped per day for a certain date range and data target.
 */
export class TransactionResultsLoadAction
  extends LoadDateRangeWithCallbackAction<TransactionResultsResponseIO, TransactionResultEntry[]>
{
  // region public methods

  /**
   * Constructs an instance.
   *
   * @protected
   */
  public constructor(aDateRange: DateRange, aCallback: (entries: TransactionResultEntry[]) => any) {
    super('/transaction/results', aDateRange, aCallback);
  }

  // endregion

  // region protected methods

  /**
   * @inheritDoc
   */
  protected process(aResponse: TransactionResultsResponseIO): Promise<void> {
    this.callback(this.convertEntriesFromIO(aResponse.entries));
    return Promise.resolve();
  }

  // endregion

  // region private methods

  /**
   * Converts data received from the server to data structure used within the application.
   */
  private convertValuesFromIO(aValues?: TransactionResultValuesIO): TransactionResultValues {
    return {
      successCount: aValues?.success_count || 0,
      successAmount: aValues?.success_amount || 0,
      failCount: aValues?.fail_count || 0,
      failAmount: aValues?.fail_amount || 0,
    };
  }

  /**
   * Converts data received from the server to data structure used within the application.
   */
  private convertEntryFromIO(anEntry: TransactionResultEntryIO): TransactionResultEntry {
    return {
      date: new Date(anEntry.date),
      week: anEntry.week,
      subscription: this.convertValuesFromIO(anEntry.subscription),
      recurring: this.convertValuesFromIO(anEntry.recurring),
      coin: this.convertValuesFromIO(anEntry.coin)
    };
  }

  /**
   * Converts data received from the server to data structure used within the application.
   */
  private convertEntriesFromIO(anEntries: TransactionResultEntryIO[]): TransactionResultEntry[] {
    return anEntries.map(entry => this.convertEntryFromIO(entry));
  }

  // endregion
}

// endregion