// region imports

import React from "react";
import {useAppSelector} from "../../../store/store";
import {Stack} from "../../styled/layouts/Stack";
import {WebsiteDomainButtons} from "../../buttons/WebsiteDomainButtons";
import {NumberType} from "../../../types/enums/NumberType";
import {StackDirection} from "../../styled/layouts/stack/StackDirection";
import {FullWidthContentPanel} from "../../styled/containers/FullWidthContentPanel";
import {Tools} from "../../../tools/Tools";
import {mainController} from "../../../controllers/mainController";
import {UserLocation} from "../../../types/enums/UserLocation";
import {CurrentAndPreviousRankedRow} from "../../../types/classes/CurrentAndPreviousRankedRow";
import {CurrentAndPreviousRankedTableData} from "../../../types/classes/table-data/CurrentAndPreviousRankedTableData";
import {GeneralTableWithTitle} from "../../tables/GeneralTableWithTitle";

// endregion

// region exports

export const VisitorInfoDashboard: React.FC = () => {
  const {
    loading, initializedDashboard, dashboardCurrent, dashboardPrevious
  } = useAppSelector(state => state.visitorInfo);
  const domain = useAppSelector(state => state.application.websiteDomain);
  const tableTextSize = useAppSelector(state => state.application.tableTextSize);
  const {lastTitle, previousTitle} = Tools.getLastAndPreviousTitles();
  const available = !loading && initializedDashboard[domain];
  return (
    <FullWidthContentPanel
      loading={!available}
      title="Visitor Information"
      onClick={() => mainController.setUserLocation(UserLocation.VisitorInfo)}
    >
      {
        available &&
        <Stack
          direction={StackDirection.Column}
          gap={4}
        >
          <WebsiteDomainButtons/>
          <Stack
            direction={StackDirection.Row}
            gap={8}
            wrap
          >
            <GeneralTableWithTitle
              tableTextSize={tableTextSize}
              title="OS"
              data={
                new CurrentAndPreviousRankedTableData(
                  lastTitle,
                  previousTitle,
                  CurrentAndPreviousRankedRow.createRows(
                    dashboardCurrent[domain].osEntries, dashboardPrevious[domain].osEntries,
                    NumberType.Integer, true, 10
                  ),
                  true,
                  false,
                  false
                )
              }
            />
            <GeneralTableWithTitle
              tableTextSize={tableTextSize}
              title="Country"
              data={
                new CurrentAndPreviousRankedTableData(
                  lastTitle,
                  previousTitle,
                  CurrentAndPreviousRankedRow.createRows(
                    dashboardCurrent[domain].countryEntries, dashboardPrevious[domain].countryEntries,
                    NumberType.Integer, true, 10
                  ),
                  true,
                  false,
                  false
                )
              }
            />
            <GeneralTableWithTitle
              tableTextSize={tableTextSize}
              title="Language"
              data={
                new CurrentAndPreviousRankedTableData(
                  lastTitle,
                  previousTitle,
                  CurrentAndPreviousRankedRow.createRows(
                    dashboardCurrent[domain].languageEntries, dashboardPrevious[domain].languageEntries,
                    NumberType.Integer, true, 10
                  ),
                  true,
                  false,
                  false
                )
              }
            />
            <GeneralTableWithTitle
              tableTextSize={tableTextSize}
              title="Mobile"
              data={
                new CurrentAndPreviousRankedTableData(
                  lastTitle,
                  previousTitle,
                  CurrentAndPreviousRankedRow.createRows(
                    dashboardCurrent[domain].mobileEntries, dashboardPrevious[domain].mobileEntries,
                    NumberType.Integer, false, 10
                  ),
                  false,
                  false,
                  false
                )
              }
            />
          </Stack>
        </Stack>
      }
    </FullWidthContentPanel>
  );
}

// endregion