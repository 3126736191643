// region imports

import React from "react";
import {CurrentAndPreviousRow} from "../CurrentAndPreviousRow";
import {Text} from "../../../components/styled/texts/Text";
import {TextSize} from "../../../components/styled/texts/text/TextSize";
import {TextWeight} from "../../../components/styled/texts/text/TextWeight";
import {DifferenceInformation} from "../../../components/styled/texts/DifferenceInformation";
import {GeneralNoBordersTableData} from "./GeneralNoBordersTableData";
import {Position} from "../../enums/Position";
import {NumberType} from "../../enums/NumberType";

// endregion

// region exports

/**
 * {@link CurrentAndPreviousTableData} shows a list of current and previous values rows.
 */
export class CurrentAndPreviousTableData extends GeneralNoBordersTableData {
  // region private variables

  /**
   * Left title.
   *
   * @private
   */
  private readonly m_leftTitle: React.ReactNode;

  /**
   * Right title
   *
   * @private
   */
  private readonly m_rightTitle: React.ReactNode;

  /**
   * Current and previous entries or null to show an empty row.
   *
   * @private
   */
  private readonly m_rows: (CurrentAndPreviousRow | null)[];

  // endregion

  // region public methods

  /**
   * Creates a table data.
   *
   * @param aLeftTitle
   * @param aRightTitle
   * @param aRows
   */
  constructor(
    aLeftTitle: React.ReactNode, aRightTitle: React.ReactNode, aRows: (CurrentAndPreviousRow | null)[]
  ) {
    super();
    this.m_leftTitle = aLeftTitle;
    this.m_rightTitle = aRightTitle;
    this.m_rows = aRows;
  }

  // endregion

  // region GeneralTableData

  /**
   * @inheritDoc
   */
  cell(aColumn: number, aRow: number): React.ReactNode {
    const row = this.m_rows[aRow];
    if (row === null) {
      return null;
    }
    switch(aColumn) {
      case 0:
        return <Text size={TextSize.Small}>{row.name}</Text>;
      case 1:
        return <Text weight={TextWeight.Bold}>{row.currentAsText}</Text>;
      case 2:
        return row.valueType === NumberType.Percentage ? null : <DifferenceInformation data={row} />
      case 3:
        return <Text size={TextSize.Small}>{row.previousAsText}</Text>
      default:
        return null;
    }
  }

  /**
   * @inheritDoc
   */
  cellHorizontalPosition(aColumn: number, aRow: number): Position {
    switch(aColumn) {
      case 0:
        return Position.Start;
      default:
        return Position.End;
    }
  }

  /**
   * @inheritDoc
   */
  get columnCount(): number {
    return 5;
  }

  /**
   * @inheritDoc
   */
  header(aColumn: number, aRow: number): React.ReactNode {
    switch(aColumn) {
      case 0:
        return this.m_leftTitle;
      case 3:
        return this.m_rightTitle;
      default:
        return null;
    }
  }

  /**
   * @inheritDoc
   */
  headerHorizontalPosition(aColumn: number, aRow: number): Position {
    return Position.Start;
  }

  /**
   * @inheritDoc
   */
  headerColSpan(aColumn: number, aRow: number): number {
    switch(aColumn) {
      case 0:
        return 3;
      case 3:
        return 2;
      default:
        return 1;
    }
  }

  /**
   * @inheritDoc
   */
  get rowCount(): number {
    return this.m_rows.length;
  }

  /**
   * @inheritDoc
   */
  isEmptyRow(aRow: number): boolean {
    return this.m_rows[aRow] === null;
  }

  /**
   * @inheritDoc
   */
  isFullWidthCell(aColumn: number, aRow: number): boolean {
    return aColumn === 4;
  }

  /**
   * @inheritDoc
   */
  cellTopSpacing(aColumn: number, aRow: number): boolean {
    return aRow >0;
  }

  // endregion
}

// endregion