// region imports

import {TitleProp} from "../../types/interfaces/props/TitleProp";
import React, {useState} from "react";
import {Stack} from "../styled/layouts/Stack";
import {StackDirection} from "../styled/layouts/stack/StackDirection";
import {GeneralTable} from "../styled/tables/GeneralTable";
import {Title} from "../styled/texts/Title";
import {TitleType} from "../styled/texts/title/TitleType";
import {GeneralTableData} from "../../types/classes/table-data/GeneralTableData";
import {TableTextSize} from "../../types/enums/TableTextSize";

// endregion

// region local

interface GeneralTableWithTitleProps extends TitleProp {
  readonly data: GeneralTableData;
  readonly tableTextSize?: TableTextSize;
}

// endregion

// region

/**
 * A general table with a title handling focus locally.
 */
export const GeneralTableWithTitle: React.FC<GeneralTableWithTitleProps> = ({data, title, tableTextSize}) => {
  const [focus, setFocus] = useState(-1);
  return (
    <Stack
      direction={StackDirection.Column}
      gap={1}
      >
      <Title type={TitleType.H6}>{title}</Title>
      <GeneralTable
        data={data}
        focus={focus}
        setFocus={setFocus}
        tableTextSize={tableTextSize}
      />
    </Stack>
  )
}

// endregion