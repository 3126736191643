// region imports

import {CurrentAndPreviousValue} from "../../../types/classes/CurrentAndPreviousValue";
import React from "react";
import {GraphColorTools} from "../../../tools/GraphColorTools";
import {Arrow} from "./Arrow";
import {DirectionType} from "./arrow/DirectionType";

// endregion

// region local


interface DifferenceInformationProps {
  /**
   * Data to get information from
   */
  readonly data: CurrentAndPreviousValue;
}

// endregion

// region exports

/**
 * Shows an arrow, percentage and value difference. Color based on the difference type.
 */
export const DifferenceInformation: React.FC<DifferenceInformationProps> = ({data}) => {
  if (!data) {
    return null;
  }
  const textColor = GraphColorTools.getDifferenceTextColor(data.differenceType);
  return (
    <div className={`inline-flex flex-row align-middle items-center ${textColor}`}>
      <span className="text-[1.8em] leading-none pr-0.5">
        <Arrow direction={data.upwards ? DirectionType.Up : DirectionType.Down}/>
      </span>
      <div className="flex flex-col">
        <span className="font-semibold text-[0.87em] leading-none text-base text-right">{data.percentageAsText}</span>
        <span className="text-[0.75em] leading-none text-right">{data.differenceAsText}</span>
      </div>
    </div>
  );
}

// endregion