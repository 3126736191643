import React from "react";
import {ColumnPoints} from "./ColumnPoints";
import {ColumnLines} from "./ColumnLines";
import {GraphNumberList} from "../../../../types/classes/GraphNumberList";

interface GraphProps {
  readonly data: GraphNumberList[];
  readonly topValue: number;
  readonly bottomValue: number;
  readonly count: number;
}

/**
 * Renders the graph that exists of point and line columns.
 */
export const Graph: React.FC<GraphProps> = ({data, topValue, bottomValue, count}) => {
  const columns = [];
  for (let column = 0; column < count; column++) {
    columns.push(
      <ColumnPoints key={column} data={data} topValue={topValue} bottomValue={bottomValue} column={column}/>);
    if (column < count - 1) {
      columns.push(
        <ColumnLines key={column + count} data={data} topValue={topValue} bottomValue={bottomValue} column={column}/>);
    }
  }
  return (
    <div className="line-graph__data flex flex-row justify-between pointer-events-none">
      {columns}
    </div>
  )
}