// region imports

import {UserLocation} from "../types/enums/UserLocation";
import {TextAlign} from "../types/enums/TextAlign";
import {WebsiteDomainType} from "../types/enums/WebsiteDomainType";
import {DataPeriodType} from "../types/enums/DataPeriodType";
import {Config} from "../Config";
import {HeaderTitle} from "../components/text/HeaderTitle";
import React from "react";
import {SubscriptionAndRecurringEntry} from "../types/interfaces/data/SubscriptionAndRecurringEntry";
import {Position} from "../types/enums/Position";

// endregion

// region exports

/**
 * {@link Tools} contains various support methods.
 */
export class Tools {
  /**
   * Gets the name for a user location.
   *
   * @param aLocation
   *   Location to get name for
   *
   * @return name of location
   */
  static getLocationName(aLocation: UserLocation): string {
    switch(aLocation) {
      case UserLocation.Start:
        return 'Start';
      case UserLocation.Login:
        return 'Login';
      case UserLocation.Dashboard:
        return 'Dashboard';
      case UserLocation.TransactionResults:
        return 'Transaction Results';
      case UserLocation.UserRegistrations:
        return 'User Registrations';
      case UserLocation.TransactionFails:
        return 'Top 10 Transaction Fails';
      case UserLocation.VisitorInfo:
        return 'Visitor Information';
      case UserLocation.TrafficInfo:
        return 'Traffic Information';
      case UserLocation.Tickets:
        return 'Ticket Counts';
      case UserLocation.ActiveSubscriptions:
        return 'Active Subscriptions';
      case UserLocation.Cancellations:
        return 'Cancellations';
      case UserLocation.TrialToSubscriptions:
        return 'Trial Conversions';
      case UserLocation.Coins:
        return 'Coins';
      case UserLocation.Arpu:
        return 'ARPU';
      case UserLocation.SubscriptionsAndRecurrings:
        return 'Subscriptions and Recurring';
      case UserLocation.Churn:
        return 'Churn';
      case UserLocation.NewSubscriptionsCount:
        return 'New Subscriptions';
      case UserLocation.NewSubscriptionsAmount:
        return 'New Subscriptions Revenue';
      case UserLocation.LegacyBookings:
        return 'Legacy Bookings';
    }
  }

  /**
   * Gets the css class for a text alignment.
   *
   * @param anAlign
   *   Text alignment
   *
   * @returns css class
   */
  static getTextAlignClass(anAlign: TextAlign): string {
    switch(anAlign) {
      case TextAlign.Left:
        return 'text-left';
      case TextAlign.Center:
        return 'text-center';
      case TextAlign.Right:
        return 'text-right';
    }
  }

  /**
   * Gets the css class for a horizontal position.
   *
   * @param aPosition
   *   Position
   *
   * @returns css class
   */
  static getHorizontalPositionClass(aPosition: Position): string {
    switch(aPosition) {
      case Position.Start:
        return 'text-left';
      case Position.Center:
        return 'text-center';
      case Position.End:
        return 'text-right';
    }
  }

  /**
   * Gets the css class for a vertical position.
   *
   * @param aPosition
   *   Position
   *
   * @returns css class
   */
  static getVerticalPositionClass(aPosition: Position): string {
    switch(aPosition) {
      case Position.Start:
        return 'align-top';
      case Position.Center:
        return 'align-middle';
      case Position.End:
        return 'align-bottom';
    }
  }

  /**
   * Gets a domain value for a domain type.
   *
   * @param aDomain
   *   Domain type
   *
   * @returns textual value for domain
   */
  static getDomainAsText(aDomain: WebsiteDomainType): string {
    switch(aDomain) {
      case WebsiteDomainType.FundoradoCom:
        return 'fundorado.com';
      case WebsiteDomainType.FundoradoDe:
        return 'fundorado.de';
    }
  }

  /**
   * Gets a numerical index for a website domain and a period type.
   * @param aDomain
   *   Domain type
   * @param aPeriod
   *   Period type
   *
   * @return unique index from 0 till number of domain values * number of period types
   */
  static getIndexForDomainAndPeriod(aDomain: WebsiteDomainType, aPeriod: DataPeriodType): number {
    return aDomain + 2 * aPeriod;
  }

  /**
   * Gets last and previous titles using {@link Config.dashboardDays}.
   */
  static getLastAndPreviousTitles(): { lastTitle: React.ReactNode, previousTitle: React.ReactNode } {
    return {
      lastTitle: <HeaderTitle>Last {Config.dashboardDays} days</HeaderTitle>,
      previousTitle: <HeaderTitle>Previous {Config.dashboardDays} days</HeaderTitle>
    }
  }

  /**
   * Gets all unique plan ids, sorted on id number
   */
  static getPlanIds(anEntries: SubscriptionAndRecurringEntry[]): number[] {
    // use set, so no need to check for duplicate entries
    const result: Set<number> = new Set();
    anEntries.forEach(entry => {
      // only need to do one property, since all will have the same plan ids
      entry.subscriptionCount.forEach((value, key) => result.add(key));
    });
    return Array.from(result).sort((first, second) => first - second);
  }

}
