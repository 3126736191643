// region imports

import {UFSerialQueueAction} from "../../../UF/actions/UFSerialQueueAction";
import {UFCallbackAction} from "../../../UF/actions/UFCallbackAction";
import {
  initialized,
  reset,
  setLoading,
  setTotalPast,
  setTotalToday,
  setWebmastersPast,
  setWebmastersToday
} from "../../../store/data/arpuSlice";
import {store} from "../../../store/store";
import {ArpuLoadAction} from "./ArpuLoadAction";
import {UFDate} from "../../../UF/tools/UFDate";

// endregion

// region exports

/**
 * An action that loads all data for use with the dashboard.
 */
export class ArpuDashboardAction extends UFSerialQueueAction {
  constructor(anAlways: boolean) {
    const today = new Date();
    const past = new Date(today.getTime() - 90 * UFDate.DAY_IN_MILLISECONDS);
    if (anAlways || !store.getState().arpu.initialized) {
      super(
        new UFCallbackAction(() => store.dispatch(setLoading(true))),
        new ArpuLoadAction(today, (total, webmasters) => {
          store.dispatch(setTotalToday(total));
          store.dispatch(setWebmastersToday(webmasters));
        }),
        new ArpuLoadAction(past, (total, webmasters) => {
          store.dispatch(setTotalPast(total));
          store.dispatch(setWebmastersPast(webmasters));
        }),
        new UFCallbackAction(() => store.dispatch(initialized()))
      );
      store.dispatch(reset());
    }
    else {
      super();
    }
  }
}

// endregion