import React from "react";
import {useAppSelector} from "../../store/store";
import {Stack} from "../styled/layouts/Stack";
import {MenuButton} from "../styled/buttons/MenuButton";
import {UserLocation} from "../../types/enums/UserLocation";
import {mainController} from "../../controllers/mainController";
import {LocationMenuButton} from "../buttons/LocationMenuButton";
import {Spacer} from "../styled/layouts/Spacer";
import {StackDirection} from "../styled/layouts/stack/StackDirection";

export const MainLeft: React.FC = () => {
  const name = useAppSelector(state => state.user.fullName);
  return (
    <Stack
      direction={StackDirection.Column}
      gap={0}
    >
      <MenuButton
        submenu={
          <Stack
            direction={StackDirection.Column}
            gap={2}
          >
            <MenuButton
              onClick={() => mainController.logout()}
            >
              Logout
            </MenuButton>
          </Stack>
        }
      >
        Hi, {name}
      </MenuButton>
      <Spacer />
      <LocationMenuButton location={UserLocation.Dashboard} />
      <Spacer />
      <LocationMenuButton location={UserLocation.TransactionResults} />
      <LocationMenuButton location={UserLocation.TransactionFails} />
      <LocationMenuButton location={UserLocation.UserRegistrations} />
      <LocationMenuButton location={UserLocation.VisitorInfo} />
      <LocationMenuButton location={UserLocation.TrafficInfo} />
      <LocationMenuButton location={UserLocation.Tickets} />
      <LocationMenuButton location={UserLocation.ActiveSubscriptions} />
      <LocationMenuButton location={UserLocation.Cancellations} />
      <LocationMenuButton location={UserLocation.TrialToSubscriptions} />
      <LocationMenuButton location={UserLocation.Coins} />
      <LocationMenuButton location={UserLocation.Arpu} />
      <LocationMenuButton location={UserLocation.SubscriptionsAndRecurrings} />
      <LocationMenuButton location={UserLocation.Churn} />
      <LocationMenuButton location={UserLocation.NewSubscriptionsCount} />
      <LocationMenuButton location={UserLocation.NewSubscriptionsAmount} />
      <LocationMenuButton location={UserLocation.LegacyBookings} />
    </Stack>
  );
};
