import React from "react";
import {Triangle} from "./Triangle";
import {DirectionType} from "./arrow/DirectionType";
import {GraphColorTools} from "../../../tools/GraphColorTools";
import {NumberDifferenceType} from "../../../types/enums/NumberDifferenceType";

interface RankedTriangleProps {
  readonly currentRank: number;
  readonly previousRank: number;
}

export const RankedTriangle: React.FC<RankedTriangleProps> = ({currentRank, previousRank}) => {
  if (previousRank < 0) {
    return null;
  }
  else if (previousRank < currentRank) {
    return (
      <span className={GraphColorTools.getDifferenceTextColor(NumberDifferenceType.Danger) + ' text-sm px-[2px]'}>
        <Triangle direction={DirectionType.Up}/>
      </span>
    );
  }
  else if (previousRank > currentRank) {
    return (
      <span className={GraphColorTools.getDifferenceTextColor(NumberDifferenceType.Positive) + ' text-sm px-[2px]'}>
        <Triangle direction={DirectionType.Down}/>
      </span>
    );
  }
  else {
    return null;
  }
}