// region imports

import {UserLocation} from "../types/enums/UserLocation";
import {store} from "../store/store";
import {clear} from "../store/userSlice";
import {network} from "../tools/network";
import {DataPeriodType} from "../types/enums/DataPeriodType";
import {
  setCustomDateEnd,
  setCustomDateStart,
  setDataPeriod,
  setDateSelection,
  setLocation, setTableTextSize,
  setWebsiteDomain
} from "../store/applicationSlice";
import {UFCancellationTokenSource} from "../UF/actions/UFCancellationTokenSource";
import {IUFQueueableAction} from "../UF/actions/IUFQueueableAction";
import {UFSerialQueueAction} from "../UF/actions/UFSerialQueueAction";
import {UFCallbackAction} from "../UF/actions/UFCallbackAction";
import {StoreTools} from "../tools/StoreTools";
import {WebsiteDomainType} from "../types/enums/WebsiteDomainType";
import {ArpuDashboardAction} from "./actions/arpu/ArpuDashboardAction";
import {DateRange} from "../types/classes/DateRange";
import {CoinsDashboardAction} from "./actions/coins/CoinsDashboardAction";
import {TransactionResultsHistoryAction} from "./actions/transaction-results/TransactionResultsHistoryAction";
import {UserRegistrationsHistoryAction} from "./actions/user-registrations/UserRegistrationsHistoryAction";
import {TransactionFailsHistoryAction} from "./actions/transaction-fails/TransactionFailsHistoryAction";
import {VisitorInfoHistoryAction} from "./actions/visitor-info/VisitorInfoHistoryAction";
import {TrafficInfoHistoryAction} from "./actions/traffic-info/TrafficInfoHistoryAction";
import {TicketsHistoryAction} from "./actions/tickets/TicketsHistoryAction";
import {ActiveSubscriptionsHistoryAction} from "./actions/active-subscriptions/ActiveSubscriptionsHistoryAction";
import {CoinsHistoryAction} from "./actions/coins/CoinsHistoryAction";
import {CancellationsHistoryAction} from "./actions/cancellations/CancellationsHistoryAction";
import {CancellationsDashboardAction} from "./actions/cancellations/CancellationsDashboardAction";
import {VisitorInfoDashboardAction} from "./actions/visitor-info/VisitorInfoDashboardAction";
import {TransactionFailsDashboardAction} from "./actions/transaction-fails/TransactionFailsDashboardAction";
import {TicketsDashboardAction} from "./actions/tickets/TicketsDashboardAction";
import {ActiveSubscriptionsDashboardAction} from "./actions/active-subscriptions/ActiveSubscriptionsDashboardAction";
import {TransactionResultsDashboardAction} from "./actions/transaction-results/TransactionResultsDashboardAction";
import {SubscriptionsAndRecurringsDashboardAction} from "./actions/subscriptions-and-recurrings/SubscriptionsAndRecurringsDashboardAction";
import {TrafficInfoDashboardAction} from "./actions/traffic-info/TrafficInfoDashboardAction";
import {UserRegistrationsDashboardAction} from "./actions/user-registrations/UserRegistrationsDashboardAction";
import {
  TrialToSubscriptionsDashboardAction
} from "./actions/trial-to-subscriptions/TrialToSubscriptionsDashboardAction";
import {TrialToSubscriptionsHistoryAction} from "./actions/trial-to-subscriptions/TrialToSubscriptionsHistoryAction";
import {SubscriptionsAndRecurringsHistoryAction} from "./actions/subscriptions-and-recurrings/SubscriptionsAndRecurringsHistoryAction";
import {ChurnHistoryAction} from "./actions/churn/ChurnHistoryAction";
import {ChurnDashboardAction} from "./actions/churn/ChurnDashboardAction";
import {LegacyDashboardAction} from "./actions/legacy/LegacyDashboardAction";
import {LegacyHistoryAction} from "./actions/legacy/LegacyHistoryAction";
import {TableTextSize} from "../types/enums/TableTextSize";
import {LegacyBookingsDashboardAction} from "./actions/legacy-bookings/LegacyBookingsDashboardAction";
import {LegacyBookingsHistoryAction} from "./actions/legacy-bookings/LegacyBookingsHistoryAction";
import {BankBookingsDashboardAction} from "./actions/bank-bookings/BankBookingsDashboardAction";

// endregion

// region class

/**
 * {@link MainController} handles the flow within the application and takes care of getting the data from the server.
 */
// noinspection JSMethodCanBeStatic
class MainController {
  // region private methods

  /**
   * Token used when loading.
   *
   * @private
   */
  private m_loadCancelToken: UFCancellationTokenSource | null = null;

  /**
   * Current load action.
   *
   * @private
   */
  private m_loadAction: IUFQueueableAction | null = null;

  // endregion

  // region public methods

  /**
   * Starts the application.
   */
  async start() {
    this.setUserLocation(UserLocation.Start);
    if (await network.refreshUser()) {
      this.setUserLocation(UserLocation.Dashboard);
    }
    else {
      this.setUserLocation(UserLocation.Login);
    }
  }

  /**
   * Tries to authenticate the user.
   *
   * @param anEmail
   * @param aPassword
   */
  async login(anEmail: string, aPassword: string): Promise<boolean> {
    if (await network.login(anEmail, aPassword)) {
      this.setUserLocation(UserLocation.Dashboard);
      return true;
    }
    return false;
  }

  /**
   * Performs a logout by clearing all user related data and returning to the login screen.
   */
  logout() {
    store.dispatch(clear());
    this.setUserLocation(UserLocation.Login);
  }

  /**
   * Sets a new user location.
   *
   * @param aLocation
   */
  setUserLocation(aLocation: UserLocation) {
    store.dispatch(setLocation(aLocation));
    this.checkData(false);
  }

  /**
   * Sets a new data period.
   *
   * @param aValue
   */
  setDataPeriod(aValue: DataPeriodType) {
    store.dispatch(setDataPeriod(aValue));
    this.checkData(false);
  }

  /**
   * Sets a new website domain.
   *
   * @param aValue
   */
  setWebsiteDomain(aValue: WebsiteDomainType) {
    store.dispatch(setWebsiteDomain(aValue));
    this.checkData(false);
  }

  /**
   * Shows the selection date dialog and refresh the data if the date has changed.
   */
  async selectDate() {
    const old = store.getState().application;
    const result = await StoreTools.showSelectDate();
    if (!result) {
      return;
    }
    if (
      (old.dateSelection !== result.dateSelection)
      || (old.customDateStart !== result.customDateStart)
      || (old.customDateEnd !== result.customDateEnd)
    ) {
      store.dispatch(setCustomDateStart(result.customDateStart));
      store.dispatch(setCustomDateEnd(result.customDateEnd));
      store.dispatch(setDateSelection(result.dateSelection));
      this.checkData(false);
    }
  }

  /**
   * Reloads the data for the current user location.
   */
  refreshData() {
    this.checkData(true);
  }

  /**
   * Sets the size of the text for the tables.
   *
   * @param aValue
   */
  setTableTextSize(aValue: TableTextSize) {
    store.dispatch(setTableTextSize(aValue));
  }

  // endregion

  // region private methods

  /**
   * Checks the data for the current user location and reloads the data if needed.
   *
   * @param anAlways
   *   True to force reloading, false to only load if the data is missing or history date range has changed.
   */
  private checkData(anAlways: boolean) {
    // determine the various date ranges
    const {websiteDomain, customDateStart, customDateEnd, dateSelection} = store.getState().application;
    const historyRange = DateRange.createFromDateSelection(dateSelection, customDateStart, customDateEnd);
    const historyRangeForAllPeriods = DateRange.createForLoading(historyRange);
    const dashboardBoth = DateRange.createForCurrentAndPrevious();
    const dashboardCurrent = DateRange.createForCurrent();
    const dashboardPrevious = DateRange.createForPrevious();
    // process each user location that has data
    switch (store.getState().application.location) {
      case UserLocation.Dashboard:
        this.startLoadingAction(
          new ActiveSubscriptionsDashboardAction(anAlways, dashboardBoth),
          new ArpuDashboardAction(anAlways),
          new CancellationsDashboardAction(anAlways, dashboardBoth),
          new CoinsDashboardAction(anAlways, dashboardBoth),
          new TicketsDashboardAction(anAlways, dashboardBoth),
          new TransactionResultsDashboardAction(anAlways, dashboardBoth),
          new TrafficInfoDashboardAction(anAlways, websiteDomain, dashboardCurrent, dashboardPrevious),
          new TransactionFailsDashboardAction(anAlways, dashboardCurrent, dashboardPrevious),
          new SubscriptionsAndRecurringsDashboardAction(anAlways),
          new LegacyDashboardAction(anAlways),
          new LegacyBookingsDashboardAction(anAlways),
          new BankBookingsDashboardAction(anAlways),
          new UserRegistrationsDashboardAction(anAlways, dashboardBoth),
          new TrialToSubscriptionsDashboardAction(anAlways, dashboardBoth),
          new VisitorInfoDashboardAction(anAlways, websiteDomain, dashboardCurrent, dashboardPrevious),
          new ChurnDashboardAction(anAlways, dashboardBoth)
        );
        break;
      case UserLocation.Arpu:
        this.startLoadingAction(
          new ArpuDashboardAction(anAlways)
        );
        break;
      case UserLocation.TransactionResults:
        this.startLoadingAction(
          new TransactionResultsHistoryAction(anAlways, historyRangeForAllPeriods),
        );
        break;
      case UserLocation.UserRegistrations:
        this.startLoadingAction(
          new UserRegistrationsHistoryAction(anAlways, historyRangeForAllPeriods),
        );
        break;
      case UserLocation.TransactionFails:
        this.startLoadingAction(
          new TransactionFailsHistoryAction(anAlways, historyRange),
        );
        break;
      case UserLocation.VisitorInfo:
        this.startLoadingAction(
          new VisitorInfoHistoryAction(anAlways, websiteDomain, historyRange),
        );
        break;
      case UserLocation.TrafficInfo:
        this.startLoadingAction(
          new TrafficInfoHistoryAction(anAlways, websiteDomain, historyRange),
        );
        break;
      case UserLocation.Tickets:
        this.startLoadingAction(
          new TicketsHistoryAction(anAlways, historyRangeForAllPeriods),
        );
        break;
      case UserLocation.ActiveSubscriptions:
        this.startLoadingAction(
          new ActiveSubscriptionsHistoryAction(anAlways, historyRangeForAllPeriods),
        );
        break;
      case UserLocation.Cancellations:
        this.startLoadingAction(
          new CancellationsHistoryAction(anAlways, historyRangeForAllPeriods),
        );
        break;
      case UserLocation.TrialToSubscriptions:
        this.startLoadingAction(
          new TrialToSubscriptionsHistoryAction(anAlways, historyRangeForAllPeriods),
        );
        break;
      case UserLocation.Coins:
        this.startLoadingAction(
          new CoinsHistoryAction(anAlways, historyRangeForAllPeriods),
        );
        break;
      case UserLocation.NewSubscriptionsCount:
      case UserLocation.NewSubscriptionsAmount:
        this.startLoadingAction(
          new SubscriptionsAndRecurringsHistoryAction(anAlways, historyRangeForAllPeriods),
        );
        break;
      case UserLocation.SubscriptionsAndRecurrings:
        this.startLoadingAction(
          new SubscriptionsAndRecurringsHistoryAction(anAlways, historyRangeForAllPeriods),
          new LegacyHistoryAction(anAlways, historyRangeForAllPeriods)
        );
        break;
      case UserLocation.Churn:
        this.startLoadingAction(
          new ChurnHistoryAction(anAlways, historyRangeForAllPeriods),
        );
        break;
      case UserLocation.LegacyBookings:
        this.startLoadingAction(
          new LegacyBookingsHistoryAction(anAlways, historyRangeForAllPeriods)
        );
        break;
    }
  }

  /**
   * Cancels any active loading action and starts new loading actions.
   *
   * @param anAction
   *   One or more actions
   *
   * @private
   */
  private startLoadingAction(...anAction: IUFQueueableAction[]) {
    // cancel active loading action if any
    if (this.m_loadCancelToken) {
      this.m_loadCancelToken.cancel();
      this.m_loadCancelToken = null;
      this.m_loadAction = null;
    }
    // start new loading action
    this.m_loadCancelToken = new UFCancellationTokenSource();
    this.m_loadAction = new UFSerialQueueAction(...[
        ...anAction,
        new UFCallbackAction(() => {
          this.m_loadCancelToken = null;
          this.m_loadAction = null;
        })
      ]
    );
    // noinspection JSIgnoredPromiseFromCall
    this.m_loadAction.run(this.m_loadCancelToken.token);
  }

  // endregion
}

// endregion

// region exports

/**
 * Export singleton instance
 */
export const mainController = new MainController();

// endregion
