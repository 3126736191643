// region imports

import {DateTools} from "../../tools/DateTools";
import {DateSelectionType} from "../enums/DateSelectionType";
import {UFDate} from "../../UF/tools/UFDate";
import {Config} from "../../Config";
import {WeekInYear} from "../interfaces/data/support/WeekInYear";

// endregion

// region exports

/**
 * {@link DateRange} contains a start and end date.
 */
export class DateRange {
  // region private methods

  /**
   * See {@link start}
   *
   * @private
   */
  private readonly m_start: Date;

  /**
   * See {@link end}
   *
   * @private
   */
  private readonly m_end: Date;

  // endregion

  // region public methods

  /**
   * Constructs an instance of {@link DateRange}
   *
   * @param aStart
   * @param anEnd
   */
  constructor(aStart: Date, anEnd: Date) {
    this.m_start = aStart;
    this.m_end = anEnd;
  }

  /**
   * Checks if the instance is equal to another instance. The instances are equal if they use the same dates.
   */
  isEqual(aRange?: DateRange): boolean
  {
    return (aRange !== undefined) && (this.startAsCompactText === aRange.startAsCompactText) &&
      (this.endAsCompactText === aRange.endAsCompactText);
  }

  /**
   * Creates an array of dates per day from start to end (inclusive)
   */
  createDayDates(): Date[] {
    const result: Date[] = [];
    const startTime = this.start.getTime();
    const endTime = this.end.getTime();
    for(let time = startTime; time < endTime; time += UFDate.DAY_IN_MILLISECONDS) {
      result.push(new Date(time));
    }
    return result;
  }

  /**
   * Creates an array of week numbers from start to end (inclusive)
   */
  createWeekNumbers(): WeekInYear[] {
    const result: WeekInYear[] = [];
    const startTime = this.start.getTime();
    const endTime = this.end.getTime();
    for(let time = startTime; time <= endTime; time += 7 * UFDate.DAY_IN_MILLISECONDS) {
      const date = new Date(time);
      result.push({
        week: DateTools.getWeekNumber(date),
        year: date.getFullYear()
      });
    }
    return result;
  }

  /**
   * Creates an array of month dates from start to end (inclusive)
   */
  createMonthDates(): Date[] {
    const result: Date[] = [];
    for(let date = DateTools.getStartOfMonth(this.start); date <= this.end; date = DateTools.getNextMonth(date)) {
      result.push(new Date(date));
    }
    return result;
  }

  /**
   * Creates an array of year dates from start to end (inclusive)
   */
  createYearDates(): Date[] {
    const result: Date[] = [];
    const startYear = this.start.getFullYear();
    const endYear = this.start.getFullYear();
    for(let year = startYear; year <= endYear; year++) {
      result.push(new Date(year, 1, 1, 0, 0, 0, 0));
    }
    return result;
  }

  /**
   * Gets a date range that covers the same number of days, preceding the current date range.
   */
  getPrevious(): DateRange {
    const startTime = this.start.getTime();
    const deltaTime = this.end.getTime() - startTime;
    // end with the day before the start of this date range
    return new DateRange(
      new Date(startTime - deltaTime), new Date(startTime - UFDate.DAY_IN_MILLISECONDS)
    );
  }

  // endregion

  // region factory methods

  /**
   * Gets a date range based on the date selection.
   *
   * @param aSelection
   * @param aCustomStart
   * @param aCustomEnd
   */
  static createFromDateSelection(aSelection: DateSelectionType, aCustomStart: number, aCustomEnd: number): DateRange {
    const now = new Date();
    switch (aSelection) {
      case DateSelectionType.Today:
        return new DateRange(
          DateTools.getStartOfDay(now),
          now
        );
      case DateSelectionType.Yesterday:
        const yesterday = DateTools.getPreviousDay(now);
        return new DateRange(
          DateTools.getStartOfDay(yesterday),
          DateTools.getEndOfDay(yesterday)
        );
      case DateSelectionType.ThisMonth:
        return new DateRange(
          new Date(now.getFullYear(), now.getMonth(), 1),
          now
        );
      case DateSelectionType.LastMonth:
        const lastMonth = DateTools.getPreviousMonth(now);
        return new DateRange(
          new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1),
          new Date(
            lastMonth.getFullYear(), lastMonth.getMonth(), DateTools.getLastDayOfMonth(lastMonth), 23, 59, 59, 999
          )
        );
      case DateSelectionType.Last7Days:
        return new DateRange(
          DateTools.getStartOfDay(new Date(now.getTime() - 6 * UFDate.DAY_IN_MILLISECONDS)),
          now
        );
      case DateSelectionType.Last30Days:
        return new DateRange(
          DateTools.getStartOfDay(new Date(now.getTime() - 29 * UFDate.DAY_IN_MILLISECONDS)),
          now
        );
      case DateSelectionType.ThisWeek:
        return new DateRange(
          DateTools.getStartOfWeek(now),
          now
        );
      case DateSelectionType.LastWeek:
        const lastWeek = DateTools.getPreviousWeek(now);
        return new DateRange(
          DateTools.getStartOfWeek(lastWeek),
          DateTools.getEndOfDay(DateTools.getEndOfWeek(lastWeek))
        );
      case DateSelectionType.Custom:
        return new DateRange(
          DateTools.getStartOfDay(new Date(aCustomStart)),
          DateTools.getEndOfDay(new Date(aCustomEnd))
        );
      default:
        throw new Error(`Unknown dateSelection type ${aSelection}`);
    }
  }

  /**
   * Gets the date range to load both entries for the current and previous date ranges.
   */
  static createForCurrentAndPrevious(): DateRange {
    const now = new Date();
    return new DateRange(
      new Date(now.getTime() - Config.dashboardDays * 2 * UFDate.DAY_IN_MILLISECONDS),
      now
    );
  }

  /**
   * Gets the date range to load the data for the current information.
   */
  static createForCurrent(): DateRange {
    const now = new Date();
    return new DateRange(
      new Date(now.getTime() - (Config.dashboardDays - 1) * UFDate.DAY_IN_MILLISECONDS),
      now
    );
  }

  /**
   * Gets the date range to load the data for the previous information.
   */
  static createForPrevious(): DateRange {
    const now = new Date();
    return new DateRange(
      new Date(now.getTime() - (Config.dashboardDays * 2 - 1) * UFDate.DAY_IN_MILLISECONDS),
      new Date(now.getTime() - Config.dashboardDays * UFDate.DAY_IN_MILLISECONDS),
    );
  }

  /**
   * Gets the date range to load data for. Assumes the biggest range, that is the start of the starting year till the
   * ending of the ending year.
   *
   * @param aRange
   */
  static createForLoading(aRange: DateRange): DateRange {
    return new DateRange(
      new Date(aRange.start.getFullYear(), 0, 1, 0, 0, 0, 0),
      new Date(aRange.end.getFullYear(), 11, 31, 23, 59, 59, 999)
    );
  }

  // endregion

  // region public properties

  /**
   * Get the date part of the {@link start} as 'YYYY-[M]M-[D]D'.
   */
  get startAsCompactText(): string {
    return DateTools.formatDateAsCompactText(this.m_start);
  }

  /**
   * Get the date part of the {@link end} as 'YYYY-[M]M-[D]D'.
   */
  get endAsCompactText(): string {
    return DateTools.formatDateAsCompactText(this.m_end);
  }

  /**
   * The end date
   */
  get end(): Date {
    return this.m_end;
  }

  /**
   * The start date
   */
  get start(): Date {
    return this.m_start;
  }

  /**
   * True if the date part is equal
   */
  get sameDay(): boolean {
    return this.startAsCompactText === this.endAsCompactText;
  }

  // endregion
}

// endregion