import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";
import {BulletListType} from "./bullet-list/BulletListType";
import React from "react";

interface BulletListProps extends UFChildrenProp {
  readonly type?: BulletListType;
}

export const BulletList: React.FC<BulletListProps> = ({children, type = BulletListType.Unordered}) => {
  const className = "pl-6"
  switch(type) {
    case BulletListType.Ordered:
      return <ol className={className}>{children}</ol>;
    case BulletListType.Unordered:
      return <ul className={className}>{children}</ul>;
  }
}
