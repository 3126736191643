import React from "react";
import {useAppSelector} from "../../../store/store";
import {GraphColor} from "../../../types/enums/GraphColor";
import {NumberType} from "../../../types/enums/NumberType";
import {HistoryPanel} from "../../panels/HistoryPanel";
import {Text} from "../../styled/texts/Text";
import {SubscriptionsAndRecurringsTools} from "../../../tools/data/SubscriptionsAndRecurringsTools";
import {TextPlacement} from "../../styled/texts/text/TextPlacement";

export const NewSubscriptionsCountHistory: React.FC = () => {
  const {historyEntries, historyRange, loading} = useAppSelector(state => state.subscriptionsAndRecurrings);
  const tools = new SubscriptionsAndRecurringsTools();
  return (
    <HistoryPanel
      entries={historyEntries}
      loading={loading || !historyRange}
      tools={tools}
      providers={[
        {
          color: GraphColor.Success,
          name: 'New subscriptions',
          type: NumberType.Integer,
          getValue: entry => tools.totalSubscriptionCount(entry)
        },
        {
          color: GraphColor.Failure,
          name: 'Cancellations',
          type: NumberType.Integer,
          getValue: entry => entry.cancellations
        },
        {
          color: GraphColor.None,
          name: 'Difference',
          type: NumberType.Integer,
          getValue: entry => tools.totalSubscriptionCount(entry) - entry.cancellations
        }
      ]}
      bottom={
        <Text placement={TextPlacement.Paragraph}>
          The subscription count is the number of successful subscription transactions within the period.<br/>
          The cancellation count is the number of subscriptions that have a <em>cancelled_on</em> within the period.
        </Text>
      }
    />
  );
}
