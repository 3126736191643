/**
 * Font size of the text
 */
export enum TextSize {
  Inherited,
  VerySmall,
  Small,
  Normal,
  Large,
  VeryLarge
}