import React, {useEffect, useState} from "react";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";
import {ReactComponent as ChevronRight} from "../../../resources/icons/chevron-right.svg";

interface MenuButtonProps extends UFChildrenProp {
  /**
   * Click handler. This property is ignored if {@link submenu} is used.
   */
  readonly onClick?: () => any;

  /**
   * When true, render button as div in selected view
   */
  readonly selected?: boolean;

  /**
   * When set show a right chevron at the end. On clicks show the content on the right.
   */
  readonly submenu?: React.ReactNode;
}

/**
 * A general usable menu button component.
 */
export const MenuButton: React.FC<MenuButtonProps> = (
  {
    children,
    onClick= null,
    selected = false,
    submenu = null
  }
) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  useEffect(() => {
    document.body.addEventListener('click', closeSubmenu );

    return function cleanup() {
      window.removeEventListener('click', closeSubmenu );
    }
  },[]);
  const closeSubmenu = () => {
    setShowSubmenu(false);
  }
  let className = 'relative inline-flex flex-row items-center tracking-wide focus:outline-none transition ease-in-out duration-150 gap-1 relative w-full';
  className += ' py-1 rounded-md gap-1 bg-white no-underline whitespace-nowrap';
  if (selected || showSubmenu) {
    className += ' text-gray-900 font-bold';
    return (
      <div
        className={className}
      >
        {children}
        {
          submenu &&
          <div className="absolute top-2.5 right-0 w-4 h-4">
            <ChevronRight/>
          </div>
        }
        {
          submenu &&
          <div className="absolute top-0 right-0 z-40">
            <div className="absolute border border-gray-300 bg-white px-4 pb-1 rounded shadow font-normal">
              {submenu}
            </div>
          </div>
        }
      </div>
    );
  }
  else {
    className += ' bg-white text-gray-800 no-underline hover:underline focus:ring ring-gray-300';
    return (
      <button
        className={className}
        onClick={(event) => {
          if (submenu) {
            setShowSubmenu(!showSubmenu);
            event.stopPropagation();
          }
          else if (onClick) {
            onClick();
          }
        }}
      >
        {children}
        {
          submenu &&
          <div className="absolute top-2.5 right-0 w-4 h-4">
            <ChevronRight/>
          </div>
        }
      </button>
    );
  }
};
