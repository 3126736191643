// region imports

import {RadioButtonsBar} from "../styled/buttons/RadioButtonsBar";
import {DataPeriodType} from "../../types/enums/DataPeriodType";
import React from "react";
import {useAppSelector} from "../../store/store";
import {mainController} from "../../controllers/mainController";

// endregion

// region local

/**
 * Maps enum to string
 */
const s_dataPeriods = new Map<DataPeriodType, string>([
  [DataPeriodType.Day, 'Day'],
  [DataPeriodType.Week, 'Week'],
  [DataPeriodType.Month, 'Month'],
  [DataPeriodType.Year, 'Year'],
]);

// endregion

// region exports

/**
 * Shows buttons to select a certain data period.
 */
export const DataPeriodButtons: React.FC = () => {
  const current = useAppSelector(state => state.application.dataPeriod);
  return (
    <RadioButtonsBar
      onChange={(value) => mainController.setDataPeriod(value)}
      values={s_dataPeriods}
      value={current}
    />
  );
}

// endregion