import {UserLocation} from "../../types/enums/UserLocation";
import {StartPage} from "../pages/StartPage";
import {LoginPage} from "../pages/LoginPage";
import {MainPage} from "../pages/MainPage";
import React from "react";
import {useAppSelector} from "../../store/store";

export const Pages: React.FC = () => {
  const location = useAppSelector(state => state.application.location);
  switch(location) {
    case UserLocation.Start:
      return <StartPage />;
    case UserLocation.Login:
      return <LoginPage />;
    default:
      return <MainPage />;
  }
}