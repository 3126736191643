// region imports

import React from "react";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";
import {TextColor} from "./text/TextColor";
import {TextSize} from "./text/TextSize";
import {TextPlacement} from "./text/TextPlacement";
import {TextWeight} from "./text/TextWeight";
import {TextAlign} from "../../../types/enums/TextAlign";
import {GraphColorTools} from "../../../tools/GraphColorTools";
import {NumberDifferenceType} from "../../../types/enums/NumberDifferenceType";
import {Config} from "../../../Config";

// endregion

// region local

/**
 * Properties for text block
 */
interface TextProps extends UFChildrenProp {
  /**
   * Color for text
   */
  readonly color?: TextColor;

  /**
   * Size of text
   */
  readonly size?: TextSize;

  /**
   * Placement of text
   */
  readonly placement?: TextPlacement;

  /**
   * True to use a monospaced font (default false)
   */
  readonly monospace?: boolean;

  /**
   * Background color class name
   */
  readonly backgroundColor?: string;

  /**
   * True to add full width styling
   */
  readonly fullWidth?: boolean;

  /**
   * True to use additional line height.
   */
  readonly lineHeight?: boolean;

  /**
   * When true show font with bold.
   */
  readonly weight?: TextWeight;

  /**
   * Alignment
   */
  readonly align?: TextAlign;
}

// endregion

// region exports

/**
 * General purpose text.
 */
export const Text: React.FC<TextProps> = (
  {
    color = TextColor.Normal,
    monospace = false,
    backgroundColor = '',
    size = TextSize.Inherited,
    placement = TextPlacement.Inline,
    fullWidth = false,
    lineHeight = false,
    weight = TextWeight.Normal,
    children,
    align = TextAlign.Left
  }: TextProps
) => {
  let className = '';
  switch (color) {
    case TextColor.Failure:
      className += ' ' + GraphColorTools.getDifferenceTextColor(NumberDifferenceType.Danger);
      break;
    case TextColor.Success:
      className += ' ' + GraphColorTools.getDifferenceTextColor(NumberDifferenceType.Positive);
      break;
    case TextColor.Warning:
      className += ' ' + GraphColorTools.getDifferenceTextColor(NumberDifferenceType.Warning);
      break;
    case TextColor.Inverse:
      className += ' text-white';
      break;
    case TextColor.Light:
      className += ' ' + Config.theme.grayTextColor;
      break;
  }
  switch (size) {
    case TextSize.Small:
      className += ' text-[0.87em]';
      break;
    case TextSize.VerySmall:
      className += ' text-xs';
      break;
    case TextSize.Large:
      className += ' text-lg';
      break;
    case TextSize.VeryLarge:
      className += ' text-2xl';
      break;
    case TextSize.Normal:
      className += ' text-base';
      break;
  }
  switch(weight) {
    case TextWeight.Light:
      className += ' font-light';
      break;
    case TextWeight.Medium:
      className += ' font-medium';
      break;
    case TextWeight.SemiBold:
      className += ' font-semibold';
      break;
    case TextWeight.Bold:
      className += ' font-bold';
      break;
    case TextWeight.Normal:
      className += ' font-normal';
      break;
  }
  switch(align) {
    case TextAlign.Center:
      className += ' text-center';
      break;
    case TextAlign.Right:
      className += ' text-right';
      break;
  }
  if (!lineHeight && (placement !== TextPlacement.Paragraph)) {
    className += ' leading-none';
  }
  if (monospace) {
    className += ' font-mono';
  }
  if (backgroundColor) {
    className += ' ' + backgroundColor;
  }
  if (fullWidth) {
    className += ' w-full';
  }
  switch (placement) {
    case TextPlacement.Inline:
      return (
        <span className={className}>
          {children}
        </span>
      );
    case TextPlacement.Block:
      return (
        <div className={className}>
          {children}
        </div>
      );
    case TextPlacement.Paragraph:
      return (
        <p className={className + ' mb-4'}>
          {children}
        </p>
      );
  }
}

// endregion