import React from "react";
import {useAppSelector} from "../../../store/store";
import {NumberType} from "../../../types/enums/NumberType";
import {UserLocation} from "../../../types/enums/UserLocation";
import {mainController} from "../../../controllers/mainController";
import {LastAndPreviousDaysDashboardPanel} from "../../panels/LastAndPreviousDaysDashboardPanel";
import {CurrentAndPreviousValue} from "../../../types/classes/CurrentAndPreviousValue";

// status that indicate a chargeback
const CHARGEBACK_STATUS = [410, 420];

export const TransactionFailsDashboard: React.FC = () => {
  const {
    loading, initializedDashboard, dashboardCurrent, dashboardPrevious
  } = useAppSelector(state => state.transactionFails);
  const current = dashboardCurrent.entries.find(entry => CHARGEBACK_STATUS.includes(entry.status))?.count || 0;
  const previous = dashboardPrevious.entries.find(entry => CHARGEBACK_STATUS.includes(entry.status))?.count || 0;
  const currentAndPrevious = new CurrentAndPreviousValue(
    {value: current}, {value: previous}, NumberType.Integer, true
  );
  return (
    <LastAndPreviousDaysDashboardPanel
      title="Transaction chargebacks"
      currentAndPrevious={currentAndPrevious}
      loading={loading || !initializedDashboard}
      onClick={() => mainController.setUserLocation(UserLocation.TransactionFails)}
    />
  );
}