import React from "react";
import {CenteredContentPage} from "../styled/pages/CenteredContentPage";
import {Stack} from "../styled/layouts/Stack";
import {Title} from "../styled/texts/Title";
import {BusyAnimation} from "../styled/animations/BusyAnimation";
import {StackDirection} from "../styled/layouts/stack/StackDirection";
import {StackAlignItems} from "../styled/layouts/stack/StackAlignItems";
import {AutoSizePaperContainer} from "../styled/containers/AutoSizePaperContainer";

export const StartPage: React.FC = () => {
  return (
    <CenteredContentPage>
      <AutoSizePaperContainer>
        <Stack
          direction={StackDirection.Column}
          gap={5}
          alignItems={StackAlignItems.Center}
        >
          <Title>Start...</Title>
          <BusyAnimation size="60px" />
        </Stack>
      </AutoSizePaperContainer>
    </CenteredContentPage>
  );
}
