// region imports

import {DateAndWeekAndValueEntry} from "../../types/interfaces/data/DateAndWeekAndValueEntry";
import {StoreTools} from "../../tools/StoreTools";

// endregion

// region exports

/**
 * Coins related values.
 */
export const coinsSlice = StoreTools.createDataListForDateRangeSlice<DateAndWeekAndValueEntry>('coins');

/**
 * The actions for the slice
 */
export const {
  initializeDashboard,
  setHistoryRange,
  setLoading,
  setDashboard,
  setHistoryEntries,
  resetDashboard,
} = coinsSlice.actions;

// endregion