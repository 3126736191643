// region imports

import {DateRange} from "../../../types/classes/DateRange";
import {DateAndWeekAndValueResponseIO} from "../../../types/interfaces/data/io/DateAndWeekAndValueResponseIO";
import {DateAndWeekAndValueEntry} from "../../../types/interfaces/data/DateAndWeekAndValueEntry";
import {LoadDateRangeWithCallbackAction} from "../LoadDateRangeWithCallbackAction";

// endregion

// region exports

/**
 * Loads the transaction results, grouped per day for a certain date range and data target.
 */
export class CoinsLoadAction
  extends LoadDateRangeWithCallbackAction<DateAndWeekAndValueResponseIO, DateAndWeekAndValueEntry[]>
{
  // region public methods

  /**
   * Constructs an instance.
   *
   * @protected
   */
  public constructor(aDateRange: DateRange, aCallback: (entries: DateAndWeekAndValueEntry[]) => any) {
    super('/transaction/coins', aDateRange, aCallback);
  }

  // endregion

  // region protected methods

  /**
   * @inheritDoc
   */
  protected process(aResponse: DateAndWeekAndValueResponseIO): Promise<void> {
    this.callback(this.convertDateWithWeekAndValueEntriesFromIO(aResponse.entries));
    return Promise.resolve();
  }

  // endregion
}

// endregion