// region imports

import {NumberTypeTools} from "../../tools/NumberTypeTools";
import {NumberType} from "../enums/NumberType";
import {NumberDifferenceType} from "../enums/NumberDifferenceType";
import {TextColor} from "../../components/styled/texts/text/TextColor";
import {ValueField} from "../interfaces/data/fields/ValueField";

// endregion

// region exports

/**
 * {@link CurrentAndPreviousValue} stores a current and previous value and has functions to show the difference
 * as percentage.
 */
export class CurrentAndPreviousValue {
  // region private variables

  /**
   * See {@link current}
   *
   * @private
   */
  private readonly m_current: ValueField;

  /**
   * See {@link previous}
   *
   * @private
   */
  private readonly m_previous: ValueField;

  /**
   * How to format the values
   *
   * @private
   */
  private readonly m_valueType: NumberType;

  /**
   * When true swap positive and negative value interpretation.
   *
   * @private
   */
  private readonly m_reverse: boolean;

  // endregion

  // region public methods

  /**
   * Constructs an instance of {@link CurrentAndPreviousValue}
   *
   * @param aCurrent
   *   Current value
   * @param aPrevious
   *   Previous value
   * @param aType
   *   Used to create text versions
   * @param aReverse
   *   True to interpret decline as positive and incline as negative
   */
  constructor(aCurrent: ValueField, aPrevious: ValueField, aType: NumberType, aReverse: boolean = false) {
    this.m_current = aCurrent;
    this.m_previous = aPrevious;
    this.m_valueType = aType;
    this.m_reverse = aReverse;
  }

  // endregion

  // region public properties

  /**
   * Gets the current data
   */
  get current(): ValueField {
    return this.m_current;
  }

  /**
   * Gets the previous data
   */
  get previous(): ValueField {
    return this.m_previous;
  }

  /**
   * Gets the numeric value for current
   */
  get currentAsNumber(): number {
    return this.m_current.value;
  }

  /**
   * Gets the numeric value for previous
   */
  get previousAsNumber(): number {
    return this.m_previous.value;
  }

  /**
   * Formatted textual version of the current value
   */
  get currentAsText(): string {
    return NumberTypeTools.getAsText(this.currentAsNumber, this.m_valueType);
  }

  /**
   * Formatted textual version of the previous value
   */
  get previousAsText(): string {
    return NumberTypeTools.getAsText(this.previousAsNumber, this.m_valueType);
  }

  /**
   * Formatted textual version of the difference
   */
  get differenceAsText(): string {
    return NumberTypeTools.getAsText(this.currentAsNumber - this.previousAsNumber, this.m_valueType, true);
  }

  /**
   * Formatted textual version of the percentage
   */
  get percentageAsText(): string {
    if (this.previousAsNumber === 0) {
      return '-';
    }
    const percentage = this.calcPreviousPercentage();
    return (percentage > 0 ? '+' : '') + this.calcPreviousPercentage().toFixed(1) + '%';
  }

  /**
   * True if the current value is larger or equal than the previous value
   */
  get upwards(): boolean {
    return this.currentAsNumber >= this.previousAsNumber;
  }

  /**
   * True if the current value is less or equal than the previous value
   */
  get downwards(): boolean {
    return this.currentAsNumber <= this.previousAsNumber;
  }

  /**
   * Gets the number difference type based on the current and previous value.
   */
  get differenceType(): NumberDifferenceType {
    if (this.m_reverse && this.downwards) {
      return NumberDifferenceType.Positive;
    }
    if (!this.m_reverse && this.upwards) {
      return NumberDifferenceType.Positive;
    }
    const percentage = Math.abs(this.calcPreviousPercentage());
    return percentage > 5 ? NumberDifferenceType.Danger : NumberDifferenceType.Warning;
  }

  /**
   * Gets the text color based on {@link differenceType}
   */
  get textColor(): TextColor {
    switch (this.differenceType) {
      case NumberDifferenceType.Positive:
        return TextColor.Success;
      case NumberDifferenceType.Warning:
        return TextColor.Warning;
      case NumberDifferenceType.Danger:
        return TextColor.Failure;
      default:
        return TextColor.Normal;
    }
  }

  /**
   * Type of the values.
   */
  get valueType(): NumberType {
    return this.m_valueType;
  }

  // endregion

  // region private methods

  /**
   * Calculate the percentage change, using the previous value as 100%. If the previous value is 0, the method
   * will return 100 (for 100%).
   *
   * @private
   */
  private calcPreviousPercentage(): number {
    return this.previousAsNumber !== 0
      ? (100 * (this.currentAsNumber - this.previousAsNumber) / this.previousAsNumber)
      : 100;
  }

  // endregion
}

// endregion