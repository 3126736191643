// region imports

import React from "react";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";
import {StackDirection} from "./stack/StackDirection";
import {StackAlignItems} from "./stack/StackAlignItems";
import {StackJustifyContent} from "./stack/StackJustifyContent";
import {StackAlignContent} from "./stack/StackAlignContent";

// endregion

// region local

/**
 * Need to define used tailwind styles, they can not be created dynamically.
 */
const gaps = [
  '', ' gap-1', ' gap-2', ' gap-3', ' gap-4', ' gap-5', ' gap-6', ' gap-7', ' gap-8', ' gap-9',
  ' gap-10', ' gap-11', ' gap-12', ' gap-13', ' gap-14', ' gap-15', ' gap-16', ' gap-17', ' gap-18', ' gap-19'
];

/**
 * Properties for the component.
 */
interface StackProperties extends UFChildrenProp {
  /**
   * Direction to stack children
   */
  readonly direction?: StackDirection;

  /**
   * Gap index (0..19)
   */
  readonly gap?: number;

  /**
   * Align the children in the other direction within the available space (per row or column)
   */
  readonly alignItems?: StackAlignItems;

  /**
   * Align the children in the same diraction within the available space
   */
  readonly alignContent?: StackAlignContent;

  /**
   * How to distribute the children within the row or column.
   */
  readonly justifyContent?: StackJustifyContent;

  /**
   * When true set width to full width
   */
  readonly fullWidth?: boolean;

  /**
   * When true set height to full height
   */
  readonly fullHeight?: boolean;

  /**
   * When true wrap around elements
   */
  readonly wrap?: boolean;
}

// endregion

// region exports

/**
 * Stacks children horizontal or vertically.
 */
export const Stack: React.FC<StackProperties> = (
  {
    children,
    direction = StackDirection.Column,
    gap = 0,
    alignItems = StackAlignItems.Start,
    alignContent= StackAlignContent.Normal,
    fullHeight = false,
    fullWidth = false,
    justifyContent= StackJustifyContent.Start,
    wrap= false
  }
) => {
  let className='flex';
  switch(direction) {
    case StackDirection.Row:
      className += ' flex-row';
      break;
    default:
      className += ' flex-col';
      break;
  }
  if (gap > 0) {
    className += gaps[Math.min(gap, 19)];
  }
  switch(alignItems) {
    case StackAlignItems.Center:
      className += ' items-center';
      break;
    case StackAlignItems.End:
      className += ' items-end';
      break;
    case StackAlignItems.Stretch:
      className += ' items-stretch';
      break;
    case StackAlignItems.Base:
      className += ' items-base';
      break;
    default:
      className += ' items-start';
      break;
  }
  switch(alignContent) {
    case StackAlignContent.Start:
      className += ' content-start';
      break;
    case StackAlignContent.End:
      className += ' content-end';
      break;
    case StackAlignContent.Center:
      className += ' content-center';
      break;
    case StackAlignContent.SpaceAround:
      className += ' content-around';
      break;
    case StackAlignContent.SpaceBetween:
      className += ' content-between';
      break;
    case StackAlignContent.SpaceEvenly:
      className += ' content-evenly';
      break;
    case StackAlignContent.Stretch:
      className += ' content-stretch';
      break;
  }
  switch (justifyContent) {
    case StackJustifyContent.End:
      className += ' flex-end';
      break;
    case StackJustifyContent.Center:
      className += ' flex-center';
      break;
    case StackJustifyContent.SpaceBetween:
      className += ' flex-between';
      break;
    case StackJustifyContent.SpaceAround:
      className += ' flex-around';
      break;
    case StackJustifyContent.SpaceEvenly:
      className += ' flex-evenly';
      break;
    default:
      className += ' flex-start';
      break;
  }
  if (fullWidth) {
    className += ' w-full';
  }
  if (fullHeight) {
    className += ' h-full';
  }
  if (wrap) {
    className += ' flex-wrap';
  }
  return (
    <div className={className}>
      {children}
    </div>
  );
};

// endregion