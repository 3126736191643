// region imports

import {DateRange} from "../../../types/classes/DateRange";
import {LoadDateRangeWithCallbackAction} from "../LoadDateRangeWithCallbackAction";
import {BankBookingsEntry} from "../../../types/interfaces/data/BankBookingsEntry";
import {BankBookingsResponseIO} from "../../../types/interfaces/data/io/BankBookingsResponseIO";
import {BankBookingsEntryIO} from "../../../types/interfaces/data/io/BankBookingsEntryIO";

// endregion

// region exports

/**
 * Loads the transaction results, grouped per day for a certain date range and data target.
 */
export class BankBookingsLoadAction
  extends LoadDateRangeWithCallbackAction<BankBookingsResponseIO, BankBookingsEntry[]> {
  // region public methods

  /**
   * Constructs an instance.
   *
   * @protected
   */
  public constructor(aDateRange: DateRange, aCallback: (entries: BankBookingsEntry[]) => any) {
    super('/transaction/bank-bookings', aDateRange, aCallback);
  }

  // endregion

  // region protected methods

  /**
   * @inheritDoc
   */
  protected process(aResponse: BankBookingsResponseIO): Promise<void> {
    this.callback(this.convertEntriesFromIO(aResponse.entries));
    return Promise.resolve();
  }

  // endregion

  // region private methods

  /**
   * Converts the loaded entries, grouping them per plan.
   *
   * @private
   */
  private convertEntryFromIO(anEntry: BankBookingsEntryIO): BankBookingsEntry {
    return {
      date: new Date(anEntry.date),
      week: anEntry.week,
      incomingPayments: anEntry.incoming_payments || 0,
      incomingCount: anEntry.incoming_count || 0,
      outgoingPayments: anEntry.outgoing_payments || 0,
      outgoingCount: anEntry.outgoing_count || 0
    };
  }

  /**
   * Converts the loaded entries, grouping them per plan.
   *
   * @private
   */
  private convertEntriesFromIO(anEntries: BankBookingsEntryIO[]): BankBookingsEntry[] {
    return anEntries.map(entry => this.convertEntryFromIO(entry));
  }

  // endregion
}

// endregion