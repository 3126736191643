// region imports

import {DateRange} from "../../../types/classes/DateRange";
import {LoadDateRangeWithCallbackAction} from "../LoadDateRangeWithCallbackAction";
import {LegacyResponseIO} from "../../../types/interfaces/data/io/LegacyResponseIO";
import {LegacyEntry} from "../../../types/interfaces/data/LegacyEntry";
import {LegacyEntryIO} from "../../../types/interfaces/data/io/LegacyEntryIO";

// endregion

// region exports

/**
 * Loads the transaction results, grouped per day for a certain date range and data target.
 */
export class LegacyLoadAction
  extends LoadDateRangeWithCallbackAction<LegacyResponseIO, LegacyEntry[]> {
  // region public methods

  /**
   * Constructs an instance.
   *
   * @protected
   */
  public constructor(aDateRange: DateRange, aCallback: (entries: LegacyEntry[]) => any) {
    super('/transaction/legacy', aDateRange, aCallback);
  }

  // endregion

  // region protected methods

  /**
   * @inheritDoc
   */
  protected process(aResponse: LegacyResponseIO): Promise<void> {
    this.callback(this.convertEntriesFromIO(aResponse.entries));
    return Promise.resolve();
  }

  // endregion

  // region private methods

  /**
   * Converts the loaded entries, grouping them per plan.
   *
   * @private
   */
  private convertEntryFromIO(anEntry: LegacyEntryIO): LegacyEntry {
    return {
      date: new Date(anEntry.date),
      week: anEntry.week,
      payments: anEntry.payments.map(payment => ({
        website: payment.website,
        bank: payment.bank,
        amount: payment.recurring_amount || 0,
        count: payment.recurring_count || 0
      })),
      cancellations: anEntry.cancellations || 0
    };
  }

  /**
   * Converts the loaded entries, grouping them per plan.
   *
   * @private
   */
  private convertEntriesFromIO(anEntries: LegacyEntryIO[]): LegacyEntry[] {
    return anEntries.map(entry => this.convertEntryFromIO(entry));
  }

  // endregion
}

// endregion