// region imports

import {VisitorInfo} from "../../types/interfaces/data/VisitorInfo";
import {StoreTools} from "../../tools/StoreTools";

// endregion

// region exports

export const visitorInfoSlice = StoreTools.createSingleDataForDateRangePerDomainSlice<VisitorInfo>(
  'visitor-info',
  {
    countryEntries: [],
    languageEntries: [],
    mobileEntries: [],
    osEntries: [],
    resolutionEntries: []
  }
);

/**
 * The actions for the slice
 */
export const {
  initializeDashboard,
  setLoading,
  setDashboardCurrent,
  setDashboardPrevious,
  setHistoryRange,
  setHistoryCurrent,
  setHistoryPrevious,
  resetDashboard,
} = visitorInfoSlice.actions;

// endregion