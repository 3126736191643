import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";
import React from "react";

interface DialogButtonsProps extends UFChildrenProp {
}

export const DialogButtons: React.FC<DialogButtonsProps> = ({children}) => (
  <div className="px-4 py-4 bg-gray-100 border-t border-t-gray-200 flex flex-row gap-1 w-full">
    {children}
  </div>
);