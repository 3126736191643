/**
 * How to align the items in the stack direction.
 */
export enum StackJustifyContent {
  Start,
  End,
  Center,
  SpaceBetween,
  SpaceAround,
  SpaceEvenly
}