// region imports

import React from "react";
import {Stack} from "../styled/layouts/Stack";
import {useAppSelector} from "../../store/store";
import {Filler} from "../styled/layouts/Filler";
import {DataPeriodButtons} from "../buttons/DataPeriodButtons";
import {SelectDateButton} from "../buttons/SelectDateButton";
import {RefreshButton} from "../buttons/RefreshButton";
import {StackDirection} from "../styled/layouts/stack/StackDirection";
import {StackAlignItems} from "../styled/layouts/stack/StackAlignItems";
import {StackJustifyContent} from "../styled/layouts/stack/StackJustifyContent";
import {UserLocation} from "../../types/enums/UserLocation";
import {TableTextSizeButtons} from "../buttons/TableTextSizeButtons";

// endregion

// region exports

/**
 * The top bar with buttons on the right
 */
export const MainTop: React.FC = () => {
  const location = useAppSelector(state => state.application.location);
  let dateSelection: boolean;
  let periodSelection: boolean;
  switch(location) {
    case UserLocation.Dashboard:
    case UserLocation.Arpu:
      dateSelection = false;
      break;
    default:
      dateSelection = true;
      break;
  }
  switch(location) {
    case UserLocation.Dashboard:
    case UserLocation.Arpu:
    case UserLocation.VisitorInfo:
    case UserLocation.TrafficInfo:
    case UserLocation.TransactionFails:
      periodSelection = false;
      break;
    default:
      periodSelection = true;
      break;
  }
  return (
    <Stack
      alignItems={StackAlignItems.Center}
      direction={StackDirection.Row}
      justifyContent={StackJustifyContent.SpaceBetween}
      fullHeight
      gap={2}
    >
      {/*
      <Title type={TitleType.H5}>
        {Tools.getLocationName(location)}
      </Title>
      */}
      <Filler/>
      {dateSelection && <SelectDateButton/>}
      {periodSelection && <DataPeriodButtons/>}
      <RefreshButton/>
      <TableTextSizeButtons />
    </Stack>
  );
};

// endregion