// region imports

import {ArpuResponseIO} from "../../../types/interfaces/data/io/ArpuResponseIO";
import {RevenueAndUsersAndWebmasterFields} from "../../../types/interfaces/data/fields/RevenueAndUsersAndWebmasterFields";
import {LoadDataAction} from "../LoadDataAction";
import {RevenueAndUsersFields} from "../../../types/interfaces/data/fields/RevenueAndUsersFields";
import {DateTools} from "../../../tools/DateTools";
import {UFText} from "../../../UF/tools/UFText";

// endregion

// region exports

/**
 * Action class to get arpu information from the server.
 */
export class ArpuLoadAction extends LoadDataAction<ArpuResponseIO> {
  // region private methods

  /**
   * Callback to call when IO was successful.
   *
   * @private
   */
  private readonly m_callback: (total: RevenueAndUsersFields, webmasters: RevenueAndUsersAndWebmasterFields[]) => any;

  /**
   * End date to use
   *
   * @private
   */
  private readonly m_endDate: Date;


  // endregion

  // region public methods

  /**
   * Constructs an instance.
   *
   * @protected
   */
  public constructor(
    anEndDate: Date, aCallback: (total: RevenueAndUsersFields, webmasters: RevenueAndUsersAndWebmasterFields[]) => any
  ) {
    super('/transaction/arpu');
    this.m_callback = aCallback;
    this.m_endDate = anEndDate;
  }

  // endregion

  // region LoadDataAction

  /**
   * @inheritDoc
   */
  protected getAction(): string {
    return UFText.joinPath(super.getAction(), DateTools.formatDateAsCompactText(this.m_endDate));
  }

  /**
   * @inheritDoc
   */
  protected process(aResponse: ArpuResponseIO): Promise<void> {
    const total = {users: aResponse.users, revenue: aResponse.revenue};
    const webmasters = aResponse.entries.map<RevenueAndUsersAndWebmasterFields>(entry => ({
      users: entry.users,
      revenue: entry.revenue,
      webmasterId: entry.webmaster_id
    }));
    this.m_callback(total, webmasters);
    return Promise.resolve();
  }

  // endregion
}

// endregion