// region imports

import {DateRange} from "../../../types/classes/DateRange";
import {SubscriptionAndRecurringEntryIO} from "../../../types/interfaces/data/io/SubscriptionAndRecurringEntryIO";
import {SubscriptionsAndRecurringsResponseIO} from "../../../types/interfaces/data/io/SubscriptionsAndRecurringsResponseIO";
import {SubscriptionAndRecurringEntry} from "../../../types/interfaces/data/SubscriptionAndRecurringEntry";
import {LoadDateRangeWithCallbackAction} from "../LoadDateRangeWithCallbackAction";

// endregion

// region exports

/**
 * Loads the transaction results, grouped per day for a certain date range and data target.
 */
export class SubscriptionsAndRecurringsLoadAction
  extends LoadDateRangeWithCallbackAction<SubscriptionsAndRecurringsResponseIO, SubscriptionAndRecurringEntry[]>
{
  // region public methods

  /**
   * Constructs an instance.
   *
   * @protected
   */
  public constructor(aDateRange: DateRange, aCallback: (entries: SubscriptionAndRecurringEntry[]) => any) {
    super('/transaction/subscriptions', aDateRange, aCallback);
  }

  // endregion

  // region protected methods

  /**
   * @inheritDoc
   */
  protected process(aResponse: SubscriptionsAndRecurringsResponseIO): Promise<void> {
    this.callback(this.convertEntriesFromIO(aResponse.entries));
    return Promise.resolve();
  }

  // endregion

  // region private methods

  /**
   * Converts the loaded entries, grouping them per plan.
   *
   * @private
   */
  private convertEntryFromIO(anEntry: SubscriptionAndRecurringEntryIO): SubscriptionAndRecurringEntry {
    const result: SubscriptionAndRecurringEntry = {
      date: new Date(anEntry.date),
      week: anEntry.week,
      recurringAmount: new Map(),
      recurringCount: new Map(),
      subscriptionAmount: new Map(),
      subscriptionCount: new Map(),
      cancellations: anEntry.cancellations || 0
    };
    if (anEntry.plans) {
      anEntry.plans.forEach(plan => {
        result.subscriptionCount.set(plan.id, plan.subscription_count || 0);
        result.subscriptionAmount.set(plan.id, plan.subscription_amount || 0);
        result.recurringCount.set(plan.id, plan.recurring_count || 0);
        result.recurringAmount.set(plan.id, plan.recurring_amount || 0);
      });
    }
    return result;
  }

  /**
   * Converts the loaded entries, grouping them per plan.
   *
   * @private
   */
  private convertEntriesFromIO(anEntries: SubscriptionAndRecurringEntryIO[]): SubscriptionAndRecurringEntry[] {
    return anEntries.map(entry => this.convertEntryFromIO(entry));
  }

  // endregion
}

// endregion