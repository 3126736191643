import {DateAndWeekFields} from "../types/interfaces/data/fields/DateAndWeekFields";
import {WeekInYear} from "../types/interfaces/data/support/WeekInYear";
import {DateTools} from "./DateTools";
import {DateAndWeekAndValueEntry} from "../types/interfaces/data/DateAndWeekAndValueEntry";
import {DateRange} from "../types/classes/DateRange";
import {DataPeriodType} from "../types/enums/DataPeriodType";
import {DateAndWeekTools} from "./data/base/DateAndWeekTools";

export class DataTools {
  // region public methods

  /**
   * A reducers that adds the {@link DateAndWeekAndValueEntry.value} values.
   *
   * @param aPrevious
   *   The reduced entry
   * @param anEntry
   *   New entry to process
   *
   * @return updated reduced entry
   */
  static dateWithValueReducer(
    aPrevious: DateAndWeekAndValueEntry, anEntry: Readonly<DateAndWeekAndValueEntry>
  ): DateAndWeekAndValueEntry {
    return {
      ...aPrevious,
      value: aPrevious.value + anEntry.value
    };
  }

  /**
   * Reduces a group of day entries for a certain period. If period is {@link DataPeriodType.Day}, the source array
   * gets filtered to only include entries that are within the range.
   *
   * @param aPeriod
   *   Period to reduce values for
   * @param aRange
   *   Start and ending dates
   * @param anEntries
   *   Entries to process
   * @param aTools
   *   Factory and reduce functions for TData
   *
   * @return a new list of data entries
   */
  static reduceForPeriod<TData extends DateAndWeekFields>(
    aPeriod: DataPeriodType,
    aRange: DateRange,
    anEntries: TData[],
    aTools: DateAndWeekTools<TData>,
  ): TData[] {
    switch (aPeriod) {
      case DataPeriodType.Day:
        return anEntries.filter(entry => (entry.date >= aRange.start) && (entry.date <= aRange.end));
      case DataPeriodType.Week:
        return this.reduceForWeek(aRange, anEntries, aTools);
      case DataPeriodType.Month:
        return this.reduceForMonth(aRange, anEntries, aTools);
      case DataPeriodType.Year:
        return this.reduceForYear(aRange, anEntries, aTools);
    }
  }

  /**
   * Reduces a list of data entries to a list of entries grouped per week.
   *
   * @param aRange
   *   Starting and ending date to determine the first and last week
   * @param anEntries
   *   Entries to process
   * @param aTools
   *   Factory and reduce functions for TData
   *
   * @return a set of data entries, one for each period. The date is set to the start of the period.
   */
  static reduceForWeek<TData extends DateAndWeekFields>(
    aRange: DateRange,
    anEntries: TData[],
    aTools: DateAndWeekTools<TData>,
  ): TData[] {
    return this.reducePerPeriod(
      aRange,
      anEntries,
      aTools,
      (date: Date) => date.getFullYear() * 100 + DateTools.getWeekNumber(date),
      (index: number) => {
        return DateTools.getDateForWeek(index % 100, Math.floor(index / 100))
      }
    );
  }

  /**
   * Reduces a list of data entries to a list of entries grouped per month.
   *
   * @param aRange
   *   Starting and ending date to determine the first and last month
   * @param anEntries
   *   Entries to process
   * @param aTools
   *   Factory and reduce functions for TData
   *
   * @return a set of data entries, one for each period. The date is set to the start of the period.
   */
  static reduceForMonth<TData extends DateAndWeekFields>(
    aRange: DateRange,
    anEntries: TData[],
    aTools: DateAndWeekTools<TData>,
  ): TData[] {
    return this.reducePerPeriod(
      aRange,
      anEntries,
      aTools,
      (date: Date) => date.getFullYear() * 12 + date.getMonth(),
      (index: number) => new Date(Math.floor(index / 12), index % 12, 1)
    );
  }

  /**
   * Reduces a list of data entries to a list of entries grouped per year.
   *
   * @param aRange
   *   Starting and ending date to determine the first and last year
   * @param anEntries
   *   Entries to process
   * @param aTools
   *   Factory and reduce functions for TData
   *
   * @return a set of data entries, one for each period. The date is set to the start of the period.
   */
  static reduceForYear<TData extends DateAndWeekFields>(
    aRange: DateRange,
    anEntries: TData[],
    aTools: DateAndWeekTools<TData>,
  ): TData[] {
    return this.reducePerPeriod(
      aRange,
      anEntries,
      aTools,
      (date: Date) => date.getFullYear(),
      (index: number) => new Date(index, 0, 1),
    );
  }

  /**
   * Reduces a list of data entries to a single entry of the current dashboard value.
   *
   * @param anEntries
   *   Entries to process
   * @param aTools
   *   Factory and reduce functions for TData
   *
   * @return the single entry or null if not valid entry could be found within anEntries
   */
  static reduceForDashboardCurrent<TData extends DateAndWeekFields>(
    anEntries: TData[],
    aTools: DateAndWeekTools<TData>
  ): TData {
    const range = DateRange.createForCurrent();
    const result = this.reducePerPeriod(
      range,
      anEntries,
      aTools,
      (date: Date) => (date >= range.start) && (date <= range.end) ? 1 : 0,
      (index: number) => range.start,
    );
    return result.length ? result[0] : aTools.default();
  }

  /**
   * Reduces a list of data entries to a single entry of the previous dashboard value.
   *
   * @param anEntries
   *   Entries to process
   * @param aTools
   *   Factory and reduce functions for TData
   *
   * @return the single entry
   */
  static reduceForDashboardPrevious<TData extends DateAndWeekFields>(
    anEntries: TData[],
    aTools: DateAndWeekTools<TData>
  ): TData {
    const range = DateRange.createForPrevious();
    const result = this.reducePerPeriod(
      range,
      anEntries,
      aTools,
      (date: Date) => (date >= range.start) && (date <= range.end) ? 1 : 0,
      (index: number) => range.start,
    );
    return result.length ? result[0] : aTools.default();
  }

  /**
   * Finds an entry for a certain year and month.
   *
   * @param aDate
   *   Date to find entry for
   * @param anEntries
   *   List of entries
   *
   * @return first entry that matches the date or null if none could be found
   */
  static findForMonth<TData extends DateAndWeekFields>(aDate: Date, anEntries: TData[]): TData | null {
    const year = aDate.getFullYear();
    const month = aDate.getMonth();
    const entry = anEntries.find(entry => (entry.date.getFullYear() === year) && (entry.date.getMonth() === month));
    return entry ?? null;
  }

  /**
   * Finds an entry for a certain year.
   *
   * @param aDate
   *   Date to find entry for
   * @param anEntries
   *   List of entries
   *
   * @return first entry that matches the date or null if none could be found
   */
  static findForYear<TData extends DateAndWeekFields>(aDate: Date, anEntries: TData[]): TData | null {
    const year = aDate.getFullYear();
    const entry = anEntries.find(entry => (entry.date.getFullYear() === year));
    return entry ?? null;
  }

  /**
   * Finds an entry for a certain year, month and day.
   *
   * @param aDate
   *   Date to find entry for
   * @param anEntries
   *   List of entries
   *
   * @return first entry that matches the date or null if none could be found
   */
  static findForDay<TData extends DateAndWeekFields>(aDate: Date, anEntries: TData[]): TData | null {
    const year = aDate.getFullYear();
    const month = aDate.getMonth();
    const day = aDate.getDate();
    const entry = anEntries.find(
      entry => (entry.date.getFullYear() === year) && (entry.date.getMonth() === month)
        && (entry.date.getDate() === day)
    );
    return entry ?? null;
  }

  /**
   * Finds an entry for a certain year, month, day and hour.
   *
   * @param aDate
   *   Date to find entry for
   * @param anEntries
   *   List of entries
   *
   * @return first entry that matches the date or null if none could be found
   */
  static findForHour<TData extends DateAndWeekFields>(aDate: Date, anEntries: TData[]): TData | null {
    const year = aDate.getUTCFullYear();
    const month = aDate.getUTCMonth();
    const day = aDate.getUTCDate();
    const hour = aDate.getUTCHours();
    const entry = anEntries.find(
      entry => (entry.date.getUTCFullYear() === year) && (entry.date.getUTCMonth() === month)
        && (entry.date.getUTCDate() === day) && (entry.date.getUTCHours() === hour)
    );
    return entry ?? null;
  }

  /**
   * Finds an entry for a certain week and year
   *
   * @param aWeekInYear
   *   Week and year to find entry for
   * @param anEntries
   *   List of entries
   *
   * @return first entry that matches the date or null if none could be found
   */
  static findForWeek<TData extends DateAndWeekFields>(aWeekInYear: WeekInYear, anEntries: TData[]): TData | null {
    const found = anEntries.find(
      entry => (entry.week === aWeekInYear.week) && (entry.date.getFullYear() === aWeekInYear.year)
    );
    return found ?? null;
  }

  // endregion

  // region private methods

  /**
   * Reduces a list of data entries to a list of entries grouped by a certain period.
   *
   * @param aRange
   *   Starting and ending date (both inclusive)
   * @param anEntries
   *   Entries to process
   * @param aTools
   *   Factory and reduce function for TData
   * @param aGetIndex
   *   A function that returns a unique number for a date; dates that are within the same period return the same number.
   * @param aGetDate
   *   A function that returns the starting date for index (the index is obtained from aGetIndex).
   *   A factory function that creates an empty version of TData.
   *
   * @return a set of data entries, one for each period. The date is set to the start of the period.
   *
   * @private
   */
  private static reducePerPeriod<TData extends DateAndWeekFields>(
    aRange: DateRange,
    anEntries: TData[],
    aTools: DateAndWeekTools<TData>,
    aGetIndex: (date: Date) => number,
    aGetDate: (index: number) => Date
  ): TData[] {
    const result: Map<number, TData> = new Map();
    const startIndex = aGetIndex(aRange.start);
    const endIndex = aGetIndex(aRange.end);
    anEntries.forEach(entry => {
      const index = aGetIndex(entry.date);
      if ((index >= startIndex) && (index <= endIndex)) {
        if (!result.has(index)) {
          const date = aGetDate(index);
          result.set(index, aTools.factory(date, DateTools.getWeekNumber(date)));
        }
        result.set(index, aTools.reduce(result.get(index)!, entry));
      }
    });
    return Array.from(result.values());
  }

  // endregion
}