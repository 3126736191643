import {DialogTitle} from "../styled/dialog/DialogTitle";
import {Dialog} from "../styled/dialog/Dialog";
import {DialogContent} from "../styled/dialog/DialogContent";
import {Filler} from "../styled/layouts/Filler";
import {Button} from "../styled/buttons/Button";
import {DialogButtons} from "../styled/dialog/DialogButtons";
import {ReactComponent as Exclamation} from "../../resources/icons/exclamation.svg";
import {useAppSelector} from "../../store/store";
import React from "react";

export const AlertDialog: React.FC = () => {
  const title = useAppSelector(state => state.alert.title);
  const content = useAppSelector(state => state.alert.content);
  const onClose = useAppSelector(state => state.alert.onClose);
  const closeCaption = useAppSelector(state => state.alert.closeCaption);
  return (
    <Dialog
      visible={true}
    >
      {
        title &&
        <DialogTitle
          icon={<Exclamation/>}
        >
          {title}
        </DialogTitle>
      }
      <DialogContent>
        {content}
      </DialogContent>
      <DialogButtons>
        <Filler/>
        <Button
          onClick={onClose}
        >
          {closeCaption}
        </Button>
      </DialogButtons>
    </Dialog>
  )
};