// region imports

import {store} from "../store/store";
import React from "react";
import {showAlertUsingPayload} from "../store/alertSlice";
import {SelectDateResult, showSelectDateUsingPayload} from "../store/selectDateSlice";
import {DateRange} from "../types/classes/DateRange";
import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {DateAndWeekFields} from "../types/interfaces/data/fields/DateAndWeekFields";
import {DateWithWeekListSliceState} from "../types/interfaces/data/slices/DateWithWeekListSliceState";
import {SingleDataForDateRangeSliceState} from "../types/interfaces/data/slices/SingleDataForDateRangeSliceState";
import {SingleDataForDateRangePerDomainSliceState} from "../types/interfaces/data/slices/SingleDataForDateRangePerDomainSliceState";
import {WebsiteDomainType} from "../types/enums/WebsiteDomainType";
import {DataPerDomain} from "../types/interfaces/data/support/DataPerDomain";
import {UFEnum} from "../UF/tools/UFEnum";
import {UFArray} from "../UF/tools/UFArray";

// endregion

// region exports

/**
 * Support methods for use with the store.
 */
export class StoreTools {
  /**
   * Gets the current date range for history views.
   */
  static getHistoryDateRange(): DateRange {
    const {dateSelection, customDateStart, customDateEnd} = store.getState().application;
    return DateRange.createFromDateSelection(dateSelection, customDateStart, customDateEnd);
  }

  /**
   * Shows an alert dialog and wait for the user to close it.
   *
   * The methods takes care of creating the payload object and unwrapping the result.
   *
   * @param aContent
   * @param aTitle
   * @param aCloseCaption
   */
  static async showAlert(
    aContent: React.ReactNode, aTitle: string = '', aCloseCaption: string = 'Close'
  ): Promise<void> {
    return await store.dispatch(showAlertUsingPayload(
      {content: aContent, title: aTitle, closeCaption: aCloseCaption}
    )).unwrap();
  }

  /**
   * Shows the date selection dialog and wait for the user to close it.
   */
  static async showSelectDate(): Promise<SelectDateResult | null> {
    return await store.dispatch(showSelectDateUsingPayload()).unwrap();
  }

  /**
   * Creates a store slice used with date with week data lists.
   *
   * It defines the follow reducer/action functions:
   * initializeDashboard
   * initializeHistory
   * setLoading
   * setDashboard
   * setHistory
   */
  static createDataListForDateRangeSlice<TEntry extends DateAndWeekFields>(aName: string) {
    const initialState: DateWithWeekListSliceState<TEntry> = {
      initializedDashboard: false,
      historyRange: undefined,
      loading: false,
      dashboardEntries: [],
      historyEntries: []
    }
    return createSlice({
      name: aName,
      initialState,
      reducers: {
        initializeDashboard(state) {
          state.initializedDashboard = true;
          state.loading = false;
        },
        setHistoryRange(state, action: PayloadAction<DateRange>) {
          state.historyRange = action.payload;
          state.loading = false;
        },
        setLoading(state, action: PayloadAction<boolean>) {
          state.loading = action.payload;
        },
        setDashboard(state, action: PayloadAction<TEntry[]>) {
          state.dashboardEntries = action.payload as Draft<TEntry[]>;
        },
        setHistoryEntries(state, action: PayloadAction<TEntry[]>) {
          state.historyEntries = action.payload as Draft<TEntry[]>;
        },
        resetDashboard(state) {
          state.initializedDashboard = false;
        }
      }
    });
  }

  /**
   * Creates a store slice used with data for a date range.
   *
   * It defines the follow reducer/action functions:
   * initializeDashboard
   * setLoading
   * setDashboardCurrent
   * setDashboardPrevious
   * setHistoryRange
   * setHistoryData
   */
  static createSingleDataForDateRangeSlice<TInfo>(aName: string, aDefault: TInfo) {
    const initialState: SingleDataForDateRangeSliceState<TInfo> = {
      initializedDashboard: false,
      loading: false,
      dashboardCurrent: aDefault,
      dashboardPrevious: aDefault,
      historyRange: undefined,
      historyData: aDefault
    }
    return createSlice({
      name: aName,
      initialState,
      reducers: {
        initializeDashboard(state) {
          state.initializedDashboard = true;
          state.loading = false;
        },
        setLoading(state, action: PayloadAction<boolean>) {
          state.loading = action.payload;
        },
        setDashboardCurrent(state, action: PayloadAction<TInfo>) {
          state.dashboardCurrent = action.payload as Draft<TInfo>;
        },
        setDashboardPrevious(state, action: PayloadAction<TInfo>) {
          state.dashboardPrevious = action.payload as Draft<TInfo>;
        },
        setHistoryRange(state, action: PayloadAction<DateRange>) {
          state.historyRange = action.payload;
          state.loading = false;
        },
        setHistoryData(state, action: PayloadAction<TInfo>) {
          state.historyData = action.payload as Draft<TInfo>;
        },
        resetDashboard(state) {
          state.initializedDashboard = false;
        }
      }
    });
  }

  /**
   * Creates a store slice used with a single data for a date range defined per website domain.
   *
   * It defines the follow reducer/action functions:
   * initializeDashboard
   * setLoading
   * setDashboardCurrent
   * setDashboardPrevious
   * setHistoryRange
   * setHistoryData
   */
  static createSingleDataForDateRangePerDomainSlice<TInfo>(aName: string, aDefault: TInfo) {
    const [, max] = UFEnum.getMinMax(WebsiteDomainType);
    const initialState: SingleDataForDateRangePerDomainSliceState<TInfo> = {
      initializedDashboard: UFArray.createFilled(false, max + 1),
      loading: false,
      dashboardCurrent: UFArray.createFilled(aDefault, max + 1),
      dashboardPrevious: UFArray.createFilled(aDefault, max + 1),
      historyRange: UFArray.createFilled(undefined, max + 1),
      historyCurrent: UFArray.createFilled(aDefault, max + 1),
      historyPrevious: UFArray.createFilled(aDefault, max + 1)
    }
    return createSlice({
      name: aName,
      initialState,
      reducers: {
        initializeDashboard(state, action: PayloadAction<WebsiteDomainType>) {
          state.initializedDashboard[action.payload] = true;
          state.loading = false;
        },
        setLoading(state, action: PayloadAction<boolean>) {
          state.loading = action.payload;
        },
        setDashboardCurrent(state, action: PayloadAction<DataPerDomain<TInfo>>) {
          state.dashboardCurrent[action.payload.domain] = action.payload.data as Draft<TInfo>;
        },
        setDashboardPrevious(state, action: PayloadAction<DataPerDomain<TInfo>>) {
          state.dashboardPrevious[action.payload.domain] = action.payload.data as Draft<TInfo>;
        },
        setHistoryRange(state, action: PayloadAction<DataPerDomain<DateRange>>) {
          state.historyRange[action.payload.domain] = action.payload.data;
          state.loading = false;
        },
        setHistoryCurrent(state, action: PayloadAction<DataPerDomain<TInfo>>) {
          state.historyCurrent[action.payload.domain] = action.payload.data as Draft<TInfo>;
        },
        setHistoryPrevious(state, action: PayloadAction<DataPerDomain<TInfo>>) {
          state.historyPrevious[action.payload.domain] = action.payload.data as Draft<TInfo>;
        },
        resetDashboard(state, action: PayloadAction<WebsiteDomainType>) {
          state.initializedDashboard[action.payload] = false;
        }
      }
    });
  }
}

// endregion