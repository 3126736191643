// region imports

import React from "react";
import {Config} from "../../../Config";
import {UFChildrenProp} from "../../../UF-react/types/UFChildrenProp";

// endregion

// region local

interface CenteredContentPageProps extends UFChildrenProp {
}

// endregion

// region exports

/**
 * A page that will show the children centered vertically and horizontally on the page.
 */
export const CenteredContentPage: React.FC<CenteredContentPageProps> = ({children}) => (
  <div className={"absolute inset-0 flex justify-center items-center " + Config.theme.backgroundColor}>
    {children}
  </div>
);

// endregion