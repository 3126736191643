// region imports

import {setNetworkBusy} from "../store/networkSlice";
import {UFFetchMethod} from "../UF-dom/types/UFFetchMethod";
import {store} from "../store/store";
import {Config} from "../Config";
import {UFNetwork} from "../UF-dom/tools/UFNetwork";
import {ServerResult} from "../types/enums/ServerResult";
import {ErrorResponseIO} from "../types/interfaces/data/io/ErrorResponseIO";
import {LoginResponseIO} from "../types/interfaces/data/io/LoginResponseIO";
import {LoginRequestIO} from "../types/interfaces/data/io/LoginRequestIO";
import {setAuthenticationToken, setFullName} from "../store/userSlice";
import {UserResponseIO} from "../types/interfaces/data/io/UserResponseIO";
import {Stack} from "../components/styled/layouts/Stack";
import {Title} from "../components/styled/texts/Title";
import {mainController} from "../controllers/mainController";
import {UFText} from "../UF/tools/UFText";
import {StoreTools} from "./StoreTools";
import {TitleType} from "../components/styled/texts/title/TitleType";
import {Text} from "../components/styled/texts/Text";
import {TextSize} from "../components/styled/texts/text/TextSize";

// endregion

// region local

/**
 * Response from an API call
 */
interface ApiResponse<TResponse> {
  /**
   * Response data or null if there was an error
   */
  response: TResponse | null,

  /**
   * Result from server
   */
  result: ServerResult
}

// noinspection JSMethodCanBeStatic
/**
 * This controller handles all interaction with the server, encapsulating the API calls.
 */
class NetworkController {
  // region public methods

  /**
   * Authenticates the credentials and store the token and name.
   *
   * @param anEmail
   * @param aPassword
   */
  async login(anEmail: string, aPassword: string): Promise<boolean> {
    const {response, result} = await this.apiPost<LoginRequestIO, LoginResponseIO>(
      '/login',
      {
        email: anEmail,
        password: aPassword
      },
      true,
      false
    );
    if (result !== ServerResult.Success) {
      return false;
    }
    // store user information
    store.dispatch(setFullName(response!.name));
    store.dispatch(setAuthenticationToken(response!.token));
    return true;
  }

  /**
   * Refreshes the user at the server.
   */
  async refreshUser(): Promise<boolean> {
    // if no token is set, the user logged out or never logged in before
    if (!store.getState().user.authenticationToken) {
      return false;
    }
    // try to get user information
    const {response, result} = await this.apiGet<UserResponseIO>('/user', true);
    if (result !== ServerResult.Success) {
      return false;
    }
    // update user information
    store.dispatch(setFullName(response!.name));
    return true;
  }

  /**
   * Performs an api call using a post method.
   *
   * @param aPath
   *   Path to api, relative to the api root.
   * @param aBodyData
   *   Data to send to the server
   * @param aReturnErrors
   *   When true return all errors or use an array of errors which should be returned. If false is used or unknown
   *   error is received, a popup is shown to ask the user to retry the IO.
   * @param anUseToken
   *   True to send the token with the request
   *
   * @return a combination of the response and the result from the server.
   */
  public async apiPost<TRequest extends FormData | object, TResponse>(
    aPath: string, aBodyData: TRequest, aReturnErrors: ServerResult[] | boolean = false, anUseToken: boolean = true
  ): Promise<ApiResponse<TResponse>> {
    return await this.api(aPath, UFFetchMethod.Post, aBodyData, aReturnErrors, anUseToken);
  }

  /**
   * Performs an api call using a get method.
   *
   * @param aPath
   *   Path to api, relative to the api root; this should include the query data.
   * @param aReturnErrors
   *   When true return all errors or use an array of errors which should be returned. If false is used or unknown
   *   error is received, a popup is shown to ask the user to retry the IO.
   * @param anUseToken
   *   True to send the token with the request
   *
   * @return a combination of the response and the result from the server.
   */
  public async apiGet<TResponse>(
    aPath: string, aReturnErrors: ServerResult[] | boolean = false, anUseToken: boolean = true
  ): Promise<ApiResponse<TResponse>> {
    return await this.api(aPath, UFFetchMethod.Get, null, aReturnErrors, anUseToken);
  }

  // endregion

  // region private methods

  /**
   * Performs an api call. The function will handle errors.
   *
   * @param aPath
   *   Path to API call (not including /api/v1, for example: '/users/authenticate')
   * @param aMethod
   *   Method to use
   * @param aBodyData
   *   When not null, include body data as JSON encoded string
   * @param aReturnErrors
   *   True to return all errors, false to handle all errors or a list of errors to return (other errors should be
   *   handled by the method)
   * @param anUseToken
   *   True (default) to add token to the request
   *
   * @returns the response
   */
  private async api<TRequest extends FormData | object | null, TResponse>(
    aPath: string,
    aMethod: UFFetchMethod,
    aBodyData: TRequest,
    aReturnErrors: ServerResult[] | boolean,
    anUseToken: boolean = true
  ): Promise<ApiResponse<TResponse>> {
    store.dispatch(setNetworkBusy(true));
    const options = this.buildFetchOptions(aMethod, aPath, aBodyData, anUseToken);
    const url = UFText.joinPath(Config.apiRoot, aPath);
    while (true) {
      let errorResponse: ErrorResponseIO;
      let code: string;
      let responseClone: Response | undefined = undefined;
      try {
        responseClone = undefined;
        const response = await fetch(url, options);
        responseClone = response.clone();
        if (response.ok) {
          store.dispatch(setNetworkBusy(false));
          return await this.processSuccess(response, aMethod, aPath);
        }
        if (response.status === 403) {
          store.dispatch(setNetworkBusy(false));
          return await this.processExpiredToken(response, aMethod, aPath);
        }
        errorResponse = await response.json();
        UFNetwork.logApiResult(response, aMethod, aPath, errorResponse);
        code = response.status + '';
        if (
          aReturnErrors === true ||
          (Array.isArray(aReturnErrors) && aReturnErrors.includes(errorResponse.error))
        ) {
          store.dispatch(setNetworkBusy(false));
          return {response: null, result: errorResponse.error};
        }
      } catch (error: any) {
        UFNetwork.logApiError(error, aMethod, aPath);
        errorResponse = {
          class: error.class,
          error: ServerResult.InternalException,
          message: error.message
        };
        code = '(none)';
      }
      await this.showNetworkError(code, errorResponse, responseClone);
    }
  }

  /**
   * Shows a message with information about the error.
   */
  private async showNetworkError(aCode: string, anErrorResponse: ErrorResponseIO, aResponse?: Response) {
    await StoreTools.showAlert(
      <Stack gap={2}>
        <Text>
          An error has occurred while communicating with the server.<br/>
          Click retry to resend the message to the server.
        </Text>
        <Title type={TitleType.H5}>
          Error information
        </Title>
        <div
          style={{
            maxWidth: '700px',
            maxHeight: '300px',
            overflow: 'auto'
          }}
        >
          <Text
            monospace
            backgroundColor="bg-gray-200"
            size={TextSize.Small}
            fullWidth
          >
            {aCode} - {anErrorResponse.error} ({anErrorResponse.class})<br/>
            {anErrorResponse.message}<br/>
            <br/>
            {
              aResponse &&
              <pre style={{width: 'fit-content', background: 'inherit'}}>
                {await aResponse.text()}
              </pre>
            }
          </Text>
        </div>
      </Stack>,
      'Network error',
      'Retry'
    );
  }

  /**
   * Process an expired token response.
   *
   * @param aResponse
   * @param aMethod
   * @param aPath
   */
  private async processExpiredToken(aResponse: Response, aMethod: UFFetchMethod, aPath: string) {
    UFNetwork.logApiResult(aResponse, aMethod, aPath);
    await StoreTools.showAlert('Your login session has expired, you need to login.', 'Session expired', 'Login');
    mainController.logout();
    return {response: null, result: ServerResult.TokenExpired};
  }

  /**
   * Processes a successful network action.
   *
   * @param aResponse
   * @param aMethod
   * @param aPath
   *
   * @returns
   */
  private async processSuccess(aResponse: Response, aMethod: UFFetchMethod, aPath: string) {
    const received = await aResponse.json();
    UFNetwork.logApiResult(aResponse, aMethod, aPath, received);
    return {response: received, result: ServerResult.Success};
  }

  /**
   * Build the options for fetch.
   *
   * @param aMethod
   * @param aPath
   * @param aBodyData
   * @param anUseToken
   *
   * @returns
   */
  private buildFetchOptions(
    aMethod: UFFetchMethod, aPath: string, aBodyData: FormData | object | null, anUseToken: boolean
  ): RequestInit {
    return anUseToken
      ? UFNetwork.buildFetchOptions(
        aMethod,
        aPath,
        aBodyData,
        headers => headers.append('Authorization', 'Bearer ' + store.getState().user.authenticationToken)
      )
      : UFNetwork.buildFetchOptions(aMethod, aPath, aBodyData);
  }

  // endregion
}

// endregion

// region exports

/**
 * Export as a singleton instance
 */
export const network = new NetworkController();

// endregion