// region imports

import React from "react";
import {GraphNumberList} from "../../../types/classes/GraphNumberList";
import {VerticalLinesAndValues} from "./line-graph/VerticalLinesAndValues";
import {Legend} from "./line-graph/Legend";
import {Graph} from "./line-graph/Graph";
import {HorizontalLabels} from "./line-graph/HorizontalLabels";
import {HorizontalLines} from "./line-graph/HorizontalLines";
import {VerticalLabels} from "./line-graph/VerticalLabels";
import "./LineGraph.css";
import {GraphColor} from "../../../types/enums/GraphColor";

// endregion

// region local

/**
 * Properties for the component.
 */
interface LineGraphProps {
  /**
   * One or more graph number list to draw.
   */
  readonly data: GraphNumberList[];

  /**
   * Labels to use at the bottom.
   */
  readonly bottomLabels: React.ReactNode[];

  /**
   * Titles to use in hover popups
   */
  readonly hoverTitles: React.ReactNode[];

  /**
   * Unit step will be used to round down and up the min and max value, so they become a multiple of the unitStep value.
   */
  readonly unitStep: number;

  /**
   * Number of additional horizontal lines to show (the top and bottom line are always shown)
   */
  readonly lines: number;

  /**
   * Extra text for legend
   */
  readonly legendExtra?: React.ReactNode;

  /**
   * index of slice to focus or -1 to focus none
   */
  readonly focus: number;

  /**
   * Callback to change focus.
   */
  readonly setFocus: (value: number) => any;
}

// endregion

// region exports

/**
 * Renders a line graph.
 */
export const LineGraph: React.FC<LineGraphProps> = (
  {
    data,
    bottomLabels,
    hoverTitles,
    unitStep,
    lines,
    legendExtra = '',
    focus,
    setFocus
  }
) => {
  // remove entries that don't have a color
  const filteredData = data.filter(list => list.color !== GraphColor.None);
  // gets information from the graph data
  let numberCount = 0;
  let maxValue = Number.MIN_VALUE;
  let minValue = Number.MAX_VALUE;
  filteredData.forEach(list => {
    numberCount = Math.max(numberCount, list.size);
    maxValue = Math.max(maxValue, list.max());
    minValue = Math.min(minValue, list.min());
  });
  // calculate values to use at the top and at the bottom
  const bottomValue = unitStep * Math.floor(minValue / unitStep);
  const topValue = unitStep * Math.ceil(maxValue / unitStep);
  return (
    <div
      className="line-graph__container h-[400px] w-full"
    >
      <VerticalLinesAndValues hoverTitles={hoverTitles} data={filteredData} count={numberCount} focus={focus} setFocus={setFocus}/>
      <Legend data={filteredData} extra={legendExtra}/>
      <VerticalLabels count={lines} topValue={topValue} bottomValue={bottomValue}/>
      <HorizontalLines count={topValue !== bottomValue ? lines : 1}/>
      <HorizontalLabels labels={bottomLabels} count={numberCount}/>
      <Graph data={filteredData} topValue={topValue} bottomValue={bottomValue} count={numberCount}/>
    </div>
  );
}

// endregion