import React from "react";
import {DialogContainer} from "../styled/dialog/DialogContainer";
import {AlertDialog} from "../dialogs/AlertDialog";
import {useAppSelector} from "../../store/store";
import {SelectDateDialog} from "../dialogs/SelectDateDialog";

export const Dialogs: React.FC = () => {
  const alertVisible = useAppSelector(state => state.alert.visible);
  const selectDateVisible = useAppSelector(state => state.selectDate.visible);
  return (
    <DialogContainer visible={alertVisible || selectDateVisible}>
      {
        alertVisible &&
        <AlertDialog />
      }
      {
        selectDateVisible &&
        <SelectDateDialog />
      }
    </DialogContainer>
  );
}