import React from "react";
import {MainLogo} from "../main/MainLogo";
import {MainGridPage} from "../styled/pages/MainGridPage";
import {MainGridLogo} from "../styled/grid/MainGridLogo";
import {MainGridLeft} from "../styled/grid/MainGridLeft";
import {MainGridTop} from "../styled/grid/MainGridTop";
import {MainGridContent} from "../styled/grid/MainGridContent";
import {MainLeft} from "../main/MainLeft";
import {MainTop} from "../main/MainTop";
import {MainContent} from "../main/MainContent";

/**
 * The main page.
 *
 * @constructor
 */
export const MainPage: React.FC = () => {
  return (
    <MainGridPage>
      <MainGridContent>
        <MainContent />
      </MainGridContent>
      <MainGridTop>
        <MainTop />
      </MainGridTop>
      <MainGridLeft>
        <MainLeft />
      </MainGridLeft>
      <MainGridLogo>
        <MainLogo />
      </MainGridLogo>
    </MainGridPage>
  );
}
