export enum UserLocation {
  Start,
  Login,
  Dashboard,
  TransactionResults,
  UserRegistrations,
  TransactionFails,
  VisitorInfo,
  TrafficInfo,
  Tickets,
  ActiveSubscriptions,
  Cancellations,
  TrialToSubscriptions,
  Coins,
  Arpu,
  SubscriptionsAndRecurrings,
  Churn,
  NewSubscriptionsCount,
  NewSubscriptionsAmount,
  LegacyBookings
}