import React from "react";
import {Button, ButtonType} from "../styled/buttons/Button";
import {mainController} from "../../controllers/mainController";
import {ReactComponent as Refresh} from "../../resources/icons/refresh.svg";

export const RefreshButton: React.FC = () => {
  return (
    <Button
      onClick={() => mainController.refreshData()}
      type={ButtonType.Secondary}
      iconAtStart={<Refresh />}
    >
      Refresh
    </Button>
  );
}