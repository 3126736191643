import {GraphNumberList} from "../../../../types/classes/GraphNumberList";
import React from "react";
import {GraphColorTools} from "../../../../tools/GraphColorTools";
import {LineStyle} from "../../../../types/enums/LineStyle";

/**
 * Styles for stroke (for lines)
 */
const strokeDashArray: Map<LineStyle, string> = new Map([
  [LineStyle.Solid, ''],
  [LineStyle.Dotted, '3,3'],
  [LineStyle.Dashed, '10,10']
]);

interface ColumnLinesProps {
  readonly data: GraphNumberList[];
  readonly topValue: number;
  readonly bottomValue: number;
  readonly column: number;
}

/**
 * Renders the lines from one point to another point in a column.
 */
export const ColumnLines: React.FC<ColumnLinesProps> = (
  {data, topValue, bottomValue, column}
) => {
  const lines: React.ReactNode[] = [];
  data.forEach((list, index) => {
    if ((column < list.start) || (column >= list.size - 1)) {
      return;
    }
    const hasLightColor = false;
    const value0 = list.get(column);
    const value1 = list.get(column + 1);
    const y0 = topValue === bottomValue ? 50 : 100 - ((value0 - bottomValue) * 100 / (topValue - bottomValue));
    const y1 = topValue === bottomValue ? 50 : 100 - ((value1 - bottomValue) * 100 / (topValue - bottomValue));
    lines.unshift(
      <svg
        className={
          'absolute w-full h-full stroke-2 '
          + GraphColorTools.getStrokeClass(list.color, hasLightColor)
        }
        key={index}
        strokeDasharray={strokeDashArray.get(list.getLineStyle(column))}
      >
        <line x1="0" x2="100%" y1={y0 + '%'} y2={y1 + '%'}/>
      </svg>
    );
  });
  return (
    <div
      className="flex-1 relative"
    >
      {lines}
    </div>
  );
}