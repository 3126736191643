// region imports

import React from "react";
import {useAppSelector} from "../../../store/store";
import {WebsiteDomainButtons} from "../../buttons/WebsiteDomainButtons";
import {NumberType} from "../../../types/enums/NumberType";
import {mainController} from "../../../controllers/mainController";
import {UserLocation} from "../../../types/enums/UserLocation";
import {CurrentAndPreviousTableDashboardPanel} from "../../panels/CurrentAndPreviousTableDashboardPanel";
import {CurrentAndPreviousRow} from "../../../types/classes/CurrentAndPreviousRow";
import {ValueAndLabelFields} from "../../../types/interfaces/data/fields/ValueAndLabelFields";
import {GraphColorTools} from "../../../tools/GraphColorTools";

// endregion

// region local

function findValue(anEntries: ValueAndLabelFields[], aLabel: string): number {
  const entry = anEntries.find(entry => entry.label === aLabel);
  return entry?.value || 0;
}

// endregion

// region exports

export const TrafficInfoDashboard: React.FC = () => {
  const {
    loading, initializedDashboard, dashboardCurrent, dashboardPrevious
  } = useAppSelector(state => state.trafficInfo);
  const domain = useAppSelector(state => state.application.websiteDomain);
  const available = !loading && initializedDashboard[domain];
  const rows = dashboardCurrent[domain].visits.map(
    (entry, index) =>  new CurrentAndPreviousRow(
      {value: entry.value},
      {value: findValue(dashboardPrevious[domain].visits, entry.label)},
      NumberType.Integer,
      entry.label,
      GraphColorTools.getOtherColor(index)
    )
  );
  return (
    <CurrentAndPreviousTableDashboardPanel
      title="Traffic info"
      rows={rows}
      loading={!available}
      onClick={() => mainController.setUserLocation(UserLocation.TrafficInfo)}
      top={
        <WebsiteDomainButtons/>
      }
    />
  );
}

// endregion