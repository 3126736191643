// region imports

import {GeneralTableData} from "./GeneralTableData";
import React from "react";
import {SubscriptionAndRecurringEntry} from "../../interfaces/data/SubscriptionAndRecurringEntry";
import {DateTools} from "../../../tools/DateTools";
import {DataTools} from "../../../tools/DataTools";
import {DateRange} from "../DateRange";
import {HeaderTitle} from "../../../components/text/HeaderTitle";
import {CurrentAndPreviousValue} from "../CurrentAndPreviousValue";
import {Text} from "../../../components/styled/texts/Text";
import {DifferenceInformation} from "../../../components/styled/texts/DifferenceInformation";
import {TextWeight} from "../../../components/styled/texts/text/TextWeight";
import {FundoTools} from "../../../tools/FundoTools";
import {Tools} from "../../../tools/Tools";
import {SubscriptionsAndRecurringsTools} from "../../../tools/data/SubscriptionsAndRecurringsTools";
import {Position} from "../../enums/Position";
import {LegacyEntry} from "../../interfaces/data/LegacyEntry";
import {DateAndWeekFields} from "../../interfaces/data/fields/DateAndWeekFields";
import {DateAndWeekTools} from "../../../tools/data/base/DateAndWeekTools";
import {LegacyTools} from "../../../tools/data/LegacyTools";
import {TextPlacement} from "../../../components/styled/texts/text/TextPlacement";
import {TextSize} from "../../../components/styled/texts/text/TextSize";
import {WebsiteAndBank} from "../../interfaces/data/WebsiteAndBank";
import {TableTextSize} from "../../enums/TableTextSize";
import {LegacyBookingPaymentType} from "../../enums/LegacyBookingPaymentType";
import {LegacyBookingsEntry} from "../../interfaces/data/LegacyBookingsEntry";
import {LegacyBookingTools} from "../../../tools/data/LegacyBookingTools";
import {BankBookingsEntry} from "../../interfaces/data/BankBookingsEntry";
import {BankBookingPaymentType} from "../../enums/BankBookingPaymentType";
import {BankBookingTools} from "../../../tools/data/BankBookingTools";

// endregion

// region local

/**
 * Number of months to show
 */
const MONTH_COUNT = 12;

/**
 * Maps a number to list of current and previous values
 */
type NumberToCurrentAndPreviousMap = Map<number, CurrentAndPreviousValue[]>;

/**
 * Maps a payment type to a list of current and previous values
 */
type LegacyBookingPaymentTypeToCurrentAndPreviousMap = Map<LegacyBookingPaymentType, CurrentAndPreviousValue[]>;

/**
 * Maps a payment type to a list of current and previous values
 */
type BankBookingPaymentTypeToCurrentAndPreviousMap = Map<BankBookingPaymentType, CurrentAndPreviousValue[]>;

/**
 * Adds a list of current and previous values to a website and bank combination.
 */
interface EntriesPerWebsiteAndBank extends WebsiteAndBank {
  readonly entries: CurrentAndPreviousValue[];
}

// endregion

// region exports

export abstract class SubscriptionsDashboardTableData extends GeneralTableData {
  // region private variable

  /**
   * All known plan ids
   *
   * @private
   */
  private readonly m_planIds: number[];

  /**
   * All known payment types.
   *
   * @private
   */
  private readonly m_legacyBookingPayments: LegacyBookingPaymentType[] = [
    LegacyBookingPaymentType.Incoming, LegacyBookingPaymentType.Outgoing
  ];

  /**
   * All known payment types.
   *
   * @private
   */
  private readonly m_bankBookingPayments: BankBookingPaymentType[] = [
    BankBookingPaymentType.Incoming, BankBookingPaymentType.Outgoing
  ];

  /**
   * Top header row labels
   *
   * @private
   */
  private readonly m_dateLabels: React.ReactNode[];

  /**
   * Maps a plan id to an array of current and previous entries (one for each date)
   *
   * @private
   */
  private readonly m_entriesPerPlanId: NumberToCurrentAndPreviousMap;

  /**
   * Entries per website and bank name (number is index into m_websiteAndBanks list).
   *
   * @private
   */
  private readonly m_entriesPerWebsiteAndBank: EntriesPerWebsiteAndBank[];

  /**
   * Entries per legacy booking payment type.
   *
   * @private
   */
  private readonly m_entriesPerLegacyBookingPayment: LegacyBookingPaymentTypeToCurrentAndPreviousMap;

  /**
   * Entries per legacy booking payment type.
   *
   * @private
   */
  private readonly m_entriesPerBankBookingPayment: BankBookingPaymentTypeToCurrentAndPreviousMap;

  /**
   * Totals per column.
   *
   * @private
   */
  private readonly m_totals: CurrentAndPreviousValue[];

  /**
   * Size of text in table
   *
   * @private
   */
  private readonly m_tableTextSize: TableTextSize;

  // endregion

  // region public methods

  constructor(
    aCurrentEntries: SubscriptionAndRecurringEntry[],
    anLegacyEntries: LegacyEntry[],
    aLegacyBookingsEntries: LegacyBookingsEntry[],
    aBankBookingsEntries: BankBookingsEntry[],
    aTableTextSize: TableTextSize
  ) {
    super();
    this.m_planIds = Tools.getPlanIds(aCurrentEntries);
    const dates = this.getDatesPerMonth();
    this.m_entriesPerPlanId = this.getPerPlanAndDate(aCurrentEntries, this.m_planIds, dates);
    this.m_entriesPerWebsiteAndBank = this.getPerWebsiteAndBankAndDate(anLegacyEntries, dates);
    this.m_entriesPerLegacyBookingPayment = this.getPerLegacyBookingPayment(
      aLegacyBookingsEntries, this.m_legacyBookingPayments, dates
    );
    this.m_entriesPerBankBookingPayment = this.getPerBankBookingPayment(
      aBankBookingsEntries, this.m_bankBookingPayments, dates
    );
    this.m_dateLabels = dates.map(date => DateTools.formatMonth(date));
    this.m_totals = this.calcTotals();
    this.m_tableTextSize = aTableTextSize;
  }

  // endregion

  // region GeneralTableData

  cell(aColumn: number, aRow: number): React.ReactNode {
    let relativeRow = aRow;
    if (relativeRow < this.m_planIds.length) {
      return this.cellForPlan(aColumn, relativeRow, aRow);
    }
    relativeRow -= this.m_planIds.length;
    if (relativeRow < this.m_entriesPerWebsiteAndBank.length) {
      return this.cellForLegacy(aColumn, relativeRow, aRow);
    }
    relativeRow -= this.m_entriesPerWebsiteAndBank.length;
    if (relativeRow < this.m_legacyBookingPayments.length) {
      return this.cellForLegacyBookings(aColumn, relativeRow, aRow);
    }
    relativeRow -= this.m_legacyBookingPayments.length;
    if (relativeRow < this.m_bankBookingPayments.length) {
      return this.cellForBankBookings(aColumn, relativeRow, aRow);
    }
    return this.cellWithTotal(aColumn);
  }

  get columnCount(): number {
    return (this.m_dateLabels.length * 2) + 1;
  }

  header(aColumn: number, aRow: number): React.ReactNode {
    if (aColumn === 0) {
      return '';
    }
    aColumn--;
    aColumn = 2 * (MONTH_COUNT - Math.floor(aColumn / 2) - 1) + (aColumn % 2);
    if (aRow === 0) {
      return <HeaderTitle>{this.m_dateLabels[Math.floor(aColumn / 2)]}</HeaderTitle>;
    }
    return <HeaderTitle>{aColumn % 2 === 0 ? 'sub.' : 'rec.'}</HeaderTitle>;
  }

  get rowCount(): number {
    return this.m_planIds.length
      + this.m_entriesPerWebsiteAndBank.length
      + this.m_legacyBookingPayments.length
      + this.m_bankBookingPayments.length
      + 1;
  }

  get headerRowCount(): number {
    return 2;
  }

  headerColSpan(aColumn: number, aRow: number): number {
    if (aColumn === 0) {
      return 1;
    }
    if (aRow === 1) {
      return 1;
    }
    return aColumn % 2 === 1 ? 2 : 1;
  }

  cellHorizontalPosition(aColumn: number, aRow: number): Position {
    return aColumn === 0 ? Position.Start : Position.End;
  }

  cellVerticalPosition(aColumn: number, aRow: number): Position {
    return Position.Start;
  }

  get hasFixedColumn(): boolean {
    return true;
  }

  get fixedColumnWidth(): number {
    return 130;
  }

  rowHeight(aRow: number): number {
    return this.m_tableTextSize === TableTextSize.Normal ? 64 : 48;
  }

  headerRowHeight(aRow: number): number {
    return 36;
  }

  // endregion

  // region protected properties

  protected get planIds(): number[] {
    return this.m_planIds;
  }

  protected get entriesPerPlanId(): NumberToCurrentAndPreviousMap {
    return this.m_entriesPerPlanId;
  }

  protected get entriesPerWebsiteAndBank(): EntriesPerWebsiteAndBank[] {
    return this.m_entriesPerWebsiteAndBank;
  }

  // endregion

  // region abstract protected methods

  /**
   * Convert subscription entries to current and previous value entries
   */
  protected abstract getAsCurrentAndPreviousForPlan(
    anEntries: SubscriptionAndRecurringEntry[], anId: number
  ): CurrentAndPreviousValue[];

  /**
   * Convert legacy entries to current and previous value entries
   */
  protected abstract getAsCurrentAndPreviousForWebsite(
    anEntries: LegacyEntry[], aWebsiteAndBank: WebsiteAndBank
  ): CurrentAndPreviousValue[];

  /**
   * Convert legacy bookings entries to current and previous value entries
   */
  protected abstract getAsCurrentAndPreviousForLegacyBooking(
    anEntries: LegacyBookingsEntry[], aType: LegacyBookingPaymentType
  ): CurrentAndPreviousValue[];

  /**
   * Convert bank bookings entries to current and previous value entries
   */
  protected abstract getAsCurrentAndPreviousForBankBooking(
    anEntries: BankBookingsEntry[], aType: BankBookingPaymentType
  ): CurrentAndPreviousValue[];

  // endregion

  // region private methods

  /**
   * Renders cells in the bottom total row.
   *
   * @param aColumn
   *
   * @private
   */
  private cellWithTotal(aColumn: number): React.ReactNode {
    if (aColumn === 0) {
      return <Text weight={TextWeight.Bold}>Total</Text>;
    }
    aColumn--;
    aColumn = 2 * (MONTH_COUNT - Math.floor(aColumn / 2) - 1) + (aColumn % 2);
    const entry = this.m_totals[aColumn];
    return aColumn < 3
      ? <Text weight={TextWeight.Bold}>{entry.currentAsText}</Text>
      : <><Text weight={TextWeight.Bold}>{entry.currentAsText}</Text><br/><DifferenceInformation data={entry}/></>
  }

  /**
   * Calculates the total value for a certain columnn.
   *
   * @param aColumn
   *
   * @private
   */
  private calcTotal(aColumn: number): number {
    return this.m_planIds.reduce(
        (previous, id) =>
          previous + this.m_entriesPerPlanId.get(id)![aColumn].currentAsNumber,
        0
      ) +
      this.m_entriesPerWebsiteAndBank.reduce(
        (previous, websiteAndBank) =>
          previous + websiteAndBank.entries[aColumn].currentAsNumber,
        0
      ) +
      this.m_legacyBookingPayments.reduce(
        (previous, type) =>
          previous + this.m_entriesPerLegacyBookingPayment.get(type)![aColumn].currentAsNumber,
        0
      ) +
      this.m_bankBookingPayments.reduce(
        (previous, type) =>
          previous + this.m_entriesPerBankBookingPayment.get(type)![aColumn].currentAsNumber,
        0
      )
      ;
  }

  /**
   * Creates the data for the total row for every column.
   *
   * @private
   */
  private calcTotals(): CurrentAndPreviousValue[] {
    const result: CurrentAndPreviousValue[] = [];
    const entries = this.m_entriesPerPlanId.get(this.m_planIds[0])!;
    const valueType = entries[0].valueType;
    let previous = 0;
    entries.forEach((value, index) => {
      const total = this.calcTotal(index);
      result.push(new CurrentAndPreviousValue({value: total}, {value: previous}, valueType));
      previous = total;
    });
    return result;
  }

  /**
   * Renders the cell for a certain plan.
   *
   * @param aColumn
   * @param aDataRow
   * @param aTableRow
   *
   * @private
   */
  private cellForPlan(aColumn: number, aDataRow: number, aTableRow: number): React.ReactNode {
    const id = this.m_planIds[aDataRow];
    if (aColumn === 0) {
      return FundoTools.getSubscriptionPlanAsNode(id);
    }
    aColumn--;
    aColumn = 2 * (MONTH_COUNT - Math.floor(aColumn / 2) - 1) + (aColumn % 2);
    const entry = this.m_entriesPerPlanId.get(id)![aColumn];
    return aColumn < 3
      ? <Text weight={TextWeight.SemiBold}>{entry.currentAsText}</Text>
      : <><Text weight={TextWeight.SemiBold}>{entry.currentAsText}</Text><br/><DifferenceInformation data={entry}/></>
  }

  /**
   * Renders the cell for a legacy entry.
   *
   * @param aColumn
   * @param aDataRow
   * @param aTableRow
   *
   * @private
   */
  private cellForLegacy(aColumn: number, aDataRow: number, aTableRow: number): React.ReactNode {
    const websiteAndBankEntry = this.m_entriesPerWebsiteAndBank[aDataRow];
    if (aColumn === 0) {
      return <Text placement={TextPlacement.Block}>
        {websiteAndBankEntry.website}<br/>
        <em>{websiteAndBankEntry.bank}</em><br/>
        <Text size={TextSize.Small}>legacy</Text>
      </Text>;
    }
    if ((aColumn % 2) === 1) {
      // no new subs
      return '';
    }
    aColumn--;
    aColumn = 2 * (MONTH_COUNT - Math.floor(aColumn / 2) - 1) + (aColumn % 2);
    const entry = websiteAndBankEntry.entries![aColumn];
    return aColumn < 3
      ? <Text weight={TextWeight.SemiBold}>{entry.currentAsText}</Text>
      : <><Text weight={TextWeight.SemiBold}>{entry.currentAsText}</Text><br/><DifferenceInformation data={entry}/></>
  }

  /**
   * Renders the cell for a bank bookings entry.
   *
   * @param aColumn
   * @param aDataRow
   * @param aTableRow
   *
   * @private
   */
  private cellForBankBookings(aColumn: number, aDataRow: number, aTableRow: number): React.ReactNode {
    const type = this.m_bankBookingPayments[aDataRow];
    if (aColumn === 0) {
      return <Text placement={TextPlacement.Block}>
        {type === BankBookingPaymentType.Incoming ? 'Incoming payments' : 'Outgoing payments'}<br/>
        <Text size={TextSize.Small}>bank bookings</Text>
      </Text>;
    }
    if ((aColumn % 2) === 1) {
      // no new subs
      return '';
    }
    aColumn--;
    aColumn = 2 * (MONTH_COUNT - Math.floor(aColumn / 2) - 1) + (aColumn % 2);
    const entry = this.m_entriesPerBankBookingPayment.get(type)![aColumn];
    return aColumn < 3
      ? <Text weight={TextWeight.SemiBold}>{entry.currentAsText}</Text>
      : <><Text weight={TextWeight.SemiBold}>{entry.currentAsText}</Text><br/><DifferenceInformation data={entry}/></>
  }

  /**
   * Renders the cell for a legacy bookings entry.
   *
   * @param aColumn
   * @param aDataRow
   * @param aTableRow
   *
   * @private
   */
  private cellForLegacyBookings(aColumn: number, aDataRow: number, aTableRow: number): React.ReactNode {
    const type = this.m_legacyBookingPayments[aDataRow];
    if (aColumn === 0) {
      return <Text placement={TextPlacement.Block}>
        {type === LegacyBookingPaymentType.Incoming ? 'Incoming payments' : 'Outgoing payments'}<br/>
        <Text size={TextSize.Small}>legacy bookings</Text>
      </Text>;
    }
    if ((aColumn % 2) === 1) {
      // no new subs
      return '';
    }
    aColumn--;
    aColumn = 2 * (MONTH_COUNT - Math.floor(aColumn / 2) - 1) + (aColumn % 2);
    const entry = this.m_entriesPerLegacyBookingPayment.get(type)![aColumn];
    return aColumn < 3
      ? <Text weight={TextWeight.SemiBold}>{entry.currentAsText}</Text>
      : <><Text weight={TextWeight.SemiBold}>{entry.currentAsText}</Text><br/><DifferenceInformation data={entry}/></>
  }

  /**
   * Fills an array with dates per month.
   */
  private getDatesPerMonth(): Date[] {
    const result: Date[] = [];
    let current = DateTools.getStartOfMonth(new Date());
    for (let index: number = MONTH_COUNT; index > 0; index--) {
      result.unshift(current);
      current = DateTools.getPreviousMonth(current);
    }
    return result;
  }

  /**
   * Reduce entries per date, making sure the resulting array maps to the entries of aDates.
   */
  private getEntriesPerDate<T extends DateAndWeekFields>(
    anEntries: T[], aDates: Date[], aTools: DateAndWeekTools<T>
  ): T[] {
    const dateRange = new DateRange(aDates.at(0)!, aDates.at(-1)!);
    const reducedEntries = DataTools.reduceForMonth(dateRange, anEntries, aTools);
    return aDates.map(
      date => DataTools.findForMonth(date, reducedEntries) || aTools.factory(date, DateTools.getWeekNumber(date))
    );
  }

  /**
   * Create current and previous value entries for each date entry per id.
   */
  private getPerPlanAndDate(
    anEntries: SubscriptionAndRecurringEntry[], anIds: number[], aDates: Date[]
  ): NumberToCurrentAndPreviousMap {
    const result: NumberToCurrentAndPreviousMap = new Map<number, CurrentAndPreviousValue[]>();
    const tools = new SubscriptionsAndRecurringsTools();
    anIds.forEach(id => {
      result.set(id, this.getAsCurrentAndPreviousForPlan(this.getEntriesPerDate(anEntries, aDates, tools), id));
    });
    return result;
  }

  /**
   * Create current and previous value entries for each date entry per id.
   */
  private getPerWebsiteAndBankAndDate(anEntries: LegacyEntry[], aDates: Date[]): EntriesPerWebsiteAndBank[] {
    const tools = new LegacyTools();
    const entriesPerDate = this.getEntriesPerDate(anEntries, aDates, tools);
    const websiteAndBanks = tools.getAllWebsiteAndBanks(entriesPerDate);
    return websiteAndBanks.map<EntriesPerWebsiteAndBank>(websiteAndBank => ({
      ...websiteAndBank,
      entries: this.getAsCurrentAndPreviousForWebsite(entriesPerDate, websiteAndBank)
    }));
  }

  /**
   * Create current and previous value entries for each date entry per payment type.
   */
  private getPerLegacyBookingPayment(
    anEntries: LegacyBookingsEntry[], aTypes: LegacyBookingPaymentType[], aDates: Date[]
  ): NumberToCurrentAndPreviousMap {
    const result: LegacyBookingPaymentTypeToCurrentAndPreviousMap = new Map();
    const tools = new LegacyBookingTools();
    aTypes.forEach(type => {
      result.set(
        type, this.getAsCurrentAndPreviousForLegacyBooking(this.getEntriesPerDate(anEntries, aDates, tools), type)
      );
    });
    return result;
  }

  /**
   * Create current and previous value entries for each date entry per payment type.
   */
  private getPerBankBookingPayment(
    anEntries: BankBookingsEntry[], aTypes: BankBookingPaymentType[], aDates: Date[]
  ): NumberToCurrentAndPreviousMap {
    const result: BankBookingPaymentTypeToCurrentAndPreviousMap = new Map();
    const tools = new BankBookingTools();
    aTypes.forEach(type => {
      result.set(
        type, this.getAsCurrentAndPreviousForBankBooking(this.getEntriesPerDate(anEntries, aDates, tools), type)
      );
    });
    return result;
  }

  // endregion
}

// endregion