// region imports

import React from "react";
import {GraphColor} from "../../enums/GraphColor";
import {Position} from "../../enums/Position";

// endregion

// region exports

/**
 * Base abstract class for data structures shown in a {@link GeneralTable}
 */
export abstract class GeneralTableData {
  // region public properties

  /**
   * Number of columns
   */
  abstract get columnCount(): number;

  /**
   * Number of rows
   */
  abstract get rowCount(): number;

  /**
   * Number of header rows.
   *
   * The default implementation returns 1
   */
  get headerRowCount(): number {
    return 1;
  }

  /**
   * When true, show the first column at fixed position and add horizontal scrolling for the other columns.
   *
   * Classes overriding this method must also override the {@link fixedColumnWidth}, {@link rowHeight} and
   * {@link headerRowHeight} methods.
   */
  get hasFixedColumn(): boolean {
    return false;
  }

  /**
   * This value only used if {@link hasFixedColumn} is true. It is the width of the first column in pixels.
   *
   * The default implementation returns 0.
   */
  get fixedColumnWidth(): number {
    return 0;
  }

  // endregion

  // region public methods

  /**
   * Gets the contents for a header cell
   */
  abstract header(aColumn: number, aRow: number): React.ReactNode;

  /**
   * Returns the number of columns a header column spans.
   *
   * The default implementation returns 1.
   */
  headerColSpan(aColumn: number, aRow: number): number {
    return 1;
  }

  /**
   * Gets the horizontal position of the content for a header cell.
   *
   * The default implementation returns {@link Position.Center}
   */
  headerHorizontalPosition(aColumn: number, aRow: number): Position {
    return Position.Center;
  }

  /**
   * Gets the vertical position of the content for a header cell.
   *
   * The default implementation returns {@link Position.Start}
   */
  headerVerticalPosition(aColumn: number, aRow: number): Position {
    return Position.Start;
  }

  /**
   * Gets if a header cell has a left border.
   *
   * The default implementation returns true.
   */
  headerLeftBorder(aColumn: number, aRow: number): boolean {
    return true;
  }

  /**
   * Gets if a header cell has a right border
   *
   * The default implementation returns true.
   */
  headerRightBorder(aColumn: number, aRow: number): boolean {
    return true;
  }

  /**
   * Gets if a header cell has a top border.
   *
   * The default implementation returns true.
   */
  headerTopBorder(aColumn: number, aRow: number): boolean {
    return true;
  }

  /**
   * Gets if a header cell has a bottom border.
   *
   * The default implementation returns true.
   */
  headerBottomBorder(aColumn: number, aRow: number): boolean {
    return true;
  }


  /**
   * Gets if a header cell has left spacing.
   *
   * The default implementation returns true.
   */
  headerLeftSpacing(aColumn: number, aRow: number): boolean {
    return true;
  }

  /**
   * Gets if a header cell has right spacing
   *
   * The default implementation returns true.
   */
  headerRightSpacing(aColumn: number, aRow: number): boolean {
    return true;
  }

  /**
   * Gets if a header cell has top spacing.
   *
   * The default implementation returns true.
   */
  headerTopSpacing(aColumn: number, aRow: number): boolean {
    return true;
  }

  /**
   * Gets if a header cell has bottom spacing.
   *
   * The default implementation returns true.
   */
  headerBottomSpacing(aColumn: number, aRow: number): boolean {
    return true;
  }

  /**
   * Returns the number of columns a cell spans.
   *
   * The default implementation returns 1.
   */
  cellColSpan(aColumn: number, aRow: number): number {
    return 1;
  }

  /**
   * Gets the contents for a data cell.
   */
  abstract cell(aColumn: number, aRow: number): React.ReactNode;

  /**
   * Gets the color to use for background.
   *
   * The default implementation returns {@link GraphColor.None}.
   */
  cellColor(aColumn: number, aRow: number): GraphColor {
    return GraphColor.None;
  }

  /**
   * Gets the horizontal position of the content for a cell.
   *
   * The default implementation returns {@link Position.End}
   */
  cellHorizontalPosition(aColumn: number, aRow: number): Position {
    return Position.End;
  }

  /**
   * Gets the vertical position of the content for a cell.
   *
   * The default implementation returns {@link Position.Center}
   */
  cellVerticalPosition(aColumn: number, aRow: number): Position {
    return Position.Center;
  }

  /**
   * Gets if a cell has a left border.
   *
   * The default implementation returns true.
   */
  cellLeftBorder(aColumn: number, aRow: number): boolean {
    return true;
  }

  /**
   * Gets if a cell has a right border
   *
   * The default implementation returns true.
   */
  cellRightBorder(aColumn: number, aRow: number): boolean {
    return true;
  }

  /**
   * Gets if a cell has a top border.
   *
   * The default implementation returns true.
   */
  cellTopBorder(aColumn: number, aRow: number): boolean {
    return true;
  }

  /**
   * Gets if a cell has a bottom border.
   *
   * The default implementation returns true.
   */
  cellBottomBorder(aColumn: number, aRow: number): boolean {
    return true;
  }

  /**
   * Gets if a cell has left spacing.
   *
   * The default implementation returns true.
   */
  cellLeftSpacing(aColumn: number, aRow: number): boolean {
    return true;
  }

  /**
   * Gets if a cell has right spacing
   *
   * The default implementation returns true.
   */
  cellRightSpacing(aColumn: number, aRow: number): boolean {
    return true;
  }

  /**
   * Gets if a cell has top spacing.
   *
   * The default implementation returns true.
   */
  cellTopSpacing(aColumn: number, aRow: number): boolean {
    return true;
  }

  /**
   * Gets if a cell has bottom spacing.
   *
   * The default implementation returns true.
   */
  cellBottomSpacing(aColumn: number, aRow: number): boolean {
    return true;
  }

  /**
   * Gets if a row is an empty row. An empty row is rendered without borders using a small height and can be used
   * to split a table in sections.
   *
   * The default implementation returns false.
   */
  isEmptyRow(aRow: number): boolean {
    return false;
  }

  /**
   * Gets if a cell in a row should use all available space.
   *
   * The default implementation returns false.
   */
  isFullWidthCell(aColumn: number, aRow: number): boolean {
    return false;
  }

  /**
   * This value only used if {@link hasFixedColumn} is true. It is the height of all columns in a row in pixels.
   *
   * The default implementation returns 0.
   */
  rowHeight(aRow: number): number {
    return 0;
  }

  /**
   * This value only used if {@link hasFixedColumn} is true. It is the height of all columns in a header row in pixels.
   *
   * The default implementation returns 0.
   */
  headerRowHeight(aRow: number): number {
    return 0;
  }

  // endregion
}

// endregion