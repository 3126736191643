// region imports

import {VisitorInfo} from "../../../types/interfaces/data/VisitorInfo";
import {VisitorInfoResponseIO} from "../../../types/interfaces/data/io/VisitorInfoResponseIO";
import {WebsiteDomainType} from "../../../types/enums/WebsiteDomainType";
import {DateRange} from "../../../types/classes/DateRange";
import {LoadDateRangeWithDomainAndCallbackAction} from "../LoadDateRangeWithDomainAndCallbackAction";
import {ValueAndKeyAndLabelFields} from "../../../types/interfaces/data/fields/ValueAndKeyAndLabelFields";

// endregion

// region exports

/**
 * Base class for all actions that get transaction results for a certain period.
 */
export class VisitorInfoLoadAction
  extends LoadDateRangeWithDomainAndCallbackAction<VisitorInfoResponseIO, VisitorInfo>
{
  // region public methods

  /**
   * Constructs an instance.
   *
   *
   * @protected
   */
  public constructor(aDomain: WebsiteDomainType, aDateRange: DateRange, aCallback: (data: VisitorInfo) => any) {
    super('/analytics/info', aDomain, aDateRange, aCallback);
  }

  // endregion

  // region protected methods

  /**
   * @inheritDoc
   */
  protected process(aResponse: VisitorInfoResponseIO): Promise<void> {
    this.callback(this.convertVisitorInfoFromIO(aResponse));
    return Promise.resolve();
  }

  // endregion

  // region private methods

  /**
   * Converts a list of entries.
   *
   * @private
   */
  private convertEntriesFromIO<T>(
    anEntries: T[], aConvert: (entry: T) => ValueAndKeyAndLabelFields
  ): ValueAndKeyAndLabelFields[] {
    return anEntries.map(entry => aConvert(entry));
  }

  /**
   * Converts IO structure to internal data structure
   *
   * @private
   */
  private convertVisitorInfoFromIO(aResponse: VisitorInfoResponseIO): VisitorInfo {
    return {
      osEntries: this.convertEntriesFromIO(
        aResponse.os,
        entry => ({
          value: entry.metric,
          label: entry.dimensions.os + ' (' + entry.dimensions.device_category,
          key: entry.dimensions.os + entry.dimensions.device_category
        })
      ),
      resolutionEntries: this.convertEntriesFromIO(
        aResponse.resolution,
        entry => ({
          value: entry.metric,
          label: entry.dimensions.resolution + ' (' + entry.dimensions.device_category,
          key: entry.dimensions.resolution + entry.dimensions.device_category
        })
      ),
      mobileEntries:this.convertEntriesFromIO(
        aResponse.mobile,
        entry => ({
          value: entry.metric,
          label: entry.dimensions.mobile_info + ' (' + entry.dimensions.device_category,
          key: entry.dimensions.mobile_info + entry.dimensions.device_category
        })
      ),
      countryEntries: this.convertEntriesFromIO(
        aResponse.country,
        entry => ({
          value: entry.metric,
          label: entry.dimensions.country,
          key: entry.dimensions.country
        })
      ),
      languageEntries: this.convertEntriesFromIO(
        aResponse.language,
        entry => ({
          value: entry.metric,
          label: entry.dimensions.language,
          key: entry.dimensions.language
        })
      )
    }
  }

  // endregion
}

// endregion