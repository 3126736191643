import {GraphColor} from "../../../../types/enums/GraphColor";
import React from "react";
import {GraphColorTools} from "../../../../tools/GraphColorTools";

interface LegendColorProps {
  readonly color: GraphColor;
  readonly light: boolean;
}

export const LegendColor: React.FC<LegendColorProps> = ({color, light}) => {
  return (
    <div
      className={
        "w-3 h-3 rounded-full relative border-solid inline-block "
        + GraphColorTools.getBackgroundClass(color, light) + " "
        + GraphColorTools.getBorderClass(color, light) + " "
      }
    />
  );
}