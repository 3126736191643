import {TicketEntry} from "../../types/interfaces/data/TicketEntry";
import {DateAndWeekTools} from "./base/DateAndWeekTools";

export class TicketsTools extends DateAndWeekTools<TicketEntry> {
  factory(aDate: Date, aWeek: number): TicketEntry {
    return {
      date: aDate,
      week: aWeek,
      count: 0,
      replies: 0,
      time: 0
    };
  }

  reduce(aPrevious: TicketEntry, anEntry: Readonly<TicketEntry>): TicketEntry {
    aPrevious.count += anEntry.count;
    aPrevious.time += anEntry.time;
    aPrevious.replies += anEntry.replies;
    return aPrevious;
  }
}