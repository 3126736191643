import React from "react";
import {useAppSelector} from "../../../store/store";
import {DataTools} from "../../../tools/DataTools";
import {NumberType} from "../../../types/enums/NumberType";
import {mainController} from "../../../controllers/mainController";
import {UserLocation} from "../../../types/enums/UserLocation";
import {CurrentAndPreviousRow} from "../../../types/classes/CurrentAndPreviousRow";
import {CurrentAndPreviousTableDashboardPanel} from "../../panels/CurrentAndPreviousTableDashboardPanel";
import {GraphColor} from "../../../types/enums/GraphColor";
import {TransactionResultsTools} from "../../../tools/data/TransactionResultsTools";

export const TransactionResultsDashboard: React.FC = () => {
  const {dashboardEntries, loading, initializedDashboard} = useAppSelector(state => state.transactionResults);
  const tools = new TransactionResultsTools();
  const current = DataTools.reduceForDashboardCurrent(dashboardEntries, tools);
  const previous = DataTools.reduceForDashboardPrevious(dashboardEntries, tools);
  const rows = [
    new CurrentAndPreviousRow(
      {value: current.subscription.successCount},
      {value: previous.subscription.successCount},
      NumberType.Integer,
      'Subscriptions',
      GraphColor.Other1
    ),
    new CurrentAndPreviousRow(
      {value: current.recurring.successCount},
      {value: previous.recurring.successCount},
      NumberType.Integer,
      'Recurring',
      GraphColor.Other1
    ),
    new CurrentAndPreviousRow(
      {value: current.coin.successCount},
      {value: previous.coin.successCount},
      NumberType.Integer,
      'Coins',
      GraphColor.Other1
    ),
    null,
    new CurrentAndPreviousRow(
      {value: current.recurring.failCount + current.subscription.failCount + current.coin.failCount},
      {value: previous.recurring.failCount + previous.recurring.failCount + previous.coin.failCount},
      NumberType.Integer,
      'Total failed',
      GraphColor.Other1,
      true
    ),
  ];
  return (
    <CurrentAndPreviousTableDashboardPanel
      title="Transaction results"
      rows={rows}
      loading={loading || !initializedDashboard}
      onClick={() => mainController.setUserLocation(UserLocation.TransactionResults)}
    />
  );
};
