// region imports

import {UFSerialQueueAction} from "../../../UF/actions/UFSerialQueueAction";
import {UFCallbackAction} from "../../../UF/actions/UFCallbackAction";
import {setHistoryEntries, setHistoryRange, setLoading} from "../../../store/data/trialToSubscriptionsSlice";
import {store} from "../../../store/store";
import {DateRange} from "../../../types/classes/DateRange";
import {TrialToSubscriptionsLoadAction} from "./TrialToSubscriptionsLoadAction";

// endregion

// region exports

/**
 * An action that loads all data used for transaction results history.
 */
export class TrialToSubscriptionsHistoryAction extends UFSerialQueueAction {
  constructor(anAlways: boolean, aDateRange: DateRange) {
    if (anAlways || !aDateRange.isEqual(store.getState().trialToSubscriptions.historyRange)) {
      super(
        new UFCallbackAction(() => store.dispatch(setLoading(true))),
        new TrialToSubscriptionsLoadAction(aDateRange, entries => store.dispatch(setHistoryEntries(entries))),
        new UFCallbackAction(() => store.dispatch(setHistoryRange(aDateRange)))
      );
    }
    else {
      super();
    }
  }
}

// endregion