/**
 * How the text is wrapped.
 */
export enum TextPlacement {
  /**
   * Use a span tag
   */
  Inline,

  /**
   * Use a div tag
   */
  Block,

  /**
   * Use a p tag (when embedding other texts within, only inline can be used)
   */
  Paragraph
}