// region imports

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

// endregion

// region local

/**
 * Slice state
 */
interface UserInterfaceState {
  /**
   * Current data row/vertical line having the focus or -1 if there is none
   */
  focus: number;
}

/**
 * Initial slice state
 */
const initialState: UserInterfaceState = {
  focus: -1
};

// endregion

// region exports

/**
 * TransactionCounts related values. This slice gets saved in the local storage.
 */
export const userInterfaceSlice = createSlice({
  name: 'transaction-results',
  initialState,
  reducers: {
    setFocus: (state, action: PayloadAction<number>) => {
      state.focus = action.payload;
    },
  }
})

/**
 * The actions for the slice
 */
export const {
  setFocus
} = userInterfaceSlice.actions;

// endregion